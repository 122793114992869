export default function frequencyOverdue(verifyChecks, frequency, createdAt) {
    // console.log("createdAt: ", createdAt);
    let createdDate = new Date(createdAt)
    let days
    let difference
    let dagensDatum = new Date()

    if (verifyChecks.length > 0) {
        let senasteDatum = new Date(Math.max(...verifyChecks.map(e => new Date(e.date))));
        difference = dagensDatum.getTime() - senasteDatum.getTime()
        days = Math.floor(difference / (1000 * 3600 * 24));

    } else {
        difference = dagensDatum.getTime() - createdDate?.getTime()
        days = Math.floor(difference / (1000 * 3600 * 24));
    }


    switch (frequency) {
        case "Dagligen":
            if (days > 0) { return true } else return false
        case "Veckovis":
            if (days > 6) { return true } else return false
        case "Månadsvis":
            if (days > 30) { return true } else return false
        case "Kvartalsvis":
            if (days > 90) { return true } else return false
        case "Tertialsvis":
            if (days > 120) { return true } else return false
        case "Halvårsvis":
            if (days > 180) { return true } else return false
        case "Årligen":
            if (days > 364) { return true } else return false
        case "Två år":
            if (days > 364 * 2) { return true } else return false
        case "Tre år":
            if (days > 364 * 3) { return true } else return false
        case "Fyra år":
            if (days > 364 * 4) { return true } else return false
        case "Fem år":
            if (days > 364 * 5) { return true } else return false
        default:
            return false
    }

    // } else { return false }
}