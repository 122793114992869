import React, { useContext, useState } from "react";
import { UserContext } from "../../../../../contexts/UserContext";
import axios from "axios";
import styles from "./VerifySection.module.css";
import Modal from "./../../../../../modal/Modal";
import DatePicker from "react-datepicker";
import WarningModal from "./../../../../../modal/WarningModal";

export default function Verifysection({ data, refetch, status }) {
  const { activeUser } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [activeVerifyCheck, setActiveVerifyCheck] = useState(null);
  const [verifyCheckComment, setVerifyCheckComment] = useState("");
  const [attestDate, setAttestDate] = useState(new Date());
  const [asSuggestionBox, setAsSuggestionBox] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const onChangeAttestDate = (date) => { setAttestDate(new Date(date)); };
  const onChangeAsSuggestionBox = () => { setAsSuggestionBox(state => !state); };

  const submitVerifyCheck = async () => {
    await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/review/add-verify-check",
      {
        controllerId: data._id,
        verifyCheck: {
          user: activeUser.firstName + " " + activeUser.lastName,
          comment: verifyCheckComment,
          date: attestDate,
          asSuggestion: asSuggestionBox
        },
      },
      { headers: { authorization: "Bearer " + activeUser.token, }, }
    );
    setShowModal((status) => !status);
    setVerifyCheckComment("");
    setAsSuggestionBox(false)
    refetch();
  };

  const deleteVerifyCheck = async () => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/review/delete-verify-check", {
      headers: { authorization: "Bearer " + activeUser.token, },
      data: { controllerId: data._id, verifyCheckId: activeVerifyCheck._id, },
    });
    setShowModal(false);
    setShowWarningModal(false);
    setActiveVerifyCheck(null);
    refetch();
    return res.status;
  };

  return (
    <>
      <WarningModal
        passedFunction={() => { deleteVerifyCheck() }}
        warningTitle={"Vill du verkligen ta bort rapporteringen?"}
        warningText={"Allt arbete med rapporteringen kommer gå förlorat"}
        optionTextOne={"Ta bort"}
        optionTextTwo={"Ångra"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />
      <Modal
        activeVerifyCheck={activeVerifyCheck}
        open={showModal}
        onClose={() => {
          setActiveVerifyCheck(null);
          setShowModal(false);
        }}
      >
        {activeVerifyCheck === null ? (
          <div className={styles.commentVerifyCheck}>
            <h4>Avrapportera utförd kontroll</h4>
            <label>Datum för rapporteringstillfället</label>
            <div className={styles.fieldRow}>

              <DatePicker
                selected={attestDate}
                name="attestDate"
                onChange={onChangeAttestDate} className={styles.datePicker}
              />
            </div>
            <label>Kommentar för rapporteringen</label>
            <textarea
              name="description"
              type="textarea"
              onChange={(e) => setVerifyCheckComment(e.target.value)}
              className={`${styles.textArea} `}
            />

            <div style={{ marginBottom: "25px", cursor: "pointer" }} onClick={() => onChangeAsSuggestionBox()}>
              {asSuggestionBox
                ? <i className="far fa-check-square"></i>
                : <i className="far fa-square"></i>
              }
              <span className={styles.riskOptionDescr}>
                Markera som förbättringsförslag
              </span>
            </div>
            <div className={styles.buttonsContainer}>
              <input type="button" onClick={() => submitVerifyCheck()} value="Lägg till" className={styles.addVerifyCheckButton} />
              <input
                type="button"
                onClick={() => {
                  setAsSuggestionBox(false)
                  setActiveVerifyCheck(null);
                  setShowModal((status) => !status)
                }}
                value="Avbryt"
                className={styles.cancelVerifyCheckButton}
              />
            </div>
          </div>
        ) : (
          <div className={styles.commentVerifyCheck}>
            <div className={styles.verifyCheckHeaderMenu}>
              <h4>Avrapporterad kontroll</h4>
              <div className={styles.verifyCheckSubHeaderMenu} onClick={() => { setShowWarningModal(true) }} >
                <div style={{ marginRight: "10px", fontWeight: "600", fontSize: "14px" }}>Ta bort</div>
                <i style={{ color: "black", fontSize: "15px", marginTop: "3px" }} className={"fas fa-trash-alt"} ></i>
              </div>
            </div>
            <div style={{ marginTop: "5px" }}>Utförd {activeVerifyCheck.date.slice(0, 10)}</div>
            <div style={{ marginTop: "0px" }}>Utförd av {activeVerifyCheck.user}</div>
            <div style={{ marginTop: "15px" }}>
              <span style={{ marginRight: "15px" }}> Markerad som förbättringsförslag </span>
              {activeVerifyCheck.asSuggestion ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>}
            </div>
            <h5 style={{ marginTop: "35px", marginBottom: "5px" }}>Kommentar för rapporteringen:</h5>
            <textarea type="textarea" defaultValue={activeVerifyCheck.comment} className={styles.boxCommentVerifyCheck} />
            <div className={styles.buttonsContainer}>
              <input
                type="button"
                onClick={() => {
                  setAsSuggestionBox(false)
                  setShowModal((status) => !status)
                  setActiveVerifyCheck(null);
                }}
                value="Stäng"
                className={styles.cancelVerifyCheckButton}
              />
            </div>
          </div>
        )}
      </Modal>
      <div
        style={{
          padding: "0px",
          margin: "0px",
          height: "28px",
          marginBottom: "5px",
        }}
      >
        <h4>Utförda kontroller</h4>
        <i
          onClick={() => setShowModal(true)}
          style={{
            cursor: "pointer",
            color: "rgb(47, 100, 171)",
            position: "relative",
            top: "-41px",
            left: "150px",
            paddingLeft: "0px",
            marginRight: "0px",
            fontSize: "20px",
          }}
          className="fas fa-plus-circle"
        ></i>
      </div>
      {
        status === "success" ? (
          <div>
            <div id={styles.styleScroller} className={styles.verifyChecksContainer}>
              {data?.verifyChecks?.map((check) => {
                return (
                  <div
                    key={check._id}
                    className={styles.verifyListRow}
                    onClick={() => {
                      setActiveVerifyCheck(check);
                      setShowModal(true);
                    }}
                  >
                    <div>{check.date.slice(0, 10)}</div>
                    <div>{check.user}</div>
                    <div className={styles.barne}>{check.comment}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null
      }
    </>
  );
}