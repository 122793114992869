import React, { useState, useContext } from "react";
import { UserContext } from "../../../contexts/UserContext"
import styles from "../styles/RiskInfoCard.module.css";
import ViewCosoDiagram from "../../../components/view-coso-diagram/ViewCosoDiagram";
import axios from "axios";
import { AddButtonShadow } from '../../../styles/buttons'
import { useQuery } from "react-query";
import calculateRiskGroup from '../../../functions/calculateRiskGroup'
import FileOptionsModal from "./../../../modal/FileOptionsModal";
import FileUploadModal from "./../../../modal/FileUploadModal";
import PickFileModal from "./../../../modal/PickFileModal";
import WarningModal from "./../../../modal/WarningModal";

export default function Riskinfocard({ risk, setUpdateState, onlyIcp, mirrorFromIcp }) {
    const [hideInfoCard, setHideInfoCard] = useState(true);
    const [downloadFileUrl, setDownloadFileUrl] = useState(null);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [completeFileToUpload, setCompleteFileToUpload] = useState(null);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [fileWarningText, setFileWarningText] = useState("");
    const [showPickFileModal, setShowPickFileModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showFileOptionsModal, setShowFileOptionsModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [fileNameAsState, setFileNameAsState] = useState("")
    const { activeUser } = useContext(UserContext)

    const getCurrentSettings = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-current-portfolio-settings", {
            headers: { authorization: "Bearer " + activeUser.token, },
        });
        return res.data;
    };

    const { data: settingsData, status } = useQuery(["readCurrentsettings"], getCurrentSettings, {});

    const getFile = async (fileName) => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/file/get-file",
            { fileName },
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        );
        setDownloadFileUrl(res.data);
        return res.data;
    };

    const deleteFile = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/file/delete-file",
            { fileToDelete },
            {
                headers: { authorization: "Bearer " + activeUser.token, },
            }
        );

        if (res.status === 200 && risk.files.includes(fileToDelete)) {
            let testArray = risk.files.filter((e) => { return e; });
            let positionOfElement = testArray.indexOf(fileToDelete);
            testArray.splice(positionOfElement, 1);

            let updatedRiskResponse = await axios.put(
                process.env.REACT_APP_IC_SERVICE + "/risk/update-risk",
                { id: risk._id, payload: { files: testArray }, },
                { headers: { authorization: "Bearer " + activeUser.token, }, }
            );
            if (updatedRiskResponse.status === 200) { setUpdateState((state) => !state); }
        }
    };

    const uploadFile = async (e) => {
        e.preventDefault();
        const filePath = "risks/" + risk._id + "/description/"
        const formData = new FormData();

        if (fileToUpload !== null) { formData.append("file", fileToUpload.file); }

        formData.append("filePath", filePath);

        let klart = await axios.post(process.env.REACT_APP_IC_SERVICE + "/file/upload", formData, {
            headers: {
                ContentType: "multipart/form-data",
                authorization: "Bearer " + activeUser.token,
            },
        });

        let fileNameToAdd = filePath + fileToUpload.file.name;
        if (klart.status === 200 && !risk.files.includes(fileNameToAdd)) {
            await axios.put(
                process.env.REACT_APP_IC_SERVICE + "/risk/update-risk",
                {
                    id: risk._id,
                    payload: { files: [...risk.files, fileNameToAdd] },
                },
                {
                    headers: { authorization: "Bearer " + activeUser.token, },
                }
            );
            setUpdateState((state) => !state);
            setFileToUpload(null);
        }
    };

    const onFileChange = (e) => {
        setFileToUpload({ file: e.target.files[0] });
        setCompleteFileToUpload(e)
        setShowFileUploadModal(true)
    };

    return (
        <>
            <PickFileModal
                passedFunction={(e) => { onFileChange(e) }}
                warningTitle={"Bifoga fil"}
                warningText={"Välj en fil att ladda upp och koppla till den här risken"}
                optionTextOne={""}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={showPickFileModal}
                onClose={() => {
                    setShowPickFileModal(false)
                }}
            />
            <FileUploadModal
                passedFunction={() => {
                    if (risk.files.includes("risks/" + risk._id + "/description/" + fileToUpload.file.name)) {
                        setFileWarningText(`Det finns redan en fil med namnet ${fileToUpload.file.name}`)
                        setShowWarningModal(true)
                    } else { uploadFile(completeFileToUpload); }
                }}
                warningTitle={fileToUpload?.file?.name}
                warningText={"Filen kopplas till den här risken och du kan ta bort den närsomhelst."}
                optionTextOne={"Ladda upp"}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={showFileUploadModal}
                onClose={() => {
                    setFileToUpload(null);
                    setShowFileUploadModal(false)
                }}
            />
            <FileOptionsModal
                fileName={fileNameAsState}
                downloadFileUrl={downloadFileUrl}
                hideDeleteButton={(mirrorFromIcp && mirrorFromIcp) || onlyIcp ? true : false}
                deleteFunction={() => { deleteFile() }}
                open={showFileOptionsModal}
                onClose={() => {
                    setFileNameAsState("")
                    setShowFileOptionsModal(false)
                }}
            />
            <WarningModal
                passedFunction={() => { }}
                warningTitle={"Det gick inte ladda upp filen!"}
                warningText={fileWarningText}
                optionTextOne={"OK"}
                colour={"red"}
                open={showWarningModal}
                onClose={() => {
                    setShowWarningModal(false);
                    setFileWarningText("")
                    setFileToUpload(null)
                }}
            />

            {status === "success" ?
                <div className={onlyIcp ? styles.cardContainerIcp : styles.cardContainer}>
                    <div onClick={() => setHideInfoCard((state) => !state)} className={styles.infoContainer}>
                        <div className={styles.header}>{risk.name}</div>
                        <div style={{ marginBottom: "3px" }}>
                            {risk.organisation?.name} - {risk.department?.name}
                        </div>
                        <div style={{ marginBottom: "3px" }}>
                            Huvudprocess: {risk.category}
                        </div>
                        <div style={{ marginBottom: "3px" }}>
                            Delprocess: {risk.subCategory}
                        </div>
                        <div style={{ marginBottom: "3px" }}>
                            Kategori: {risk.process}
                        </div>
                        {risk.createdBy ?
                            <div style={{ marginBottom: "3px" }}>
                                Skapad av: {risk.createdBy?.firstName} {risk.createdBy.lastName}

                            </div>
                            : null}
                        <div>Skapad: {risk.createdAt.slice(0, 10)}</div>
                        <div className={`${settingsData.coso.size === 5 ? styles.largeCoso : styles.smallCoso}`} >
                            <ViewCosoDiagram scale={settingsData.coso.size}
                                maxConsequenceMakesRed={settingsData.coso.maxConsequenceRed}
                                maxProbabilityMakesRed={settingsData.coso.maxProbabilityRed}
                                consequence={risk.consequence}
                                probability={risk.probability}
                            // probability={3}
                            // consequence={3}
                            />
                        </div>
                    </div>
                    {hideInfoCard ? (
                        <div>
                            <div className={styles.descriptionContainer}>
                                <div style={{ marginTop: "0px" }} className={styles.subHeader}>Riskvärde: {risk.probability * risk.consequence}</div>
                                <div className={styles.subHeader}>Riskvärdesgrupp: {calculateRiskGroup(risk.probability, risk.consequence,
                                    settingsData?.riskGroupDefinitions, settingsData?.coso).group}</div>
                                {calculateRiskGroup(risk.probability, risk.consequence,
                                    settingsData?.riskGroupDefinitions, settingsData?.coso).rgd}
                                <div style={{ marginTop: "20px" }} className={styles.subHeader}>Beskrivning:</div>
                                <div>{risk.description}</div>
                            </div>

                            <div className={styles.hiddenContainer}>
                                <div className={styles.item}>
                                    <div className={styles.subHeader}>Sannolikhet: {risk.probability}</div>
                                    {risk.probabilityMotivation}
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.subHeader}>Konsekvens: {risk.consequence}</div>
                                    {risk.consequenceMotivation}
                                </div>
                            </div>
                            <div className={styles.appendix}>
                                <div className={styles.subHeader}>Bifogade filer:</div>
                                {risk.files.length > 0 ? (
                                    risk.files.map((riskFile, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={styles.listedFile}
                                                onClick={() => {
                                                    getFile(riskFile);
                                                    setFileToDelete(riskFile);
                                                    setShowFileOptionsModal(true)
                                                    let fileNameString = riskFile.slice(riskFile.lastIndexOf("/") + 1)
                                                    setFileNameAsState(fileNameString)
                                                }}
                                            >
                                                {riskFile.slice(riskFile.lastIndexOf("/") + 1)}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>Det finns inga bifogade filer.</div>
                                )}

                                {onlyIcp === false && mirrorFromIcp !== true ?
                                    <div className={styles.AddUploadButton}>
                                        <AddButtonShadow icp={false} icon="add" onClick={() => {
                                            setFileWarningText("")
                                            setFileToUpload(null)
                                            setShowPickFileModal(true)
                                        }}>Ladda upp</AddButtonShadow>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    ) : null}
                </div>
                : null}
        </>
    );
}