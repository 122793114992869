import React, { useContext } from "react";
import LogoutBar from "./components/LogoutBar";
import { UserContext } from "../../contexts/UserContext";
import logo from "../../media/kommunresurs-logo-header.svg";
import styles from "./TopBar.module.css";

const TopBar = () => {
    const { isLoggedIn } = useContext(UserContext);

    return (
        <div className={styles.topBarContainer}>
            {process.env.REACT_APP_COPE_WEB_TARGET === "kommunresurs" ?
                <>
                    <div>Kommunresurs</div>
                    <img className={styles.logoTitle} src={logo} alt="Kommunresurs" />
                </>
                :
                <>
                    <div>Cope</div>
                    <img className={styles.copeLogoTitle} src={logo} alt="Cope" />
                </>

            }
            <div className={styles.rightTitle}>Internkontroll</div>
            {isLoggedIn ? <LogoutBar /> : null}
        </div>
    );
};

export default TopBar;
