import React, { useState, useEffect } from "react";
import styles from "./Tooltip.module.css"

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);
    const [isMounted, setIsMounted] = useState(true)
    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [])

    const showTip = () => {
        timeout = setTimeout(() => {
            if (isMounted) { setActive(true) }
        }, props.delay || 200);
    };

    const hideTip = () => {
        clearInterval(timeout);
        if (isMounted) { setActive(false) }
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
            clearInterval(timeout);
            if (isMounted) { setActive(false) }
        };
    }, [timeout, isMounted])

    let styleLeft = `${styles.TooltipTip} ${styles.left}`
    let styleRight = `${styles.TooltipTip} ${styles.right}`

    return (
        <div
            className={styles.TooltipWrapper}
            onMouseEnter={() => showTip()}
            onMouseLeave={() => hideTip()}
        >
            {props.children}
            {active && (
                // <div className={`${styles.TooltipTip} ${styles.right}`}>
                <div className={props.direction === "left" ? styleLeft : styleRight} style={props.content.length > 20 ? { minWidth: "200px" } : null}>
                    {/* // <div className={props.direction === "left" ? styleLeft : styleRight} style={props.content.length > 60 ? { whiteSpace: "pre-wrap" } : { whiteSpace: "pre-wrap" }}> */}
                    {/* <div style={{ whiteSpace: "no-wrap" }}> */}
                    {props.content}
                    {/* </div> */}
                </div>
            )
            }
        </div >
    );
};

export default Tooltip;