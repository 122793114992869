import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SelectPeriod from "../../modules/SelectPeriod";
import { UserContext } from "../../contexts/UserContext";
// import "react-datepicker/dist/react-datepicker.css";
// import sv from 'date-fns/locale/sv';
import styles from "./styles/CreateInternalControlPlanPage.module.css";
import axios from "axios";
import { useQuery } from "react-query";
import { PageHeaderButton, SaveButton } from "../../styles/buttons"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { useForm } from "react-hook-form"
import DatePicker from "react-datepicker";
// import { setDefaultLocale } from "react-datepicker";
import WarningModal from "./../../modal/WarningModal";
import LoadingModal from "./../../modal/LoadingModal";
// setDefaultLocale(sv)

const CreateInternalControlPlanPage = () => {
  const history = useHistory();
  const { sideBar, activeUser } = useContext(UserContext);
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date());
  const [year, setYear] = useState("2020");
  const [periodTypeYear, setPeriodTypeYear] = useState(false);
  const [atOrganisation, setAtOrganisation] = useState("");
  const [dateError, setDateError] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  const [attestDate, setAttestDate] = useState(new Date());
  const onChangeAttestDate = (date) => {
    setAttestDate(new Date(date));
  };

  const readOrganisations = async () => {
    const res = await axios.get(
      process.env.REACT_APP_IC_SERVICE + "/organisation/all-objects",
      {
        headers: { authorization: "Bearer " + activeUser.token, },
      }
    );
    return res.data;
  };

  const readSettings = async () => {
    const res = await axios.get(
      process.env.REACT_APP_IC_SERVICE + "/setting/read-settings",
      {
        headers: { authorization: "Bearer " + activeUser.token, },
      }
    );
    if (isMounted) { setFadeInTrigger(true) }
    return res.data;
  };

  const { data, status } = useQuery(["readOrgToCreateIcp"], readOrganisations);
  const { data: settings } = useQuery(["getSettingsToCreateIcp"], readSettings);

  const { register, handleSubmit, formState: { errors } } = useForm()

  const submitRiskDocumentation = async (formData) => {
    let ress;
    let timePeriod;
    let newRiskDocumentation;

    if (!periodTypeYear) {

      const startdatum = startDate.toJSON();
      const slutdatum = stopDate.toJSON();
      ress = atOrganisation + " " + startdatum.slice(0, 10) + " - " + slutdatum.slice(0, 10);
      timePeriod = startdatum.slice(0, 10) + " - " + slutdatum.slice(0, 10);

      newRiskDocumentation = {
        attestDate: attestDate,
        name: ress,
        period: timePeriod,
        organisationName: atOrganisation,
        initCosoSize: settings.coso.size,
        startDate: startDate,
        stopDate: stopDate,
      };
    } else {
      ress = atOrganisation + " " + year;
      timePeriod = year
      newRiskDocumentation = {
        attestDate: attestDate,
        organisationName: atOrganisation,
        name: ress,
        period: timePeriod,
        initCosoSize: settings.coso.size,
        year: year,
      };
    }

    let sendThisData = { ...formData, ...newRiskDocumentation }
    if (!periodTypeYear && startDate >= stopDate) { setDateError(true) } else {
      try {
        let createNewPlan = await axios.post(
          process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/create-internalcontrolplan",
          sendThisData,

          {
            headers: {
              ContentType: "application/json",
              authorization: "Bearer " + activeUser.token,
            },
          }
        );
        if (createNewPlan.data.existsAlready) { setShowWarningModal(true) } else {

          history.push("/internalcontrolplans");
        }
      } catch (error) {
        console.error(error);
      }

    }
  };

  const setStartDateCallBack = (dataFromChild) => {
    setStartDate(dataFromChild);
  };

  const setStopDateCallBack = (dataFromChild) => {
    setStopDate(dataFromChild);
  };

  const setYearCallBack = (dataFromChild) => {
    setYear(dataFromChild);
  };

  const setPeriodTypeYearCallBack = (dataFromChild) => {
    setPeriodTypeYear(dataFromChild);
  };

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, []);

  return (
    <>
      <WarningModal
        passedFunction={() => { }}
        warningTitle={"Internkontrollsplanen finns redan!"}
        warningText={"Det finns redan en internkontrollsplan med samma period för denna organisation. Var god ändra tidsperiod."}
        optionTextOne={"OK"}
        colour={"blue"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />
      <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
        <LoadingModal open={!fadeInTrigger} />
        <div style={{ marginBottom: "40px" }}>
          <PageTitle>Skapa ny internkontrollplan</PageTitle>
          <PageDelimiter />
          <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Ångra</PageHeaderButton>
          <SaveButton icon="save" onClick={handleSubmit(submitRiskDocumentation)} >Spara</SaveButton>
        </div>
        <div style={{ width: "800px", marginBottom: "30px" }}>
          <div style={{ fontSize: "13px" }}>
            Filtrera planer efter organisation nedan. Klicka på frågetecknet för
            hjälp och beskrivning för hur arbetsflödet bör upprättas och användas
            på bästa sätt.
          </div>
        </div>

        <form onSubmit={handleSubmit(submitRiskDocumentation)}>
          {status === "success" ? (
            <select
              style={{ width: "300px", padding: "2px", marginBottom: "18px" }}
              name="organisation"
              className={styles.selectBox}
              {...register('organisation', { required: "Välj organisation" })}
              onChange={(e) => {
                setAtOrganisation(data.find((obj) => obj._id === e.target.value)?.name);
              }}
            >
              <option value="">Välj organisation</option>
              {data
                .filter((org) => {
                  return org.level === 1;
                })
                .map((d) => {
                  return (
                    <option key={d._id} value={d._id}>
                      {d.name}
                    </option>
                  );
                })}
            </select>
          ) : null}
          <SelectPeriod
            startDate={setStartDateCallBack}
            stopDate={setStopDateCallBack}
            year={setYearCallBack}
            periodTypeYear={setPeriodTypeYearCallBack}
          />
          <div className={styles.createPortfolioContainer}>
            <label style={{ fontSize: "14px", marginBottom: "2px" }}>Attestdatum: </label>
            {/* <input name="attestDate" style={{ width: "165px", marginBottom: "25px", marginTop: "5px", minHeight: "18px", }} type="date" ref={register()} /> */}
            <DatePicker
              selected={attestDate}
              name="attestDate"
              className={styles.dateFromPicker}
              onChange={onChangeAttestDate}
            />
            <label style={{ marginTop: "25px", fontSize: "14px" }}>Beskrivning: </label>
            <textarea
              name="description"
              {...register('description')}
              type="textarea"
              className={styles.createPortfolioItem}
              required
              style={{
                width: "520px",
                minHeight: "120px",
                lineHeight: "20px",
                fontFamily: "Arial",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #bbb"
              }}
            />
            <div style={{ color: "red" }}>
              {Object.keys(errors).length > 0 ? <p>{errors[Object.keys(errors)[0].toString()].message}</p> : null}
              {dateError ? <p style={{ color: "red" }}>Slutdatum måste vara senare än startdatum</p> : null}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateInternalControlPlanPage;