import React from 'react'
import styles from './styles/TopTenRisks.module.css'
import { useHistory } from 'react-router-dom'

export default function TopTenRisks({ data, activeIcp, icpName }) {
    const history = useHistory();

    return (
        <div className={styles.mainContainer}>
            <div className={styles.ContainerTitle}>Topplistan - 10 risker med högst riskvärde</div>
            <div className={styles.riskRow}>
                <div className={`${styles.largeColumn} ${styles.riskHeader}`}>Risk</div>
                <div className={`${styles.largeColumn} ${styles.riskHeader}`}>Avdelning</div>
                <div className={`${styles.largeColumn} ${styles.riskHeader}`}>Kategori</div>
                <div className={`${styles.largeColumn} ${styles.riskHeader}`}>Process</div>
                <div className={`${styles.smallColumn} ${styles.riskHeader}`}>RV</div>
                <div className={`${styles.smallColumn} ${styles.riskHeader}`}>S</div>
                <div className={`${styles.smallColumn} ${styles.riskHeader}`}>K</div>
            </div>
            {
                data?.map((risk, index) => {
                    return (
                        <div className={styles.riskRow} key={index}>
                            <div className={styles.largeColumnRiskName} onClick={() => {
                                if (activeIcp) {
                                    history.push("/show-icp-risk", { riskId: risk._id, icpRiskId: risk.icpRiskId, icpName: icpName })
                                } else {
                                    history.push("/show-risk", { riskId: risk._id, onlyIcp: false, mirrorIcp: false })
                                }
                            }
                            }>{risk.name.length > 30 ? risk.name.slice(0, 32) + "..." : risk.name}</div>
                            <div className={styles.largeColumn}>{risk.department.name}</div>
                            <div className={styles.largeColumn}>{risk.process}</div>
                            <div className={styles.largeColumn}>{risk.category}</div>
                            <div className={styles.smallColumn}>{risk.probability * risk.consequence}</div>
                            <div className={styles.smallColumn}>{risk.probability}</div>
                            <div className={styles.smallColumn}>{risk.consequence}</div>
                        </div>
                    )
                }
                )
            }
        </div >
    )
}
