import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton } from '../../styles/buttons'
import axios from "axios"
import { useQuery } from "react-query";
import LoadingModal from "./../../modal/LoadingModal";

const AttestSummaryPage = () => {
    const history = useHistory()
    const { sideBar, activeUser } = useContext(UserContext)
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [isMounted, setIsMounted] = useState(true)

    const readIcpData = async () => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/read-icp-attest-data",
            { icpId: history.location.state.icpId },
            {
                headers: { authorization: "Bearer " + activeUser.token, },
            }
        );
        if (isMounted) { setFadeInTrigger(true) }

        return res.data;
    };
    const { data: attestList, status } = useQuery(["getIcpAttestListData3"], readIcpData);

    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, []);

    return (
        <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <>
                <PageTitle>Sammanställning avrapport för {history.location.state.icpName} </PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Gå tillbaka</PageHeaderButton>
            </>

            <div style={{ width: "750px", marginBottom: "50px", marginTop: "40px" }}>
                <div className="bodyText">
                    Här visas en sammanställd avrapportering för den här internkontrollplanen. Endast klarmarkerade rapporter visas här.
                </div>
            </div >

            {status === "success" && attestList.length > 0 ?
                <div style={{ marginBottom: "60px", fontSize: "14px" }}>
                    {attestList.filter(lino => { return lino.done }).map((attestRow, index) => {
                        return <div style={{ backgroundColor: "#white", width: "900px" }} key={index}>
                            <div style={{ fontWeight: "600" }}>{attestRow.name} - {attestRow.attestedBy} - {attestRow.attestDate.slice(0, 10)}</div>
                            <div style={{ marginBottom: "30px", fontSize: "13px" }}>{attestRow.comment?.length > 0 ? attestRow.comment : "Finns ingen kommentar."}</div>
                        </div>
                    })}
                </div>
                : null
            }
        </div >
    )
}

export default AttestSummaryPage