import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton } from '../../styles/buttons'
import axios from "axios";
import { useQuery } from "react-query";
import RiskInfoCard from "./components/RiskInfoCard";
import ListControllers from "./components/ListControllers";
import ListReviews from "./components/ListReviews";
import CreateController from "./components/CreateController";
import CreateReview from "./components/CreateReview";
import IcpInfoCard from "./components/IcpInfoCard";
import styles from "./styles/ShowRiskPage.module.css";
import LoadingModal from "./../../modal/LoadingModal";
import ExportOneRiskModal from "./../../modal/ExportOneRiskModal"
// import { exportAsPdf } from "../../functions/exportToFile/exportPdf"
import { exportOneRisk } from "../../functions/exportToFile/exportOneRisk"
import { exportAsExcel } from "../../functions/exportToFile/exportExcel"

const ShowRiskPage = () => {
    const [updateState, setUpdateState] = useState(false)
    const history = useHistory()
    const { sideBar, activeUser } = useContext(UserContext)
    const [showControllerForm, setShowControllerForm] = useState(false);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [isMounted, setIsMounted] = useState(true)
    const [showExportModal, setShowExportModal] = useState(false)

    const getCurrentSettings = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-current-portfolio-settings", {
            headers: { authorization: "Bearer " + activeUser.token, },
        });
        return res.data;
    }

    const { data: settingsData } = useQuery(["readCurrentsettings"], getCurrentSettings, {});

    const readOneRisk = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/risk/one",
            { id: history.location.state.riskId },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        if (isMounted) { setFadeInTrigger(true) }
        return res.data;
    };

    const { data: riskData, status, isError, refetch } = useQuery(["getOneRiskToRp"], readOneRisk, {
        cacheTime: 0,
    });

    const editRiskRequest = async () => {
        const res = await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/risk/update-risk",
            {
                id: riskData._id,
                payload: { icp: !riskData.icp, icpBy: activeUser.firstName + " " + activeUser.lastName, icpDate: Date.now() },
            },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        setUpdateState((status) => !status);
        return res;
    };

    useEffect(() => {
        refetch();
    }, [updateState, refetch]);

    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, []);

    useEffect(() => {
        if (isError) {
            history.push("/", { from: "location", autoLogOut: true })
        }
    }, [isError, history])

    return (
        <div style={{ width: "1200px", paddingBottom: "40px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            {status === "success" ?
                <>
                    <PageTitle>Riskvy för {riskData.name}</PageTitle>
                    <PageDelimiter />
                    <div style={{ marginBottom: "60px" }}>

                        <PageHeaderButton icon="undo" onClick={() => { history.goBack() }}>Gå tillbaka</PageHeaderButton>
                        <PageHeaderButton icon="save" onClick={() => { setShowExportModal(true) }}>Exportera</PageHeaderButton>
                        {history?.location?.state?.mirrorIcp !== true ?
                            <>
                                <PageHeaderButton icon="cog" onClick={() => history.push("/edit-risk", {
                                    riskData: riskData
                                })}>Ändra / Ta bort</PageHeaderButton>
                                <PageHeaderButton icon={riskData.icp ? "toggleOn" : "toggleOff"}
                                    onClick={() => { editRiskRequest() }}
                                >Internkontrollplan</PageHeaderButton>
                            </>
                            : null
                        }
                    </div>

                    <ExportOneRiskModal
                        passedFunction={(fileType, extendedList) => {
                            let title = history.location.state.onlyIcp ? history.location.state.icpName : ""
                            let settings = { riskportfolio: !history.location.state.onlyIcp, active: false, extended: extendedList, title }

                            switch (fileType) {
                                case "pdf":
                                    // exportAsPdf([riskData], settings, history.location.state.settingsData, activeUser.customer)
                                    exportOneRisk([riskData], settings, settingsData, activeUser.customer)
                                    break;
                                case "excel":
                                    exportAsExcel([riskData], settings)
                                    break;
                                default:
                                    break;
                            }
                        }}
                        warningTitle={"Skapa rapport av risk!"}
                        warningText={"Välj filformat och om du vill ha utvecklad vy eller inte."}
                        optionTextOne={"Ladda ner"}
                        optionTextTwo={"Avbryt"}
                        colour={"blue"}
                        open={showExportModal}
                        onClose={() => {
                            setShowExportModal(false);
                        }}
                    />

                    <div id="riskInfo" className={styles.flexRowContainer}>
                        <div>
                            {history?.location?.state?.mirrorIcp ? (
                                <div className={styles.portfolioContainer} >
                                    <div className={styles.portfolioIconChild}><i className={"fas fa-briefcase fa-lg"}></i></div>
                                    <div className={styles.portfolioTextContainer}>
                                        <div className={styles.portfolioHeaderChild}>{history.location.state.icpName} </div>
                                    </div>
                                </div>
                            ) : null}

                            <RiskInfoCard
                                setUpdateState={setUpdateState}
                                risk={riskData}
                                onlyIcp={false}
                                mirrorFromIcp={history?.location?.state?.mirrorIcp ? true : false}
                            />
                            <ListControllers risk={riskData} setUpdateState={setUpdateState}
                                setShowReviewForm={setShowReviewForm}
                                setShowControllerForm={setShowControllerForm}
                                fromIcp={false}
                                mirrorFromIcp={history?.location?.state?.mirrorIcp ? true : false}
                            />
                            {riskData.icp ? (
                                <>
                                    <IcpInfoCard risk={riskData} />
                                    <ListReviews risk={riskData} setUpdateState={setUpdateState}
                                        setShowReviewForm={setShowReviewForm}
                                        setShowControllerForm={setShowControllerForm}
                                        mirrorFromIcp={history?.location?.state?.mirrorIcp ? true : false}
                                    />
                                </>
                            ) : null}
                        </div>
                        <div>
                            {showControllerForm ? (
                                <CreateController setUpdateState={setUpdateState}
                                    riskOrg={riskData.organisation}
                                    riskControllers={riskData.controllers.map((control) => { return control.name })}
                                    riskDep={riskData.department}
                                    riskId={riskData._id} setShowControllerForm={setShowControllerForm} />
                            ) : null}

                            {showReviewForm ? (
                                <CreateReview setUpdateState={setUpdateState}
                                    riskOrg={riskData.organisation}
                                    riskControllers={riskData.controllers.map((control) => { return control.name })}
                                    riskDep={riskData.department}
                                    riskId={riskData._id} setShowReviewForm={setShowReviewForm}
                                />
                            ) : null}
                        </div>
                    </div>
                </>
                : null
            }
        </div>
    )
}

export default ShowRiskPage