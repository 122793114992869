import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import "./sidebar.css";

let activeStyle = {
    backgroundColor: "#aaa",
    fontWeight: "600",
    color: "#111"
}

const SideBar = () => {
    const { activeUser, sideBar, setSideBar, sideBarActiveItem, setSideBarActiveItem } = useContext(UserContext);

    return (
        <nav>
            <div style={{ zIndex: "20" }} className={`sidenav ${sideBar ? "" : "main-no-sidebar "} `}>
                <div className={`side-bar-footer ${sideBar ? '' : 'hide-footer '}`}>
                    <div>Version {process.env.REACT_APP_RELEASE}</div>
                    <div>Cope Business Sweden AB © {new Date().getFullYear()}</div>
                </div>
                <div className={`welcome-container ${sideBar ? "" : "welcome-container-hide "} `}>
                    <i className="fas fa-user-circle welcome-container-icon"></i>

                    <div className="welcome-container-greeting">Välkommen</div>
                    <div>
                        {activeUser.firstName} {activeUser.lastName}
                    </div>
                    <hr className="welcome-container-hr" />
                </div>

                <div>
                    <i
                        onClick={() => {
                            setSideBar(!sideBar);
                        }}
                        className={`toggleSideBar ${sideBar ? "fas fa-arrow-left" : "fas fa-arrow-right "} `}
                    ></i>
                    <div className="circleStrip">
                        <div className="t1"></div>
                        <div className="t2">
                            <div className="quarter-circle-top-left"></div>
                        </div>
                        <div className="t3"></div>
                    </div>
                </div>

                <ul className={`sidebar-item ${sideBar ? "sideBarShow" : "sideBarHide"} `}>
                    <li className="">
                        <Link to="/hem"
                            style={sideBarActiveItem === "home" ? activeStyle : null}
                            onClick={() => setSideBarActiveItem("home")}
                        >
                            <i className="fas fa-home sidebar-icon"></i>
                            <p>Hem</p>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/organisation" style={sideBarActiveItem === "organisation" ? activeStyle : null}
                            onClick={() => setSideBarActiveItem("organisation")}>
                            <i className="fas fa-sitemap sidebar-icon" style={{ paddingLeft: "2px" }}></i>
                            <p>Organisation</p>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/riskportfolio" style={sideBarActiveItem === "riskportfolio" ? activeStyle : null}
                            onClick={() => setSideBarActiveItem("riskportfolio")}
                        >
                            <i className="fas fa-briefcase sidebar-icon "></i>
                            <p>Riskportfölj</p>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/internalcontrolplans" style={sideBarActiveItem === "internalcontrolplans" ? activeStyle : null}
                            onClick={() => setSideBarActiveItem("internalcontrolplans")}>
                            <i className="fas fa-folder sidebar-icon "></i>
                            <p>Internkontrollplaner</p>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/uppgifter" style={sideBarActiveItem === "todo" ? activeStyle : null}
                            onClick={() => setSideBarActiveItem("todo")}>
                            <i className="fas fa-tasks sidebar-icon"></i>
                            <p>Uppgifter</p>
                        </Link>
                    </li>
                    <li className="">
                        <Link to="/användare" style={sideBarActiveItem === "användare" ? activeStyle : null}
                            onClick={() => setSideBarActiveItem("användare")}>
                            <i className="fas fa-user-friends sidebar-icon"></i>
                            <p>Användare</p>
                        </Link>
                    </li>
                    {activeUser.admin ?
                        <li className="">
                            <Link to="/admin" style={sideBarActiveItem === "admin" ? activeStyle : null}
                                onClick={() => setSideBarActiveItem("admin")}>
                                <i className="fas fa-user-cog sidebar-icon"></i>
                                <p>Admin</p>
                            </Link>
                        </li>
                        : null}
                </ul>
            </div>
        </nav >
    );
};

export default SideBar;
