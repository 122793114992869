import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import UserContextProvider from "./contexts/UserContext"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import App from "./App"

let pageTitle
if (process.env.REACT_APP_COPE_WEB_TARGET === "kommunresurs") {
    pageTitle = "Kommunresurs Internkontroll"
} else if (process.env.REACT_APP_COPE_WEB_TARGET === "cope") {
    pageTitle = "Cope Internkontroll"
}
document.title = pageTitle

const queryClient = new QueryClient()

createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <BrowserRouter>
            <UserContextProvider>
                <QueryClientProvider client={queryClient}>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </UserContextProvider>
        </BrowserRouter>
    </React.StrictMode>
)
