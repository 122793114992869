import React from 'react'
import styles from './styles/SuggestionsAndDeviationAnalyze.module.css'
import { useHistory } from "react-router-dom";

export default function ImprovementSuggesttions({ riskData }) {
    const history = useHistory()
    let risksWithSuggestions = riskData.map((risk) => {
        let riskReviews = risk["controllers"]
        if (riskReviews.length > 0) {
            let reviewsToRisks = []
            for (const rev of riskReviews) {
                if (rev.verifyChecks.length > 0) {
                    let suggestionsToReviews = []
                    for (const vCheck of rev.verifyChecks) {
                        if (vCheck.asSuggestion) {
                            suggestionsToReviews.push(vCheck)
                        }
                    }

                    if (suggestionsToReviews.length > 0) {
                        reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                    }
                }
            }

            if (reviewsToRisks.length > 0) {
                return {
                    riskName: risk.name, reviewsWithSuggestions: reviewsToRisks,
                    orgRiskId: risk._id, departmentName: risk.department.name
                }
            } else return null

        } else return null

    }).filter(element => { return element !== null })

    const renderSuggestions = (suggestions) => {
        let suggestionToRender = suggestions.map((suggestion, index) => {
            return <li key={index}><div className={styles.suggestionNameRow} >
                <div style={{ fontWeight: "600" }}>Förbättringsförslag: {suggestion.date.slice(0, 10)} - {suggestion.user}</div>
                <div style={{ marginBottom: "10px" }}> {suggestion.comment}</div>
            </div></li>
        })
        return suggestionToRender
    }

    const renderControllers = (reviewsWithSuggestions) => {
        let reviewToRender = reviewsWithSuggestions.map((review, index) => {
            return <div key={index} className={styles.reviewNameRow}>
                <div className={styles.riskNameRow2} style={{ marginBottom: "6px" }}>Riskhanteringsåtgärd: {review.reviewName}</div>
                <ul>{renderSuggestions(review.suggestions)}</ul>
            </div>
        })
        return reviewToRender
    }

    const renderRisks = () => {
        let riskToRender = risksWithSuggestions.map((risk, index) => {

            return <div key={index} className={styles.riskDataContainer}><div className={styles.riskNameRow} onClick={() => {
                history.push("/show-risk", {
                    riskId: risk.orgRiskId
                })
            }}>{risk.riskName} - {risk.departmentName}</div>{renderControllers(risk.reviewsWithSuggestions)}</div>
        })
        return riskToRender
    }
    return (
        <div className={styles.mainContainer}>
            <div className={styles.ContainerTitle}>Förbättringsförslag</div>
            {renderRisks()}
        </div >
    )
}