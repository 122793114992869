import { jsPDF } from 'jspdf'
import { kommunresursLogo } from './logotypes/kommunresursLogo'
import { ltnbdLogo } from './logotypes/ltnbdLogo'
// import { calendarImageBlack } from './calendarImageBlack'
import { calendarImageRed } from './calendarImageRed'
import { checkImageBlack } from './checkImageBlack'
import calculateRiskGroupColor from '../calculateRiskGroupColorRGB'

const fontName = 'helvetica'
const bulletItem = '\u2022  '

let fileName = null

export const exportAsPdf = (data, settings, settingsData, customer) => {
    if (!data || !settings) return null

    const createPageHeader = () => {
        if (customer === 'ltnbd') {
            pdf.addImage(ltnbdLogo, 'PNG', x - 2, y + 3, 23, 15)
        } else {
            pdf.addImage(kommunresursLogo, 'JPG', x - 2, y + 3, 23, 15)
        }
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(8)
        pdf.text(x + 240, y + 17, 'Rapport uttagen ' + pdf.getCreationDate().slice(2, 10).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'))
    }

    const createPageFooter = () => {
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(8)

        const numberOfPages = pdf.getNumberOfPages()

        let offsetTotalPage = 0
        if (numberOfPages > 9) {
            offsetTotalPage = 3;
        } else if (numberOfPages > 99) {
            offsetTotalPage = 6;
        }

        for (let page = 0; page < numberOfPages; page++) {
            pdf.setPage(page)
            let offsetCurrentPage = 0
            if (page > 9) {
                offsetCurrentPage = 3;
            } else if (page > 99) {
                offsetCurrentPage = 6;
            }
            let totalOffset = offsetTotalPage + offsetCurrentPage
            pdf.text((x + 255 - totalOffset), y + 190, 'Rapportsida ' + pdf.getCurrentPageInfo().pageNumber + ' / ' + numberOfPages)
        }
    }

    const createPageTitle = (pageTitle) => {
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(10)
        pdf.text(x, y, pageTitle)
        // pdf.setLineWidth(0.05)
        // pdf.line(10, y + 4, 286, y + 4)
    }

    const createListHeader = (type) => {
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(8)

        if (type === 'rp') {
            const listHeader = ['Risker', 'Huvudprocess', 'Delprocess', 'Kategori', 'Avdelning', 'AVR', 'Status', 'RV', 'IKP', 'RHÅ']
            listHeader.forEach(item => {
                if (item === 'Risker') { y += 12 }
                if (item === 'Huvudprocess') { x += 50 }
                if (item === 'Delprocess') { x += 40 }
                if (item === 'Kategori') { x += 40 }
                if (item === 'Avdelning') { x += 40 }
                if (item === 'AVR') { x += 40 }
                if (item === 'Status') { x += 20 }
                if (item === 'RV') { x += 20 }
                if (item === 'IKP') { x += 10 }
                if (item === 'RHÅ') { x += 10 }
                pdf.text(x, y, item)
            })
        }

        if (type === 'ikp-aktiv') {
            const listHeader = ['Risker', 'Huvudprocess', 'Delprocess', 'Kategori', 'Avdelning', 'AVR', 'Status', 'RV', 'Kontroller']
            listHeader.forEach(item => {
                if (item === 'Risker') { y += 12 }
                if (item === 'Huvudprocess') { x += 50 }
                if (item === 'Delprocess') { x += 40 }
                if (item === 'Kategori') { x += 40 }
                if (item === 'Avdelning') { x += 40 }
                if (item === 'AVR') { x += 40 }
                if (item === 'Status') { x += 20 }
                if (item === 'RV') { x += 20 }
                if (item === 'Kontroller') { x += 10 }
                pdf.text(x, y, item)
            })
        }

        if (type === 'ikp-väntande') {
            const listHeader = ['Risker', 'Huvudprocess', 'Delprocess', 'Kategori', 'Avdelning', 'RV', 'Kontroller']
            listHeader.forEach(item => {
                if (item === 'Risker') { y += 12 }
                if (item === 'Huvudprocess') { x += 50 }
                if (item === 'Delprocess') { x += 40 }
                if (item === 'Kategori') { x += 40 }
                if (item === 'Avdelning') { x += 40 }
                if (item === 'RV') { x += 82 }
                if (item === 'Kontroller') { x += 10 }
                pdf.text(x, y, item)
            })
        }

        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(8)
    }

    const computeMultipleLines = (str, maxSize) => {
        let lineArray = []

        if (str.length <= maxSize) {
            lineArray.push(str)
            return lineArray
        }

        // Put words in array, excluding white spaces
        let strArray = str.split(/\s+/)

        let currentLine = null
        let newLine = null
        for (const word of strArray) {
            currentLine = newLine
            if (newLine) { newLine = newLine.concat(' ', word) }
            else { newLine = word }

            if (newLine?.length > maxSize) {
                lineArray.push(currentLine)
                newLine = word
            }
        }

        lineArray.push(newLine)
        return lineArray
    }

    const addRiskGroupColor = (x, y, probability, consequence, settingsData) => {
        const color = calculateRiskGroupColor(probability, consequence, settingsData)
        const regExp = /^rgb\((\d+), (\d+), (\d+)\)/
        const match = regExp.exec(color)
        if (match) {
            const red = Number(match[1])
            const green = Number(match[2])
            const blue = Number(match[3])
            pdf.setFillColor(red, green, blue)
            pdf.roundedRect(x - 3, y - 1.5, 1.5, 1.5, .4, .4, 'F')
        }
    }

    const addRiskGroupAndStatusColor = (x, y, probability, consequence, settingsData, status) => {
        const color = calculateRiskGroupColor(probability, consequence, settingsData)
        const regExp = /^rgb\((\d+), (\d+), (\d+)\)/
        const match = regExp.exec(color)
        if (match) {
            const red = Number(match[1])
            const green = Number(match[2])
            const blue = Number(match[3])
            pdf.setFillColor(red, green, blue)
            pdf.roundedRect(x - 7, y - 1.5, 1.5, 1.5, .4, .4, 'F')
        }

        if (status === 1) {
            pdf.setFillColor(84, 180, 122)
        } else if (status === 2) {
            pdf.setFillColor(252, 255, 102)
        } else if (status === 3) {
            pdf.setFillColor(231, 37, 37)
        } else {
            pdf.setFillColor(255, 255, 255)
        }
        pdf.circle(x - 3, y - 0.78, 0.78, 'FD')
    }

    // const createStrikeThroughText = (x, y, text) => {
    //   pdf.text(x, y, text)
    //   const textWidth = pdf.getTextWidth(text)
    //   pdf.setLineDash()
    //   pdf.line(x, y-0.8, x+textWidth, y-0.8)
    // }

    // const addRiskName = (x, y, name, deleted) => {
    //   if (deleted) {
    //     createStrikeThroughText(x, y, name)
    //   } else {
    //     pdf.text(x, y, name)
    //   }
    // }

    const createNewPage = (type) => {
        pdf.addPage()

        x = 10
        y = 10
        createPageHeader()
        y += 20

        createListHeader(type)
        y += 2
    }

    const options = { orientation: 'landscape', unit: 'mm' }
    const pdf = new jsPDF(options)
    pdf.setTextColor(40, 40, 40)

    const maxPageHeight = pdf.getPageHeight() - 30
    pdf.setLineWidth(0.05)

    let x = 10
    let y = 10
    createPageHeader()

    if (settings.riskportfolio) { // Riskportfölj
        const type = 'rp'
        const pageTitle = 'Riskportfölj'
        const maxSize = 48
        // const repeated = true

        y += 30
        createPageTitle(pageTitle)
        createListHeader(type)
        y += 2

        let listData = data
        // let listData = []
        // if (repeated) {
        //     for (let times = 0; times < 800; times++) {
        //         if (times % 3 === 0) { listData.push(data[2]) }
        //         if (times % 2 === 0) { listData.push(data[1]) }
        //         else { listData.push(data[2]) }
        //     }
        // }
        // else { listData = data }

        listData.forEach((risk, index) => {
            const name = risk.name.slice(0, maxSize)
            const process = risk.category.slice(0, maxSize - 10)
            const subProcess = risk.subCategory.slice(0, maxSize - 10)
            const category = risk.process.slice(0, maxSize - 10)
            const department = risk.department["name"].slice(0, maxSize - 10)
            const avrDate = risk.latestDeviationReport?.slice(0, 10)
            const status = risk.calendar
            const riskValue = (risk.consequence * risk.probability).toString()
            const icp = risk.icp
            const numMeasures = risk.controllers?.filter(controll => !controll.icp).length

            if (y + 13 > maxPageHeight) createNewPage(type)

            x = 10
            y += 5

            pdf.setFontSize(7)

            addRiskGroupColor(x, y, risk.probability, risk.consequence, settingsData)
            // addRiskName(x, y, name, risk.deleted); x += 50
            pdf.text(x, y, name); x += 50
            pdf.text(x, y, process); x += 40
            pdf.text(x, y, subProcess); x += 40
            pdf.text(x, y, category); x += 40
            pdf.text(x, y, department); x += 40
            pdf.text(x, y, avrDate || ''); x += 20
            if (status) {
                pdf.addImage(calendarImageRed, 'PNG', x + 3.5, y - 2.3, 2.5, 2.5); x += 20
            } else {
                x += 20
            }
            pdf.text(x + 1.5, y, riskValue, 'center'); x += 10
            if (icp) {
                pdf.addImage(checkImageBlack, 'PNG', x + 0.7, y - 2.2, 2.7, 2.7); x += 10
            } else {
                x += 10
            }
            pdf.text(x + 2.5, y, numMeasures.toString(), 'center'); x += 10

            if (settings.extended) { // Riskportfölj, utfälld
                const description = risk.description ? risk.description : "-"
                const measureList = risk.controllers?.filter(controll => !controll.icp)

                const descriptionLines = computeMultipleLines(description, maxSize + 90)

                const d_len = descriptionLines?.length
                const m_len = numMeasures
                const numLines = Math.max(d_len, m_len)

                x = 10
                y += 5
                pdf.setFontSize(6.5)
                pdf.text(x, y, 'Beskrivning:')
                x += 170
                pdf.text(x, y, 'Riskhanteringsåtgärder')

                x = 10
                pdf.setFontSize(6)
                for (let line = 0; line < numLines; line++) {
                    if (y + 3 > maxPageHeight) {
                        createNewPage(type)
                        y += 2
                        pdf.setFontSize(6)
                    }
                    x = 10
                    y += 3
                    if (descriptionLines[line])
                        pdf.text(x, y, descriptionLines[line])
                    x += 170
                    if (measureList[line])
                        pdf.text(x, y, bulletItem + measureList[line].name)
                }
                if (measureList.length === 0)
                    pdf.text(x, y, "-")
            }
            y += 2
            pdf.setLineDash([.5], 0)
            pdf.line(10, y, 287, y)

            if (settings.extended) {
                if (!(index === listData.length - 1)) {
                    createNewPage(type)
                }
            } else {
                if (y > maxPageHeight) createNewPage(type)
            }
        })
        x = 10
        y = 10
        createPageFooter()

        if (settings.extended) { fileName = 'Riskportfölj_utfälld_lista.pdf' }
        else { fileName = 'Riskportfölj.pdf' }
        pdf.save(fileName)
    } else { // IKP
        if (settings.active) { // IKP, aktiv
            const type = 'ikp-aktiv'
            const pageTitle = 'Aktiv internkontrollplan ' + settings.title
            const maxSize = 48
            // const repeated = true

            y += 30
            createPageTitle(pageTitle)
            createListHeader(type)
            y += 2
            let listData = data
            listData.forEach((risk, index) => {
                const name = risk.name.slice(0, maxSize - 10)
                const process = risk.category.slice(0, maxSize - 10)
                const subProcess = risk.subCategory.slice(0, maxSize - 10)
                const category = risk.process.slice(0, maxSize - 10)
                const department = risk.department["name"].slice(0, maxSize - 10)
                const avrDate = risk.latestDeviationReport?.slice(0, 10)
                const status =
                    risk.status === 0 ? 'Ej rapporterad' :
                        risk.status === 1 ? 'Klar' :
                            risk.status === 2 ? 'Avvaktande' :
                                risk.status === 3 ? 'Varningar' : '-'
                const riskValue = (risk.consequence * risk.probability).toString()
                const numControls = risk.reviews?.length

                if (y + 13 > maxPageHeight) createNewPage(type)

                x = 10
                y += 5

                pdf.setFontSize(7)

                addRiskGroupAndStatusColor(x, y, risk.probability, risk.consequence, settingsData, risk.status)
                // addRiskName(x, y, name, risk.deleted); x += 50
                pdf.text(x, y, name); x += 50
                pdf.text(x, y, process); x += 40
                pdf.text(x, y, subProcess); x += 40
                pdf.text(x, y, category); x += 40
                pdf.text(x, y, department); x += 40
                pdf.text(x, y, avrDate); x += 20
                pdf.text(x, y, status); x += 20
                pdf.text(x + 1.5, y, riskValue, 'center'); x += 10
                pdf.text(x + 6.5, y, numControls?.toString(), 'center'); x += 10

                if (settings.extended) { // IKP, aktiv, utfälld
                    const description = risk.description ? risk.description : "-"
                    const result = risk.result ? risk.result : "-"
                    const suggestion = risk.suggestion ? risk.suggestion : "-"
                    const controlList = risk.reviews
                    const numReports = risk.reviews?.reduce((sum, item) => { return sum + Number(item.deviationReports.length) }, 0).toString()
                    const numSuggestions = risk.reviews?.reduce((sum, item) => {
                        return sum + item.verifyChecks?.reduce((sum2, item2) => {
                            if (item2.asSuggestion) { return sum2 + 1 } else { return sum2 }
                        }, 0)
                    }, 0).toString()

                    const descriptionLines = computeMultipleLines(description, maxSize - 5)
                    const resultLines = computeMultipleLines(result, maxSize - 10)
                    const suggestionLines = computeMultipleLines(suggestion, maxSize - 10)

                    const d_len = descriptionLines?.length
                    const r_len = resultLines?.length
                    const s_len = suggestionLines?.length
                    const c_len = numControls
                    const numLines = Math.max(d_len, r_len, s_len, c_len)

                    x = 10
                    y += 5
                    pdf.setFontSize(6.5)
                    pdf.text(x, y, 'Beskrivning:')
                    x += 50
                    pdf.text(x, y, 'Utfall:')
                    x += 40
                    pdf.text(x, y, 'Åtgärd:')
                    x += 40
                    pdf.text(x, y, 'Kontroller')
                    x += 40
                    pdf.text(x, y, 'Avvikelserapport')
                    pdf.text(x, y + 3, numReports > 0 ? numReports : "-")
                    x += 20
                    pdf.text(x, y, 'Förbättringsförslag')
                    pdf.text(x, y + 3, numSuggestions > 0 ? numSuggestions : "-")

                    x = 10
                    pdf.setFontSize(6)
                    for (let line = 0; line < numLines; line++) {
                        if (y + 3 > maxPageHeight) {
                            createNewPage(type)
                            y += 2
                            pdf.setFontSize(6)
                        }

                        x = 10
                        y += 3
                        if (descriptionLines[line])
                            pdf.text(x, y, descriptionLines[line])
                        x += 50
                        if (resultLines[line])
                            pdf.text(x, y, resultLines[line])
                        x += 40
                        if (suggestionLines[line])
                            pdf.text(x, y, suggestionLines[line])
                        x += 40
                        if (controlList[line])
                            pdf.text(x, y, bulletItem + controlList[line].name)
                    }
                    if (controlList?.length === 0)
                        pdf.text(x, y, "-")
                }

                y += 2
                pdf.setLineDash([.5], 0)
                pdf.line(10, y, 285, y)

                if (settings.extended) {
                    if (!(index === listData.length - 1)) {
                        createNewPage(type)
                    }
                } else {
                    if (y > maxPageHeight) createNewPage(type)
                }
            })

            x = 10
            y = 10
            createPageFooter()

            if (settings.extended) { fileName = 'IKP_aktiv_utfälld.pdf' }
            else { fileName = 'IKP_aktiv.pdf' }

            pdf.save(fileName)
        } else { // IKP, väntande
            const type = 'ikp-väntande'
            const pageTitle = 'Väntande internkontrollplan ' + settings.title
            const maxSize = 48

            y += 30
            createPageTitle(pageTitle)
            createListHeader(type)
            y += 2

            let listData = data

            listData.forEach((risk, index) => {
                const name = risk.name.slice(0, maxSize - 10)
                const process = risk.category.slice(0, maxSize - 10)
                const subProcess = risk.subCategory.slice(0, maxSize - 10)
                const category = risk.process.slice(0, maxSize - 10)
                const department = risk.department["name"].slice(0, maxSize - 10)
                const riskValue = (risk.consequence * risk.probability).toString()
                const numControls = risk.controllers?.filter((control) => { return control.icp }).length

                if (y + 13 > maxPageHeight) createNewPage(type)

                x = 10
                y += 5

                pdf.setFontSize(7)
                addRiskGroupColor(x, y, risk.probability, risk.consequence, settingsData)
                pdf.text(x, y, name); x += 50
                pdf.text(x, y, process); x += 40
                pdf.text(x, y, subProcess); x += 40
                pdf.text(x, y, category); x += 40
                pdf.text(x, y, department); x += 82
                pdf.text(x + 1.5, y, riskValue, 'center'); x += 10
                pdf.text(x + 6.5, y, numControls.toString(), 'center'); x += 10

                if (settings.extended) { // IKP, väntande, utfälld
                    const description = risk.description ? risk.description : "-"
                    const motivation = risk.icpMotivation
                    const controlList = risk.controllers.filter((control) => { return control.icp })

                    const descriptionLines = computeMultipleLines(description, maxSize + 30)
                    const motivationLines = computeMultipleLines(motivation, maxSize + 20)

                    const d_len = descriptionLines?.length
                    const m_len = motivationLines?.length
                    const c_len = numControls
                    const numLines = Math.max(d_len, m_len, c_len)

                    x = 10
                    y += 5
                    pdf.setFontSize(6.5)
                    pdf.text(x, y, 'Beskrivning:')
                    x += 90
                    pdf.text(x, y, 'Motivering till IKP:')
                    x += 80
                    pdf.text(x, y, 'Kontroller')

                    x = 10
                    pdf.setFontSize(6)
                    for (let line = 0; line < numLines; line++) {
                        if (y + 3 > maxPageHeight) {
                            createNewPage(type)
                            y += 2
                            pdf.setFontSize(6)
                        }

                        x = 10
                        y += 3
                        if (descriptionLines[line])
                            pdf.text(x, y, descriptionLines[line])
                        x += 90
                        if (motivationLines[line])
                            pdf.text(x, y, motivationLines[line])
                        x += 80
                        if (controlList[line])
                            pdf.text(x, y, bulletItem + controlList[line].name)
                    }
                    if (controlList.length === 0) { pdf.text(x, y, "-") }
                }
                y += 2
                pdf.setLineDash([.5], 0)
                pdf.line(10, y, 287, y)

                if (settings.extended) {
                    if (!(index === listData.length - 1)) {
                        createNewPage(type)
                    }
                } else {
                    if (y > maxPageHeight) createNewPage(type)
                }
            })
            x = 10
            y = 10
            createPageFooter()
            if (settings.extended) { fileName = 'IKP_väntande_utfälld.pdf' }
            else { fileName = 'IKP_väntande.pdf' }
            pdf.save(fileName)
        }
    }
}
