import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({ props, children, ...rest }) {
  const { isLoggedIn, activeUser } = useContext(UserContext);

  let createdDate
  let dagensDatum
  let difference
  let timeHasNotExpired

  if (activeUser?.tokenExpireDate) {
    createdDate = new Date(activeUser?.tokenExpireDate)
    dagensDatum = new Date()
    difference = dagensDatum.getTime() - createdDate?.getTime()
    timeHasNotExpired = difference < (1000 * 60 * 60 * 7)
    // timeHasNotExpired = difference < (8000)

  } else {
    timeHasNotExpired = false
  }



  return (
    <Route
      {...rest}
      render={({ location }) => {
        // rest.isAuthenticated ? (
        return isLoggedIn && timeHasNotExpired ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location, autoLogOut: activeUser?.tokenExpireDate ? true : false },
            }}
          />
        );
      }}
    />
  );
}