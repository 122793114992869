import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton } from '../../styles/buttons'
import axios from "axios";
import { useQuery } from "react-query";
import RiskInfoCard from "../riskportfolio/components/RiskInfoCard";
import ListControllers from "../riskportfolio/components/ListControllers";
import ListIcpRiskReviews from "./components/ListIcpRiskReviews";
import IcpInfoCardIcpRisk from "./components/IcpInfoCardIcpRisk";
import ReportAndSuggestIcp from "./components/ReportAndSuggestIcp";
import styles from "./styles/ShowIcpRiskPage.module.css";
import LoadingModal from "./../../modal/LoadingModal";
import ExportOneRiskModal from "./../../modal/ExportOneRiskModal"
// import { exportAsPdf } from "../../functions/exportToFile/exportPdf"
import { exportOneRisk } from "../../functions/exportToFile/exportOneRisk"
import { exportAsExcel } from "../../functions/exportToFile/exportExcel"

const ShowIcpRiskPage = () => {
    const [updateState, setUpdateState] = useState(false)
    const history = useHistory()
    const { sideBar, activeUser } = useContext(UserContext)
    const [isMounted, setIsMounted] = useState(true)
    const [fadeInTrigger, setFadeInTrigger] = useState(true)
    const [showExportModal, setShowExportModal] = useState(false)

    const getCurrentSettings = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-current-portfolio-settings", {
            headers: { authorization: "Bearer " + activeUser.token, },
        });
        return res.data;
    }

    const { data: settingsData } = useQuery(["readCurrentsettings"], getCurrentSettings, {});

    const readOneRisk = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/risk/one",
            { id: history.location.state.riskId },
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        );

        return res.data;
    };
    const readOneIcpRisk = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/get-icp-risk",
            { icpRiskId: history?.location?.state?.icpRiskId },
            { headers: { authorization: "Bearer " + activeUser.token } }
        );
        if (isMounted) { setFadeInTrigger(true) }
        return res.data;
    };

    const { data: riskData, status, isError, refetch } = useQuery(["getOneOriginalRiskRiskToIcp"], readOneRisk, {
        cacheTime: 0,
    });
    const { data: icpRiskData, status: icpRiskStatus, refetch: icpRiskRefetch } = useQuery(["getOneIcpRisk"], readOneIcpRisk, {
        enabled: !!riskData,
        cacheTime: 0,
    });

    const readAllReviews = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/review/get-all-reviews",
            { icpRiskId: icpRiskData._id },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        return res.data;
    };

    const { data: reviewsList, status: icpReviewsList } = useQuery(["getAllReviews"], readAllReviews, {
        enabled: !!riskData && !!icpRiskData,
        cacheTime: 0,
    });

    useEffect(() => {
        refetch();
        return () => {
            setIsMounted(false)
        }
    }, [updateState, refetch]);

    useEffect(() => {
        if (isError) {
            history.push("/", { from: "location", autoLogOut: true })
        }

    }, [isError, history])

    return (
        <>
            <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
                <LoadingModal open={!fadeInTrigger} />
                {status === "success" && icpRiskStatus === "success" && icpReviewsList === "success" ?
                    <>
                        <PageTitle>Riskvy för {riskData.name}</PageTitle>
                        <PageDelimiter />
                        <div style={{ marginBottom: "60px" }}>
                            <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Gå tillbaka</PageHeaderButton>
                            <PageHeaderButton icon="save" onClick={() => { setShowExportModal(true) }}>Exportera</PageHeaderButton>
                        </div>
                        <>
                            <div className={styles.portfolioContainer} >
                                <div className={styles.portfolioIconChild}><i className={"fas fa-briefcase fa-lg"}></i></div>
                                <div className={styles.portfolioTextContainer}>
                                    <div className={styles.portfolioHeaderChild}>{history.location.state.icpName} </div>
                                </div>
                            </div>
                            <RiskInfoCard setUpdateState={setUpdateState} risk={riskData} onlyIcp={true} />
                            <IcpInfoCardIcpRisk risk={riskData} icpRiskId={history.location.state.icpRiskId} />
                            <ReportAndSuggestIcp risk={riskData} icpRiskId={history.location.state.icpRiskId} icpRiskData={icpRiskData} icpRiskRefetch={icpRiskRefetch} />
                            <ListIcpRiskReviews risk={riskData} setUpdateState={setUpdateState} icpRiskId={history.location.state.icpRiskId} />
                            <ListControllers mirrorFromIcp={true} fromIcp={true} risk={riskData} setUpdateState={setUpdateState} />
                        </>

                    </>
                    : null}
            </div>

            <ExportOneRiskModal
                passedFunction={(fileType, extendedList) => {
                    let title = history.location.state.icpName
                    let settings = { riskportfolio: false, active: true, extended: extendedList, title }
                    switch (fileType) {
                        case "pdf":
                            // exportAsPdf([riskData], settings, settingsData, activeUser.customer)
                            exportOneRisk([riskData], settings, settingsData, activeUser.customer, reviewsList, icpRiskData)
                            break;
                        case "excel":
                            exportAsExcel([riskData], settings)
                            break;
                        default:
                            break;
                    }
                }}
                warningTitle={"Skapa rapport av risk!"}
                warningText={"Välj filformat och om du vill ha utvecklad vy eller inte."}
                optionTextOne={"Ladda ner"}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={showExportModal}
                onClose={() => {
                    setShowExportModal(false)
                }}
            />
        </>
    )
}

export default ShowIcpRiskPage
