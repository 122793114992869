export const Frequencies = [
    "Ingen vald",
    "Dagligen",
    "Veckovis",
    "Månadsvis",
    "Kvartalsvis",
    "Tertialsvis",
    "Halvårsvis",
    "Årligen",
    "Två år",
    "Tre år",
    "Fyra år",
    "Fem år"
]