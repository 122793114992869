import { useRef, useState, useEffect } from 'react'
import styles from "./OrganisationContextMenu.module.css"

function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return null
                }
                handler(event)
            }
            document.addEventListener("mousedown", listener)
            document.addEventListener("touchstart", listener)
            return () => {
                document.removeEventListener("mousedown", listener)
                document.removeEventListener("touchstart", listener)
            }
        },
        [ref, handler]
    )
}

const OrganisationContextMenu = ({ x, y, closeContextMenu }) => {
    const [isAdding, setIsAdding] = useState(false)
    const handleAddingMouseOver = () => setIsAdding(true)
    const handleAddingMouseOut = () => setIsAdding(false)

    const [isDeleting, setIsDeleting] = useState(false)
    const handleDeletingMouseOver = () => setIsDeleting(true)
    const handleDeletingMouseOut = () => setIsDeleting(false)

    const contextMenuRef = useRef(null)
    useOnClickOutside(contextMenuRef, closeContextMenu)

    return (
        <div
            className={styles.contextMenu}
            style={{ position: 'fixed', top: `${y}px`, left: `${x}px` }}
            ref={contextMenuRef}
            onClick={(e) => closeContextMenu(e, isAdding, isDeleting)}
        >
            <div
                className={styles.contextMenuItem}
                onMouseOver={handleAddingMouseOver}
                onMouseOut={handleAddingMouseOut}
            >Lägg till underområde
            </div>
            <div
                className={styles.contextMenuItem}
                onMouseOver={handleDeletingMouseOver}
                onMouseOut={handleDeletingMouseOut}
            >Ta bort område
            </div>
        </div>
    )
}

export default OrganisationContextMenu
