import React, { useState, useContext } from "react";
import { UserContext } from "../../../contexts/UserContext"
import styles from "../styles/ListIcpRiskReviews.module.css";
import axios from "axios";
import Modal from "./../../../modal/Modal";
import ReviewModal from "./review-modal/ReviewModal";
import { useQuery } from "react-query";
import frequencyOverdue from '../../../functions/frequencyOverdue.js'
import Tooltip from "../../../components/tooltip/Tooltip";

export default function ListIcpRiskReviews({ risk, icpRiskId, reviews, setUpdateState, setShowControllerForm, setShowReviewForm }) {
    const [showModal, setShowModal] = useState(false);
    const [activeReview, setActiveReview] = useState(null);
    const { activeUser } = useContext(UserContext)

    const readAllReviews = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/review/get-all-reviews",
            { icpRiskId: icpRiskId },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        return res.data;
    };

    const { data: reviewsList, status, refetch: refetchReviews } = useQuery(["getAllReviews"], readAllReviews, {
        cacheTime: 0,
    });

    // const deleteReview = async (reviewData) => {
    //   // console.log("leteReviewdata: ", reviewData);
    //   const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/review/delete-review", {
    //     headers: {
    //       authorization: "Bearer " + activeUser.token,
    //     },
    //     data: {
    //       reviewData
    //     },
    //   });
    //   setUpdateState((state) => !state);

    //   if (res.status === 200) {
    //     refetchReviews()
    //   }

    //   return res.status;
    // };

    return (
        <>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <ReviewModal
                    setShowModal={setShowModal}
                    risk={risk}
                    icpRiskId={icpRiskId}
                    activeReview={activeReview}
                    setUpdateState={setUpdateState}
                    refetchReviews={refetchReviews}
                />
            </Modal>

            <div className={styles.mainContainer}>
                <div className={styles.header}>Kontroller
                    {/* <div style={{ position: "relative", left: "510px", top: "-25px" }}>
            <AddButtonShadow icp={true} icon="add" onClick={() => {
              setShowReviewForm((status) => !status);
              setShowControllerForm(false);
            }}>Lägg till</AddButtonShadow>
          </div> */}
                </div>
                {status === "success" && reviewsList.length > 0 ? (
                    reviewsList
                        .map((controller) => (
                            <div className={styles.oneControllerContainer} key={controller._id}>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setActiveReview(controller);
                                        setShowModal(true);
                                    }}
                                >
                                    {/* {controller.name} */}
                                    {controller.name.length > 23 ?
                                        <Tooltip content={controller.name} direction={"right"}>{controller.name.slice(0, 23)}...</Tooltip>
                                        : controller.name
                                    }
                                </div>
                                <div className={styles.controllerColumnZero}>{controller.assignedUserId?.firstName} {controller.assignedUserId?.lastName}</div>
                                <div className={styles.controllerColumnOne}><Tooltip content={"Metod"} direction={"right"}>{controller.method}</Tooltip></div>
                                <div className={styles.controllerColumnTwo}><Tooltip content={"Frekvens"} direction={"right"}>{controller.frequency}</Tooltip></div>
                                <div className={styles.controllerColumnFour}>{frequencyOverdue(controller.verifyChecks, controller.frequency, controller.createdAt) ?
                                    <div className={styles.riskRowIconItem}><i className={"far fa-calendar-alt"} ></i></div>
                                    : null
                                }</div>
                                <div className={styles.controllerColumnThree}><Tooltip content={"Avvikelserapporter"} direction={"right"}>{controller.deviationReports.length}</Tooltip></div>
                                {/* <div className={styles.iconBox}>
                  <i className="fas fa-times fa-md sidebar-icon" onClick={() => {
                    deleteReview({
                      belongToIcp: controller.belongToIcp,
                      belongToIcpRisk: controller.belongToIcpRisk,
                      reviewId: controller._id
                    })
                  }}></i>
                </div> */}
                            </div>
                        ))
                ) : (
                    <div className={styles.oneControllerContainer}>
                        Det finns inga kontroller till den här risken!
                    </div>
                )}
            </div>
        </>
    );
}
