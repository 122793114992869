import React, { useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext"
import axios from "axios";
import styles from "./FilesInController.module.css";
import FileOptionsModal from "./../../../../../modal/FileOptionsModal";
import FileUploadModal from "./../../../../../modal/FileUploadModal";
import PickFileModal from "./../../../../../modal/PickFileModal";
import WarningModal from "./../../../../../modal/WarningModal";

export default function FilesInController({ controller, refetch, icpRiskId, status, pathFromHomeScreen, riskId }) {
  const { activeUser } = useContext(UserContext)
  const [downloadFileUrl, setDownloadFileUrl] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [completeFileToUpload, setCompleteFileToUpload] = useState(null);
  const [fileWarningText, setFileWarningText] = useState("");
  const [showPickFileModal, setShowPickFileModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showFileOptionsModal, setShowFileOptionsModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [fileNameAsState, setFileNameAsState] = useState("")
  const [fileToDelete, setFileToDelete] = useState(null);

  const getFile = async (fileName) => {
    const res = await axios.post(
      process.env.REACT_APP_IC_SERVICE + "/file/get-file",
      { fileName },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    setDownloadFileUrl(res.data);
    return res.data;
  };

  const deleteFile = async () => {
    const res = await axios.post(
      process.env.REACT_APP_IC_SERVICE + "/file/delete-file",
      { fileToDelete },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );

    if (res.status === 200 && controller.files.includes(fileToDelete)) {
      let testArray = controller.files.filter((e) => {
        return e;
      });
      let positionOfElement = testArray.indexOf(fileToDelete);
      testArray.splice(positionOfElement, 1);

      let updatedControllerResponse = await axios.put(
        process.env.REACT_APP_IC_SERVICE + "/review/update-review",
        { id: controller._id, payload: { files: testArray }, },
        { headers: { authorization: "Bearer " + activeUser.token, }, }
      );

      if (updatedControllerResponse.status === 200) {
        refetch();
      }
    }
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    let filePath = "icps/" + controller.belongToIcp + "/risks/" + icpRiskId + "/reviews/" + controller._id + "/"
    const formData = new FormData();
    if (fileToUpload !== null) { formData.append("file", fileToUpload.file); }
    formData.append("filePath", filePath);
    let klart = await axios.post(process.env.REACT_APP_IC_SERVICE + "/file/upload", formData, {
      headers: {
        ContentType: "multipart/form-data",
        authorization: "Bearer " + activeUser.token,
      },
    });

    let fileNameToAdd = filePath + fileToUpload.file.name;
    if (klart.status === 200 && !controller.files.includes(fileNameToAdd)) {
      await axios.put(
        process.env.REACT_APP_IC_SERVICE + "/review/update-review",
        { id: controller._id, payload: { files: [...controller.files, fileNameToAdd] }, },
        { headers: { authorization: "Bearer " + activeUser.token, }, }
      );
      refetch();
    }
  };

  const onFileChange = (e) => {
    setFileToUpload({ file: e.target.files[0] });
    setCompleteFileToUpload(e)
    setShowFileUploadModal(true)
  };

  return (
    <>
      <PickFileModal
        passedFunction={(e) => { onFileChange(e) }}
        warningTitle={"Bifoga fil"}
        warningText={"Välj en fil att ladda upp och koppla till den här risken"}
        optionTextOne={""}
        optionTextTwo={"Avbryt"}
        colour={"blue"}
        open={showPickFileModal}
        onClose={() => {
          setShowPickFileModal(false)
        }}
      />
      <FileUploadModal
        passedFunction={() => {
          if (controller.files.includes("icps/" + controller.belongToIcp + "/risks/" + icpRiskId + "/reviews/" + controller._id + "/" + fileToUpload.file.name)) {
            setFileWarningText(`Det finns redan en fil med namnet ${fileToUpload.file.name}`)
            setShowWarningModal(true)
          } else { uploadFile(completeFileToUpload); }
        }}
        warningTitle={fileToUpload?.file?.name}
        warningText={"Filen kopplas till den här risken och du kan ta bort den närsomhelst."}
        optionTextOne={"Ladda upp"}
        optionTextTwo={"Avbryt"}
        colour={"blue"}
        open={showFileUploadModal}
        onClose={() => {
          setFileToUpload(null);
          setShowFileUploadModal(false)
        }}
      />
      <FileOptionsModal
        fileName={fileNameAsState}
        downloadFileUrl={downloadFileUrl}
        hideDeleteButton={false}
        deleteFunction={() => { deleteFile() }}
        open={showFileOptionsModal}
        onClose={() => {
          setFileNameAsState("")
          setShowFileOptionsModal(false)
        }}
      />
      <WarningModal
        passedFunction={() => { }}
        warningTitle={"Det gick inte ladda upp filen!"}
        warningText={fileWarningText}
        optionTextOne={"OK"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
          setFileWarningText("")
          setFileToUpload(null)
        }}
      />
      <div style={{ padding: "0px", margin: "0px", height: "28px", marginBottom: "5px", }} >
        <h4 style={{ marginBottom: "0px", paddingBottom: "0px", marginTop: "50px" }}>Bifogade filer</h4>

        <i
          onClick={() => {
            setFileWarningText("")
            setFileToUpload(null)
            setShowPickFileModal(true)
          }}
          style={{
            cursor: "pointer",
            color: "rgb(47, 100, 171)",
            position: "relative",
            top: "-19px",
            left: "115px",
            paddingLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            fontSize: "20px",
          }}
          className="fas fa-plus-circle"
        ></i>
      </div>

      <div className="">
        <div className={styles.appendix}>
          {status === "success" && controller.files?.length > 0 ? (
            controller.files.map((riskFile, index) => {
              return (
                <div
                  key={index}
                  className={styles.listedFile}
                  onClick={() => {
                    getFile(riskFile);
                    setFileToDelete(riskFile);
                    setShowFileOptionsModal(true)
                    let fileNameString = riskFile.slice(riskFile.lastIndexOf("/") + 1)
                    setFileNameAsState(fileNameString)
                  }}
                >
                  {riskFile.slice(riskFile.lastIndexOf("/") + 1)}
                </div>
              );
            })
          ) : (
            <div>Det finns inga bifogade filer.</div>
          )}
        </div>
      </div>
    </>
  );
}