import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton, HelpButton } from '../../styles/buttons'
import styles from "./styles/RiskGroupPage.module.css";
import axios from "axios"
import { useQuery } from "react-query";
import LoadingModal from "./../../modal/LoadingModal";

const RiskGroupPage = () => {
  const history = useHistory()
  const { sideBar, activeUser } = useContext(UserContext)
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  const LightenColor = (percent) => {
    let color = "#4e7bb8"

    let num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00FF) + amt,
      G = (num & 0x0000FF) + amt;
    return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
  };

  const getRiskGroupsNames = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-risk-groups-names", {
      headers: { authorization: "Bearer " + activeUser.token, },
    });
    if (isMounted) { setFadeInTrigger(true) }
    return res.data;
  };

  const { data, status } = useQuery(["readRiskGroupsNames2"], getRiskGroupsNames, { cacheTime: 0 });

  const renderGroups = () => {

    return data?.map((element, index) => {
      return (
        <div key={index} style={{ background: LightenColor(data.length - index * 3) }} onClick={() => { history.push("/show-risk-group", element) }} >
          <div className={styles.riskGroupTitle}>{element?.name} </div>
          <div className={styles.riskGroupInfoText}>Skapad {element.createdDate?.slice(0, 10)}</div>
          <div className={styles.riskGroupInfoText}>{element?.createdBy ? element.createdBy?.firstName + " " + element.createdBy?.lastName : null}</div>
        </div>
      )
    })
  }

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />

      <>
        <PageTitle>Riskgrupper</PageTitle>
        <PageDelimiter />
        <PageHeaderButton icon="undo" onClick={() => history.push("/riskportfolio")}>Gå tillbaka</PageHeaderButton>
        <PageHeaderButton icon="add" onClick={() => { history.push("/create-risk-group") }}>Skapa ny riskgrupp</PageHeaderButton>
        <HelpButton></HelpButton>
      </>

      <div style={{ width: "750px", marginBottom: "50px", marginTop: "40px" }}>
        <div className="bodyText">
          Nedan visas alla riskgrupper som är skapade. Genom att klicka in i en riskgrupp kan du ändra och lägga till fler
          risker till gruppen. Du kan även upplösa en grupp, vilket innebär att riskerna lever vidare i riskportföljen men
          att gruppen och kopplingen till riskerna försvinner.
        </div>
      </div >

      <div className={styles.groupContainer}>
        {status === "success" ? renderGroups() : null}
      </div>
    </div >
  )
}

export default RiskGroupPage