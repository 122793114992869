import React, { useContext, useState } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton, PortfolioButton } from '../../styles/buttons'
import styles from "./styles/AnalyzeRiskPortfolioPage.module.css";
import axios from "axios"
import { useQuery } from "react-query";
import LoadingModal from "./../../modal/LoadingModal";
import CategoryDiagram from "./components/analyze/CategoryDiagram"
import PlotterDiagram from "./components/analyze/PlotterDiagram"
import RiskDataBoxes from "./components/analyze/RiskDataBoxes"
import TopTenRisks from "./components/analyze/TopTenRisks"
import SubProcessChart from "./components/analyze/SubProcessChart"
import ImprovementSuggestions from "./components/analyze/ImprovementSuggestions"
import ImprovementSuggestionsRP from "./components/analyze/ImprovementSuggestionsRP"
import DeviationReportSummary from "./components/analyze/DeviationReportSummary"
import DeviationReportSummaryRP from "./components/analyze/DeviationReportSummaryRP"
import Attests from "./components/analyze/Attests"
import AttestsRP from "./components/analyze/AttestsRP"
import VerticalBarDiagram from "./components/analyze/VerticalBarDiagram"
import { exportAnalyzeAsPdf } from "./../../functions/exportToFile/exportAnalyze"

const AnalyzeRiskPortfolioPage = () => {
    const history = useHistory()
    let onlyIcp = history?.location?.state.onlyIcp
    let icpId = history?.location?.state.icpId
    let icpName = history?.location?.state.icpName
    let icpDescription = history?.location?.state.icpDescription
    let activeIcp = history?.location?.state.activeIcp
    let icpOrg = history?.location?.state.icpOrg

    const { sideBar, activeUser } = useContext(UserContext)
    const [pickedOrganisation, setPickedOrganisation] = useState(() => onlyIcp ? icpOrg : activeUser.organisation)
    const [includeChildren, setIncludeChildren] = useState(() => activeUser.objectManager || activeUser.admin ? true : false)
    const [activeButton, setActiveButton] = useState("first")
    const [year, SetYear] = useState('Samtliga')
    const [onlyWithAttestComment, setOnlyWithAttestComment] = useState(false)

    const readWaitingIcpAttests = async () => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/read-first-attests",
            { icpId },
            {
                headers: { authorization: "Bearer " + activeUser.token, },
            }
        );
        return res.data;
    }

    const readRisks = async () => {
        let urlToServer = activeIcp ? "/internalcontrolplan/read-risks" : "/riskportfolio/read-risks"

        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + urlToServer,
            { organisation: pickedOrganisation, onlyIcp: onlyIcp, icpId },
            {
                headers: { authorization: "Bearer " + activeUser.token, },
            }
        );


        if (onlyIcp) {

            let temp1 = res.data.objectAccessList.sort(function (a, b) {
                return a.level - b.level
            }).filter((object) => object.parents.includes(icpOrg) || object._id === icpOrg)
            var resss1 = await temp1.reduce((results, item) => {
                if (item.parents.includes(icpOrg)) results.push(item._id)
                return results
            }, [])
            await setAllChildren(resss1)
            setObjectToAnalyze(temp1[0])
        }
        else if (activeUser.admin) {

            let temp1 = res.data.objectAccessList
            var resultsa = await temp1.reduce((results, item) => {
                if (item.parents.includes(activeUser.organisation)) results.push(item._id)
                return results
            }, [])
            await setAllChildren(resultsa)
            setObjectToAnalyze(res.data.objectAccessList.find((object) => object._id === activeUser.organisation))
        } else if (activeUser.objectManager) {
            let temp1 = res.data.objectAccessList
            var resultChildren = await temp1.reduce((results, item) => {
                if (item.parents.includes(activeUser.department)) results.push(item._id)
                return results
            }, [])
            await setAllChildren(resultChildren)
            // var ress3 = await temp1.reduce((results, item) => {
            //     if (item.parents.includes(temp1[0]._id)) results.push(item._id)
            //     return results
            // }, [])
            // await setAllChildren(ress3)
            setObjectToAnalyze(() => temp1.find((object) => object._id === activeUser.department))


        } else {

            await setAllChildren([])
            setObjectToAnalyze(res.data.objectAccessList[0])
        }
        return res.data;
    };
    const { data: risksData, status: risksDataStatus, isFetching: isRisksFetching } = useQuery(
        ["read-risks", onlyIcp ? ("icp - " + icpId) : "riskportfolio"], readRisks,
        {
            refetchOnWindowFocus: false, refetchOnmount: false, refetchOnReconnect: false, retry: false,
            staleTime: 0
        }
    );
    const { data: firstAttestData } = useQuery(
        ["read-first-attests", "waiting", icpId], readWaitingIcpAttests,
        {
            refetchOnWindowFocus: false, refetchOnmount: false, refetchOnReconnect: false, retry: false,
            staleTime: 20000, enabled: onlyIcp
        }
    );
    const [allChildren, setAllChildren] = useState(() => {
        if (risksData) {
            if (onlyIcp) {
                let temp1 = risksData.objectAccessList.sort(function (a, b) {
                    return a.level - b.level
                }).filter((object) => object.parents.includes(icpOrg) || object._id === icpOrg)
                return temp1.reduce((results, item) => {
                    if (item.parents.includes(icpOrg)) results.push(item._id)
                    return results
                }, [])
            } else if (activeUser.admin) {
                let temp2 = risksData.objectAccessList
                return temp2.reduce((results, item) => {
                    if (item.parents.includes(activeUser.organisation)) results.push(item._id)
                    return results
                }, [])
            } else if (activeUser.objectManager) {
                let temp2 = risksData.objectAccessList
                return temp2.reduce((results, item) => {
                    if (item.parents.includes(activeUser.department)) results.push(item._id)
                    return results
                }, [])
            }
        } else return []
    })
    const [objectToAnalyze, setObjectToAnalyze] = useState(() => {
        if (risksData) {
            if (onlyIcp) {
                let mopedo = risksData.objectAccessList.sort(function (a, b) {
                    return a.level - b.level
                }).filter((object) => object.parents.includes(icpOrg) || object._id === icpOrg)
                return mopedo[0]
            } else if (activeUser.admin) {
                return risksData.objectAccessList.find((object) => object._id === activeUser.organisation)
            } else if (activeUser.objectManager) {
                let objectList = risksData.objectAccessList
                return objectList[0]
            } else {
                let objectLdist = risksData.objectAccessList
                return objectLdist[0]
            }
        } else return []
    })

    const sortDropDown = () => {
        let initList = risksData?.objectAccessList
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt = initList.find((dep) => {
            if (activeUser.admin) {
                return dep._id === pickedOrganisation
            } else {
                return dep._id === activeUser.department
            }
        }
        )
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }
    const findAllChildren = async (objId) => {
        var resultsy = await risksData?.objectAccessList.reduce((results, item) => {
            if (item.parents.includes(objId ? objId : activeUser.organisation)) results.push(item._id)
            return results
        }, [])
        setAllChildren(resultsy)
    }

    const filteredRisks = () => {
        let listToRespond = risksData?.risksList?.sort(function (a, b) {
            return b.probability * b.consequence - a.probability * a.consequence
        }).filter((risk) => {
            if (includeChildren) {
                return allChildren?.includes(risk.department._id.toString()) || risk.department._id.toString() === objectToAnalyze?._id.toString()
            } else {
                return risk.department._id.toString() === objectToAnalyze?._id.toString()
            }
        }
        )

        return listToRespond
    }
    const memoFilteredRisks = filteredRisks()

    const computePlotterData = (data) => {
        return data.reduce((accumulator, item) => {
            const existingItem = accumulator.find(acc => {
                return acc.probability === item.probability && acc.consequence === item.consequence
            })
            if (existingItem) {
                existingItem.n += 1 // Add 1 to n for existing item
            } else {
                const newItem = { probability: item.probability, consequence: item.consequence, n: 1 }
                accumulator.push(newItem) // Add new item to accumulator
            }
            return accumulator
        }, [])
    }
    const computeObjectBranchData = (data) => {
        let branchesList = risksData.objectAccessList.filter(dep => {
            return ((dep.level === objectToAnalyze.level + 1) && dep.parents.includes(objectToAnalyze._id))
        })
        let filteredRisks = data.filter((risk) => {
            return risk.department.parents.includes(objectToAnalyze._id)
        })
        let nrOfRiskInObjectToAnalyze = data.length - filteredRisks.length
        let newBranchList = []
        for (const dep of branchesList) {
            let countRisk = 0
            for (const risk of filteredRisks) {
                let findSelfInDepartment = risksData.objectAccessList.find((dep) => dep._id.toString() === risk.department._id.toString())
                if (findSelfInDepartment?.parents.includes(dep._id.toString()) || risk.department._id.toString() === dep._id.toString()) { countRisk++ }
            }
            newBranchList.push({ nr: countRisk, name: dep.name })
        }
        newBranchList.unshift({ nr: nrOfRiskInObjectToAnalyze, name: objectToAnalyze.name })
        return newBranchList
    }

    const computeCategoryDataToDiagram = (datan) => {
        const computeCategoryData = (data) => {
            return data.reduce((accumulator, item) => {
                const existingItem = accumulator.find(acc => {
                    return acc.category === item.process
                })
                if (existingItem) {
                    existingItem.n += 1 // Add 1 to n for existing item
                } else {
                    const newItem = { category: item.process, n: 1 }
                    accumulator.push(newItem) // Add new item to accumulator
                }
                return accumulator
            }, [])
        }
        let nrOfCategories = computeCategoryData(datan).length
        let totalDegreesForPadding = 2 * nrOfCategories
        let totalDegreesForSectors = 360 - totalDegreesForPadding
        let sectorStartDegree = 0
        let categoryDiagramData = computeCategoryData(datan).map((risk) => {

            let sectorPercentage = (risk.n / memoFilteredRisks.length)
            let sectorAngel = sectorPercentage * totalDegreesForSectors

            let sectorData = {
                category: risk.category,
                n: risk.n,
                percentage: (sectorPercentage * 100).toString().slice(0, 4),
                startDegree: sectorStartDegree,
                stopDegree: sectorStartDegree + sectorAngel
            }
            sectorStartDegree = sectorStartDegree + sectorAngel + 2
            return sectorData
        })

        let startY = (250 - (nrOfCategories * 15)) / 2
        return { categoryDiagramData, startY }
    }
    const computeProcessDataToDiagram = (datan) => {
        const computeProcessData = (data) => {
            return data?.reduce((accumulator, item) => {
                const existingItem = accumulator.find(acc => {
                    return acc.category === item.category
                })
                if (existingItem) {
                    existingItem.n += 1 // Add 1 to n for existing item
                } else {
                    const newItem = { category: item.category, n: 1 }
                    accumulator.push(newItem) // Add new item to accumulator
                }
                return accumulator
            }, [])
        }

        let nrOfCategories = computeProcessData(datan).length
        let totalDegreesForPadding = 2 * nrOfCategories
        let totalDegreesForSectors = 360 - totalDegreesForPadding
        let sectorStartDegree = 0

        let categoryDiagramData = computeProcessData(datan).map((risk) => {

            let sectorPercentage = (risk.n / memoFilteredRisks.length)
            let sectorAngel = sectorPercentage * totalDegreesForSectors

            let sectorData = {
                category: risk.category,
                n: risk.n,
                percentage: (sectorPercentage * 100).toString().slice(0, 4),
                startDegree: sectorStartDegree,
                stopDegree: sectorStartDegree + sectorAngel
            }
            sectorStartDegree = sectorStartDegree + sectorAngel + 2
            return sectorData
        })

        let startY = (250 - (nrOfCategories * 15)) / 2
        return { categoryDiagramData, startY }
    }

    const computeSubProcessDataToDiagram = (datan) => {
        let listOfFoundProcesses = []
        let karta = {}
        for (const risk of datan) {

            if (risk.category && risk.category !== "Ej definierad process") {
                listOfFoundProcesses.push(risk.category)
                if (risk.subCategory) {
                    if (karta.hasOwnProperty(risk.category)) {
                        karta[risk.category].push(risk.subCategory)
                    } else {
                        karta[risk.category] = [risk.subCategory]
                    }
                }
            }
        }
        let listOfFoundProcAndSubProc = []

        for (const key in karta) {
            if (Object.hasOwnProperty.call(karta, key)) {
                const element = karta[key];
                let ert = element.reduce((map, val) => { map[val] = (map[val] || 0) + 1; return map }, {})
                let objToAdd = { mainProcess: key, subProcesses: ert, totalNr: element.length }
                listOfFoundProcAndSubProc.push(objToAdd)
            }
        }
        return listOfFoundProcAndSubProc
    }

    const risksWithAttestsRP = () => {
        let risksWithAttests = memoFilteredRisks?.map((risk) => {
            let riskReviews = risk["controllers"]
            if (riskReviews.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.verifyChecks.length > 0) {
                        let suggestionsToReviews = []
                        for (let vCheck of rev.verifyChecks) {
                            vCheck.onlyWithAttestComment = onlyWithAttestComment
                            vCheck.year = year
                            if (year === 'Samtliga' || year === vCheck.date.slice(0, 4)) {
                                if (onlyWithAttestComment) {
                                    if (vCheck.comment) {
                                        suggestionsToReviews.push(vCheck)
                                    }
                                } else {
                                    suggestionsToReviews.push(vCheck)
                                }
                            }
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        riskName: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk._id, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })

        return risksWithAttests
    }

    const risksWithAttestsICP = () => {
        let risksWithAttests = memoFilteredRisks?.map((risk) => {
            let riskReviews = risk["reviews"]
            if (riskReviews?.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.verifyChecks.length > 0) {
                        let suggestionsToReviews = []
                        for (let vCheck of rev.verifyChecks) {
                            vCheck.onlyWithAttestComment = onlyWithAttestComment
                            if (onlyWithAttestComment) {
                                if (vCheck.comment) {
                                    suggestionsToReviews.push(vCheck)
                                }
                            } else {
                                suggestionsToReviews.push(vCheck)
                            }
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        riskName: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk.riskId, icpRiskId: risk.icpRiskId, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })

        return risksWithAttests
    }

    return (
        <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${!isRisksFetching ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={isRisksFetching} />

            <PageTitle>Analysera {onlyIcp ? "internkontrollplan" : "riskportfölj"}</PageTitle>
            <PageDelimiter />
            <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Gå tillbaka</PageHeaderButton>
            <PageHeaderButton icon="save" onClick={() => {
                if (onlyIcp) {
                    risksWithAttestsICP()
                } else {
                    risksWithAttestsRP()
                }
                exportAnalyzeAsPdf({
                    activeIcp: activeIcp, onlyIcp: onlyIcp, icpName: icpName,
                    icpDescription: icpDescription,
                    firstAttestData: firstAttestData, data: memoFilteredRisks, departments: risksData.objectAccessList,
                    objectToAnalyze: objectToAnalyze, includeChildren: includeChildren,
                    subProcessData: computeSubProcessDataToDiagram(memoFilteredRisks), customer: activeUser.customer
                })
            }} >Exportera</PageHeaderButton>
            {
                activeIcp ? <>
                    <PortfolioButton activePortfolio={activeButton === "first"} onClick={() => setActiveButton("first")}>Visa sammanställning</PortfolioButton>
                    <PortfolioButton activePortfolio={activeButton === "second"} onClick={() => setActiveButton("second")}>Visa förbättringsförslag</PortfolioButton>
                    <PortfolioButton activePortfolio={activeButton === "third"} onClick={() => setActiveButton("third")}>Visa avvikelserapporter</PortfolioButton>
                    <PortfolioButton activePortfolio={activeButton === "fourth"} onClick={() => setActiveButton("fourth")}>Visa attester</PortfolioButton>

                </>
                    : <>
                        <PortfolioButton activePortfolio={activeButton === "first"} onClick={() => setActiveButton("first")}>Visa sammanställning</PortfolioButton>
                        <PortfolioButton activePortfolio={activeButton === "second"} onClick={() => setActiveButton("second")}>Visa förbättringsförslag</PortfolioButton>
                        <PortfolioButton activePortfolio={activeButton === "third"} onClick={() => setActiveButton("third")}>Visa avvikelserapporter</PortfolioButton>
                        <PortfolioButton activePortfolio={activeButton === "fourth"} onClick={() => setActiveButton("fourth")}>Visa attester</PortfolioButton>
                    </>

            }

            {
                (activeUser.admin || activeUser.objectManager) && risksData?.objectAccessList?.length > 0 ?
                    <div className={styles.selectBox}>
                        {risksDataStatus === "success" && activeUser.admin && !onlyIcp ?
                            <>
                                <label style={{ fontSize: "14px" }}>Organisation: </label>
                                <select style={{ marginLeft: "15px", marginRight: "35px" }} name="organisation"
                                    placeholder={objectToAnalyze.name}
                                    defaultValue={objectToAnalyze._id}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        let element = document.getElementById("testarIdNot");
                                        if (element) {
                                            element.value = ""
                                        }
                                        let newObjToAnalyze = risksData.objectAccessList.find((obj) => obj._id === e.target.value)
                                        setPickedOrganisation(e.target.value)
                                        setObjectToAnalyze(newObjToAnalyze)
                                        findAllChildren(e.target.value)
                                    }}>
                                    {risksData?.objectAccessList?.filter((org) => { return org.level === 1 }).map((dep) => {
                                        return <option key={dep._id} value={dep._id} >{dep.name}</option>
                                    })}
                                </select>
                            </>
                            : null
                        }
                        <label style={{ fontSize: "14px" }}>Objekt att analysera: </label>
                        {risksDataStatus === "success" ? (
                            <select name="department" style={{ marginLeft: "10px" }}
                                id="testarIdNot"
                                placeholder={objectToAnalyze.name}
                                onChange={(e) => {
                                    findAllChildren(e.target.value)
                                    let newObjToAnalyze = risksData.objectAccessList.find((obj) => obj._id === e.target.value)
                                    setObjectToAnalyze(newObjToAnalyze)
                                }}
                            >
                                {sortDropDown()
                                    .map((dep, index) => {

                                        return <option key={index} value={dep?._id} >{"- ".repeat(parseInt(dep?.level - 1))}{dep?.name}</option>
                                    }
                                    )}
                            </select>
                        ) : null}
                        {objectToAnalyze?.children?.length === 0 ? null :
                            <div className={styles.riskOption} >
                                <label style={{ fontSize: "14px" }}>Inkludera ackumulerade underobjekt: </label>
                                {includeChildren
                                    ? <i className="far fa-check-square" onClick={() => {
                                        setIncludeChildren(false)
                                    }}></i>
                                    : <i className="far fa-square" onClick={() => {
                                        setIncludeChildren(true)
                                    }}></i>
                                }
                            </div>
                        }
                    </div>
                    : <div style={{ marginBottom: "40px" }}></div>
            }

            {
                risksDataStatus === "success" && memoFilteredRisks ?
                    <div hidden={activeButton === "second" || activeButton === "third" || activeButton === "fourth"}>
                        <div className={styles.diagramContainer}>
                            <RiskDataBoxes activeIcp={activeIcp} onlyIcp={onlyIcp} firstAttestData={firstAttestData} data={memoFilteredRisks} departments={risksData.objectAccessList} objectToAnalyze={objectToAnalyze} includeChildren={includeChildren} />
                            <VerticalBarDiagram data={computeObjectBranchData(memoFilteredRisks)} />
                            <PlotterDiagram data={computePlotterData(memoFilteredRisks)} onlyIcp={onlyIcp} />
                            <TopTenRisks icpName={icpName} activeIcp={activeIcp} data={memoFilteredRisks.slice(0, 10)} />
                            <CategoryDiagram connectedId="perProcess" data={computeProcessDataToDiagram(memoFilteredRisks)} title="huvudprocess" />
                            <CategoryDiagram connectedId="perCategory" data={computeCategoryDataToDiagram(memoFilteredRisks)} title="kategori" />
                            <SubProcessChart icpName={icpName} activeIcp={activeIcp} data={computeSubProcessDataToDiagram(memoFilteredRisks)} />
                        </div>
                    </div>
                    : null
            }
            {
                risksDataStatus === "success" && filteredRisks().length > 0 && activeButton === "second" ?
                    < div style={{ marginBottom: "50px", marginTop: "0px" }}>
                        <>
                            <div className={styles.chartContainer}>
                                {activeIcp ? <ImprovementSuggestions riskData={memoFilteredRisks} icpName={icpName} /> :
                                    <ImprovementSuggestionsRP riskData={memoFilteredRisks} />}
                                <div style={{ height: "60px" }}></div>
                            </div>

                        </>
                    </div> : risksDataStatus === "success" && filteredRisks().length > 0 && activeButton === "third" ?
                        < div style={{ marginBottom: "50px", marginTop: "0px" }}>
                            <>
                                <div className={styles.chartContainer}>
                                    {activeIcp ? <DeviationReportSummary riskData={memoFilteredRisks} icpName={icpName} /> :
                                        <DeviationReportSummaryRP riskData={memoFilteredRisks} />}
                                    <div style={{ height: "60px" }}></div>
                                </div>
                            </>
                        </div> : risksDataStatus === "success" && filteredRisks().length > 0 && activeButton === "fourth" ?
                            <div style={{ marginBottom: "50px", marginTop: "0px" }}>
                                <>
                                    <div className={styles.chartContainer}>
                                        {onlyIcp ?
                                            <Attests
                                                riskData={memoFilteredRisks}
                                                icpName={icpName}
                                                setOnlyWithAttestComment={setOnlyWithAttestComment}
                                                onlyWithAttestComment={onlyWithAttestComment}
                                                risksWithAttestsICP={risksWithAttestsICP}
                                            />
                                            :
                                            <AttestsRP
                                                riskData={memoFilteredRisks}
                                                SetYear={SetYear} year={year}
                                                setOnlyWithAttestComment={setOnlyWithAttestComment} onlyWithAttestComment={onlyWithAttestComment}
                                                risksWithAttestsRP={risksWithAttestsRP}
                                            />
                                        }
                                        <div style={{ height: "60px" }}></div>
                                    </div>
                                </>
                            </div>
                            : null
                // <div className="bodyText">För få risker för analys.</div>
            }
            <div style={{ height: "60px" }}></div>
        </div >
    )
}
export default AnalyzeRiskPortfolioPage