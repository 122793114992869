import React from 'react'
import styles from './styles/SuggestionsAndDeviationAnalyze.module.css'
import { useHistory } from "react-router-dom"

export default function Attests({ riskData, icpName, setOnlyWithAttestComment, onlyWithAttestComment, risksWithAttestsICP }) {
    const history = useHistory()

    let risksWithAttests = risksWithAttestsICP()

    const hasAttestToRender = (risk) => {
        let hasComment = false
        for (const controller of risk.reviewsWithSuggestions) {
            if (onlyWithAttestComment) {
                let foundComment = controller.suggestions?.some(attest => attest.comment)
                if (foundComment) hasComment = true
            } else {
                hasComment = true
            }
        }
        return (hasComment)
    }

    const renderOnlyWithAttestComment = () => {
        return (
            <div className={styles.riskOption}>
                <>
                    <label style={{ fontSize: "14px" }}>Visa endast med kommentar: </label>
                    {onlyWithAttestComment
                        ? <i className="far fa-check-square" onClick={() => { setOnlyWithAttestComment(false) }}></i>
                        : <i className="far fa-square" onClick={() => { setOnlyWithAttestComment(true) }}></i>
                    }
                </>
            </div>
        )
    }

    const renderSuggestions = (suggestions) => {
        let suggestionToRender = suggestions.map((suggestion, index) => {
            if (suggestion.asSuggestion && onlyWithAttestComment) {
                return null
            }
            return <li key={index}><div className={styles.suggestionNameRow} >
                <div style={{ fontWeight: "600" }}>Attest: {suggestion.date.slice(0, 10)} - {suggestion.user}</div>
                <div style={{ marginBottom: "10px" }}>{suggestion.asSuggestion ? '' : suggestion.comment}</div>
            </div></li>
        })
        return suggestionToRender
    }

    const renderControllers = (reviewsWithSuggestions) => {
        let reviewToRender = reviewsWithSuggestions.map((review, index) => {
            return <div key={index} className={styles.reviewNameRow}>
                <div className={styles.riskNameRow2} style={{ marginBottom: "6px" }}>Kontroll: {review.reviewName}</div>
                <ul>{renderSuggestions(review.suggestions)}</ul>
            </div>
        })
        return reviewToRender
    }

    const renderRisks = () => {
        let riskToRender = risksWithAttests.map((risk, index) => {
            const hasAttest = hasAttestToRender(risk)
            if (!hasAttest) {
                return null
            }
            return <div key={index} className={styles.riskDataContainer}><div className={styles.riskNameRow} onClick={() => {
                history.push("/show-icp-risk", {
                    riskId: risk.orgRiskId,
                    icpRiskId: risk.icpRiskId,
                    icpName: icpName
                })
            }}>{risk.riskName} - {risk.departmentName}</div>{renderControllers(risk.reviewsWithSuggestions)}</div>
        })
        return riskToRender
    }

    return (
        <>
            <div className={styles.riskSelections}>
                {renderOnlyWithAttestComment()}
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.ContainerTitle}>Attester</div>
                {renderRisks()}
            </div >
        </>
    )
}
