import React, { useState, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { UserContext } from "../../contexts/UserContext"
import axios from "axios"
import styles from "./styles/LoginPage.module.css"
import Loader from "react-loader-spinner"
import GdprModal from "./../../modal/GdprModal"

const RegisterPage = () => {
    const { setIsLoggedIn, initActiveUser } = useContext(UserContext)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [loginError, setLoginError] = useState("")
    const [regEmail, setRegEmail] = useState("")
    const [regPasswordOne, setRegPasswordOne] = useState("")
    const [regPasswordTwo, setRegPasswordTwo] = useState("")
    const [loadingSpinner, setLoadingSpinner] = useState(false)
    const [hideEmailPasswordReg, setHideEmailPasswordReg] = useState(false)
    const history = useHistory()
    const [showGdprModal, setShowGdprModal] = useState(false)
    const [gdprURL, setGdprURL] = useState("")

    const triggerSubmitRegister = async (data) => {
        let svar = await submitRegister(data)
        if (svar.registered === false) {
            setLoginError("Användaren finns ej registrerad")
        }
        if (svar.registered === true) {
            setGdprURL(svar.gdprUrl)
            setRegEmail(data.email.toLowerCase())
            setLoginError("")
            setHideEmailPasswordReg(true)
        }
    }

    const submitRegister = async (data) => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/auth/registered",
            {
                email: data.email.toLowerCase(),
            }
        )
        return res.data
    }

    const submitLogin = async (data) => {
        setLoadingSpinner(true)
        try {
            const res = await axios.post(
                process.env.REACT_APP_IC_SERVICE + "/auth/login",
                {
                    email: data.email.toLowerCase(),
                    password: data.password,
                }
            )

            if (res.status === 200) {
                initActiveUser({ ...res.data.user, token: res.data.token })
                setIsLoggedIn(true)
                history.push("/hem", { registration: true })
            }
            return res
        } catch (error) {
            setLoginError("Användaren finns ej registrerad")
            setLoadingSpinner(false)
            return error
        }
    }

    const registerAndLogin = async () => {
        if (regPasswordOne !== regPasswordTwo) {
            setLoginError("Olika lösenord!")
        } else if (
            regPasswordOne.length > 7 &&
            regPasswordOne.length < 20 &&
            regPasswordOne.match(/[a-z]/) &&
            regPasswordOne.match(/\d+/)
        ) {
            setShowGdprModal(true)
        } else if (regPasswordOne.length === 0 && regPasswordOne.length === 0) {
            setLoginError("Skriv lösenord!")
        } else {
            setLoginError(
                "Lösenordet måste vara minst åtta tecken långt samt innehålla minst en siffra och en bokstav!"
            )
        }
    }

    useEffect(() => {
        document.getElementsByTagName("body")[0].style.overflowY = "hidden"
        return () => {
            document.getElementsByTagName("body")[0].style.overflowY = "visible"
        }
    }, [])

    return (
        <>
            <GdprModal
                passedFunction={async () => {
                    try {
                        const res = await axios.post(
                            process.env.REACT_APP_IC_SERVICE + "/auth/register-user",
                            {
                                email: regEmail.toLowerCase(),
                                payload: { password: regPasswordOne },
                            }
                        )
                        if (res.status === 200 && res.data.registered) {
                            submitLogin({
                                email: regEmail.toLowerCase(),
                                password: regPasswordOne,
                            })
                        }
                    } catch (error) {
                        console.log("register user error: ", error)
                        setLoginError("Användaren finns ej registrerad")
                        return error
                    }
                }}
                warningTitle={"GDPR och personuppgiftspolicy"}
                // warningText={"Genom att jag registrerar mig som användare i Kommunresurs Internkontroll godkänner jag att mina uppgifter sparas i enlighet med GDPR och Kommunresurs personuppgiftspolicy"}
                optionTextOne={"OK"}
                gdprLink={gdprURL}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={showGdprModal}
                onClose={() => {
                    setShowGdprModal(false)
                }}
            />
            <div className={styles.loginPageContainer}>
                <div className={styles.loginFormContainer}>
                    <form
                        onSubmit={handleSubmit(triggerSubmitRegister)}
                        className={styles.loginForm}
                    >
                        {hideEmailPasswordReg ? null : (
                            <div>
                                <input
                                    style={{ textAlign: "center" }}
                                    name="email"
                                    {...register("email", {
                                        required: "Fyll i e-postadress",
                                        maxLength: 50,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Felaktigt e-postadress",
                                        },
                                    })}
                                    placeholder="E-postadress"
                                    onChange={() => setLoginError(null)}
                                />
                            </div>
                        )}
                        <div>
                            {regEmail ? (
                                <>
                                    <input
                                        onChange={(e) => {
                                            setLoginError("")
                                            setRegPasswordOne(e.target.value)
                                        }}
                                        autoComplete="newPassword"
                                        style={{ textAlign: "center" }}
                                        placeholder="Nytt lösenord"
                                        type="password"
                                        name="passwordOne"
                                    />
                                    <input
                                        onChange={(e) => {
                                            setLoginError("")
                                            setRegPasswordTwo(e.target.value)
                                        }}
                                        autoComplete="newPassword"
                                        style={{ textAlign: "center" }}
                                        placeholder="Nytt lösenord igen"
                                        type="password"
                                        name="passwordTwo"
                                    />

                                    <button
                                        disabled={loadingSpinner}
                                        type="button"
                                        onClick={() => {
                                            registerAndLogin()
                                        }}
                                        className={styles.loginButton}
                                        style={{ marginLeft: "20px" }}
                                    >
                                        {loadingSpinner ? (
                                            <Loader
                                                type="ThreeDots"
                                                color="#eee"
                                                height={16}
                                                width={25}
                                            />
                                        ) : (
                                            "Registrera"
                                        )}
                                    </button>
                                </>
                            ) : null}
                        </div>
                        {!regEmail ? (
                            <input
                                type="submit"
                                id="registerButton"
                                className={styles.loginButton}
                                style={{ marginLeft: "0px" }}
                                value={"Registrera"}
                            />
                        ) : null}
                    </form>
                    <div className={styles.regMessageContainer}>
                        <div className={styles.regText} style={{ marginLeft: "60px" }}>
                            <p>eller logga in </p>{" "}
                            <p
                                className={styles.regLink}
                                onClick={() => {
                                    if (!loadingSpinner) {
                                        history.push("/")
                                    }
                                }}
                            >
                                {" "}
                                här{" "}
                            </p>
                        </div>
                        <div className={styles.errorTextRegPage}>
                            {Object.keys(errors).length > 0 ? (
                                <>{errors[Object.keys(errors)[0].toString()].message}</>
                            ) : loginError ? (
                                loginError
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterPage
