export const homeText00 = `
Processen är cyklisk, eller om man så vill sker stegvis.Arbetet börjar med att riskerna i organisationen
identifieras, vilket sker i riskportföljen. Riskportföljen är ett levande dokument som hela tiden visar de 
risker som finns. En internkontrollplan – IKP – som alltså visar vilka risker organisationen vill ha lite 
extra fokus på initieras(skapas) och är då under initieringsfasen en spegling av riskportföljen, för de 
risker som har markerats att de ska ingå i en IKP.IKP:n förbereds genom att man arbetar med
riskerna, dokumenterar dom och lägger till både riskhanteringsåtgärder och kontroller.Samtliga 
begrepp och delar i processen förklaras närmre längre fram i denna manual. När perioden för IKP: n 
startar aktiveras planen och arbetet med att följa upp de särskilda riskerna startar då, parallellt med att 
arbetet med övriga risker fortgår (i riskportföljen). När perioden närmar sig sitt slut följs både riskerna 
och planen i sin helhet upp och avrapporteras.
`
export const homeText01 = `

I hemfliken syns det du har att göra i programmet – internkontrollplaner att hantera eller
riskhanteringsåtgärder eller kontroller att utföra. Du når allt du behöver göra direkt från denna vy och
det är här du främst rör dig, framförallt under året med det löpande arbetet med riskerna.
`

export const homeText02 = `
När internkontrollplaner är igångsatta – med arbetet inför perioden eller under den aktiva perioden –
syns dessa i hemvyn för administratörer och objektsägare. Här syns sista datum för attest och det går
också att utföra attesten. Attestera genom att klicka på knappen, och ta bort attesten om
förutsättningarna ändras genom att klicka på knappen igen.
En plan som är färdig, där arbetet med kontrollerna är klart, ska avrapporteras (se mer under
internkontrollplan nedan). Det görs på samma sätt som vid attesteringen, genom att klicka på knappen
till höger. För avrapportering dyker då en textruta upp, där kommentarer till internkontrollplanen i sin
helhet kan lämnas (enskilda risker kommenteras i sina respektive avrapporteringar). Du kan
närsomhelst gå tillbaka och redigera informationen i takt med att arbetet fortlöper. Först när du känner
dig färdig med informationen markerar du avrapporteringen som ”klar”, vilket ger en signal till
administratören att avrapportering är färdig för ert organisationsobjekt. `
export const homeText03 = `
Här ser du om det finns några riskhanteringsåtgärder kopplade till dig att utföra. Klicka på
riskhanteringsåtgärden för att öppna upp den och klicka på själva risken för att öppna den. I denna
översikt ser du vilka riskhanteringsåtgärder det åligger dig att utföra, vilket organisationsobjekt och
vilken risk de tillhör. Du ser också hur ofta åtgärderna ska utföras och det senast skedde. En röd
kalendersymbol dyker upp om du har missat att rapportera en åtgärd.`
export const homeText04 = `
Här ser du om det finns några kontroller kopplade till dig att utföra. Klicka på kontrollen för att öppna
upp den och klicka på själva risken för att öppna den. I denna översikt ser du vilka kontroller det
åligger dig att utföra, vilket organisationsobjekt och vilken risk de tillhör. Du ser också hur ofta
kontrollerna ska utföras och det senast skedde. En röd kalendersymbol dyker upp om du har missat
att rapportera en kontroll. Finns det flera internkontrollplaner skapade kan du växla mellan dessa
genom att klicka på respektive plan ovanför kontrollerna. `

export const homeText1 = `Processen
Processen är cyklisk, eller om man så vill sker stegvis.Arbetet börjar med att riskerna i organisationen
identifieras, vilket sker i riskportföljen. Riskportföljen är ett levande dokument som hela tiden visar de 
risker som finns. En internkontrollplan – IKP – som alltså visar vilka risker organisationen vill ha lite 
extra fokus på initieras(skapas) och är då under initieringsfasen en spegling av riskportföljen, för de 
risker som har markerats att de ska ingå i en IKP.IKP:n förbereds genom att man arbetar med
riskerna, dokumenterar dom och lägger till både riskhanteringsåtgärder och kontroller.Samtliga 
begrepp och delar i processen förklaras närmre längre fram i denna manual. När perioden för IKP: n 
startar aktiveras planen och arbetet med att följa upp de särskilda riskerna startar då, parallellt med att 
arbetet med övriga risker fortgår (i riskportföljen). När perioden närmar sig sitt slut följs både riskerna 
och planen i sin helhet upp och avrapporteras.`

export const homeText2 = `
        I riskportföljen samlas alla risker organisationen identifierar. Det är ett levande dokument
        som hela tiden bör uppdateras och där riskhanteringsåtgärder - åtgärder som syftar till att minska
        risken - rapporteras och dokumenteras.`

export const homeText3 = `
        Arbetet med en internkontrollplan - IKP - börjar med att den initieras. Det ger en signal till organisationen
        om en IKP ska finnas för en viss period och att arbetet med den planen börjar.`

export const homeText4 = `
        Under tiden planen är initierad är den en spegling av riskportföljen och du väljer vilka risker som ska ingå i IKP:n.
        Däri förbereds de kontroller som ska finnas, som syftar till att minska risken och kan t.ex. vara att
        riskhanteringsåtgärder utförs och fungerar väl.`
export const homeText5 = `
        När perioden för en IKP startar aktiveras planen. Det går då att utföra kontroller, rapportera status för dessa och
        lämna avvikelserapporter och åtgärdsförslag.`
export const homeText6 = `
        När perioden närmar sig sitt slut rapporteras status för respektive risk, hur riskarbetet gått och om något inträffat.
        Varje organisationsobjekt avrapporterar också hur arbetet med IKP gått i sin helhet för det objektet. `

export const organisationText1 = `
Organisationsträdet byggs upp på det sätt ni vill spegla organisationen i er kommun, på det sätt som
arbetsprocessen i internkontrollarbetet är uppbyggt.`
export const organisationText2 = `
Som administratör kommer du åt samtliga organisationer som finns upplagda och kan också skapa
nya. Byt organisation i drop-down-listan högst upp, och skapa en ny genom att trycka på plustecknet.`
export const organisationText3 = `
Objektsägare och användare ser den organisation de är upplagda inom.`
export const organisationText4 = `
Administratörer och objektsägare kan förändra organisationsträdet, administratören på samtliga nivåer
och objektsägare från sin nivå och neråt. Användare i varje objekt kan därefter skapa risker och agera
inom objektet. Tänk på att bygga organisationsträdet så att det möjliggör rätt behörigheter. En
objektsägare har behörighet till sitt objekt och de nedanför på grenen / grenarna under. En användare
bara till ett enskilt objekt. Om ni vill ge någon tillgång till två grenar i ett träd men inte alla nedåt kan
det vara en idé att bygga en mellannivå som knyter samman just de grenarna. `


export const riskText1 = `
I riskportföljen samlas organisationens samtliga risker. Personer med olika behörigheter ser de risker
som finns tillgängliga just för dom, som en samlad vy. Riskportföljen har inte olika versioner från år till
år, utan är ett levande dokument och en samlingsplats för de risker som är aktuella för organisationen.
De risker som bedöms behöva lite extra fokus under en period markeras till internkontrollplanen som
är tidsbestämd, vilket förklaras närmre längre fram i denna manual. 
`
export const riskText2 = `
Skapa en ny risk genom att trycka på plustecknet ”skapa ny risk”. I den vyn benämner och beskriver
du risken. Beskriven följer med till riskportföljen och syns i den utfällda vyn. Välj organisation och
organisationsobjekt utifrån de behörigheter du har och sortera in risken i kategori, ev. process och
delprocess.
Bedöm värden för sannolikhet och konsekvens för risken. Genom att flytta markören bland de olika
alternativen ser du beskrivningarna för de olika värdena som finns angivna i din organisation, som kan
fungera som en vägledning och hjälp i valet av sannolikhet / konsekvens. Motivera också gärna
bedömningen i boxarna nedanför poängen. Spara risken när du är färdig. Du kan närsomhelst gå in
och redigera risken, eller ta bort den helt och hållet.  
`
export const riskText3 = `
Genom att klicka på ikonen för riskgrupper kommer du åt befintliga riskgrupper och kan också skapa
nya. Riskgrupperna har några olika syften:
`
export const riskText4 = `
Riskerna kan genom riskgruppen skapas flera åt gången. En riskanalys över ett särskilt
område kan då ske och dessa kan ställas mot varandra med större tydlighet. På samma
sätt som när man skapar enskilda risker kan man sen gå in i risken och beskriva den
närmre och redigera / ta bort risken. De element som kan definieras i gruppen är
benämning, huvudprocess, delprocess, kategori, organisation, avdelning samt bedömning
av sannolikhet och konsekvens. Man kan antingen definiera dessa element som förvalda
och då får varje ny risk i riskgruppen samma definition eller så kan de lämnas tomma och
definieras för varje enskild risk i riskgruppen. Elementen går också att redigera i
riskgruppsvyn. Riskerna måste sparas för att synas i riskportföljen. En riskgrupp går
närsomhelst att gå in i och redigera. 
`
export const riskText5 = `
En risk som man vill ska finnas på flertalet ställen i organisationen skapas med fördel
genom riskgrupper. Det är då enkelt att duplicera en risk till så många organisationsobjekt
som önskas och säkerställa att risken finns överallt. Exempel på ett tillfälle där detta
lämpar sig är att säkerställa att alla organisationsobjekt hanterar risk kopplad till GDPR
eller vid ett förändringsarbete som sker i organisationen.
`
export const riskText6 = `
Riskerna finns tillgängliga både i riskportföljerna och via riskgruppen. En riskgrupp kan upplösas,
riskerna försvinner inte utan finns kvar i riskportföljen men utan koppling till gruppen. 
`

export const riskText7 = `
Riskhanteringsåtgärder läggs upp inne i risken. Det är de åtgärder som utförs för att minska risken, att
minska sannolikheten för att den ska inträffa. Exempel kan vara systemkontroller, dualitet i beslut,
larmanordningar eller något annat. I riskhanteringsåtgärden (benämns RHÅ) sätts hur ofta åtgärden
ska utföras och av vem. Här rapporteras sen åtgärden, avvikelserapporter läggs upp och ev.
förbättringsförslag.
Om rapportering på en riskhanteringsåtgärd uteblivit enligt den frekvens den är upplagd på, dyker det
upp en röd kalendersymbol som en påminnelse, både i riskportföljen för alla som har tillgång till risken
och i hemvyn för den som är ansvarig för risken.
Man kan också använda riskhanteringsåtgärderna som dokumentation, utan att rapportera på dom.
Förslagsvis läggs då inte upp någon frekvens, så slipper statussymbolen för utebliven rapportering
dyka upp. 
`
export const riskText8 = `
Portföljen fungerar som en samlingsplats för alla risker i organisationen. Personer med olika
behörigheter ser de risker som finns tillgängliga just för dom, som en samlad vy. Riskportföljen har inte
olika versioner från år till år, utan är ett levande dokument och en samlingsplats för de risker som är
aktuella för organisationen. De risker som bedöms behöva lite extra fokus under en period markeras
till internkontrollplanen som är tidsbestämd, vilket förklaras närmre längre fram i denna manual.
Genom att klicka på pilen uppe till vänster i riskportföljen minimerar du sidopanelen och får fram
ytterligare en kolumn i riskportföljen; delprocess.
Portföljen kan sorteras och filtreras i en mängd olika kombinationer. Den är också sökbar på
risknamnet. Genom att klicka på kalendersymbolen får du fram alla risker som har en försenad
rapportering och genom att klicka på IKP ser du samtliga risker som är markerade till
internkontrollplanen. Du kan flytta markören för riskvärde för att se riskerna inom det intervall du väljer.
Genom att klicka på någon av kolumnbenämningarna sorterar du riskerna utifrån den benämningen.
Klickar du på visa utfälld lista öppnas riskportföljen upp och beskrivningen av risken samt vilka
riskhanteringsåtgärder som finns kopplade till den visas.
Riskportföljen används ofta för att presentera underlaget av risker, till beslutande ledning, nämnd eller
styrelse, för att kunna påvisa utifrån vilket underlag urvalet till internkontrollplanen har skett samt för
att kunna ge en totalbild över organisationens risker. 
`
export const riskText9 = `
Genom att klicka på knappen analysera kommer du till en vy där riskerna sorteras utifrån olika
parametrar på ett sätt som är gjort både för att skapa en bättre förståelse för riskernas
sammansättning och för att lättare kunna presentera riskerna överskådligt. Också i analysen kan
filtrering på organisationsobjekt göras och man kan välja om man vill inkludera underobjekt till valt
objekt eller inte. Användarbehörigheten styr även här vilka analysobjekt man ser
`
export const riskText10 = `
Från analysvyn kan man ta sig vidare till en samlad vy för avvikelserapporter respektive
förbättringsförslag. Även dessa går att filtrera på organisationsobjekt och inkluderat eller inte
inkluderat för underobjekt. 
`
export const riskText11 = `
Både riskportföljen som lista och analysvyn med avvikelserapporter och förbättringsförslag går att
exportera. Riskportföljen är valbar om den skall vara utfälld med mer detaljer eller som enkel lista,
samt om den ska exporteras i pdf-format eller i excel-format. Analysvyn exporteras till pdf. 
`

export const ikpText1 = `
Internkontrollplanerna används för att välja ut de risker som skall ha extra fokus under en viss 
tidsperiod, vanligen ett verksamhetsår. Alla risker finns i riskportföljen och det är där man markerar om 
en risk ska finnas med på interkontrollplanen. När en ny internkontrollplan skapas är den från början 
klassad som initierad. Det går då en signal till objektsägare att det finns en process för ny 
internkontrollplan påbörjad. Så länge planen endast är initierad speglar den riskportföljen och alla 
ändringar som görs i portföljen kommer automatiskt med till internkontrollplanen. Det är via 
riskportföljen man lägger upp riskhanteringsåtgärder (för det löpande riskarbetet) och kontroller till 
respektive risk (för uppföljningen i internkontrollplanen). När förarbetet med riskerna till 
internkontrollplanen är färdigt, aktiverar systemadministratören planen, för att sedan låsa den tills 
tidsperioden för planen påbörjas. Vanligen fattas det då beslut om planen i ansvarig nämnd eller 
styrelse. 
`
export const ikpText2 = `
När perioden för planen börjar, låser systemadministratören upp planen. Den är då öppen för att utföra 
kontroller i och göra olika uppföljningar. Avvikelserapporter och förbättringsförslag kan rapporteras. 
Kontrollerna som ska utföras syns nu i hemvyn för respektive ansvarig person. Om det finns en 
frekvens inlagd i kontrollen och kontrollen inte utförs inom den frekvensen dyker en röd 
kalendersymbol upp som visar att kontrollen är försenad. 
`
export const ikpText3 = `
När perioden för internkontrollplanen närmar sig sitt slut ska planen avrapporteras. För varje risk finns 
en sektion för detta. Risken färgmarkeras för att visa status (grön, gul eller röd) och det finns utrymme 
för att beskriva utfall och ev. åtgärd. Åtgärden kan skickas till uppgifter om så önskas. Planen i sin 
helhet avrapporteras också, av respektive objektsägare. Planen markeras därefter som klar, för att 
visa att det objektet är färdigrapporterat. När arbetet med avrapporteringen är färdigt låses planen av 
systemadministratören. Inga ändringar kan då göras, men planen kan fortsatt exporteras och 
analyseras. 
`
export const ikpText4 = `
Flera planer kan vara igång samtidigt och det är vanligt att en initierad plan påbörjas innan den 
tidigare är färdigrapporterad. På så vis kan man planera för nästkommande år även om det förra inte 
är stängt ännu. 
`
export const ikpText5 = `
Du skapar enkelt en ny plan genom symbolen med plustecknet ”skapa ny internkontrollplan”. Väl där 
väljer du organisation, datum som planen sträcker sig över och datum för attest. Det går inte att ha två 
planer för precis samma tidsperiod. Du kan också välja att ge en beskrivning av internkontrollplanen, 
om det är någon information kring den som ska vidare i organisationen, t.ex. ett särskilt fokusområde 
eller instruktion. 
`
export const ikpText6 = `
När planen är skapad är den initierad, dvs. är under arbete och förberedelser inför själva planperioden 
pågår. Så länge planen endast är initierad speglar den riskportföljen och alla ändringar som görs i 
portföljen kommer automatiskt med till internkontrollplanen. Det är via riskportföljen man lägger upp de 
kontroller till respektive risk som ska utföras. När man är färdig med att förbereda riskerna som ligger 
med till internkontrollplanen attesterar man planen, för att visa systemadministratören att det 
organisationsobjekt som man representerar är färdigt för att bli en aktiv plan. 
`
export const ikpText7 = `
När förarbetet med riskerna till internkontrollplanen är färdigt, aktiverar systemadministratören planen. 
Ofta är den sen låst till dess att perioden för planen börjar. När perioden startat startar också arbetet 
med riskerna under innevarande plan, dvs. att utföra de kontroller som är ålagda risken / personen. 
`
export const ikpText8 = `
Den är då öppen för att utföra kontroller i och göra olika uppföljningar. Avvikelserapporter och 
förbättringsförslag kan rapporteras. Kontrollerna som ska utföras syns nu i hemvyn för respektive 
ansvarig person. Om det finns en frekvens inlagd i kontrollen och den inte utförs inom den frekvensen 
dyker en röd kalendersymbol upp som visar att kontrollen är försenad. 
`
export const ikpText9 = `
Systemadministratören kan aktivera planen även om inte alla organisationsobjekt är attesterade, 
attesten är mer än hjälp för att kunna se hur arbetet fortskrider. 
`
export const ikpText10 = `
Systemadministratören kan låsa en plan, genom att klicka på hänglåssymbolen. Planen blir då stängd 
för alla utan admin-behörighet och syns inte heller på hemvyn. Låsningsfunktionen används ofta när 
en plan är förberedd och klar, i väntan på beslut eller i väntan på att tidsperioden för planen ska börja.
`
export const ikpText11 = `
Kontroller till en internkontrollplan läggs upp inne i risken. Det är de åtgärder som utförs för att minska 
risken, att minska sannolikheten för att den ska inträffa. Ofta är kontrollerna ett sätt att säkerställa att 
riskhanteringsåtgärderna fungerar som de ska eller att på annat sätt minska att risken inträffar. 
Exempel kan vara rapportering eller stickprov. 
Här rapporteras sen också uppföljning av kontroller, avvikelserapporter och ev. förbättringsförslag. 
`
export const ikpText12 = `
Om rapportering på en kontroll uteblivit enligt den frekvens den är upplagd på, dyker det upp en röd 
kalendersymbol som en påminnelse, både i internkontrollplansvyn för alla som har tillgång till risken 
och i hemvyn för den som är ansvarig för risken. 
`
export const ikpText13 = `
Både riskerna och planen som helhet avrapporteras vid periodens slut. 
`
export const ikpText14 = `
Inne i en risk, i en aktiv internkontrollplan, finns ett stycke för avrapportering. Där markeras risken med 
grön, gul eller röd – klar, avvaktande eller varning, utifrån hur utfallet för risken blivit. Det finns en 
rapporteringsdel där kommentarer lämnas och ett åtgärdsfält där förslag till åtgärd kan lämnas. Om en 
åtgärd lämnas kan denna också skickas till uppgiftslistan, för att säkerställa att den inte faller mellan 
stolarna. Läs mer om uppgifter i hjälpavsnittet på uppgiftssidan.
`
export const ikpText15 = `
Planen ska också avrapporteras i sin helhet. Detta görs via knappen ”avrapportera” vid planen på 
hemvyn. En pop-up dyker då upp där utfallet av arbetet med planen i sin helhet kan beskrivas. Det går 
att gå tillbaka till denna avrapporteringsvy flera gånger. Markera att du är färdig med avrapporteringen 
genom att kryssa i rutan ”markera som klar”. En administratör kan se hur avrapportering fortlöper 
genom att klicka in i en plan och välja ”attestering”. Där syns vilka objekt som är markerade som klara 
och om det finns någon kommentar lämnad. Där syns också vem som avrapporterat vilket objekt. 
Genom att gå in i ”sammanställning” syns samtliga rapporter som lämnats. Observera att endast 
klarmarkerade objekt hamnar i sammanställningen. 
`
export const ikpText16 = `
Internkontrollplanen påminner mycket om riskportföljen i utseende. Personer med olika behörigheter 
ser de risker som finns tillgängliga just för dom, som en samlad vy. 
`
export const ikpText17 = `
Genom att klicka på pilen uppe till vänster i internkontrollplanen minimerar du sidopanelen och får 
fram ytterligare en kolumn i internkontrollplanen; delprocess. 
`
export const ikpText18 = `
Planen kan sorteras och filtreras i en mängd olika kombinationer. Den är också sökbar på risknamnet. 
Genom att klicka på kalendersymbolen får du fram alla risker som har en försenad rapportering. Du
kan flytta markören för riskvärde för att se riskerna inom det intervall du väljer. Genom att klicka på 
någon av kolumnbenämningarna sorterar du riskerna utifrån den benämningen. 
`
export const ikpText19 = `
Klickar du på visa utfälld lista öppnas internkontrollplanen upp och beskrivningen av risken samt 
motivering till varför risken är på internkontrollplanen visas. Det syns också vilka kontroller som finns 
kopplade till risken för den här internkontrollplansperioden. 
`
export const ikpText20 = `
Den aktiva planen har en något annorlunda utfälld lista. Där syns den färgmarkering som valts när 
risken har avrapporterats. Utöver beskrivning av risken syns också utfallet och eventuellt förslag till 
åtgärd. Kontrollerna är angivna, samt om några avvikelserapporter eller förbättringsförslag har 
lämnats.
`
export const ikpText21 = `
Planerna kan exporteras både som enkel och utfälld lista, till pdf eller excel. 
`
export const ikpText22 = `
Genom att klicka på knappen analysera kommer du till en vy där riskerna sorteras utifrån olika 
parametrar på ett sätt som är gjort både för att skapa en bättre förståelse för riskernas 
sammansättning och för att lättare kunna presentera riskerna överskådligt. Också i analysen kan 
filtrering på organisationsobjekt göras och man kan välja om man vill inkludera underobjekt till valt 
objekt eller inte. Användarbehörigheten styr även här vilka analysobjekt man ser. 
`
export const ikpText23 = `
Från analysvyn kan man ta sig vidare till en samlad vy för avvikelserapporter respektive 
förbättringsförslag. Även dessa går att filtrera på organisationsobjekt och inkluderat eller inte 
inkluderat för underobjekt. 
`
export const ikpText24 = `
Både internkontrollplanen som lista och analysvyn med avvikelserapporter och förbättringsförslag går 
att exportera. Internkontrollplanen är valbar om den skall vara utfälld med mer detaljer eller som enkel 
lista, samt om den ska exporteras i pdf-format eller i excel-format. Analysvyn exporteras till pdf. 
`
export const todoText1 = `
En uppgift skapas för att markera att något av engångskaraktär ska utföras, eller för att något skall 
utföras längre fram i tiden, t.ex. något som framkommit som en åtgärd i en risk. Uppgifterna är ett bra 
sätt för att förhindra att något faller mellan stolarna. 
`
export const todoText2 = `
En uppgift kan skapas direkt i uppgiftsfliken men också via avrapporteringen av en risk i en 
internkontrollplan. Om en åtgärd beskrivs dyker uppgiftsknappen upp och kan då skapas därifrån. En 
sådan uppgift skickas direkt till uppgiftslistan. 
`
export const todoText3 = `
Det är inte en särskild person som är ansvarig för en uppgift utan den kopplas till ett 
organisationsobjekt. Samtliga användare och objektsägare har då tillgång till uppgiften. Uppgiften syns 
både i uppgiftslistan i sidopanelen och via hemvyn, för de användare som är knutna till det objektet. 
När en uppgift klarmarkeras visas den inte längre i uppgiftslistan eller på hemvyn men kan tas fram 
genom att trycka på ”visa klarmarkerade uppgifter”. 
`
export const userText1 = `
I denna flik skapas nya användare samt administreras befintliga. De användare du har behörighet att 
hantera är understrukna. Du har behörighet att hantera användare som är i ditt objekt eller något 
objekt nedanför, om du är objektsägare, och tillgång till samtliga användare som administratör i 
programmet. Du som användare har inte behörighet att hantera användare. 
`
export const userText2 = `
Klicka på plustecknet vid skapa ny användare. Här väljer du först organisation och därefter 
organisationsobjekt. Lägg till namn och epost, samt vilken behörighetsnivå användaren ska ha (se 
nedan). Ett mail om registrering går nu iväg till användaren, med en länk till programmet. 
`
export const userText3 = `
Genom att välja vilken typ av användare en person ska vara och genom att knyta denne till ett visst 
organisationsobjekt denne hör, styrs behörigheterna för användaren. En administratör har tillgång till 
samtliga organisationer (om det finns flera) och kan agera inom varje organisationsobjekt, t.ex. genom 
att skapa risker. 
En objektsägare har tillgång till sitt objekt och till de som finns nedanför, i sin gren. En objektsägare 
har inte tillgång uppåt i organisationsträdet eller åt sidan. En användare har tillgång till endast sitt 
enskilda organisationsobjekt. Om ni vill ge någon tillgång till två grenar i ett träd men inte alla nedåt 
kan det vara en idé att bygga en mellannivå som knyter samman just de grenarna. Detta görs i så fall i 
organisationsfliken. 
`
export const userText4 = `
Klicka på namnet för att redigera användaren (namnet måste vara understruket för att du ska ha 
behörighet att utföra åtgärden). Inne i användarens profil kan du redigera namn och behörigheter, 
samt ta bort användaren. Om en användare tas bort finns namnet kvar på tidigare utförda kontroller 
och uppgifter. 
`
export const userText5 = `
Klicka på namnet för att komma in i användarens profil, och därefter menyvalet ”återställ lösenord”. 
Användaren kan därefter registrera sig på nytt och välja ett nytt lösenord. 
`
export const adminText1 = `
I administratörsinställningarna sköter du som administratör de olika val och inställningar som ska gälla 
runtom i programmet, för alla användare. Dessa inställningar bör göras innan arbetet påbörjas i 
programmet, men kan också ändras efterhand. 
`
export const adminText2 = `
Storleken på riskmatrisen visar vilka grader på sannolikhet och konsekvens ni vill arbeta med i 
organisationen. Nivåerna 3 x 3, 4 x 4 och 5 x 5 finns tillgängliga. Om riskmatrisinställningarna ändras 
när risker finns skapade, följer inte förändringarna med till de tidigare riskerna. Dvs. om 
sannolikshetstalet är satt till nivå 5 och det alternativet inte längre finns i riskmatrisen, kommer ändå 
värdet för den tidigare skapta risken vara fem. Vid en förändring av riskmatris rekommenderar vi att 
man ser över tidigare skapta risker och dess riskvärden. 
`
export const adminText3 = `
Ni kan själva för er organisation benämna de olika nivåerna för sannolikhet och konsekvens och hur ni 
definierar dessa. Det kan vara bra att ge olika exempel på tidsintervall för hur ofta en risk inträffar för 
att man i organisationen ska kunna värdera hur stor sannolikhet en viss siffra bör uppfattas. Likaså på 
konsekvens, vad innebär ett lågt respektive ett högt tal? Exempeltexter finns som start, men redigeras 
genom att man klickar på siffran. 
`
export const adminText4 = `
De olika riskvärdesgrupperna finns för att illustrera i vilket kluster en risk hamnar. Genom att klicka på 
definitionen av respektive grupp kan du redigera den om ni vill använda någon annan formulering. 
`
export const adminText5 = `
Här lägger ni upp de processer ni vill sortera riskerna i, om ni arbetar processtyrt, helt eller delvis, i er 
organisation. Ni kan också lägga upp delprocesser för att specificera ytterligare. Alla processer och 
delprocesser blir tillgängliga för samtliga delar av organisationen.
`
export const adminText6 = `
Om varje nämnd eller förvaltning har många egna processer kan en idé vara att ha en process för 
varje nämnd och däri lägga upp deras egna processer som delprocesser. Kommunövergripande 
processer kan fortsatt finnas som gemensamma.
`
export const adminText7 = `
Om en process eller en delprocess som används i en risk tas bort, finns den kvar i just den/de 
riskerna. Processen försvinner dock från processlistan och blir inte heller valbar för nya risker. 
`
export const adminText8 = `
Här lägger ni upp de kategorier ni vill använda i programmet. Alla kategorier blir tillgängliga för alla 
delar av organisationen. Kategorierna används för att enklare sortera riskerna, både i riskportföljen 
och internkontrollplanerna. Om en kategori som används i en risk tas bort, finns den kvar i just den/de 
riskerna. Kategorin försvinner dock från kategorilistan och blir inte heller valbar för nya risker. 
`