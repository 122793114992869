import React, { createContext, useState } from "react";
export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [sideBar, setSideBar] = useState(true);
  const [sideBarActiveItem, setSideBarActiveItem] = useState("home");
  const [activePortfolioId, setActivePortfolioId] = useState(null);


  const [activeUser, setActiveUser] = useState(() => {
    if (process.env.NODE_ENV === 'development') {
      return { ...JSON.parse(localStorage.getItem("user")), token: localStorage.getItem("token") }
    }
    return null
  });
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    if (process.env.NODE_ENV === 'development') {
      return true
    } else return false
  });

  // const [isLoggedIn, setIsLoggedIn] = useState(true);
  // const [activeUser, setActiveUser] = useState({
  //   admin: true,
  //   objectManager: true,
  //   registered: true,
  //   _id: "60a7997b55f00f000a6fc8d5",
  //   customer: "ic-test",
  //   customerId: "609156b6e824b921806aac4e",
  //   organisation: "60915719e824b921806aac4f",
  //   department: "60915719e824b921806aac4f",
  //   email: " nf@test.test",
  //   firstName: "Nicklas A",
  //   lastName: "Forsell",
  //   token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImFkbWluIjp0cnVlLCJvYmplY3RNYW5hZ2VyIjp0cnVlLCJyZWdpc3RlcmVkIjp0cnVlLCJfaWQiOiI2MGE3OTk3YjU1ZjAwZjAwMGE2ZmM4ZDUiLCJjdXN0b21lciI6ImljLXRlc3QiLCJjdXN0b21lcklkIjoiNjA5MTU2YjZlODI0YjkyMTgwNmFhYzRlIiwiZW1haWwiOiJuZkB0ZXN0LnRlc3QiLCJmaXJzdE5hbWUiOiJOaWNrbGFzIiwibGFzdE5hbWUiOiJGb3JzZWxsIiwib3JnYW5pc2F0aW9uIjoiNjA5MTU3MTllODI0YjkyMTgwNmFhYzRmIiwiZGVwYXJ0bWVudCI6IjYwOTE1NzE5ZTgyNGI5MjE4MDZhYWM0ZiIsImNyZWF0ZWRBdCI6IjIwMjEtMDUtMjFUMTE6Mjg6NTkuMjc0WiIsInVwZGF0ZWRBdCI6IjIwMjEtMDctMDFUMTQ6MDI6MDQuMzM1WiJ9LCJpYXQiOjE2Mjg4NTIwMzQsImV4cCI6MTYyODg2NjQzNH0.6qcTw_IU1-uq8g8p7T4SQUdcuj4zTT2gZ70QpZLfraE"
  // });

  const initActiveUser = (user) => {
    setActiveUser(user);
  };

  const clearActiveUser = () => {
    setActiveUser(null)
  }

  return (
    <UserContext.Provider
      value={{
        sideBar,
        setSideBar,
        isLoggedIn,
        setIsLoggedIn,
        activeUser,
        initActiveUser,
        activePortfolioId,
        setActivePortfolioId,
        clearActiveUser,
        sideBarActiveItem,
        setSideBarActiveItem
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
