export default function calculateRiskGroupColor(prob, cons, cosoSettings) {



    if (cosoSettings.size === 3) {

        if (prob * cons >= 6) { return 'rgb(231, 37, 37)' }
        else if (prob === 3 && cosoSettings.maxProbabilityRed) {
            return 'rgb(231, 37, 37)'
        }
        else if (cons === 3 && cosoSettings.maxConsequenceRed) {
            return 'rgb(231, 37, 37)'
        }
        else if (prob * cons === 3 || prob * cons === 4) {
            return 'rgb(252, 255, 102)'
        }
        else { return 'rgb(84, 180, 122)' }


    } else if (cosoSettings.size === 4) {

        if (cons === 4 && cosoSettings.maxConsequenceRed) { return 'rgb(231, 37, 37)' }
        else if (prob === 4 && cosoSettings.maxProbabilityRed) {
            return 'rgb(231, 37, 37)'
        }
        else if (prob * cons < 4) {
            return 'rgb(84, 180, 122)'
        }
        else if (prob * cons < 8) {
            return 'rgb(252, 255, 102)'
        }
        else if (prob * cons < 12) {
            return 'rgb(254, 125, 53)'
        }
        else { return 'rgb(231, 37, 37)' }


    } else if (cosoSettings.size === 5) {


        if (cons === 5 && cosoSettings.maxConsequenceRed) { return 'rgb(231, 37, 37)' }
        else if (prob === 5 && cosoSettings.maxProbabilityRed) {
            return 'rgb(231, 37, 37)'
        }
        else if (prob * cons < 3) {
            return 'rgb(84, 180, 122)'
        }
        else if (prob * cons < 5) {
            return 'rgb(106, 226, 154)'
        }
        else if (prob * cons < 10) {
            return 'rgb(252, 255, 102)'
        }
        else if (prob * cons < 15) {
            return 'rgb(254, 125, 53)'
        }
        else { return 'rgb(231, 37, 37)' }


    }

}

