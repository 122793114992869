import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton, HelpButton, SaveButton } from '../../styles/buttons'
import styles from "./styles/CreateRiskGroupPage.module.css"
import axios from "axios"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import LoadingModal from "./../../modal/LoadingModal"

const CreateRiskGroupPage = () => {
  const history = useHistory()
  const { sideBar, activeUser } = useContext(UserContext)
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  const createRiskGroupRequest = async (groupDataToSend) => {
    const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/setting/create-risk-group", groupDataToSend, {
      headers: { authorization: "Bearer " + activeUser.token },
    });
    return res

  };

  const getRiskGroupsNames = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-risk-groups-names", {
      headers: { authorization: "Bearer " + activeUser.token, },
    });
    // let groupNames = res.data.map((group) => { return group.name.toLowerCase() })
    let groupNames = res.data.length > 0 ? res.data.map((group) => { return group.name.toLowerCase() }) : []
    if (isMounted) { setFadeInTrigger(true) }
    return groupNames;
  };

  const { data } = useQuery(["readRiskGroupsNames"], getRiskGroupsNames, {});
  const { register, handleSubmit, formState: { errors } } = useForm({});

  const submitNewGroup = async (datan) => {
    // console.log('datan till submitNewGroup', datan);
    let rass = await createRiskGroupRequest(datan);

    if (rass.status === 200) {
      history.push("/risk-groups")
    }

  };

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />

      <>
        <PageTitle>Skapa ny riskgrupp</PageTitle>
        <PageDelimiter />
        <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Gå tillbaka</PageHeaderButton>
        <SaveButton icon="save" onClick={handleSubmit(submitNewGroup)} >Skapa riskgrupp</SaveButton>
        <HelpButton></HelpButton>
      </>

      <div style={{ width: "750px", marginBottom: "50px", marginTop: "40px" }}>
        <div className="bodyText" style={{ marginBottom: "16px" }}>
          Här skapar du en ny riskgrupp. Riskgrupper kan exempelvis användas för att koppla likadana risker över olika organisationer eller
          avdelningar, som en slags mall? När gruppen är skapad kan du klicka in i den
          och skapa risker på ett mer överskådligt sätt. Du kan dessutom gå in i befintliga risker från riskportföljen och koppla dem till grupper.
        </div>
        {/* <div className="bodyText">
          Om det bara är administratörer så blir det mycket lättare.
        </div> */}
      </div >

      <form className={styles.formContainer} autoComplete="off" >
        <div className={styles.textInputRow}>
          <label>Benämning:</label>
          <input name="name" {...register('name', {
            required: "Fyll i benämning",
            minLength: { value: 2, message: "Minst två bokstäver i benämning" },
            maxLength: { value: 30, message: "Max trettio bokstäver i benämning" },
            validate: (value) => {
              return data.includes(value.toLowerCase().trim()) ? "Finns redan en riskgrupp med detta namn" : true
            }
          })} />
        </div>
        <div className={styles.textInputRow}>
          <label>Beskrivning: </label>
          <textarea
            name="description"
            {...register('description')}
            type="textarea"
            className={styles.createPortfolioItem}
          />
        </div>
      </form>
      {Object.keys(errors).length > 0 ? <p style={{ color: "red" }}>{errors[Object.keys(errors)[0].toString()].message}</p> : null}
    </div >
  )
}

export default CreateRiskGroupPage