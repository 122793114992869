export default function compareForSort(key, order = 'asc') {
    return function innerSort(a, b) {

        if (key === "departmentName") {

            let compared = 0;
            if (a.department?.name.toUpperCase() < b.department?.name.toUpperCase()) {
                compared = 1;
            } else if (a.department?.name.toUpperCase() > b.department?.name.toUpperCase()) {
                compared = -1;
            }
            return (
                (order === 'desc') ? (compared * -1) : compared
            );
        }
        if (key === "organisationName") {

            let compared = 0;
            if (a.organisation?.name.toUpperCase() < b.organisation?.name.toUpperCase()) {
                compared = 1;
            } else if (a.organisation?.name.toUpperCase() > b.organisation?.name.toUpperCase()) {
                compared = -1;
            }
            return (
                (order === 'desc') ? (compared * -1) : compared
            );
        }

        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}