import styled from "styled-components"

export const PageTitle = styled.h1`
    /* font-size: 1.4rem; */
    /* margin-bottom: 1.2rem; */
    font-size: 22px;
  font-weight: 350;
  margin-bottom: 14px;
`

export const PageDelimiter = styled.hr`
    --page-delimiter-color: rgb(117, 151, 197, 0.7);

    width: 55vw;
    margin-left: 0;
    margin-bottom: 18px;

    border-bottom: 1px;
    border-color: var(--page-delimiter-color);
`

export const Space = styled.div`
    height: 40px;
`

export const P = styled.p`
text-align: left;
text-indent: 0px;
`
