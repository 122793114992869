import React from 'react'
import styles from './styles/RiskListHeader.module.css'

export default function RiskListHeader(props) {

  return (
    <div className={styles.headerContainer}>
      <div>Namn</div>
      <div>Huvudprocess</div>
      <div>Delprocess</div>
      <div>Kategori</div>
      <div>Organisation</div>
      <div>Avdelning</div>
      <div>S</div>
      <div>K</div>
    </div>
  )
}
