import React, { useState, useEffect } from "react";
import styles from "./ViewCosoDiagram.module.css";

const CosoDiagram = (props) => {
  const [xArray, setXArray] = useState([]);
  const [yArray, setYArray] = useState([]);
  // let cons = 3
  let cons = props.consequence
  // let prob = 2
  let prob = props.probability

  useEffect(() => {
    let z = [];
    let y = [];

    for (let index = 1; index <= props.scale; index++) {
      z.push(index);
      y.unshift(index);
    }
    setXArray(z);
    setYArray(y);
  }, [props]);

  const renderSmall = () => {
    return yArray.map((elb, indexa) => {
      return (
        <div key={indexa} className={styles.flexContainerSmall}>
          {xArray.map((ela, indexb) => {
            return (
              <div
                style={{ cursor: "auto" }}
                className={
                  ela * elb >= 6
                    ? `${styles.flexItemSmall} ${styles.orangeDarkColor}`
                    : ela === 3 && props.maxProbabilityMakesRed
                      ? `${styles.flexItemSmall} ${styles.orangeDarkColor}`
                      : elb === 3 && props.maxConsequenceMakesRed
                        ? `${styles.flexItemSmall} ${styles.orangeDarkColor}`
                        : ela * elb === 3 || ela * elb === 4
                          ? `${styles.flexItemSmall} ${styles.yellowColor}`
                          : `${styles.flexItemSmall} ${styles.greenDarkColor}`

                }
                key={indexb}
              >{ela === prob && elb === cons ? (
                // <i className={`far fa-circle ${styles.markerIconSmall}`}></i>
                <div className={styles.circleIcon}></div>
              ) : null}</div>
            );
          })}
        </div>
      );
    });
  };

  const renderMedium = () => {
    return yArray.map((elb, indexa) => {
      return (
        <div key={indexa} className={styles.flexContainerMedium}>
          {xArray.map((ela, indexb) => {
            return (
              <div
                style={{ cursor: "auto" }}
                className={
                  ela === 4 && props.maxProbabilityMakesRed
                    ? `${styles.flexItemMedium} ${styles.orangeDarkColor}`
                    : elb === 4 && props.maxConsequenceMakesRed
                      ? `${styles.flexItemMedium} ${styles.orangeDarkColor}`

                      : ela * elb < 4
                        ? `${styles.flexItemMedium} ${styles.greenDarkColor}`
                        : ela * elb < 8
                          ? `${styles.flexItemMedium} ${styles.yellowColor}`
                          : ela * elb < 12
                            ? `${styles.flexItemMedium} ${styles.orangeLightColor}`
                            : `${styles.flexItemMedium} ${styles.orangeDarkColor}`
                }
                key={indexb}
              >{ela === prob && elb === cons ? (
                // <i className={`far fa-circle ${styles.markerIconMedium}`}></i>
                <div className={styles.circleIconMedium}></div>
              ) : null}</div>
            );
          })}
        </div>
      );
    });
  };

  const renderLarge = () => {
    return yArray.map((elb, indexa) => {
      return (
        <div key={indexa} className={styles.flexContainerLarge}>
          {xArray.map((ela, indexb) => {
            return (
              <div
                style={{ cursor: "auto" }}
                className={
                  ela === 5 && props.maxProbabilityMakesRed
                    ? `${styles.flexItemLarge} ${styles.orangeDarkColor}`
                    : elb === 5 && props.maxConsequenceMakesRed
                      ? `${styles.flexItemLarge} ${styles.orangeDarkColor}`
                      : ela * elb < 3
                        ? `${styles.flexItemLarge} ${styles.greenDarkColor}`
                        : ela * elb < 5
                          ? `${styles.flexItemLarge} ${styles.greenLightColor}`
                          : ela * elb < 10
                            ? `${styles.flexItemLarge} ${styles.yellowColor}`
                            : ela * elb < 15
                              ? `${styles.flexItemLarge} ${styles.orangeLightColor}`
                              : `${styles.flexItemLarge} ${styles.orangeDarkColor}`
                }
                key={indexb}
              >{ela === prob && elb === cons ? (
                // <i className={`far fa-circle ${styles.markerIconLarge}`}></i>
                <div className={styles.circleIconMedium}></div>
              ) : null}</div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <>
      {/* {prob}-{cons} */}
      {props.scale === 4 ? (
        <div className={styles.diagramContainerMedium}>
          {renderMedium()}
          <div className={styles.probabilityMediumLabel}>Sannolikhet</div>
          <div className={styles.consequenceMediumLabel}>Konsekvens</div>
        </div>
      ) : props.scale === 5 ? (
        <div className={styles.diagramContainerLarge}>
          {renderLarge()}
          <div className={styles.probabilityLargeLabel}>Sannolikhet</div>
          <div className={styles.consequenceLargeLabel}>Konsekvens</div>
        </div>
      ) : props.scale === 3 ? (
        <div className={styles.diagramContainerSmall}>
          {renderSmall()}
          <div className={styles.probabilitySmallLabel}>Sannolikhet</div>
          <div className={styles.consequenceSmallLabel}>Konsekvens</div>
        </div>
      ) : null}
      {/* {renderLarge()} */}
    </>
  );
};

export default CosoDiagram;
