import React, { useContext, useState } from "react";
import styles from "../styles/CreateController.module.css";
import { UserContext } from "../../../contexts/UserContext";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import axios from "axios";
import { ControllerButton } from '../../../styles/buttons'
import { Frequencies } from '../../../constants/frequencies'

const CreateController = ({ setShowControllerForm, riskId, setUpdateState, riskOrg, riskDep, riskControllers }) => {
  const { activeUser } = useContext(UserContext);
  const [assignedUserId, setAssignedUserId] = useState(null)
  const [controllerAlreadyExist, setControllerAlreadyExist] = useState(false)

  const readUsersToAssign = async () => {
    let mas = await axios.post(
      process.env.REACT_APP_IC_SERVICE + "/risk/get-owners",
      {
        riskOrg: riskOrg._id,
        riskDep: riskDep._id
      },
      {
        headers: {
          ContentType: "application/json",
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    return mas.data;
  };

  const { data: userAssignList, status } = useQuery(["getAllUsersForAssignToControllers"], readUsersToAssign);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      icp: false
    },
  });

  const submitController = async (data) => {

    if (riskControllers.includes(data.name)) {
      setControllerAlreadyExist(true)
    } else {
      let payload = {}
      if (assignedUserId !== null) {
        payload = { ...data, assignedUserId, riskId }
      } else {
        payload = data
      }
      await axios.put(
        process.env.REACT_APP_IC_SERVICE + "/risk/add-controller",
        {
          riskId,
          controller: payload,
        },
        {
          headers: {
            authorization: "Bearer " + activeUser.token,
          },
        }
      );
      setShowControllerForm((statusState) => !statusState);
      setUpdateState((state) => !state);
    }
  };

  return (
    <div className={styles.controllerContainer}>
      <form autoComplete="off" onSubmit={handleSubmit(submitController)}>
        <input name="icp" type="hidden" {...register('icp')} />
        <div onClick={() => { setShowControllerForm(false) }} className={styles.iconBox}>
          <i className="fas fa-times fa-lg"></i>
        </div>
        <div className={styles.addControllerHeader}>Ny riskhanteringsåtgärd</div>
        <div className={styles.addControllerLabel}>Namn: </div>
        {Object.keys(errors).length > 0 ? <div style={{ color: "red", position: "absolute", left: "250px" }}>{errors[Object.keys(errors)[0].toString()].message}</div> : null}
        {controllerAlreadyExist ? <div style={{ color: "red", position: "absolute", left: "235px" }}>namnet finns redan!</div> : null}
        <input name="name" type="text" className={styles.nameInput} onChange={(e) => {
          setControllerAlreadyExist(false)
          e.target.value = e.target.value.trimStart().replace(/\s+/g, " ")
        }}
          onBlur={(e) => {
            e.target.value = e.target.value.trimEnd()
          }}
          {...register('name', {
            required: "Fyll i namn",
            // pattern: {
            //   value: /^[a-zåäöA-ZÅÄÖ0-9\s]*$/,
            //   message: "Bara bokstäver och siffror"
            // },
            minLength: {
              value: 2,
              message: "Minst två tecken"
            },
            maxLength: {
              value: 50,
              message: "Max 50 tecken"
            }
          })} />

        <div className={styles.addControllerLabel}>Beskrivning: </div>
        <textarea
          name="description"
          type="textarea"
          className={`${styles.fieldRow} ${styles.descriptionInput}`}
          {...register('description')}
        />
        <div>
          <div className={styles.addControllerLabel}>Metod:</div>
          <select style={{ padding: "1px" }} name="method" className={styles.fieldRow} {...register('method')}>
            <option>Ingen vald</option>
            <option>Automatisk</option>
            <option>Semiautomatisk</option>
            <option>Manuell</option>
          </select>

          <div className={styles.addControllerLabel}>Frekvens:</div>
          <select style={{ padding: "1px" }} name="frequency" className={styles.fieldRow} {...register('frequency')}>
            {Frequencies.map((opt, index) => <option key={index}>{opt}</option>)}
            {/* <option>Ingen vald</option>
            <option>Dagligen</option>
            <option>Veckovis</option>
            <option>Månadsvis</option>
            <option>Kvartalsvis</option>
            <option>Tertialsvis</option>
            <option>Årligen</option> */}
          </select>
        </div>
        <div className={styles.addControllerLabel}>Ansvarig: </div>

        <select style={{ minWidth: "200px", width: "200px", padding: "1px" }} name="responsible" className={styles.fieldRow} onChange={(e) => {
          setAssignedUserId(e.target.value)
        }}>
          <option value="">Välj användare</option>
          {status === "success"
            ? userAssignList
              .map((d) => {
                return (
                  <option key={d._id} value={d._id}>
                    {d.firstName} {d.lastName}
                  </option>
                );
              })
            : null}
        </select>
        <div className={styles.buttonPlacer}>
          <ControllerButton type="submit" >Spara</ControllerButton>
        </div>
      </form>
    </div>
  );
};

export default CreateController;
