import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../../contexts/UserContext"
import styles from "../styles/IcpInfoCard.module.css";
import axios from "axios";

export default function IcpInfocard({ risk, onlyIcp }) {
    const [hideInfoCard] = useState(true);
    const { activeUser } = useContext(UserContext)

    const editRiskRequest = async (motivation) => {
        const res = await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/risk/update-risk",
            {
                id: risk._id,
                payload: { icpMotivation: motivation },
            },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        return res;
    };
    const autoGrow = (element) => {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    }

    useEffect(() => {
        autoGrow(document.getElementById("textRutan"))
    }, [])

    return (
        <div className={styles.cardContainer}>
            <div className={styles.infoContainer}>
                <div className={styles.header}>Internkontrollplan för {risk.name} </div>
                <div className={styles.subHeader}> Tillagd i internkontrollplanen av: {risk.icpBy || ''} </div>
                <div className={styles.subHeader}>Tillagd: {risk.icpDate.slice(0, 10)}</div>
            </div>
            {hideInfoCard ? (
                <div className={styles.descriptionContainer}>
                    <label className={styles.subHeader}>Beskrivning: </label>
                    <textarea
                        name="description"
                        placeholder="Här beskriver du varför risken ska läggas till i internkontrollplanen."
                        defaultValue={risk.icpMotivation}
                        type="textarea"
                        id="textRutan"
                        className={styles.textBox}
                        onInput={(e) => autoGrow(e.target)}
                        onBlur={(e) => {
                            editRiskRequest(e.target.value)
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
}
