import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import styles from "./styles/CreateUserPage.module.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { PageHeaderButton, SaveButton } from "../../styles/buttons"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import LoadingModal from "./../../modal/LoadingModal";

const CreateUserPage = () => {
  const history = useHistory();
  const { sideBar, activeUser } = useContext(UserContext);
  const [userAlreadyExist, setUserAlreadyExist] = useState(false)
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  const createUserRequest = async (userData) => {
    const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/user/create-user", userData, {
      headers: {
        authorization: "Bearer " + activeUser.token
      },
    });
    return res
  };

  const getObjectAcceessList = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    if (isMounted) { setFadeInTrigger(true) }
    return res.data
  };

  const { data: accessList, status: accessListStatus } = useQuery(["getAccessList"], getObjectAcceessList, {
    refetchOnWindowFocus: false
  });

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    defaultValues: {
      customer: activeUser.customer, customerId: activeUser.customerId,
      organisation: "", department: ""
    },
  });
  const watchOrganisation = watch("organisation")

  const submitUser = async (datan) => {
    datan["email"] = datan.email.toLowerCase()
    let rass = await createUserRequest(datan);
    // console.log('datan', datan);
    if (rass.data) {
      history.push("/användare")
    } else {
      setUserAlreadyExist(true)
    }
  };

  const sortDropDownJustDepartments = () => {
    let initList = accessList
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep._id === activeUser.department)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  const sortDropDown = () => {
    if (watchOrganisation.toString() === "") return []
    let initList = accessList.filter((org) => { return org.ancestor === watchOrganisation.toString() || org._id === watchOrganisation.toString() })
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep.level === 1)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "organisation") { setValue("department", "") }
    })
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <>
        <PageTitle>Skapa ny användare</PageTitle>
        <PageDelimiter />
        <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Ångra</PageHeaderButton>
        <SaveButton icon="save" onClick={handleSubmit(submitUser)} >Skapa användare</SaveButton>
      </>

      <div style={{ width: "1000px", marginTop: "40px" }}>
        {activeUser.admin ? (
          <div className="bodyText">Som admin hanterar du användare för hela programmet </div>
        ) : (<div className="bodyText">Som objektsägare hanterar du användare för ditt egna och underliggande objekt i trädet.</div>)}
      </div>

      <form onSubmit={handleSubmit(submitUser)} className={styles.formContainer} autoComplete="off" >
        <input name="customer" type="hidden" {...register('customer')} />
        <input name="customerId" type="hidden" {...register('customerId')} />


        {(activeUser.admin) && accessListStatus === "success" ? (
          <>
            <div className="selectBox">
              <label>Organisation: </label>
              <select {...register("organisation", { required: "Välj organisation" })} >
                <option value="">Välj organisation</option>
                {accessList.filter((obj) => obj.level === 1).map((el, index) => { return <option value={el._id} key={index}>{el.name}</option> })}
              </select>
            </div>
            {watch("organisation") !== "" ?
              <div className="selectBox">
                <label>Avdelning: </label>
                <select {...register("department", { required: "Välj avdelning" })}>
                  <option value="">Välj avdelning</option>
                  {sortDropDown().map((dep, index) => {
                    return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                  })}
                </select>
              </div>
              : null}
          </>
        ) : null}


        {(!activeUser.admin && activeUser.objectManager) && accessListStatus === "success" && accessList[0] !== null ? (
          <>
            <div className="selectBox">
              <label>Avdelning: </label>
              <select name="department" {...register('department', { required: "Välj avdelning" })}>
                <option value="">Välj avdelning</option>
                {sortDropDownJustDepartments().map((dep, index) => {
                  return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}
                  </option>
                })}
              </select>
            </div>

          </>
        ) : null}

        <div className={styles.textInputRow} >
          <label>E-postadress:</label>
          <input name="email" {...register('email', {
            required: "Fyll i e-postadress",
            maxLength: 50,
            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Felaktigt e-postadress" }
          })} />
        </div>
        <div className={styles.textInputRow}>
          <label>Förnamn:</label>
          <input name="firstName" {...register('firstName', {
            required: "Fyll i förnamn",
            // pattern: { value: /^[a-zåäöA-ZÅÄÖ\s]*$/, message: "Namn kan bara innehålla bokstäver" },
            minLength: { value: 2, message: "Minst två bokstäver i förnamn" },
            maxLength: { value: 20, message: "Max tjugo bokstäver i förnamn" }
          })} />
        </div>
        <div className={styles.textInputRow}>
          <label>Efternamn:</label>
          <input name="lastName" {...register('lastName', {
            required: "Fyll i efternamn",
            minLength: { value: 2, message: "Minst två bokstäver i efternamn" },
            maxLength: { value: 20, message: "Max tjugo bokstäver i efternamn" }
          })} />
        </div>

        {activeUser.admin ?
          <div className={styles.textInputRow}>
            <label className={styles.superCheckBox}>
              Admin:
              <input name="admin" type="checkbox" {...register('admin')} />
              <span className={styles.checkmark}></span>
            </label>
          </div>
          // <div className={styles.textInputRow}>
          //   <label className={styles.superCheckBox}>
          //     Admin:
          //     <input name="admin" type="checkbox" {...register} />
          //     <span className={styles.checkmark}></span>
          //   </label>
          // </div>
          : null}

        <div className={styles.textInputRow}>
          <label className={styles.superCheckBox}>
            Objektsägare:
            <input name="objectManager" type="checkbox" {...register('objectManager')} />
            <span className={styles.checkmark}></span>
          </label>
        </div>
      </form>
      {Object.keys(errors).length > 0 ? <p style={{ color: "red" }}>{errors[Object.keys(errors)[0].toString()].message}</p> : null}
      {userAlreadyExist ? <p style={{ color: "red" }}>E-postadressen finns redan i systemet</p> : null}
    </div >
  );
};

export default CreateUserPage;
