import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import styles from "./RiskDefinitions.module.css"
import Modal from "./../../../../modal/Modal";
import axios from "axios"

const RiskDefinitions = ({ cosoSize, riskDefinitionMatrices, RiskGroupDefinitions, refetch }) => {
    const [riskDefArray, setRiskDefArray] = useState([])
    const [riskGroupArray, setRiskGroupArray] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [changeThisRiskDef, setChangeThisRiskDef] = useState(null)
    const [changeThisGroupRiskDef, setChangeThisRiskGroupDef] = useState(null)
    const [definition, setDefinition] = useState("")
    const [groupDefinition, setGroupDefinition] = useState("")
    const [changeGroupDefinition, setChangeGroupDefinition] = useState(false)
    const { activeUser } = useContext(UserContext);

    const submitChangeDefinitionDescription = async () => {
        let dataToSend = { ...changeThisRiskDef, definition }
        await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/setting/change-matrix-risk-definition-description",
            dataToSend,
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        setShowModal(false);
        refetch();
    };

    const submitChangeGroupDefinitionDescription = async () => {
        let dataToSend = { ...changeThisGroupRiskDef, newDefinition: groupDefinition }
        await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/setting/change-risk-group-definition",
            dataToSend,
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        setChangeGroupDefinition(false)
        setShowModal(false);
        refetch();
    };

    useEffect(() => {
        let defArr = []
        let groupArr = []

        if (cosoSize === 3) {
            defArr = riskDefinitionMatrices.smallMatrixRiskDefinitionArray
            groupArr = RiskGroupDefinitions.smallRiskGroupDefinition
        }

        if (cosoSize === 4) {
            defArr = riskDefinitionMatrices.mediumMatrixRiskDefinitionArray
            // groupArr = [
            //     { g: 'R1', v: 'Riskvärdesgrupp 1', d: 'Försumbar risk' },
            //     { g: 'R2', v: 'Riskvärdesgrupp 2', d: 'Liten risk' },
            //     { g: 'R3', v: 'Riskvärdesgrupp 3', d: 'Viss risk' },
            //     { g: 'R4', v: 'Riskvärdesgrupp 4', d: 'Hög risk' }
            // ]
            // groupArr = ["låg", "mellan", "hög", "mycket hög"]
            groupArr = RiskGroupDefinitions.mediumRiskGroupDefinition
        }

        if (cosoSize === 5) {
            defArr = riskDefinitionMatrices.largeMatrixRiskDefinitionArray
            groupArr = RiskGroupDefinitions.largeRiskGroupDefinition
        }

        setRiskDefArray(defArr)
        setRiskGroupArray(groupArr)

    }, [cosoSize, riskDefinitionMatrices, RiskGroupDefinitions])

    return (
        <div>
            <Modal
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                    setChangeGroupDefinition(false)
                    // setDefinition("")
                    // setGroupDefinition("")
                }}
            >
                <div className={styles.commentVerifyCheck}>
                    <h4>Ange ny beskrivning:</h4>
                    <textarea
                        name="description"
                        type="textarea"
                        defaultValue={changeGroupDefinition ? groupDefinition : definition}
                        onChange={(e) => {
                            if (changeGroupDefinition) {
                                setGroupDefinition(e.target.value)
                            } else {
                                setDefinition(e.target.value)
                            }
                        }}
                        className={`${styles.fieldRow} ${styles.descriptionInput}`}
                    />

                    <input
                        type="button"
                        onClick={() => {
                            if (changeGroupDefinition) {
                                submitChangeGroupDefinitionDescription()
                            } else {
                                submitChangeDefinitionDescription()
                            }
                        }}
                        value="Ändra"
                        className={styles.addVerifyCheckButton}
                    />
                </div>

            </Modal>
            <h3 className="pageSubTitle" style={{ marginTop: "90px" }}>Benämningar</h3>
            {/* <div className={styles.riskDefDescription}> */}
            <div className={styles.riskDefDescription}>
                {`Nedan redigeras texterna som visas för sannolikhet respektive konsekvens 
                för varje tal. Klicka på respektive siffra för att ge en beskrivning av 
                innebörden för er organisation. Man kan t.ex. sätta benämningar som ”låg 
                sannolik” eller ge ett exempel på hur ofta det är troligt att risken 
                inträffar, t.ex. ”en gång i veckan”.`}
            </div>
            <div className={styles.riskDefContainer}>
                {riskDefArray.map((def, index) => (
                    <span key={index}>
                        <div className={`${styles.riskDefCircle} ${styles.main}`} onClick={() => {
                            setDefinition(def.probability)
                            setChangeThisRiskDef({
                                size: cosoSize,
                                value: index + 1,
                                type: "probability"
                            })
                            setShowModal(true)
                        }}>
                            {index + 1}
                            <div className={styles.sub}>
                                {def.probability.length === 0 ? "Klicka för att ange beskrivning" : def.probability}
                            </div>
                        </div>
                        <div className={styles.riskDefDesc}>
                            {def.description}
                        </div>
                        <div className={`${styles.riskDefCircle} ${styles.main}`} onClick={() => {
                            setDefinition(def.consequence)
                            setChangeThisRiskDef({
                                size: cosoSize,
                                value: index + 1,
                                type: "consequence"
                            })
                            setShowModal(true)
                        }}>
                            {index + 1}
                            <div className={styles.sub}>
                                {def.consequence.length === 0 ? "Klicka för att ange beskrivning" : def.consequence}
                            </div>
                        </div>
                    </span>
                ))}
            </div>
            <div className={styles.riskDefExplained}>
                <div>Sannolikhet att risken inträffar</div>
                <div></div>
                <div style={{ marginRight: '35px' }}>Konsekvens om risken inträffar</div>
            </div>
            <div className={styles.riskGroupContainer}>
                <h3 className="pageSubTitle">Riskvärdesgruppering</h3>

                {/* <div className={styles.riskGroupDescription}> */}
                <div className="bodyText">
                    {`Klicka på riskdefinitionen nedan för att redigera den. `}
                </div>
                <div className={styles.riskGroupHeader}>
                    <div></div>
                    <div className={styles.riskGroupDesc}>RISKVÄRDE</div>
                    <div className={styles.riskGroupDesc}>RISKDEFINITION</div>
                </div>
                <div className={styles.riskGroupContent}>
                    {riskGroupArray.map((group, index) => (
                        <span key={index}>
                            <div className={
                                /** 3 x 3 */
                                cosoSize * (index + 1) === 3
                                    ? `${styles.riskGroupBox} ${styles.greenDarkColor}`
                                    : cosoSize * (index + 1) === 6
                                        ? `${styles.riskGroupBox} ${styles.yellowColor}`
                                        : cosoSize * (index + 1) === 9
                                            ? `${styles.riskGroupBox} ${styles.orangeDarkColor}`
                                            /** 4 x 4 */
                                            : cosoSize * (index + 1) === 4
                                                ? `${styles.riskGroupBox} ${styles.greenDarkColor}`
                                                : cosoSize * (index + 1) === 8
                                                    ? `${styles.riskGroupBox} ${styles.yellowColor}`
                                                    : cosoSize * (index + 1) === 12
                                                        ? `${styles.riskGroupBox} ${styles.orangeLightColor}`
                                                        : cosoSize * (index + 1) === 16
                                                            ? `${styles.riskGroupBox} ${styles.orangeDarkColor}`
                                                            /** 5 x 5 */
                                                            : cosoSize * (index + 1) === 5
                                                                ? `${styles.riskGroupBox} ${styles.greenDarkColor}`
                                                                : cosoSize * (index + 1) === 10
                                                                    ? `${styles.riskGroupBox} ${styles.greenLightColor}`
                                                                    : cosoSize * (index + 1) === 15
                                                                        ? `${styles.riskGroupBox} ${styles.yellowColor}`
                                                                        : cosoSize * (index + 1) === 20
                                                                            ? `${styles.riskGroupBox} ${styles.orangeLightColor}`
                                                                            : cosoSize * (index + 1) === 25
                                                                                ? `${styles.riskGroupBox} ${styles.orangeDarkColor}`
                                                                                : null
                            }>
                                {/* {group.g} */}
                                R{index + 1}
                            </div>
                            <div className={styles.riskGroupDesc}>
                                {/* {group.v} */}
                                Riskvärdesgrupp {index + 1}
                            </div>
                            <div className={styles.riskGroupDesc} onClick={async () => {
                                await setChangeGroupDefinition(true)
                                setGroupDefinition(group)
                                setChangeThisRiskGroupDef({
                                    size: cosoSize,
                                    value: index + 1,
                                    groupDefinition: group
                                })
                                setShowModal(true)
                            }}>
                                {group.length === 0 ? "Klicka för att ange beskrivning" : group}
                            </div>
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RiskDefinitions
