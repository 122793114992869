import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import OrganisationPageHeader from "./OrganisationPageHeader";
import OrganisationTree from "./OrganisationTree";
import { useQuery } from "react-query";
import axios from "axios";
import LoadingModal from "./../../modal/LoadingModal";

const OrganisationPage = () => {
  const { sideBar, activeUser } = useContext(UserContext);
  const [selectedAncestor, setSelectedAncestor] = useState(null);
  const [allNodes, setAllNodes] = useState([]);
  const [newlyCreatedAncestor, setNewlyCreatedAncestor] = useState(null);
  const [fadeTriggerProp, setFadeTriggerProp] = useState(false)

  const readAllObjects = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/organisation/all-objects", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });


    return res.data;
  };

  const { data: allObjects, refetch, status } = useQuery(["getObjects"], readAllObjects, {
    // staleTime: 2000,
    cacheTime: 0,
    refetchInterval: 10000,
  });

  useEffect(() => {
    setAllNodes(allObjects);

    if (newlyCreatedAncestor !== null) {
      setSelectedAncestor([newlyCreatedAncestor]);
      setNewlyCreatedAncestor(null);
    } else if (selectedAncestor === null && allObjects !== undefined) {
      let temp1 = allObjects.find((element) => element.level === 1);
      if (temp1 !== undefined) {
        setSelectedAncestor([activeUser.organisation]);
      }
    }
  }, [allObjects, allNodes, refetch, newlyCreatedAncestor, selectedAncestor, activeUser]);

  useEffect(() => {
    if (status === "success") {
      const timer = setTimeout(() => {
        setFadeTriggerProp(true)
      }, 300);
      return () => {
        clearTimeout(timer)
      };
    }
  }, [status]);

  return (
    <>
      <LoadingModal passedFunction={() => { }} open={!fadeTriggerProp} />
      <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeTriggerProp ? "fadeIn" : "fadeWaiting"}`}>
        <OrganisationPageHeader
          refetch={refetch}
          allNodes={allNodes}
          setAllNodes={setAllNodes}
          setSelectedAncestor={setSelectedAncestor}
          selectedAncestor={selectedAncestor}
          newlyCreatedAncestor={newlyCreatedAncestor}
          setNewlyCreatedAncestor={setNewlyCreatedAncestor}
        />
        <div style={{ maxWidth: "1000px", marginTop: "40px", marginBottom: "30px" }}>
          {activeUser.admin || activeUser.objectManager ?
            (
              <>
                <div className="bodyText">
                  Här administrerar ni er organisation. Högerklicka på ett organisationsobjekt
                  för att ta bort det eller för att lägga till ett under- eller sidoobjekt och
                  bygga ut organisationsöversikten.
                </div>
                <br></br>
                <div className="bodyText">Administratörer i programmet når samtliga organisationsobjekts risker, i samtliga organisationer om det finns flera. En användare når endast det organisationsobjekt denne är knuten till. En objektsägare når sitt eget objekt och de nedanför. Om man vill att en person ska ha tillgång till flera ben i organisationsträdet men inte hela är ett tips att skapa en mellannivå. Se gärna hjälpfilmen vid frågetecknet ovan!</div>
              </>
            ) : (
              <div className="bodyText">Här visas er organisationsöversikt. Kontakta er systemadministratör för att bygga
            ut organisationen med fler objekt, ta bort eller redigera ett objekt.</div>
            )}
        </div>
        <OrganisationTree
          refetch={refetch}
          allNodes={allNodes}
          setAllNodes={setAllNodes}
          setSelectedAncestor={setSelectedAncestor}
          selectedAncestor={selectedAncestor}
        />
      </div>
    </>
  );
};

export default OrganisationPage;
