import React, { useState, useRef, useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import styles from "./ProcessHandler.module.css";
import axios from "axios";
import Modal from "../../../../modal/Modal";
import { PageDelimiter } from '../../../../styles/pages'

export default function ProcessHandler({ processes, refetch }) {
  const [showModal, setShowModal] = useState(false);
  const [newProcess, setNewProcess] = useState("");
  const inputRef = useRef(null);
  const { activeUser } = useContext(UserContext);

  const deleteProcess = async (thisProcess) => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/setting/delete-process", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
      data: {
        processName: thisProcess,
      },
    });
    if (res.status === 200) {
      refetch();
    }
    return res.status;
  };

  const createProcess = async () => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/setting/create-process",
      {
        newProcess: newProcess.trim(),
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    return res.status;
  };

  const ProcessElement = ({ thisProcess }) => {
    return (
      <div className={styles.categoryRow}>
        <div className={styles.mainCategory}>
          {thisProcess}
          <i
            className={`fas fa-times ${styles.markerIconMedium}`}
            onClick={() => {
              deleteProcess(thisProcess);
            }}
          ></i>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal open={showModal} onClose={() => { setShowModal(false); }} >
        <form className={styles.commentVerifyCheck}>
          <h4>Ny kategori:</h4>
          <input
            name="description"
            type="text"
            maxLength="25"
            ref={inputRef}
            onChange={(e) => setNewProcess(e.target.value)}
            className={`${styles.fieldRow} ${styles.descriptionInput}`}
          />
          {newProcess.length > 24 ? <span style={{ marginRight: "22px", color: "red" }}>Max 25 tecken</span> : null}

          <input
            type="submit"
            onClick={async (e) => {
              e.preventDefault();
              let checkIfProcessExist = processes.find((element) => element.toUpperCase() === newProcess.trim().toUpperCase());
              if (checkIfProcessExist === undefined && (newProcess.trim().length < 25) && (newProcess.trim().length > 1)) {
                let waitForResponse = await createProcess();
                if (waitForResponse === 200) {
                  refetch();
                  setNewProcess("")
                  setShowModal(false)
                }
              }
            }}
            value="Lägg till"
            className={styles.addVerifyCheckButton}
          />
          <input
            type="button"
            onClick={async () => {
              setNewProcess("")
              setShowModal(false)
            }}
            value="Avbryt"
            className={styles.cancelButton}
          />
        </form>
      </Modal>
      <div className={styles.processContainer}>
        <div style={{ fontWeight: "450", fontSize: "19px" }}>Lägg till kategori</div>
        <i
          onClick={async () => {
            setShowModal(true);
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 200);
          }}
          style={{
            fontSize: "22px",
            position: "relative",
            top: "5px",
            left: "15px",
            cursor: "pointer",
            color: "#1b7f88",
            paddingLeft: "0px",
          }}
          className="fas fa-plus-circle sidebar-icon"
        ></i>
      </div>
      <PageDelimiter />
      <div className={styles.processListContainer}>
        {processes.length > 0 ? processes.map((thisProcess, index) => {
          return <ProcessElement thisProcess={thisProcess} key={index} />
        }) : "Skapa kategorier för att kunna gruppera risker"}
      </div>
    </>
  );
}
