import { createPortal } from "react-dom"
import * as M from './styles/modalStyles'
import styles from './ProcessChartInfoModal.module.css'
import process_steps from '../media/process-steps.png'
import process_wheel from '../media/process-wheel.png'

const process_step_by_step = 'Processen är cyklisk, eller om man så vill sker stegvis. Arbetet börjar med att riskerna i organisationen identifieras, vilket sker i riskportföljen. Riskportföljen är ett levande dokument som hela tiden visar de risker som finns. En internkontrollplan – IKP – som alltså visar vilka risker organisationen vill ha lite extra fokus på initieras (skapas) och är då under initieringsfasen en spegling av riskportföljen, för de risker som har markerats att de ska ingå i en IKP. IKP:n förbereds genom att man arbetar med riskerna; dokumenterar dom och lägger till både riskhanteringsåtgärder och kontroller. Samtliga begrepp och delar i processen förklaras närmre längre fram i denna manual. När perioden för IKP:n startar blir den färdiga planen tillgänglig för alla användare och arbetet med att följa upp de särskilda riskerna startar då, parallellt med att arbetet med övriga risker fortgår (i riskportföljen). När perioden närmar sig sitt slut följs både riskerna och planen i sin helhet upp och avrapporteras. '
const process_wheel_text = 'Årshjulet visar hur riskarbetet löper på hela året och också hur två planer överlappar varandra genom att förberedelsearbetet inför ett år börjar innan det gamla tar slut. Avrapporteringsarbetet fortlöper ofta en bit in på nya arbetet, parallellt med att nästa plan är aktivierad.'

export default function ProcessChartInfoModal({ onClose, open }) {

    if (!open) return null

    return createPortal(
        <>
            <M.Overlay />
            <M.Modal className={styles.processChart}>
                <M.ModalTitle className={styles.processChartTitle}>Processkarta</M.ModalTitle>
                <div className={styles.stepList}>
                    <div className={styles.stepItem}>
                        <div className={styles.stepTitle}>Processen</div>
                        <div className={styles.stepInfo}>{process_step_by_step}</div>
                    </div>
                    <div className={styles.stepItem}>
                        <div className={styles.stepTitle}>Processen steg för steg</div>
                        <img src={process_steps} alt="Processen steg-för-steg"></img>
                    </div>
                    <div className={styles.stepItem}>
                        <div className={styles.stepTitle}>Årshjul</div>
                        <div className={styles.stepInfo}>{process_wheel_text}</div>
                        <img src={process_wheel} alt="Årshjul"></img>
                    </div>
                </div>
                <M.ModalButtons style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <M.OptionButton alert={false} onClick={() => onClose()}>Stäng</M.OptionButton>
                </M.ModalButtons>
            </M.Modal>
        </>,
        document.getElementById("portal")
    )
}
