import React from "react";
import ReactDom from "react-dom";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  //   width: "600px",
  //   height: "500px",
  borderRadius: "15px",
  transform: "translate(-50%, -50%)",
  // backgroundColor: "#FFF",
  padding: "0px",
  zIndex: "900",
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgb(0,0,0, 0.6)",
  zIndex: "900",
};

export default function Modal({ onClose, open, children }) {
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <div style={MODAL_STYLES}>{children}</div>
    </>,
    document.getElementById("portal")
  );
}
