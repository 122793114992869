import React from 'react'
import { useHistory } from 'react-router'
import styles from './styles/SuggestionsAndDeviationAnalyze.module.css'

export default function DeviationReportSummary({ riskData }) {

    const history = useHistory()

    let risksWithSuggestions = riskData.map((risk) => {
        let riskReviews = risk["controllers"]
        if (riskReviews.length > 0) {
            let reviewsToRisks = []
            for (const rev of riskReviews) {
                if (rev.deviationReports.length > 0) {
                    let suggestionsToReviews = []
                    for (const devRep of rev.deviationReports) {
                        suggestionsToReviews.push(devRep)
                    }

                    if (suggestionsToReviews.length > 0) {
                        reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                    }
                }
            }

            if (reviewsToRisks.length > 0) {
                return {
                    name: risk.name, reviewsWithSuggestions: reviewsToRisks,
                    orgRiskId: risk._id, departmentName: risk.department.name
                }
            } else return null

        } else return null

    }).filter(element => { return element !== null })


    const renderSuggestions = (suggestions) => {
        let suggestionToRender = suggestions.map((suggestion, index) => {
            return <li key={index}><div className={styles.suggestionNameRow} >
                <div style={{ fontWeight: "600" }}>Avvikelserapport: {suggestion.date.slice(0, 10)} - {suggestion.user}  {suggestion.flagged ? " (hanterad) " : "(ej hanterad)"}</div>
                <div style={{ marginTop: "3px" }}> <span >Beskrivning: </span> {suggestion.description}</div>
                <div style={{ marginTop: "6px", marginBottom: "20px" }}> <span >Förslag till åtgärd: </span> {suggestion.proposal}</div>
            </div></li>
        })
        return suggestionToRender
    }

    const renderControllers = (reviewsWithSuggestions) => {
        let reviewToRender = reviewsWithSuggestions.map((review, index) => {
            return <div key={index} className={styles.reviewNameRow}>
                <div className={styles.riskNameRow2}>Riskhanteringsåtgärd: {review.reviewName}</div>
                <ul>{renderSuggestions(review.suggestions)}</ul>
            </div>
        })
        return reviewToRender
    }

    const renderRisks = () => {
        let riskToRender = risksWithSuggestions.map((risk, index) => {
            return <div key={index} className={styles.riskDataContainer}><div className={styles.riskNameRow} onClick={() => {
                history.push("/show-risk", {
                    riskId: risk.orgRiskId
                })
            }}>{risk?.name} - {risk?.departmentName}</div>{renderControllers(risk.reviewsWithSuggestions)}</div>
        })
        return riskToRender
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.ContainerTitle}>Avvikelserapporter</div>
            {renderRisks()}
        </div >
    )
}
