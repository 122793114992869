import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter, Space } from '../../styles/pages'
import { PageHeaderButton, HelpButton, DeleteHeaderButton } from '../../styles/buttons'
import styles from "./styles/ShowRiskGroupPage.module.css";
import axios from "axios"
import { useQuery } from "react-query";
import { useForm } from "react-hook-form"
import LoadingModal from "./../../modal/LoadingModal";
import WarningModal from "./../../modal/WarningModal";
import RiskListHeader from "./components/riskgroup/RiskListHeader";
import RenderNewRisksList from "./components/riskgroup/RenderNewRisksList";
import RenderGroupRisksList from "./components/riskgroup/RenderGroupRisksList";

const ShowRiskGroupPage = () => {
  const history = useHistory()
  const incProps = history.location.state;
  const { sideBar, activeUser } = useContext(UserContext)
  const [fadeInTrigger, setFadeInTrigger] = useState(true)
  const [isMounted, setIsMounted] = useState(true)
  // const [showDefaultSettings, setShowDefaultSettings] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [defaultSettingsRisk, setDefaultSettingsRisk] = useState({
    name: "Namn på risk",
    category: "Ej definierad process",
    subCategory: "Ej definierad delprocess",
    process: "Ej definierad kategori",
    organisation: "",
    department: "",
    probability: 1,
    consequence: 1,
    groups: [incProps.name]
  })
  // const [newRiskList, setNewRiskList] = useState(() => [])

  const { register, handleSubmit, watch, setValue } = useForm(
    {
      mode: 'onBlur',
      defaultValues: {
        preChoices: {
          name: "Namn på risk",
          category: "Ej definierad process",
          subCategory: "Ej definierad delprocess",
          process: "Ej definierad kategori",
          organisation: "",
          department: "",
          probability: 1,
          consequence: 1,
          groups: [incProps.name]
        }
      }
    }
  );

  const getCurrentSettings = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-current-portfolio-settings", {
      headers: { authorization: "Bearer " + activeUser.token },
    });
    return res.data;
  };
  const getObjectAccessList = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
      headers: { authorization: "Bearer " + activeUser.token },
    });
    return res.data;
  };
  const readRisksInGroup = async () => {
    const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-risks-in-group", { groupName: incProps.name }, {
      headers: { authorization: "Bearer " + activeUser.token }
    });
    if (isMounted) { setFadeInTrigger(true) }
    return res.data;
  };
  const dissolveGroup = async () => {
    const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/setting/dissolve-risk-group", { groupName: incProps.name }, {
      headers: { authorization: "Bearer " + activeUser.token }
    });
    if (res.status === 200) history.push("/risk-groups");
  };

  const { data, status } = useQuery(["readSettingsForRiskGroups"], getCurrentSettings, { refetchOnWindowFocus: false });
  const { data: accessList, status: accessListStatus } = useQuery(["getAccessListToRiskGroup"], getObjectAccessList, { refetchOnWindowFocus: false });
  const { data: risksInGroupList, status: risksInGroupListStatus, refetch } = useQuery(["getRisksInGroup", incProps.name], readRisksInGroup, {
    refetchOnWindowFocus: true,
    staleTime: 0
  });

  const watchOrganisation = watch("preChoices.organisation")

  const onSubmit = (dataFromForm) => {
    setDefaultSettingsRisk(dataFromForm.preChoices)
  }
  const sortDropDown = () => {
    if (watchOrganisation.toString() === "") return []
    let initList = accessList.filter((org) => { return org.ancestor === watchOrganisation.toString() || org._id === watchOrganisation.toString() })
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep.level === 1)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "preChoices.organisation") {
        setValue("preChoices.department", "")
      } else if (name === "preChoices.category") {
        setValue("preChoices.subCategory", "Ej definierad delprocess")
      }
    })

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <WarningModal
        passedFunction={() => dissolveGroup()}
        warningTitle={"Vill du verkligen ta bort den här riskgruppen?"}
        warningText={"Gruppen kommer tas bort men riskerna kommer att finnas kvar som vanligt i riskportföljen. "}
        optionTextOne={"Ja"}
        optionTextTwo={"Nej"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />

      <>
        <PageTitle>Riskgrupp {incProps.name}</PageTitle>
        <PageDelimiter />
        <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Gå tillbaka</PageHeaderButton>
        {/* <SaveButton icon="save" onClick={() => console.table(newRiskList)} >Spara ändringar</SaveButton> */}
        <DeleteHeaderButton icon="delete" onClick={() => setShowWarningModal(true)} >Upplös grupp</DeleteHeaderButton>
        <HelpButton></HelpButton>
      </>

      <div style={{ width: "750px", marginBottom: "30px", marginTop: "40px" }}>
        <div className="bodyText" style={{ marginBottom: "20px" }}>
          Här visas alla risker kopplade till den här gruppen. Du kan skapa nya risker härifrån
          eller koppla risker genom att gå in i dem via riskportföljen.
        </div>
        <div className="bodyText"> Beskrivning av riskgruppen: {incProps.description} </div>
        {/* <div className="bodyText"> Organisation: {incProps.organisation.name} </div>
        <div className="bodyText"> Avdelning: {incProps.department.name} </div> */}
      </div >

      <div className={styles.attestRow} style={{ fontSize: "14px", marginBottom: "40px" }}>
        <div className={styles.attestRow} style={{ fontSize: "14px", fontWeight: "600", marginBottom: "6px" }}>Förvalda riskegenskaper</div>
        {/* <div className={styles.attestRow}>namn, kategori, process, avdelning o.s.v.</div> */}
        {status === "success" ?
          <form onBlur={handleSubmit(onSubmit)}>
            <div>
              <div className={styles.newRiskListContainer}>
                <div className={styles.searchInputRow}>
                  <input
                    defaultValue={"Namn på risk"}
                    {...register("preChoices.name", { minLength: { value: 2, message: "Minst två bokstäver i förnamn" } })}
                  />
                </div>

                {/* </div> */}
                {/* {formState.errors?.newRisks?.hasOwnProperty(index) ? "ja" : "nej"} */}
                <div className={styles.selectBox}>
                  <select
                    {...register("preChoices.category")}>
                    <option value="Ej definierad process" >Välj huvudprocess</option>
                    {data?.categories?.map((cat, index) => {
                      return <option value={cat.main} key={index + "category"}>{cat.main}</option>
                    })}
                  </select>
                </div>
                <div className={styles.selectBox}>
                  <select
                    {...register("preChoices.subCategory")}>
                    <option value="Ej definierad delprocess" >Välj delprocess</option>
                    {watch("preChoices.category") !== "Ej definierad process" ? data?.categories
                      .find((cat) => cat.main === watch("preChoices.category"))
                      .children.map((cato, index) => {
                        return <option value={cato} key={index + "subcategory"}>{cato}</option>
                      }) : null}
                  </select>
                </div>
                <div className={styles.selectBox}>
                  <select defaultValue={"Ej definierad kategori"} {...register("preChoices.process")} >
                    <option value="Ej definierad kategori" >Välj kategori</option>
                    {data?.processes?.map((cat, index) => {
                      return <option value={cat} key={index + "process"}>{cat}</option>
                    })}
                  </select>
                </div>
                <div className={styles.selectBox}>
                  <select {...register("preChoices.organisation")} >
                    <option value="" >Välj organisation</option>
                    {accessList?.filter((org) => { return org.level === 1 }).map((dep, index) => {
                      return <option key={index + "organisation"} value={dep._id} >{dep.name}</option>
                    })}
                  </select>
                </div>
                <div className={styles.selectBox}>
                  <select {...register("preChoices.department")}>
                    <option value="">Välj avdelning</option>
                    {watch("preChoices.organisation") !== "" ? sortDropDown().map((dep, index) => {
                      return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                    }) : null}
                  </select>
                </div>
                <div className={styles.selectBox}><input type="number" min="1"
                  max={data?.coso.size} {...register("preChoices.probability", { valueAsNumber: true })} /></div>
                <div className={styles.selectBox}><input type="number" min="1"
                  max={data?.coso.size} {...register("preChoices.consequence", { valueAsNumber: true })} />
                </div>
                {/* </div> */}
              </div>
            </div>
          </form>
          : null}
      </div >

      <RiskListHeader />
      {risksInGroupListStatus === "success" && status === "success" && accessListStatus === "success" ? <RenderGroupRisksList risksInGroupList={risksInGroupList} settings={data} accessList={accessList} groupName={incProps.name} /> : null}
      {status === "success" ? <RenderNewRisksList defaultSettingsRisk={defaultSettingsRisk} refetch={refetch} settings={data} accessList={accessList} groupName={incProps.name} /> : null}
      <Space />
    </div >
  )
}

export default ShowRiskGroupPage