import React, { useState, useRef, useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import styles from "./RiskCategories.module.css";
import { PageDelimiter } from '../../../../styles/pages'
import axios from "axios";
import Modal from "./../../../../modal/Modal";
import WarningModal from "./../../../../modal/WarningModal";

export default function RiskCategoryHandler({ riskCategories, refetch }) {
  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [deleteThisProcess, setDeleteThisProcess] = useState(null);
  const [typeOfCategory, setTypeOfCategory] = useState(null);
  const [addToThisCategoryId, setaddToThisCategoryId] = useState(null);
  const { activeUser } = useContext(UserContext);

  const inputRef = useRef(null);

  const deleteCategory = async (categoryId) => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/setting/delete-risk-category", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
      data: {
        categoryId: categoryId,
      },
    });
    if (res.status === 200) {
      refetch();
    }
    return res.status;
  };
  const deleteSubCategory = async (data) => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/setting/delete-risk-subcategory", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
      data,
    });
    if (res.status === 200) {
      refetch();
    }
    return res.status;
  };

  const createCategory = async () => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/setting/create-risk-category",
      {
        newCategory: newCategory,
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    return res.status;
  };

  const addSubCategory = async () => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/setting/add-sub-category",
      {
        categoryId: addToThisCategoryId,
        newCategory: newCategory,
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    return res.status;
  };

  const CategoryRow = ({ category }) => {
    return (
      <div className={styles.categoryRow}>
        <div className={styles.mainCategory}>
          {category.main}
          <i
            className={`fas fa-times ${styles.markerIconMedium}`}
            onClick={() => {
              setDeleteThisProcess(category._id);
              setShowWarningModal(true)
            }}
          ></i>
        </div>
        {category.children.map((child, index) => {
          return (
            <div key={index} className={styles.subCategory}>
              {child}
              <i
                className={`fas fa-times ${styles.markerIconMedium}`}
                onClick={() => {
                  deleteSubCategory({ categoryId: category._id, index, subCategoryName: child });
                }}
              ></i>
            </div>
          );
        })}
        <i
          onClick={async () => {
            setTypeOfCategory("sub");
            setaddToThisCategoryId(category._id);
            setShowModal(true);
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 200);
          }}
          style={{
            fontSize: "23px",
            position: "relative",
            top: "11px",
            left: "0px",
            cursor: "pointer",
            color: "#4077c4",
            paddingLeft: "0px",
          }}
          className="fas fa-plus-circle sidebar-icon"
        ></i>
      </div>
    );
  };

  return (
    <>
      <WarningModal
        passedFunction={() => deleteCategory(deleteThisProcess)}
        warningTitle={"Vill du ta bort den här processen?"}
        warningText={"Om det finns delprocesser kopplade till den här processen så kommer de också att försvinna."}
        optionTextOne={"Ta bort"}
        optionTextTwo={"Ångra"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <form className={styles.commentVerifyCheck}>
          <h4>Ny {typeOfCategory === "sub" ? "del" : null}process:</h4>
          <input
            name="description"
            type="text"
            maxLength="25"
            ref={inputRef}
            onChange={(e) => setNewCategory(e.target.value)}
            className={`${styles.fieldRow} ${styles.descriptionInput}`}
          />
          {newCategory.length > 24 ? <span style={{ marginRight: "22px", color: "red" }}>Max 25 tecken</span> : null}

          <input
            type="submit"
            onClick={async (e) => {
              e.preventDefault();
              let checkIfCategoryExist = riskCategories.find((element) => element.main.toUpperCase() === newCategory.trim().toUpperCase());
              if (checkIfCategoryExist === undefined && typeOfCategory === "main" && (newCategory.trim().length < 25) && (newCategory.trim().length > 1)) {
                let waitForResponse = await createCategory();
                if (waitForResponse === 200) {
                  refetch();
                  setNewCategory("");
                  setTypeOfCategory(null);
                  setShowModal(false);
                }
              } else if (typeOfCategory === "sub" && (newCategory.trim().length < 25) && (newCategory.trim().length > 1)) {
                let waitForResponse = await addSubCategory();
                if (waitForResponse === 200) {
                  refetch();
                  setNewCategory("");
                  setTypeOfCategory(null);
                  setShowModal(false);
                }
              }
            }}
            value="Lägg till"
            className={styles.addVerifyCheckButton}
          />
          <input
            type="button"
            onClick={async () => {
              setNewCategory("");
              setTypeOfCategory(null);
              setShowModal(false);
            }}
            value="Avbryt"
            className={styles.cancelButton}
          />
        </form>
      </Modal>
      <div className={styles.titleAddRow}>
        <div style={{ fontWeight: "450", fontSize: "19px" }}>Lägg till process</div>
        <i
          onClick={async () => {
            setShowModal(true);
            setTypeOfCategory("main");
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 200);
          }}
          style={{
            fontSize: "22px",
            position: "relative",
            top: "5px",
            left: "15px",
            cursor: "pointer",
            color: "#1b4988",
            paddingLeft: "0px",
          }}
          className="fas fa-plus-circle sidebar-icon"
        ></i>
      </div>
      <PageDelimiter />

      {riskCategories.length > 0 ? riskCategories.map((category, index) => {
        return <CategoryRow category={category} key={index} />;
      }) : "Skapa processer för att kunna gruppera risker"}
    </>
  );
}
