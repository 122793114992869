import styles from './styles/VerticalBarDiagram.module.css'

const VerticalBarDiagram = ({ data }) => {
    let chartColors = ["rgb(45, 120, 163)", "rgb(20, 100, 100)", "rgb(145, 163, 45)", "rgb(200, 100, 100)", "rgb(130, 180, 150)", "rgb(128, 97, 30)", "rgb(118, 157, 194)", "rgb(185, 120, 120)", "rgb(128, 121, 30)", "rgb(82, 159, 165)"]
    let nfOfBars = data.length
    let sizeKoeff = data.length > 11 ? 1.1 : 1
    let fontSizeNr = 14 + 10 * (3 / (nfOfBars + 3))
    let fontSizeBranchName = nfOfBars < 3 ? 13 : 7 + 10 * (3 / (nfOfBars + 2))
    let maxHeightOfBar = 130
    let barSpacing = nfOfBars > 5 ? 110 * (5 / (nfOfBars + 2)) : 140 * (5 / (nfOfBars + 2))
    let barYStart = 200
    let barWidthKoeff = nfOfBars < 3 ? 40 : 100 * (3 / (nfOfBars + 3))
    let highestBar = data.reduce((results, item) => {
        if (item.nr > results) results = item.nr
        return results
    }, 1)
    let heightKoeff = maxHeightOfBar / highestBar

    const onProcessStep = () => { console.log('Clicked in process step...') }

    const renderSectors = () => {
        let count
        let newColor
        return data?.map((sector, index) => {
            count = index % chartColors.length;
            newColor = chartColors[count];

            return <g className={`${styles.notInUse} works`} onClick={onProcessStep} key={index}>
                <rect id={`${index}`} y={barYStart - sector.nr * heightKoeff} x={barSpacing * index} rx={2} ry={2} width={barWidthKoeff} height={5 + sector.nr * heightKoeff} stroke-position='center' stroke='black' strokeWidth={1} fill={newColor} />
                <text y={barYStart - 15 - sector.nr * heightKoeff} x={(barWidthKoeff * 0.38) + barSpacing * index} fontSize={fontSizeNr} dominantBaseline='middle' textAnchor="middle" fill="black" >
                    {sector.nr}
                </text>
                <text y={barYStart + 25} x={barSpacing * index} fontSize={fontSizeBranchName} fontWeight="600" dominantBaseline='middle' textAnchor="start" fill="black" >
                    {/* <text y={barYStart + 25} x={(barWidthKoeff + index * 1) - sector.name.length * 2 + barSpacing * index} fontSize={fontSizeBranchName} fontWeight="600" dominantBaseline='middle' textAnchor="middle" fill="black" > */}
                    {nfOfBars > 5 && sector.name.length > 9 ? sector.name.slice(0, 9) + "..." :
                        sector.name.length > 14 ? sector.name.slice(0, 14) + "..." : sector.name.slice(0, 16)}
                </text>
            </g>
        })
    }

    return (
        <div className={styles.plotterChart}>
            <svg id="perObjectBranch" viewBox={`-20 -30 ${sizeKoeff * 580} ${sizeKoeff * 270}`} width={sizeKoeff * 580} height={sizeKoeff * 270} >
                {renderSectors()}
                <text y={-15} x={125} fontSize={15} fontWeight="600" dominantBaseline='middle' textAnchor="middle" fill="black" >
                    Risker fördelade på objekt och undernivåer
                </text>
            </svg>
        </div >
    )
}

export default VerticalBarDiagram