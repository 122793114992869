import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "../../contexts/UserContext"
import axios from "axios"
import { saveAs } from "file-saver"
import MyAssignedControllers from "./components/MyAssignedControllers"
import MyAssignedReviews from "./components/MyAssignedReviews"
import MyIcpsToReport from "./components/MyIcpsToReport"
// import ProcessChart from "./../../components/process-chart/ProcessChart"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import LoadingModal from "./../../modal/LoadingModal";
import { HelpButton } from "../../styles/buttons"
import { PageHeaderButton, SelectionButton } from "../../styles/buttons"
import HelpTextLargeModal from "./../../modal/HelpTextLargeModal"
import { homeText00, homeText01, homeText02, homeText03, homeText04, homeText2, homeText3, homeText4, homeText5, homeText6 } from "./../../constants/helpText"
import ProcessChartInfoModal from "../../modal/ProcessChartInfoModal"

const helpText =
    // <div style={{ height: "100px", overflow: "scroll", margin: "auto" }}>
    <div style={{ paddingTop: "500px" }}>
        <div style={{ marginTop: "10px", fontWeight: "600", textAlign: "center", marginBottom: "20px" }}>
            Hjälp
        </div>
        <div style={{ marginTop: "10px" }}>
            {homeText01}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Processen</div>
            {homeText00}
        </div>

        <div style={{ border: "solid 2px rgb(255, 244, 149)", borderRadius: "5px", padding: "0px", margin: "15px", marginBottom: "20px" }}>
            <div style={{ background: "rgb(255, 244, 149)", borderRadius: "0px", padding: "2px", paddingBottom: "5px", fontWeight: "500" }}>Riskportfölj</div>
            <div style={{ textAlign: "left", padding: "5px" }}>{homeText2}</div>
        </div>
        <div style={{ border: "solid 2px rgb(47, 100, 171, 0.85)", borderRadius: "5px", padding: "0px", margin: "15px", marginBottom: "20px" }}>
            <div style={{ background: "rgb(47, 100, 171, 0.85)", borderRadius: "0px", padding: "2px", paddingBottom: "5px", fontWeight: "500", color: "white" }}>Initierad IKP</div>
            <div style={{ textAlign: "left", padding: "5px" }}>{homeText3}</div>
        </div>
        <div style={{ border: "solid 2px rgb(47, 100, 171, 0.85)", borderRadius: "5px", padding: "0px", margin: "15px", marginBottom: "20px" }}>
            <div style={{ background: "rgb(47, 100, 171, 0.85)", borderRadius: "0px", padding: "2px", paddingBottom: "5px", fontWeight: "500", color: "white" }}>Inför planstart</div>
            <div style={{ textAlign: "left", padding: "5px" }}>{homeText4}</div>
        </div>
        <div style={{ border: "solid 2px rgb(27, 127, 136)", borderRadius: "5px", padding: "0px", margin: "15px", marginBottom: "20px" }}>
            <div style={{ background: "rgb(27, 127, 136)", borderRadius: "0px", padding: "2px", paddingBottom: "5px", fontWeight: "500", color: "white" }}>Aktiverad IKP</div>
            <div style={{ textAlign: "left", padding: "5px" }}>{homeText5}</div>
        </div>
        <div style={{ border: "solid 2px rgb(27, 127, 136)", borderRadius: "5px", padding: "0px", margin: "15px", marginBottom: "20px" }}>
            <div style={{ background: "rgb(27, 127, 136)", borderRadius: "0px", padding: "2px", paddingBottom: "5px", fontWeight: "500", color: "white" }}>Uppföljning av IKP</div>
            <div style={{ textAlign: "left", padding: "5px" }}>{homeText6}</div>
        </div>


        <div style={{ marginTop: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Mina internkontrollplaner – för admin och objektsägare</div>
            {homeText02}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Mina riskhanteringsåtgärder</div>
            {homeText03}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Mina kontroller</div>
            {homeText04}
        </div>

        {/* <P style={{ background: "rgb(47, 100, 171, 0.85)", borderRadius: "15px", padding: "15px" }}>
      {homeText3}
    </P> */}
    </div>
// </div>



const HomePage = () => {
    const { sideBar, activeUser, setSideBarActiveItem } = useContext(UserContext)
    const [isMounted, setIsMounted] = useState(true)
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [showHelpTextModal, setShowHelpTextModal] = useState(false)
    const [showProcessChartInfoModal, setShowProcessChartInfoModal] = useState(false)
    // const [showProcessChartCopeModal, setShowProcessChartCopeModal] = useState(false)

    const setFadeIfMount = () => {
        if (isMounted) { setFadeInTrigger(true) }
    }

    const getUserManual = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/auth/user-manual",
            {},
            { headers: { authorization: "Bearer " + activeUser.token } }
        )
        return res.data
    }

    const fetchManual = async () => {
        const userManualUrl = await getUserManual()
        if (process.env.REACT_APP_COPE_WEB_TARGET === "cope") {
            saveAs(userManualUrl, "Användarmanual Cope Internkontroll.pdf")
        } else {
            saveAs(userManualUrl, "Användarmanual Kommunresurs Internkontroll.pdf")
        }
    }

    useEffect(() => {
        setSideBarActiveItem("home")
        return () => {
            setIsMounted(false)
        }
    }, [setSideBarActiveItem])

    return (
        <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <HelpTextLargeModal
                title="Hjälp"
                // helpTextScroll={homeText0}
                helpText={helpText}
                optionTextOne={"OK"}
                open={showHelpTextModal}
                onClose={() => { setShowHelpTextModal(false); }}
            />
            <ProcessChartInfoModal
                open={showProcessChartInfoModal}
                onClose={() => {
                    setShowProcessChartInfoModal(false)
                }}
            />
            <LoadingModal passedFunction={() => { }} open={!fadeInTrigger} />
            <PageTitle>Hem</PageTitle>
            <PageDelimiter />
            <PageHeaderButton
                icon="info"
                onClick={() => {
                    setShowProcessChartInfoModal(true)
                }}
            >
                Visa processkarta
            </PageHeaderButton>
            {process.env.REACT_APP_COPE_WEB_TARGET === "cope"
                ?
                <HelpButton onClick={() => setShowHelpTextModal(true)} ></HelpButton>
                :
                <SelectionButton color="orange" onClick={() => fetchManual()}>
                    Användarmanual
                </SelectionButton>
            }
            <div style={{ width: "800px", marginBottom: "30px", marginTop: "30px" }}>
                {activeUser.admin || activeUser.objectManager ?
                    <div className="bodyText">
                        Här visas dina uppgifter. Överst visas om du som objektsägare har några internkontrollplaner att attestera eller,
                        efter utgången tid för internkontrollplanen, att avrapportera. Därefter visas om du har några riskhanteringsåtgärder
                        i någon risk tilldelade dig, och om det finns några kontroller i en aktiv internkontrollplan tilldelade dig.
                        Klicka på riskhanteringsåtgärder eller kontroller för att komma in i den och markera som utförd.
                    </div>
                    :
                    <div className="bodyText">
                        Här visas de riskhanteringsåtgärder i riskerna som är tilldelade dig, och även om det finns några kontroller
                        i en aktiv internkontrollplan som är tilldelade dig. Klicka på dem för att komma in och markera som utförd.
                    </div>
                }
            </div>
            {/* <LoadingModal passedFunction={() => { }} open={!fadeInTrigger} /> */}
            {/* <ProcessChart /> */}
            {activeUser.objectManager ? <MyIcpsToReport /> : null}
            <MyAssignedControllers />
            <MyAssignedReviews setFadeFromHome={setFadeIfMount} />
            <div style={{ height: "60px" }}></div>
        </div >
    )
};

export default HomePage;
