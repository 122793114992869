import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton } from '../../styles/buttons'
import ListRisksInIcp from "./components/ListRisksInIcp"
import ListRisksInPortfolio from "../riskportfolio/components/ListRisksInPortfolio"
import { useQuery } from "react-query"
import axios from "axios"
import LoadingModal from "./../../modal/LoadingModal";

const ViewIcpPage = () => {
    const history = useHistory()
    const { sideBar, activeUser } = useContext(UserContext)
    const [isMounted, setIsMounted] = useState(true)
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [showExportModal, setShowExportModal] = useState(false)

    const setFadeIfMount = () => {
        if (isMounted) { setFadeInTrigger(true) }
    }

    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [])

    const readOneInternalControlPlan = async () => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/one", history.location.state, {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });
        setFadeInTrigger(true)
        return res.data;
    };
    const { data } = useQuery(["getOneInternalControlPlan"], readOneInternalControlPlan, {
        cacheTime: 0
    });

    return (
        <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <div style={{ marginBottom: "10px" }}>
                <PageTitle>Internkontrollplan {history.location.state.icpName} </PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Tillbaka</PageHeaderButton>
                <PageHeaderButton icon="chart" onClick={() => {
                    history.push("/analyze-riskportfolio", {
                        onlyIcp: true,
                        icpId: history.location.state.icpId,
                        icpName: data.name,
                        icpDescription: data.description,
                        icpOrg: data.organisation,
                        activeIcp: data.active
                    })
                }} > Analysera</PageHeaderButton>
                <PageHeaderButton icon="save" onClick={() => { setShowExportModal(true) }}>Exportera</PageHeaderButton>
                {activeUser.admin || activeUser.objectManager ?
                    <PageHeaderButton icon="sitemap" onClick={() => {
                        history.push("/icp-attests", {
                            icpId: history.location.state.icpId,
                            icpName: history.location.state.icpName,
                            active: data.active,
                            icpOrg: data.organisation
                        })
                    }}>Attestering</PageHeaderButton>
                    : null
                }
                {activeUser.admin ? <PageHeaderButton icon="cog" onClick={() => { history.push("/icp-settings", { data }) }}>Inställningar</PageHeaderButton> : null}
            </div>
            <>
                <div style={{ width: "800px", marginBottom: "10px" }}>
                    <div style={{ marginTop: "40px" }} className="bodyText">{data?.description}</div>
                </div>
                {!data?.archived && !data?.active ?
                    <ListRisksInPortfolio onlyIcp={true} icpOrg={data?.organisation} mirrorFromIcp={true}
                        icpName={data?.name} setFadeFromWaitingPlan={setFadeIfMount} setShowExportModal={setShowExportModal} showExportModal={showExportModal} />
                    :
                    <ListRisksInIcp
                        icpId={history.location.state.icpId}
                        icpOrg={data?.organisation}
                        icpName={data?.name}
                        icpDepartments={data?.departments}
                        setShowExportModal={setShowExportModal}
                        showExportModal={showExportModal}
                    />
                }
            </>
        </div >
    )
}

export default ViewIcpPage