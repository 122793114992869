import React, { useState, useContext } from "react";
import { UserContext } from "../../../../../contexts/UserContext"
import axios from "axios";
import styles from "./DeviationReportEditor.module.css";
import WarningModal from "./../../../../../modal/WarningModal";

export default function DeviationReportEditor({ deviationReport, setActiveDeviationReport, controllerId, refetch, setShowModal, riskId, mirrorFromIcp }) {
  const [flagged, setFlagged] = useState(deviationReport?.flagged || false);
  const [proposal, setProposal] = useState(deviationReport?.proposal || null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [description, setDescription] = useState(deviationReport?.description || null);
  const [fromIcp] = useState(state => mirrorFromIcp && mirrorFromIcp)
  const { activeUser } = useContext(UserContext)

  const submitDeviationReport = async () => {
    await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/controller/add-deviation-report",
      {
        controllerId: controllerId,
        proposal: proposal,
        description: description,
        flagged: flagged
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    refetch();
    setShowModal(false)
  };

  const saveChangesToDevReport = async () => {
    await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/controller/edit-deviation-report",
      {
        controllerId: controllerId,
        deviationReportId: deviationReport._id,
        proposal: proposal,
        description: description,
        flagged: flagged
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    setShowModal(false);
    setActiveDeviationReport(null)

    refetch();
  };

  const deleteDevReport = async () => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/controller/delete-deviation-report", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
      data: {
        controllerId: controllerId,
        deviationReportId: deviationReport._id,
      },
    });
    setShowModal(false);
    // setActiveDeviationReport(null)

    refetch();

    return res.status;
  };



  return (
    <>
      <WarningModal
        passedFunction={() => deleteDevReport()}
        warningTitle={"Vill du verkligen ta bort rapporten?"}
        warningText={"Allt arbete med rapporten kommer gå förlorat"}
        optionTextOne={"Ta bort"}
        optionTextTwo={"Ångra"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />
      <div className={styles.devReportEditorContainer}>
        <div className={styles.devReportEditorHeader}>
          <div>Avvikelserapport</div>
          <div className={styles.topEditorBar}>
            <div style={{ display: "flex", marginTop: "6px" }}>
              {!fromIcp ?
                <>
                  <div style={{ fontSize: "14px", marginRight: "15px" }}>Markera som hanterad</div>
                  <i
                    onClick={() => {
                      setFlagged(state => { return !state });
                    }}
                    style={{
                      cursor: "pointer",
                      color: "white",
                      fontSize: "15px",
                      marginTop: "4px"
                    }}
                    className={flagged ? "fas fa-flag" : "far fa-flag"}
                  ></i>
                </>
                :
                <>
                  <div style={{ fontSize: "14px", marginRight: "15px" }}>Markerad som hanterad</div>
                  <i
                    style={{
                      cursor: "pointer",
                      color: "white",
                      fontSize: "15px",
                      marginTop: "4px"
                    }}
                    className={flagged ? "fas fa-flag" : "far fa-flag"}
                  ></i>
                </>
              }
            </div>
            {/* <div> */}
            {deviationReport && !fromIcp ?
              <div style={{ display: "flex", marginTop: "10px", position: "relative", left: "45px" }}>

                <div style={{ fontSize: "14px", marginRight: "15px" }}>Ta bort rapport</div>
                <i
                  onClick={() => {
                    // deleteDevReport()
                    setShowWarningModal(true)
                  }}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "15px",
                    marginTop: "4px"
                  }}
                  className={"fas fa-trash-alt"}
                ></i>
              </div>
              : null}
          </div>

        </div>
        {deviationReport ?
          <div style={{ paddingLeft: "0px", marginBottom: "20px" }}>
            <div className={styles.inputHeader}>Skapad den {deviationReport?.date.slice(0, 10)}</div>
            <div className={styles.inputHeader}>Skapad av {deviationReport?.user}</div>
          </div>
          : null}
        <div className={styles.inputHeader}>Beskrivning</div>
        <textarea
          name="description"
          type="textarea"
          disabled={mirrorFromIcp}
          defaultValue={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          className={`${styles.fieldRow} ${styles.descriptionInput}`}
        // ref={register}
        />
        <div className={styles.inputHeader}>Förslag till åtgärd</div>
        <textarea
          disabled={mirrorFromIcp}
          name="description"
          type="textarea"
          defaultValue={proposal}
          onChange={(e) => {
            setProposal(e.target.value);
          }}
          className={`${styles.fieldRow} ${styles.descriptionInput}`}
        />


        {!fromIcp ?
          <div className={styles.buttonContainer}>
            <input
              type="button"
              value="Spara och stäng"
              onClick={() => {
                if (deviationReport) {
                  saveChangesToDevReport()
                } else { submitDeviationReport() }
              }
              }
              className={styles.updateDeviationReportSubmitButton}
            />
            <input
              type="button"
              value="Avbryt"
              onClick={() => {
                setActiveDeviationReport(null)
                setShowModal(false)
              }}
              className={styles.updateDeviationReportSubmitButton}
            />
          </div>
          :
          <div className={styles.buttonContainer}>
            <input
              type="button"
              value="Stäng"
              onClick={() => {
                setActiveDeviationReport(null)
                setShowModal(false)
              }}
              className={styles.updateDeviationReportSubmitButton}
            />
          </div>
        }
      </div>
    </>
  );
}
