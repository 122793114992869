import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
// import { ReactQueryDevtools } from "react-query-devtools";
import { GlobalStyles } from './styles/global';
import ScrollToTopHook from "./hooks/ScrollToTopHook";

import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
// import DatePicker from "react-datepicker";
import { setDefaultLocale } from "react-datepicker";
// setDefaultLocale(sv)

import PrivateRoute from "./components/PrivateRoute";
import MobileApp from "./components/mobile-app/MobileApp";

import SideBar from "./layout/sidebar/SideBar";
import TopBar from "./layout/topbar/TopBar";

import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/login/RegisterPage";
import ResetPassword from "./pages/login/ResetPassword";


import HomePage from "./pages/home/HomePage";

import AdminPage from "./pages/admin/AdminPage";
import MatrixSettingsPage from "./pages/admin/MatrixSettingsPage";
import CategorySettingsPage from "./pages/admin/CategorySettingsPage";

import OrganisationPage from "./pages/organisation/OrganisationPage";

import TodoPage from "./pages/todo/TodoPage";
import CreateTodoPage from "./pages/todo/CreateTodoPage";
import EditTodoPage from "./pages/todo/EditTodoPage";

import ListUsersPage from "./pages/user/ListUsersPage";
import CreateUserPage from "./pages/user/CreateUserPage";
import EditUserPage from "./pages/user/EditUserPage";


import ShowRiskPage from "./pages/riskportfolio/ShowRiskPage";
import RiskGroupPage from "./pages/riskportfolio/RiskGroupPage";
import ShowRiskGroupPage from "./pages/riskportfolio/ShowRiskGroupPage";
import CreateRiskGroupPage from "./pages/riskportfolio/CreateRiskGroupPage";
import CreateNewRiskPage from "./pages/riskportfolio/CreateRiskPage";
import EditRiskPage from "./pages/riskportfolio/EditRiskPage";
import RiskportfolioPage from "./pages/riskportfolio/RiskportfolioPage";
import AnalyzeRiskPortfolioPage from "./pages/riskportfolio/AnalyzeRiskPortfolioPage";

import IcpSettingsPage from "./pages/internalcontrolplan/IcpSettingsPage";
import AttestPage from "./pages/internalcontrolplan/AttestPage";
import AttestSummaryPage from "./pages/internalcontrolplan/AttestSummaryPage";
import ShowIcpRiskPage from "./pages/internalcontrolplan/ShowIcpRiskPage";
import InternalControlPlanPage from "./pages/internalcontrolplan/InternalControlPlanPage";
// TODO
// import ArchivedPlansPage from "./pages/internalcontrolplan/ArchivedPlansPage";
import ViewIcpPage from "./pages/internalcontrolplan/ViewIcpPage";
import CreateInternalControlPlanPage from "./pages/internalcontrolplan/CreateInternalControlPlanPage";

function App() {
    setDefaultLocale(sv)

    const { isLoggedIn } = useContext(UserContext);
    return (
        <>
            <GlobalStyles />
            <div className="MobileApp">
                <MobileApp />
            </div>
            <div className="App">
                <TopBar />
                {isLoggedIn ? <SideBar /> : null}
                <ScrollToTopHook />
                <Switch>

                    <Route exact path="/"> <LoginPage /> </Route>
                    <Route path="/registrera"> <RegisterPage /> </Route>
                    <Route path="/change-password/:resetCode">
                        {" "}
                        <ResetPassword />{" "}
                    </Route>

                    {/* PRIVATE ROUTES */}

                    {/* INTERNALCONTROLPLAN ROUTES */}
                    <PrivateRoute path="/internalcontrolplans">
                        <InternalControlPlanPage />
                    </PrivateRoute>
                    {/* <PrivateRoute path="/archived-internalcontrolplans">
            <ArchivedPlansPage />
          </PrivateRoute> */}
                    <PrivateRoute path="/icp-view">
                        <ViewIcpPage />
                    </PrivateRoute>
                    <PrivateRoute path="/create-internalcontrolplan">
                        <CreateInternalControlPlanPage />
                    </PrivateRoute>
                    <PrivateRoute path="/icp-settings">
                        <IcpSettingsPage />
                    </PrivateRoute>
                    <PrivateRoute path="/icp-attests">
                        <AttestPage />
                    </PrivateRoute>
                    <PrivateRoute path="/icp-summary-attests">
                        <AttestSummaryPage />
                    </PrivateRoute>
                    <PrivateRoute path="/show-icp-risk">
                        <ShowIcpRiskPage />
                    </PrivateRoute>

                    {/* ADMIN ROUTES */}
                    <PrivateRoute exact path="/admin">
                        <AdminPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/matrix-settings">
                        <MatrixSettingsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/category-settings">
                        <CategorySettingsPage />
                    </PrivateRoute>

                    {/* HOME ROUTES */}
                    <PrivateRoute path="/hem">
                        <HomePage />
                    </PrivateRoute>

                    {/* TODOS ROUTES */}
                    <PrivateRoute path="/uppgifter">
                        <TodoPage />
                    </PrivateRoute>
                    <PrivateRoute path="/skapa-uppgift">
                        <CreateTodoPage />
                    </PrivateRoute>
                    <PrivateRoute path="/ändra-uppgift">
                        <EditTodoPage />
                    </PrivateRoute>

                    {/* USER ROUTES */}
                    <PrivateRoute path="/användare"> <ListUsersPage /> </PrivateRoute>
                    <PrivateRoute path="/skapa-användare"> <CreateUserPage /> </PrivateRoute>
                    <PrivateRoute path="/ändra-användare"> <EditUserPage /> </PrivateRoute>

                    {/* RISKPORTFOLIO ROUTES */}
                    <PrivateRoute path="/riskportfolio">
                        <RiskportfolioPage />
                    </PrivateRoute>
                    <PrivateRoute path="/analyze-riskportfolio">
                        <AnalyzeRiskPortfolioPage />
                    </PrivateRoute>
                    <PrivateRoute path="/skapa-ny-risk">
                        <CreateNewRiskPage />
                    </PrivateRoute>
                    <PrivateRoute path="/show-risk">
                        <ShowRiskPage />
                    </PrivateRoute>
                    <PrivateRoute path="/risk-groups">
                        <RiskGroupPage />
                    </PrivateRoute>
                    <PrivateRoute path="/show-risk-group">
                        <ShowRiskGroupPage />
                    </PrivateRoute>
                    <PrivateRoute path="/create-risk-group">
                        <CreateRiskGroupPage />
                    </PrivateRoute>
                    <PrivateRoute path="/edit-risk">
                        <EditRiskPage />
                    </PrivateRoute>

                    {/* ORGANISATION ROUTES */}
                    <PrivateRoute path="/organisation">
                        <OrganisationPage />
                    </PrivateRoute>

                    <Redirect to="/" />
                </Switch>
                {/* {process.env.NODE_ENV === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : null} */}
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </div>
        </>
    );
}

export default App;
