import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import styles from "./styles/CreateRiskPage.module.css"
import ViewCosoDiagram from "../../components/view-coso-diagram/ViewCosoDiagram";
import axios from "axios"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query";
import { RiskDefinitionLabel, RiskDefinitionCircle } from '../../styles/risk-definitions'
import { PageHeaderButton, SaveButton, DeleteHeaderButton } from "../../styles/buttons"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import calculateRiskGroup from '../../functions/calculateRiskGroup'
import WarningModal from "./../../modal/WarningModal";
import LoadingModal from "./../../modal/LoadingModal";

const EditRiskPage = () => {
  const { sideBar, activeUser } = useContext(UserContext)
  const history = useHistory()
  const [riskData, setRiskData] = useState(null)
  const [riskDataLoaded, setRiskDataLoaded] = useState(false)

  const [probabilitySelection, setProbabilitySelection] = useState(null)
  const [consequenceSelection, setConsequenceSelection] = useState(null)
  const [cosoSize, setCosoSize] = useState(4)

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showWarningBoundModal, setShowWarningBoundModal] = useState(false);
  const [listOfBounds, setListOfBounds] = useState([])
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  const getCurrentSettings = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-current-portfolio-settings", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    setCosoSize(res.data.coso.size)

    const riskgroupResponse = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-risk-groups-names", {
      headers: { authorization: "Bearer " + activeUser.token, },
    });
    let groupNames = riskgroupResponse.data.map(group => group.name)

    let settingsResponse = { ...res.data, groupNames }
    // console.log('settingsResponse', settingsResponse);

    // if (isMounted) { setFadeInTrigger(true) }
    // return res.data;


    if (isMounted) { setFadeInTrigger(true) }
    return settingsResponse;
  };

  const isRiskInIcp = async () => {
    const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/risk/is-risk-in-icp", { riskId: history.location.state.riskData._id }, {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    return res.data;
  };

  const { data, status } = useQuery(["readSettingsForEditRisk"], getCurrentSettings, {});

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    defaultValues: {
      category: history.location.state.riskData.category,
      subCategory: history.location.state.riskData.subCategory,
      process: history.location.state.riskData.process
    }
  })

  const watchCategory = watch("category", false)

  const submitEditRisk = async (passedData) => {

    let riskToSend = {
      ...passedData,
      consequence: consequenceSelection,
      probability: probabilitySelection
    }
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/risk/edit-risk",
      {
        id: riskData._id,
        payload: riskToSend,
      },
      {
        headers: { authorization: "Bearer " + activeUser.token, },
      }
    );
    if (res.status === 200) history.goBack()
    return res;

  }
  const setRiskAsDeleted = async () => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/risk/set-risk-as-deleted",
      {
        id: riskData._id,
        payload: { isDeleted: true },
      },
      {
        headers: { authorization: "Bearer " + activeUser.token, },
      }
    );
    if (res.status === 200) history.push("/riskportfolio");
    return res;

  }

  const deleteRisk = async () => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/delete-risk", {
      headers: { authorization: "Bearer " + activeUser.token, },
      data: { id: riskData._id },
    });
    if (res.status === 200) history.push("/riskportfolio");

    return res.status;
  };

  useEffect(() => {
    setProbabilitySelection(history.location.state.riskData.probability)
    setConsequenceSelection(history.location.state.riskData.consequence)
    setRiskData(history.location.state.riskData)
    // console.log('history.location.state.riskData', history.location.state.riskData);

    setRiskDataLoaded(true)
  }, [history.location.state.riskData])
  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "organisation") {
        setValue("department", "")
      } else if (name === "category") {
        setValue("subCategory", "Ej definierad delprocess")
      }
    })

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <>
      <WarningModal
        passedFunction={async () => {
          let riskBounds = await isRiskInIcp()
          setListOfBounds(riskBounds)
          if (riskBounds.length > 0) {
            setShowWarningBoundModal(true)
          }
          else {
            deleteRisk();
          }
        }}
        warningTitle={"Vill du verkligen ta bort risken?"}
        warningText={`Allt arbete med riskhanteringsåtgärder och förberedelser för kontrollerna kommer att försvinna!`}
        optionTextOne={"Ta bort"}
        optionTextTwo={"Ångra"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />
      <WarningModal
        passedFunction={() => { setRiskAsDeleted() }}
        // warningTitle={`Risken är kopplad till ${listOfBounds.length > 1 ? "nedanstående internkontrollsplaner" : "nedanstående internkontrollsplan"}`}
        warningTitle={`Risken är borttagen!`}
        warningText={`Risken finns kvar som vanligt i  ${listOfBounds.length > 1 ? "nedanstående internkontrollsplaner" : "nedanstående internkontrollsplan"}`}
        optionTextOne={"OK"}
        colour={"blue"}
        open={showWarningBoundModal}
        onClose={() => {
          setShowWarningBoundModal(false);
        }}
      >
        <div style={{ marginBottom: "30px" }}>

          {listOfBounds.map((icp, index) => {
            return <div key={index} style={{ marginBottom: "3px", color: "black", fontSize: "16px", fontWeight: "600" }}><div onClick={() => {
              // history.push("/icp-view", { icpId: icp.belongToIcp._id, icpName: icp.belongToIcp.name })
            }}>
              {icp.belongToIcp.name}

            </div></div>
          })}
        </div>
      </WarningModal>

      <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
        <LoadingModal open={!fadeInTrigger} />
        <>
          <PageTitle>Ändra i risk</PageTitle>
          <PageDelimiter />
          <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Ångra</PageHeaderButton>
          <SaveButton icon="save" onClick={handleSubmit(submitEditRisk)} >Spara</SaveButton>
          <DeleteHeaderButton icon="delete" onClick={() => { setShowWarningModal(true); }} >Ta bort risken</DeleteHeaderButton>
        </>
        <div className={`${data?.coso.size === 5 ? styles.largeCoso : styles.smallCoso}`} >
          <ViewCosoDiagram scale={data?.coso.size}
            maxConsequenceMakesRed={data?.coso.maxConsequenceRed}
            maxProbabilityMakesRed={data?.coso.maxProbabilityRed}
            consequence={consequenceSelection}
            probability={probabilitySelection}

          />
        </div>

        {status === "success" && riskDataLoaded ? (
          <div className={styles.formError}>
            {Object.keys(errors).length > 0 ? <p>{errors[Object.keys(errors)[0].toString()].message}</p> : <p></p>}
          </div>
        ) : null}

        {status === "success" && riskDataLoaded ? (
          <form className={styles.formContainer} autoComplete="off" onSubmit={handleSubmit(submitEditRisk)}>

            <div className={styles.formData}>
              <div className={styles.name}>
                <div className={styles.textInputRow}>
                  <label>Benämning: </label>
                  <input name="name" placeholder={riskData.name}
                    defaultValue={riskData?.name} onChange={e => e.target.value = e.target.value.trimStart().replace(/\s+/g, " ")}
                    onBlur={(e) => {
                      e.target.value = e.target.value.trimEnd()
                    }}
                    // value={value => value.replace(/\s+/g, " ")}
                    {...register('name', {
                      required: "Fyll i fältet benämning",
                      pattern: {
                        value: /^[a-zåäöA-ZÅÄÖ0-9\s]*$/,
                        message: "Bara bokstäver och siffror"
                      },
                      minLength: {
                        value: 3,
                        message: "Minst tre bokstäver i namn"
                      },
                      maxLength: {
                        value: 50,
                        message: "Max femtio bokstäver i namn"
                      }
                    })} />
                </div>
              </div>
              <div className={styles.description}>
                <div className={styles.textBox}>
                  <label>Beskrivning: </label>
                  <textarea className={styles.textBoxArea} name="description" type="textarea" placeholder={riskData?.description}
                    defaultValue={riskData?.description} {...register('description')} />
                </div>
              </div>

              <div className={styles.category}>
                <div className="selectBox">
                  <label>Huvudprocess: </label>
                  <select {...register('category')}
                  // placeholder={riskData.category !== "" ? riskData.category : "Ej definierad process"}
                  //   defaultValue={riskData.category !== "" ? riskData.category : "Ej definierad process"}
                  >
                    <option value="Ej definierad process">Välj process</option>
                    {data.categories
                      .find((cat) => cat.main === history.location.state.riskData.category) ? null : <option value={history.location.state.riskData.category}>{history.location.state.riskData.category}</option>}
                    {data.categories?.map((cat, index) => {
                      return <option key={index}>{cat.main}</option>
                    })}
                  </select>
                </div>
                <div className="selectBox">
                  {watchCategory && watchCategory !== "Ej definierad process" ? (
                    <>
                      <label>Delprocess: </label>
                      <select name="subCategory" {...register('subCategory')}
                      //  placeholder={riskData.subCategory !== "" ? riskData.subCategory : ""}
                      //   defaultValue={riskData.subCategory !== "" ? riskData.subCategory : ""}
                      >
                        {/* <option value="">Välj process</option> */}
                        <option value="Ej definierad delprocess" >Välj delprocess</option>
                        {data.categories
                          .find((cat) => cat.main === watchCategory)
                          ?.children?.map((cato, index) => {
                            return <option key={index}>{cato}</option>
                          })}
                      </select>
                    </>
                  ) : null}
                </div>
              </div>
              <div className={styles.process}>
                <div className="selectBox">
                  <label>Kategori: </label>
                  <select {...register('process')}
                  // placeholder={riskData.process} defaultValue={riskData.process}
                  >
                    <option value="Ej definierad kategori">Välj kategori</option>
                    {data.categories
                      .find((cat) => cat.main === history.location.state.riskData.process) ? null : <option value={history.location.state.riskData.process}>{history.location.state.riskData.process}</option>}
                    {data.processes.map(p => {
                      return <option key={p}>{p}</option>
                    })}
                  </select>
                </div>
              </div>
              {activeUser.admin && data.groupNames.length > 0 ?
                <div className={styles.riskGroup}>
                  <div className="selectBox">
                    <label>Riskgrupp: </label>
                    <select name="riskGroup" {...register('groups[0]')}
                      placeholder={riskData?.groups[0]} defaultValue={riskData?.groups[0]}>
                      <option value="">Välj riskgrupp</option>
                      {data.groupNames?.map(group => {
                        return <option key={group}>{group}</option>
                      })}
                    </select>
                  </div>
                </div> : null}
              <div className={styles.probability}>
                <span className={styles.definition}>
                  <RiskDefinitionLabel size={cosoSize}>Sannolikhet: </RiskDefinitionLabel>
                  {data.riskDefinitionArray?.map((definition, index) => {
                    return (
                      <RiskDefinitionCircle
                        key={index}
                        definition={cosoSize * (index + 1)}
                        selected={probabilitySelection === definition.value ? true : false}
                        onClick={() => setProbabilitySelection(definition.value)}
                      >
                        {definition.value}
                        {/* <span className="tooltip">{definition.probability}</span> */}
                      </RiskDefinitionCircle>)
                  })}
                </span>
                <div className={styles.textBox}>
                  <label>Motivering: </label>
                  <textarea className={styles.textBoxArea}
                    name="probabilityMotivation" type="textarea"
                    placeholder={riskData.probabilityMotivation ? riskData.probabilityMotivation : "Motivera valet här"}
                    defaultValue={riskData.probabilityMotivation} {...register('probabilityMotivation')} />
                </div>
              </div>
              <div className={styles.matrix}>
                <div className={styles.definitionContainer}>
                  {probabilitySelection && consequenceSelection ? (<>
                    <div className={styles.definitionRow}>
                      <RiskDefinitionLabel size={cosoSize}>Riskpoäng: {probabilitySelection * consequenceSelection}</RiskDefinitionLabel>
                    </div>
                    <div className={styles.definitionRow}>
                      <RiskDefinitionLabel size={cosoSize}>Riskvärdesgrupp: {calculateRiskGroup(probabilitySelection, consequenceSelection,
                        data.riskGroupDefinitions, data.coso).group}
                      </RiskDefinitionLabel>
                    </div>
                    <div className={styles.definitionRow}>
                      <div className={styles.definitionDescriptionText}>
                        {calculateRiskGroup(probabilitySelection, consequenceSelection,
                          data.riskGroupDefinitions, data.coso).rgd}
                      </div>
                    </div>
                  </>) : null}
                  {probabilitySelection ? (<>
                    <div className={styles.definitionRow}>
                      <RiskDefinitionLabel size={cosoSize}>Beskrivning av sannolikhet: </RiskDefinitionLabel>
                    </div>
                    <div className={styles.definitionRow}>
                      <div className={styles.definitionDescriptionText}>
                        {data.riskDefinitionArray.find(row => row.value === probabilitySelection)?.probability}
                      </div>
                    </div>
                  </>) : null}
                  {consequenceSelection ? (<>
                    <div className={styles.definitionRow}>
                      <RiskDefinitionLabel size={cosoSize}>Beskrivning av konsekvens: </RiskDefinitionLabel>
                    </div>
                    <div className={styles.definitionRow}>
                      <div className={styles.definitionDescriptionText}>
                        {data.riskDefinitionArray.find(row => row.value === consequenceSelection)?.consequence}
                      </div>
                    </div>
                  </>) : null}
                </div>

              </div>
              <div className={styles.consequence}>
                <div className={styles.definition}>
                  <RiskDefinitionLabel size={cosoSize}>Konsekvens: </RiskDefinitionLabel>
                  {data.riskDefinitionArray.map((definition, index) => {
                    return (
                      <RiskDefinitionCircle
                        key={index}
                        definition={cosoSize * (index + 1)}
                        selected={consequenceSelection === definition.value ? true : false}
                        onClick={() => setConsequenceSelection(definition.value)}
                      >
                        {definition.value}
                        {/* <span className="tooltip">{definition.consequence}</span> */}
                      </RiskDefinitionCircle>)
                  })}
                </div>
                <div className={styles.textBox}>
                  <label>Motivering: </label>
                  <textarea className={styles.textBoxArea} name="consequenceMotivation" type="textarea"
                    placeholder={riskData.consequenceMotivation ? riskData.consequenceMotivation : "Motivera valet här"}
                    defaultValue={riskData.consequenceMotivation} {...register('consequenceMotivation')} />
                </div>
              </div>
            </div>
          </form>
        ) : null
        }
      </div >
    </>
  )
}

export default EditRiskPage