import React from 'react'
import styles from './styles/SubProcessChart.module.css'

export default function SubProcessChart({ data }) {
    const renderSectors = (inco) => {
        let ssp = inco.subProcesses
        let litta = []
        for (const key in ssp) {
            if (Object.hasOwnProperty.call(ssp, key)) {
                const element = ssp[key];
                litta.push({ name: key, nr: element })
            }
        }
        return litta.map((sector, index) => {
            let perca = ((sector.nr / inco.totalNr) * 100).toString().slice(0, 4)
            return <div className={styles.titleBar} key={index}>
                <div style={{ width: "308px" }} className={styles.subProcessRow}>{sector.name}</div>
                <div style={{ width: "70px" }} className={styles.subProcessRow}>{sector.nr}</div>
                <div style={{ width: "30px" }} className={styles.subProcessRow}>{perca}%</div>
            </div>
        })
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.containerTitle}>Riskfördelning i delprocesser</div>
            <div className={styles.processContainer}>
                <div className={styles.titleBar}>
                    <div style={{ width: "280px" }} className={styles.titleCol}>Processer</div>
                    <div style={{ width: "100px" }} className={styles.titleCol}>Antal risker</div>
                    <div style={{ width: "100px" }} className={styles.titleCol}>Andel</div>
                </div>
                {data?.map((process, index) => {
                    return <div className={styles.process} key={index}>
                        <div>{process.mainProcess}</div>
                        {renderSectors(process)}
                    </div>
                })}
            </div>
        </div >
    )
}
