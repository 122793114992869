import XLSX from "xlsx-js-style"
import { saveAs } from 'file-saver'

let FILE_NAME = null
let maxSizes = []

export const exportAsExcel = (data, settings) => {
    if (!data || !settings) return
    let wbProps, wsName = null
    let list = []

    const date = new Date()
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    const today = [day, month, year].join('/')

    if (settings.riskportfolio) { // Riskportfölj
        if (settings.extended) { // Riskportfölj, utfälld
            wbProps = { Title: 'Riskportfölj', Subject: 'Riskportfölj', Author: 'Kommunresurs', CreatedDate: date }
            wsName = 'Riskportfölj utfälld lista'
            FILE_NAME = 'Riskportfölj_utfälld_lista.xlsx'

            const pageHeader = ['Riskportfölj', '', '', '', '', '', '', '', '', '', '', `Rapport uttagen ${today}`]
            list.push(pageHeader)
            list.push([])
            const listHeader = [
                'Risker',
                'Beskrivning',
                'Huvudprocess',
                'Delprocess',
                'Kategori',
                'Avdelning',
                'Senaste avvikelsrapport',
                'Status',
                'Riskvärde',
                'IKP',
                'Antal riskhanteringsåtgärder',
                'Riskhanteringsåtgärder'
            ]
            list.push(listHeader)
            data.map(risk => {
                list.push([
                    risk.name, risk.description, risk.category, risk.subCategory, risk.process,
                    risk.department["name"], risk.latestDeviationReport?.slice(0, 10), risk.calendar ? "Försenad" : null,
                    risk.probability * risk.consequence, risk.icp ? "Ja" : "-", risk.controllers?.filter(controll => !controll.icp).length,
                    risk.controllers?.reduce((list, item) => { if (!item.icp) { return [...list, item.name] } else { return list } }, []).join(', ')
                ])
                return risk
            })

            maxSizes = [
                28, // Risker
                39, // Beskrivning
                19, // Huvudprocess
                22, // Delprocess
                19, // Kategori
                22, // Avdelning
                23, // Senaste avvikelsrapport
                12,  // Status
                9,  // Riskvärde
                3,  // IKP
                5, // Antal riskhanteringsåtgärder
                22  // Riskhanteringsåtgärder
            ]
        } else { // Riskportfölj, ej utfälld
            wbProps = { Title: 'Riskportfölj', Subject: 'Riskportfölj', Author: 'Kommunresurs', CreatedDate: date }
            wsName = 'Riskportfölj'
            FILE_NAME = 'Riskportfölj.xlsx'

            const pageHeader = ['Riskportfölj', '', '', '', '', '', '', '', '', `Rapport uttagen ${today}`]
            list.push(pageHeader)
            list.push([])
            const listHeader = [
                'Risker',
                'Huvudprocess',
                'Delprocess',
                'Kategori',
                'Avdelning',
                'Senaste avvikelsrapport',
                'Status',
                'Riskvärde',
                'IKP',
                'Antal riskhanteringsåtgärder'
            ]
            list.push(listHeader)
            data.map(risk => {
                // list.push([
                //     risk.name, risk.process, risk.subProcess, risk.category,
                //     risk.department, risk.updatedAt, risk.status,
                //     risk.riskValue, risk.icp, risk.measures
                // ])
                list.push([
                    risk.name, risk.category, risk.subCategory, risk.process,
                    risk.department["name"], risk.latestDeviationReport?.slice(0, 10), risk.calendar ? "Försenad" : "",
                    risk.probability * risk.consequence, risk.icp ? "Ja" : "-", risk.controllers?.filter(controll => !controll.icp).length
                ])
                return risk
            })

            maxSizes = [
                29, // Risker
                19, // Huvudprocess
                19, // Delprocess
                23, // Kategori
                22, // Avdelning
                23, // Senaste avvikelsrapport
                12,  // Status
                9,  // Riskvärde
                3,  // IKP
                26  // Riskhanteringsåtgärder
            ]
        }
    } else { // IKP
        if (settings.active) { // IKP, aktiv
            if (settings.extended) { // IKP, aktiv, utfälld
                wbProps = { Title: 'Internkontrollplan', Subject: 'Internkontrollplan', Author: 'Kommunresurs', CreatedDate: date }
                wsName = 'IKP aktiv utfälld lista'
                FILE_NAME = 'IKP_aktiv_utfälld.xlsx'

                const pageHeader = ['Internkontrollplan ' + settings.title, '', '', '', '', '', '', '', '', '', '', '', '', '', `Rapport uttagen ${today}`]
                list.push(pageHeader)
                list.push([])
                const listHeader = [
                    'Risker',
                    'Beskrivning',
                    'Utfall',
                    'Åtgärd',
                    'Huvudprocess',
                    'Delprocess',
                    'Kategori',
                    'Avdelning',
                    'Senaste avvikelserapport',
                    'Antal avvikelserapporter',
                    'Antal förbättringsförslag',
                    'Status',
                    'Riskvärde',
                    'Antal kontroller',
                    'Kontroller'
                ]
                list.push(listHeader)
                data.map(risk => {
                    list.push([
                        risk.name, risk.description, risk.result, risk.suggestion,
                        risk.category, risk.subCategory, risk.process, risk.department["name"],
                        risk.latestDeviationReport.slice(0, 10),
                        risk.reviews?.reduce((sum, item) => { return sum + Number(item.deviationReports.length) }, 0),
                        risk.reviews?.reduce((sum, item) => {
                            return sum + item.verifyChecks?.reduce((sum2, item2) => {
                                if (item2.asSuggestion) { return sum2 + 1 } else { return sum2 }
                            }, 0)
                        }, 0),
                        risk.status === 0 ? 'Ej rapporterad' :
                            risk.status === 1 ? 'Klar' :
                                risk.status === 2 ? 'Avvaktande' :
                                    risk.status === 3 ? 'Varningar' : 0,
                        risk.consequence * risk.probability, risk.reviews?.length,
                        risk.reviews?.reduce((list, item) => { return [...list, item.name] }, []).join(', ')
                    ])
                    return risk
                })

                maxSizes = [
                    32, // Risker
                    28, // Beskrivning
                    22, // Utfall
                    23, // Åtgärd
                    21, // Huvudprocess
                    21, // Delprocess
                    24, // Kategori
                    19,  // Avdelning
                    24, // Senaste avvikelserapport
                    24, // Antal avvikelserapporter
                    25, // Antal förbättringsförslag
                    14, // Status
                    9,  // Riskvärde
                    5, // Antal kontroller
                    28  // Kontroller
                ]
            } else { // IKP, aktiv, ej utfälld
                wbProps = { Title: 'Internkontrollplan', Subject: 'Internkontrollplan', Author: 'Kommunresurs', CreatedDate: date }
                wsName = 'IKP aktiv lista'
                FILE_NAME = 'IKP_aktiv.xlsx'

                const pageHeader = ['Internkontrollplan' + settings.title, '', '', '', '', '', '', '', `Rapport uttagen ${today}`]
                list.push(pageHeader)
                list.push([])
                const listHeader = [
                    'Risker',
                    'Huvudprocess',
                    'Delprocess',
                    'Kategori',
                    'Avdelning',
                    'Senaste avvikelserapport',
                    'Status',
                    'Riskvärde',
                    'Kontroller'
                ]
                list.push(listHeader)
                data.map(risk => {
                    list.push([
                        risk.name, risk.category, risk.subCategory, risk.process,
                        risk.department["name"], risk.latestDeviationReport.slice(0, 9),
                        risk.status === 0 ? 'Ej rapporterad' :
                            risk.status === 1 ? 'Klar' :
                                risk.status === 2 ? 'Avvaktande' :
                                    risk.status === 3 ? 'Varningar' : 0,
                        risk.consequence * risk.probability, risk.reviews?.length
                    ])
                    return risk
                })

                maxSizes = [
                    32, // Risker
                    19, // Huvudprocess
                    21, // Delprocess
                    24, // Kategori
                    21,  // Avdelning
                    24, // Senaste avvikelserapport
                    14, // Status
                    9,  // Riskvärde
                    26, // Kontroller
                ]
            }
        } else { // IKP, väntande
            if (settings.extended) { // IKP, väntande, utfälld
                wbProps = { Title: 'Internkontrollplan', Subject: 'Internkontrollplan', Author: 'Kommunresurs', CreatedDate: date }
                wsName = 'IKP väntande utfälld lista'
                FILE_NAME = 'IKP_väntande_utfälld.xlsx'

                const pageHeader = ['Internkontrollplan ' + settings.title, '', '', '', '', '', '', '', '', `Rapport uttagen ${today}`]
                list.push(pageHeader)
                list.push([])
                const listHeader = [
                    'Risker',
                    'Beskrivning',
                    'Motivering till IKP',
                    'Huvudprocess',
                    'Delprocess',
                    'Kategori',
                    'Avdelning',
                    'Riskvärde',
                    'Antal kontroller',
                    'Kontroller'
                ]
                list.push(listHeader)
                data.map(risk => {
                    list.push([
                        risk.name, risk.description, risk.icpMotivation,
                        risk.category, risk.subCategory, risk.process, risk.department["name"],
                        risk.probability * risk.consequence, risk.controllers?.filter(controll => controll.icp).length,
                        risk.controllers?.reduce((list, item) => { if (item.icp) { return [...list, item.name] } else { return list } }, []).join(', ')
                    ])

                    return risk
                })

                maxSizes = [
                    32, // Risker
                    38, // Beskrivning
                    57, // Motivering till IKP
                    19, // Huvudprocess
                    22, // Delprocess
                    24, // Kategori
                    33,  // Avdelning
                    11,  // Riskvärde
                    5, // Antal kontroller
                    26  // Kontroller
                ]
            } else { // IKP, väntande, ej utfälld
                wbProps = { Title: 'Internkontrollplan', Subject: 'Internkontrollplan', Author: 'Kommunresurs', CreatedDate: date }
                wsName = 'IKP väntande lista'
                FILE_NAME = 'IKP_väntande.xlsx'

                const pageHeader = ['Internkontrollplan ' + settings.title, '', '', '', '', '', `Rapport uttagen ${today}`]
                list.push(pageHeader)
                list.push([])
                const listHeader = ['Risker', 'Huvudprocess', 'Delprocess', 'Kategori', 'Avdelning', 'Riskvärde', 'Antal kontroller']
                list.push(listHeader)
                data.map(risk => {
                    list.push([
                        risk.name, risk.category, risk.subCategory, risk.process,
                        risk.department["name"], risk.probability * risk.consequence, risk.controllers?.filter(controll => controll.icp).length
                    ])
                    return risk
                })

                maxSizes = [
                    32, // Risker
                    22, // Huvudprocess
                    25, // Delprocess
                    44, // Kategori
                    41,  // Avdelning
                    18,  // Riskvärde
                    15 // Antal kontroller
                ]
            }
        }
    }

    if (!FILE_NAME) return null

    /**
     * Create Worksheet
     */
    let ws = XLSX.utils.aoa_to_sheet(list)
    // console.log('ws["A1"].v', ws['A1']?.v)
    const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
    ];
    ws["!merges"] = merge;
    const columnSizes = computeColumnSizes(list)
    ws['!cols'] = columnSizes

    /**
     * Create Workbook
     */
    let wb = XLSX.utils.book_new()
    wb.Props = wbProps
    wb.SheetNames.push(wsName)
    wb.Sheets[wsName] = ws

    /**
     * Create Excel File
     */
    function s2ab(s) {
        let buf = new ArrayBuffer(s.length)
        let view = new Uint8Array(buf)
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
        return buf
    }
    const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
    saveAs(new Blob([s2ab(excelFile)], { type: "application/octet-stream" }), FILE_NAME)
}

const computeColumnSizes = (list) => {
    // https://github.com/SheetJS/sheetjs/issues/1473

    // Find widest size in each column
    let actualSizes = []
    list.forEach(item => {
        item.forEach((value, column) => {
            let size = 0
            switch (typeof value) {
                // case 'number': size = 10; break;
                case 'string': size = value.length; break;
                // case 'object': if(value instanceof Date) size = 10; break;
                default: break;
            }
            actualSizes[column] = actualSizes[column] >= size ? actualSizes[column] : size
        })
    })
    // console.log('actualSizes', actualSizes)

    // Compare widest size with max size for each column
    let columnSizes = []
    // actualSizes.forEach(size => { columnSizes.push( { width: size }) })
    actualSizes.forEach((size, column) => {
        columnSizes.push({ width: size > maxSizes[column] ? maxSizes[column] : size })
    })
    return columnSizes
}
