import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/UserContext"
import styles from "../styles/ReportAndSuggestIcp.module.css";
import axios from "axios";
import { useForm } from "react-hook-form"
import { AddButtonShadow } from '../../../styles/buttons'

export default function ReportAndSuggestIcp({ risk, onlyIcp, icpRiskId, icpRiskData, icpRiskRefetch, riskData }) {
    const [hideInfoCard] = useState(true);
    const { activeUser } = useContext(UserContext)
    const [showAddTodoButton, setShowAddTodoButton] = useState(true)
    // const [disableTodoButton, setDisableTodoButton] = useState(false)
    // const [suggestionText, setSuggestionText] = useState(() => icpRiskData.suggestion)
    const [statusCode, setStatusCode] = useState(() => {
        if (!icpRiskData.status) { return 0 }
        else { return icpRiskData.status }
    })
    // console.log('icpRiskData?.suggestion', icpRiskData?.suggestion);
    // console.log('icpRiskData.latestTodo', icpRiskData.latestTodo);

    // console.log('icpRiskData', icpRiskData);
    // console.log('RiskData', riskData);
    // console.log('Risk', risk);


    const { register, handleSubmit, watch } = useForm({
        defaultValues: {
            suggestionInput: icpRiskData?.suggestion
        }
    })

    const watchSuggestion = watch("suggestionInput")
    // const watchSuggestion = watch("suggestionInput", icpRiskData?.suggestion)

    const createTodoRequest = async (data) => {

        let todoData = {
            name: "Åtgärd till risk " + risk.name,
            icp: icpRiskData.belongToIcp,
            description: data.suggestionInput,
            department: icpRiskData.department,
            // organisation: icpRiskData.organisation
            organisation: risk.organisation._id
        }
        // console.log('todoData', todoData);
        await axios.post(process.env.REACT_APP_IC_SERVICE + "/todo/create-todo", todoData, {
            headers: { authorization: "Bearer " + activeUser.token },
        });
        await axios.post(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/set-latest-todo-date", { icpRiskId: icpRiskId }, {
            headers: { authorization: "Bearer " + activeUser.token },
        });
        setShowAddTodoButton(false)
    };

    const updateIcpRiskResult = async (payload) => {
        const res = await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/update-icp-risk-result",
            { id: icpRiskId, payload: payload, },
            {
                headers: { authorization: "Bearer " + activeUser.token, },
            }
        );
        return res;
    };
    const autoGrow = (element) => {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    }

    useEffect(() => {
        autoGrow(document.getElementById("icpResultId"))
        autoGrow(document.getElementById("icpSuggestionId"))
    }, [])
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "suggestionInput") {
                // console.log("yes");
                setShowAddTodoButton(true)
            }
        })

        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className={styles.cardContainer}>
            <div className={styles.infoContainer}>
                <div className={styles.header}>Avrapportering </div>
                <div className={styles.radioContainer}>
                    <label className={styles.subHeader}>Status: </label>

                    <div className={`${styles.generalRadioButton} ${styles.whiteRadio}`}>
                        <input
                            type="radio"
                            className={`${styles.radioButtonStyle}`}
                            id="radioWhite"
                            name="materialExampleRadios"
                            onChange={() => {
                                setStatusCode(0)
                                updateIcpRiskResult({ status: 0 })
                            }}
                            checked={statusCode === 0}
                        ></input>
                        <label htmlFor="radioWhite">
                            Ej rapporterad
                        </label>
                    </div>

                    <div className={`${styles.generalRadioButton} ${styles.greenRadio}`}>
                        <input
                            type="radio"
                            className={`${styles.radioButtonStyle}`}
                            id="radioGreen"
                            name="materialExampleRadios"
                            onChange={() => {
                                setStatusCode(1)
                                updateIcpRiskResult({ status: 1 })
                            }}
                            checked={statusCode === 1}
                        ></input>
                        <label htmlFor="radioGreen">
                            Klar
                        </label>
                    </div>

                    <div className={`${styles.generalRadioButton} ${styles.yellowRadio}`}>
                        <input
                            type="radio"
                            className={`${styles.radioButtonStyle}`}
                            id="radioYellow"
                            name="materialExampleRadios"
                            onChange={() => {
                                setStatusCode(2)
                                updateIcpRiskResult({ status: 2 })
                            }}
                            checked={statusCode === 2}
                        ></input>
                        <label htmlFor="radioYellow">
                            Avvaktande
                        </label>
                    </div>

                    <div className={`${styles.generalRadioButton} ${styles.redRadio}`}>
                        <input
                            type="radio"
                            className={`${styles.radioButtonStyle}`}
                            id="radioRed"
                            name="materialExampleRadios"
                            onChange={() => {
                                setStatusCode(3)
                                updateIcpRiskResult({ status: 3 })
                            }}
                            checked={statusCode === 3}
                        ></input>
                        <label htmlFor="radioRed">
                            Varningar
                        </label>
                    </div>

                </div>
            </div>
            {hideInfoCard ? (
                <div className={styles.descriptionContainer}>
                    <label className={styles.subHeader}>Utfall: </label>
                    <textarea
                        name="icpResult"
                        placeholder="Här sammanfattar du utfallet av arbetet med risken."
                        defaultValue={icpRiskData?.result}
                        type="textarea"
                        id="icpResultId"
                        className={styles.textBox}
                        onInput={(e) => autoGrow(e.target)}
                        onBlur={(e) => {
                            updateIcpRiskResult({ result: e.target.value })
                        }}
                    />

                    <label className={styles.subHeader}>Åtgärd: </label>
                    {icpRiskData.latestTodo ? <span> (uppgift skapad: {icpRiskData.latestTodo.slice(0, 10)})</span> : null}
                    <form className={styles.formContainer} onSubmit={handleSubmit(createTodoRequest)}>
                        <textarea id="icpSuggestionId" className={styles.textBox} type="textarea"
                            placeholder="Finns det några förslag på förbättringar eller åtgärder så anger du dem här."
                            onInput={(e) => { autoGrow(e.target) }}
                            defaultValue={icpRiskData?.suggestion} {...register('suggestionInput')}
                            onBlur={(e) => {
                                // setDisableTodoButton(false)
                                updateIcpRiskResult({ suggestion: e.target.value })
                            }}
                        />
                    </form>
                    {watchSuggestion.length !== 0 && showAddTodoButton && (watchSuggestion !== icpRiskData?.suggestion || !icpRiskData.latestTodo) ?
                        <div style={{ position: "relative", left: "0px", top: "10px" }} >
                            <AddButtonShadow icp={true} icon="add" onClick={handleSubmit(createTodoRequest)} >Skapa uppgift</AddButtonShadow>
                        </div>
                        : null}

                </div>
            ) : null}
        </div>
    );
}