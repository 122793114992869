import React, { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext"
import styles from "./ReviewModal.module.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useQuery } from "react-query";
import VerifySection from "./report-section/VerifySection";
import DeviationSection from "./report-section/DeviationSection";
import FilesInController from "./files-section/FilesInController";
import { ControllerButton } from '../../../../styles/buttons'
import { Frequencies } from '../../../../constants/frequencies'

const ReviewModal = ({ risk, icpRiskId, activeReview, refetchReviews, setUpdateState, setShowModal, riskIdFromHomeScreen, rpIdFromHomeScreen, pathFromHomeScreen }) => {
  const { activeUser } = useContext(UserContext)


  const requestOneReview = async () => {
    const res = await axios.post(
      process.env.REACT_APP_IC_SERVICE + "/review/get-one-review",
      { id: activeReview._id },
      { headers: { authorization: "Bearer " + activeUser.token, }, }
    );
    return res.data;

  };


  const readUsersToAssign = async () => {
    let mas = await axios.post(
      process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/get-owners",
      { riskDep: risk.department },
      { headers: { ContentType: "application/json", authorization: "Bearer " + activeUser.token, }, }
    );
    return mas.data;
  };
  const { data: userAssignList, status: userListStatus } = useQuery(["getAllUsersForEditReview"], readUsersToAssign);
  const { data, status, refetch } = useQuery(["getReviewToModal"], requestOneReview, { cacheTime: 0, });

  const { register, handleSubmit, formState: { errors } } = useForm({});

  const submitUpdateReview = async (reviewData) => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/review/update-review",
      {
        id: activeReview._id,
        payload: reviewData,
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    if (typeof setUpdateState === "function") { setUpdateState((state) => !state); }
    setShowModal(false);
    refetchReviews()
    refetch()
    return res;
  };

  return data !== null && status === "success" && userListStatus === "success" ? (
    <div className={styles.modalContainer}>
      <div>
        <div onClick={() => { setShowModal(false) }} className={styles.iconBox}>
          <i className="fas fa-times fa-lg"></i>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(submitUpdateReview)}>
          <h3 className="">Kontroll</h3>
          <div className={styles.addControllerLabel}>Namn: </div>
          <input
            name="name"
            style={{ width: "350px", height: "25px", paddingLeft: "4px" }}
            defaultValue={data.name}
            type="text"
            className={styles.fieldRow}
            onChange={(e) => { e.target.value = e.target.value.trimStart().replace(/\s+/g, " ") }}
            onBlur={(e) => { e.target.value = e.target.value.trimEnd() }}
            {...register('name', {
              required: "Fyll i namn",
              // pattern: {
              //   value: /^[a-zåäöA-ZÅÄÖ0-9\s]*$/,
              //   message: "Bara bokstäver och siffror"
              // },
              minLength: {
                value: 2,
                message: "Minst två tecken i namn"
              },
              maxLength: {
                value: 50,
                message: "Max femtio tecken i namn"
              }
            })}
          />
          <label className={styles.addControllerLabel}>Beskrivning: </label>
          <textarea
            name="description"
            type="textarea"
            defaultValue={data.description}
            className={`${styles.fieldRow} ${styles.descriptionInput}`}
            {...register('description')}
          />
          <div style={{ marginBottom: "10px" }} className={styles.addControllerLabel}>Skapad: {data.createdAt.slice(0, 10)}</div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <div className={styles.addControllerLabel}>Metod:</div>
              <select
                name="method"
                style={{ padding: "1px 30px 1px 1px" }}
                className={styles.fieldRow}
                {...register('method')}
                defaultValue={data.method}
              >
                <option>Ingen vald</option>
                <option>Automatisk</option>
                <option>Semiautomatisk</option>
                <option>Manuell</option>
              </select>
            </div>
            <div>
              <div className={styles.addControllerLabel}>Frekvens:</div>
              <select
                style={{ padding: "1px 30px 1px 1px" }}
                name="frequency"
                className={styles.fieldRow}
                {...register('frequency')}
                defaultValue={data.frequency}
              >
                {Frequencies.map((opt, index) => <option key={index}>{opt}</option>)}
                {/* <option>Ingen vald</option>
                <option>Dagligen</option>
                <option>Veckovis</option>
                <option>Månadsvis</option>
                <option>Kvartalsvis</option>
                <option>Tertialsvis</option>
                <option>Årligen</option> */}
              </select>
            </div>
          </div>
          <div className={styles.addControllerLabel}>Ansvarig: </div>

          <select name="assignedUserId" {...register('assignedUserId')} className={styles.fieldRow}
            style={{ padding: "1px 10px 1px 1px" }}
            placeholder={data.assignedUserId}
            defaultValue={data.assignedUserId}>
            <option value="empty">Välj användare</option>
            {userListStatus === "success"
              ? userAssignList
                .map((d) => {
                  return (
                    <option key={d._id} value={d._id}>
                      {d.firstName} {d.lastName}
                    </option>
                  );
                })
              : null}
          </select>
          <div className={styles.saveButtonPlacer}>
            <ControllerButton blue={true} type="submit" disabled={false} >Spara och stäng</ControllerButton>
          </div>
        </form>

        <FilesInController riskId={risk._id} icpRiskId={icpRiskId} controller={data} refetch={refetch} status={status} pathFromHomeScreen={pathFromHomeScreen} />
        {Object.keys(errors).length > 0 ? <div style={{ color: "red", position: "absolute" }}>{errors[Object.keys(errors)[0].toString()].message}</div> : null}
        {/* {controllerAlreadyExist ? <div style={{ color: "red", position: "absolute" }}>Finns redan kontroll med detta namn i risken!</div> : null} */}
      </div>
      <div style={{ marginLeft: "100px", marginBottom: "0px" }}>
        <h3>Avrapportering</h3>
        <>
          <VerifySection data={data} status={status} refetch={refetch} />
          <DeviationSection queriedReview={data} riskId={risk._id} data={activeReview} status={status} refetch={refetch} pathFromHomeScreen={pathFromHomeScreen} />
        </>
      </div>
    </div >
  ) : null;
};
export default ReviewModal;
