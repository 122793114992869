import { createGlobalStyle } from "styled-components"

/*
 * Global Styles
 * CSS Variables
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/var
 *
 * The CSS box-sizing property allows us to include the padding and border in an element's total width and height.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing
 * See https://css-tricks.com/box-sizing/
 * 
 * The viewport is the user's visible area of a web page.
 * The viewport varies with the device, and will be smaller on a mobile phone than on a computer screen.
 * vh	Relative to the viewport* 1% height
 * vw	Relative to the viewport* 1% width
 * 
 * According to the W3C spec the definition for one rem unit is:
 * Equal to the computed value of font-size on the root element. 
 * When specified on the font-size property of the root element, the rem units refer to the property’s initial value.
 * This means that 1rem equals the font size of the html element (which for most browsers has a default value of 16px).
 * 
*/

export const GlobalStyles = createGlobalStyle`
    
    :root {
        --sidebar-footer-fg: #b0bec5;
        --primary-blue: rgb(47, 100, 171);
        --green-icp-color: rgb(27, 127, 136);
        --fade-global-loading-modal-time: 0.8s;
        --globalTestVar: purple;
        --copeWebFont : 'Arial', sans-serif;
        --bodyText: 12px;
        --labelText: 14px;

        --layout-color-fg: white;
        --layout-color-bg: rgb(80, 80, 80);

        --page-header-button-fg: white;
        --page-header-button-bg: rgb(47, 100, 171, 0.85);
        --page-header-button-bg-hover: rgb(47, 100, 171, 0.95);

        --page-delimiter-color: rgb(117, 151, 197, 0.7);

        --debug-border-color-container: white;
        --debug-bg-color-container: blue;
        --debug-border-color: black;
        --debug-bg-color-topbar: red;
        --debug-bg-color-sidebar: green;
        --debug-bg-color-main: white;

        /* risk definition colors */
        --color-green-dark: rgb(84, 180, 122);
        --color-green-light: rgb(106, 226, 154);
        --color-yellow: rgb(252, 255, 102);
        --color-orange-dark: rgb(231, 37, 37);
        --color-orange-light: rgb(254, 125, 53);

        --color-risk-definition-value: rgb(0, 0, 0, .45);
        --color-risk-definition-border: rgb(0, 0, 0, 1);

        /* form colors */
        --color-text-input: #ccc;
        --color-text-input-focus: #333;
        --color-text-box: rgb(200, 200, 200);
        --color-select-box: rgb(200, 200, 200);
        --color-form-error: #e64a19;
    }
    
    html {
        font-size: 100%;
        /* font-family: var(--copeWebFont); */
        box-sizing: border-box;
      }
      
      body {
      height: 100vh;
      margin: 0;
      padding-top: 90px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
     -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    /* *, *:before, *:after {
        box-sizing: inherit;
    }

    img {
        max-width: 100%;
    } */

    .large-side-bar {
      position: relative;
      transition: all 0.3s ease-in-out;
      margin-left: 310px;
    }

    .small-side-bar {
      position: relative;
      transition: all 0.3s ease-in-out;
      margin-left: 135px;
    }

    .pageSubTitle {
      margin-bottom: 10px;
      font-size: 17px;
      font-weight: 450;
    } 
    .bodyText {
      font-size: 14px;
      font-weight: 450;
    }
    .selectBox {
  display: flex;
}

    .selectBox label {
      width: 110px;
      padding-top: 3px;
      font-size: var(--labelText);
    }

    .selectBox select {
      font-size: 12px;
      /* height: 26px; */
      min-width: 250px;
      margin-left: 25px;
      margin-bottom: 15px;
      padding: 3px;
      cursor: pointer;
      border: thin solid var(--color-select-box);
      border-radius: 5px;
    }

    .selectBox select:focus {
      outline: none;
    }
   

.fadeWaiting {
  display: none;
}

.fadeIn {
  animation: fadeIn ease var(--fade-global-loading-modal-time);
  -webkit-animation: fadeIn ease var(--fade-global-loading-modal-time);
  -moz-animation: fadeIn ease var(--fade-global-loading-modal-time);
  -o-animation: fadeIn ease var(--fade-global-loading-modal-time);
  -ms-animation: fadeIn ease var(--fade-global-loading-modal-time);
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}

@media  screen and (min-width: 830px) {
  .MobileApp{
  display:none;
  } 
}
@media screen and (max-width: 830px) {
  .App{
    display:none;
  }
}
`