import React, { useRef, useEffect, useState } from "react";
import ReactDom from "react-dom";
import styled from "styled-components"

export const OptionButton = styled.button`

font-size: 13px;
font-family: "Montserrat", sans-serif;
  width: 150px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  background-color: ${({ colour }) => (colour === "green") ? "rgb(27,127,136)"
    : (colour === "blue") ? "rgb(47, 100, 171)" : "rgb(55, 55, 55)"};
  height: 25px;
  padding-left: 21px;
  padding-right: 21px;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;

&:hover {
  background-color: ${({ colour }) => (colour === "green") ? "rgb(17,117,126)" : (colour === "blue") ? "rgb(78,123,184)" : "rgb(40, 40, 40)"};
}
&:focus {
    outline: 0;
}
`

export const TextInput = styled.div`

  font-size: 14px;
  /* width: 300px; */
  color: red;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  /* padding-bottom: 15px; */
  margin: 10px 0px;
  padding: 0px 10px 5px 10px;

  textarea {
  width: 400px;
  resize: none;
  border: 1px solid #ccc;
  padding-bottom: 50px;
  padding: 30px;
  color: #333;
  /* margin-left: 5px; */
  font-size: 13px;
  height: 195px;
  font-family: Arial;
  padding: 5px;
  border-radius: 8px;
  }

  textarea:focus {
    outline-width: 0px;
    /* border: 1px solid #888; */
    color: #333;
  }
  &:focus {
    outline: 0;
  }
`

const MODAL_STYLES = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  position: "fixed",
  top: "50%",
  left: "50%",
  borderRadius: "15px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  // backgroundColor: "rgb(27,127,136)",
  // backgroundColor: "var(--green-icp-color)",
  zIndex: "1000",
  padding: "20px"
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgb(0,0,0, 0.6)",
  zIndex: "999",
};

const LABEL_STYLES = {
  fontSize: "15px",
  fontWeight: "600",
};

export default function ReportIcpModal({ onClose, todoToComment, checkMarkOption, open, labelText, warningText, optionTextOne, optionTextTwo, passedFunction, children, colour, setNewOrganisationName }) {
  const orgInput = useRef(null);
  const [newText, setNewText] = useState(todoToComment?.comment)
  const [markAsDone, setMarkAsDone] = useState(todoToComment?.done)

  useEffect(() => {
    orgInput?.current?.focus()

  })
  useEffect(() => {
    setMarkAsDone(todoToComment?.done);
    setNewText(todoToComment?.comment)

  }, [todoToComment])

  if (!open) return null;



  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>
        <div style={LABEL_STYLES}>{labelText}</div>
        <div style={{
          marginTop: "10px", marginBottom: "10px", maxWidth: "390px", textAlign: "center", fontSize: "12px"
        }}>{warningText}</div>

        {children}
        <div>
          <TextInput>
            <textarea
              ref={orgInput}
              defaultValue={todoToComment?.comment}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  onClose()
                }
              }}
              name="description"
              type="textarea"
              onChange={(data) => setNewText(data.target.value)}
            />
          </TextInput>

          {checkMarkOption ?
            <div className="" style={{ fontSize: "14px", marginLeft: "17px", marginBottom: "40px" }} >
              <label style={{ fontSize: "14px", marginRight: "10px", cursor: "pointer" }}>Markera som klar</label>
              {markAsDone
                ? <i style={{ cursor: "pointer" }} className="far fa-check-square" onClick={() => setMarkAsDone(false)}></i>
                : <i style={{ cursor: "pointer" }} className="far fa-square" onClick={() => setMarkAsDone(true)}></i>
              }
            </div> : null}
          <div style={{ textAlign: "center" }}>

            <OptionButton alert={true} colour="green" onClick={(e) => {
              passedFunction({ newText, markAsDone })
              setNewText("")
              onClose()
            }}>{optionTextOne}</OptionButton>
            {optionTextTwo ?
              <OptionButton alert={false} onClick={() => {
                onClose()
              }}>{optionTextTwo}</OptionButton>
              : null}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
}
