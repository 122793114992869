import React, { useContext, useState } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton, SaveButton, DeleteHeaderButton } from '../../styles/buttons'
import WarningModal from "./../../modal/WarningModal";
import styles from "./styles/IcpSettingsPage.module.css";
import axios from "axios"
import DatePicker from "react-datepicker";
import LoadingModal from "./../../modal/LoadingModal";

const IcpSettingsPage = () => {
    const history = useHistory()
    let icpAttestDate = Date.parse(history.location.state.data.attestDate)
    const { sideBar, activeUser } = useContext(UserContext)
    const [showModal, setShowModal] = useState(false);
    const [attestDate, setAttestDate] = useState(new Date(icpAttestDate));
    const [icpDescription, setIcpDescription] = useState(history.location.state.data?.description ? history.location.state.data.description : "");
    const fadeInTrigger = true

    const onChangeAttestDate = (date) => {
        setAttestDate(new Date(date));
    };

    const deleteInternalControlPlan = async (data) => {
        const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/delete-internalcontrolplan", {
            headers: { authorization: "Bearer " + activeUser.token, },
            data: { id: history.location.state.data._id },
        });
        if (res.status === 200) history.push("/internalcontrolplans");
        return res.status;
    };

    const saveChangesToIcp = async (data) => {
        let payLoad = { attestDate, description: icpDescription }

        const res = await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/update-internalcontrolplan",
            { icpId: history.location.state.data._id, payLoad },
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        );
        if (res.status === 200) history.push("/internalcontrolplans");
        return res.status;
    };

    return (
        <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <>
                <PageTitle>Inställningar för {history.location.state.data.name}</PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Gå tillbaka</PageHeaderButton>
                <SaveButton icon="save" onClick={() => { saveChangesToIcp() }} >Spara ändringar</SaveButton>
                <DeleteHeaderButton icon="delete" onClick={() => { setShowModal(true) }} >Ta bort internkontrollplanen</DeleteHeaderButton>
            </>
            <>
                <WarningModal
                    passedFunction={() => deleteInternalControlPlan("TESTID XYZ")}
                    warningTitle={"Vill du verkligen ta bort internkontrollplanen?"}
                    warningText={"Allt arbete med internkontrollplanen kommer gå förlorat"}
                    optionTextOne={"Ta bort"}
                    optionTextTwo={"Ångra"}
                    colour={"red"}
                    open={showModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
                <div style={{ width: "750px", marginBottom: "50px", marginTop: "40px" }}>
                    <div style={{ fontSize: "14px", marginBottom: "10px" }}>
                        Här kan du ändra tid för attest samt beskrivning av internkontrollsplanen.
                        Beroende vart i processen internkontrollsplanen är så innebär attestdatumet det ena
                        och det andra. Titta in videogenomgångar i hjälpavsnitten.
                        Här kan du välja om planen ska vara synlig på hemskärmen eller inte. Detta kan du även
                        göra i listvyn genom att
                        klicka på hänglåset.
                    </div>
                </div>
                <div
                    className={styles.createPortfolioContainer}
                >
                    <label style={{ fontSize: "14px" }} >Ändra attestdatum: </label>
                    <div style={{ marginBottom: "22px", marginTop: "5px" }}>
                        <DatePicker
                            selected={attestDate}
                            onChange={onChangeAttestDate} className="form-control" />
                    </div>
                    <label style={{ fontSize: "14px" }}>Ändra beskrivning: </label>
                    <textarea
                        name="description"
                        type="textarea"
                        placeholder={history.location.state.data?.description}
                        defaultValue={history.location.state.data?.description}
                        className={styles.createPortfolioItem}
                        required
                        onChange={(e) => { setIcpDescription(e.target.value) }}
                        style={{
                            width: "520px",
                            height: "120px",
                            lineHeight: "20px",
                            fontFamily: "Arial",
                            fontSize: "14px",
                            padding: "6px",
                            border: "1px solid #bbb",
                            borderRadius: "5px"
                        }}
                    />
                </div>
            </>
        </div >
    )
}

export default IcpSettingsPage