import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton, HelpButton } from '../../styles/buttons'
import LoadingModal from "./../../modal/LoadingModal";
import HelpTextModal from "./../../modal/HelpTextModal"
import { adminText1, adminText2, adminText3, adminText4, adminText5, adminText6 } from "./../../constants/helpText"
// import logo from "../../media/kommunresurs-logo-header.svg";
// import styles from "./AdminPage.module.css";

const helpText =
    // <div style={{ height: "100px", overflow: "scroll", margin: "auto" }}>
    <div style={{ textAlign: "left" }}>
        {/* <div style={{ marginTop: "10px", fontWeight: "600", textAlign: "center", marginBottom: "20px" }}>
      Hjälp
    </div> */}
        <div style={{ marginTop: "10px" }}>
            {adminText1}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Riskmatris</div>
            {adminText2}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Benämningar</div>
            {adminText3}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Riskvärdesgruppering</div>
            {adminText4}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Processer</div>
            {adminText5}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Kategorier</div>
            {adminText6}
        </div>
    </div>

const AdminPage = () => {
    const history = useHistory()
    const { sideBar } = useContext(UserContext)
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [showHelpTextModal, setShowHelpTextModal] = useState(false)

    useEffect(() => {
        setFadeInTrigger(true)
    }, [])

    return (
        <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <HelpTextModal
                title="Hjälp"
                helpText={helpText}
                optionTextOne={"OK"}
                open={showHelpTextModal}
                onClose={() => { setShowHelpTextModal(false); }}
            />
            <>
                <PageTitle>Admin</PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="matrix" onClick={() => { history.push("/matrix-settings") }}>Riskmatris</PageHeaderButton>
                <PageHeaderButton icon="folder" onClick={() => { history.push("/category-settings") }}>Kategorier / Processer</PageHeaderButton>
                <HelpButton onClick={() => setShowHelpTextModal(true)} ></HelpButton>
                {/* <PageHeaderButton icon="customer" onClick={() => { }}>Kunduppgifter</PageHeaderButton> */}
            </>
            <div style={{ maxWidth: "730px", marginTop: "40px", marginBottom: "70px" }} className="bodyText">

                <p>
                    I denna flik hanterar ni inställningar såsom riskmatrisen, kategorier och processer.
                    Klicka på de olika knapparna för att ta er vidare till respektive inställningssida.
                </p>
                <p>
                    Runt om i programmet finns symbolen <HelpButton mini={true}></HelpButton> som en hjälpsymbol. Klicka där för att få ytterligare förklaring till funktionerna på sidan.
                </p>
                <p>
                    Vår växel dit du som administratör är välkommen att ringa är öppen mån-fre helgfria dagar mellan kl. 09-15. Telefonnumret är 010-214 64 24.

                </p>
                {/* <p>
                    I denna flik hanterar ni inställningar såsom riskmatrisen, kategorier
                    och processer. Klicka på de olika knapparna för att ta er vidare till
                    respektive inställningssida

                </p>
                <div style={{ marginLeft: "30px" }}>
                    <div>- Kommunresurs har varit kund sedan <b>2021-04-01</b></div>
                    <div>- Totalt finns det <b>28</b> användare, där <b>3</b> användare inte har registrerat sig ännu.</div>
                    <div>- Ni nyttjar <b>0.6 / 2GB</b> av ert totala lagringsutrymme. Avdelning <b>Ekonomi </b>
                        i organisationen <b>Kommunen</b> använder mest utrymme.
                    </div>
                    <div>- Totalt finns <b>48</b> objekt och <b>Kommunen</b> är den största organisation</div>
                </div> */}
            </div>
            {/* <img className={styles.logoTitle} src={logo} alt="Kommunresurs" /> */}
        </div >
    )
}

export default AdminPage