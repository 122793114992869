import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
// import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { useQuery } from "react-query";
import axios from "axios";
import TextInputModal from "./../../modal/TextInputModal";
import WarningModal from "./../../modal/WarningModal";
import "./styles/treeStyle.css";
// import "./styles/context-menu.css";
import OrganisationContextMenu from "./components/OrganisationContextMenu";

const initialContextMenu = {
    show: false,
    x: 0,
    y: 0,
    childId: null,
    childObject: null
}

function OrganisationTree({ setAllNodes, refetch, allNodes, selectedAncestor, setSelectedAncestor }) {

    const { activeUser } = useContext(UserContext);
    const [enteredName, setEnteredName] = useState("");
    const [clickedAddNode, setClickedAddNode] = useState(null);
    const [errorInputMessage, setErrorInputMessage] = useState("");
    const [deleteErrorMessage, setDeleteErrorMessage] = useState("")
    const [nrOfObjectToPrint, setNrOfObjectToPrint] = useState(null)
    const [showTextInputModal, setShowTextInputModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showWarningDeleteModal, setShowWarningDeleteModal] = useState(false);

    const [contextMenu, setContextMenu] = useState(initialContextMenu)

    const normalSizeStyle = {
        fontSize: "12px"
    }
    const smallerSizeStyle = {
        fontSize: "10px"
    }
    const smallestSizeStyle = {
        fontSize: "7px"
    }

    const getObjectAccessListId = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects-id", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });

        return res.data;
    };

    const { data: accessList, refetch: refetchAccessList } = useQuery(["getAccessListIdFromOrg"], getObjectAccessListId, {});

    const sendAddChild = async () => {
        let payload = {
            name: enteredName,
            level: clickedAddNode.level ? clickedAddNode.level + 1 : 1,
            ancestor: clickedAddNode.ancestor ? clickedAddNode.ancestor : clickedAddNode._id,
            parentId: clickedAddNode._id,
            parents: [...clickedAddNode.parents, clickedAddNode._id]
        };
        try {
            let svar = await axios.post(process.env.REACT_APP_IC_SERVICE + "/organisation/new-object", payload, {
                headers: {
                    ContentType: "application/json",
                    authorization: "Bearer " + activeUser.token,
                },
            });
            if (svar.status === 200) {
                refetch();
                refetchAccessList()
            }
            return svar;
        } catch (error) {
            return error;
        }
    };
    const isNodeTop = (data) => {
        if (allNodes.length > 1) {
            let ancestorArray = allNodes.filter((node) => node.level === 1);

            if (ancestorArray[0]._id === data._id) {
                return true;
            } else {
                return false;
            }
        }
    };

    const returnLastNode = () => {
        if (allNodes.length > 1) {
            let ancestorArray = allNodes.filter((node) => node.level === 1);
            let lastObjectId = ancestorArray[1]._id;

            return lastObjectId;
        } else return false;
    };

    const sendDeleteChild = async (data) => {

        try {
            const res = await axios.post(
                process.env.REACT_APP_IC_SERVICE + "/organisation/delete-object",
                {
                    data,
                },
                {
                    headers: {
                        ContentType: "application/json",
                        authorization: "Bearer " + activeUser.token,
                    },
                }
            );
            if (res.status === 200) {
                if (data.level === 1 && !isNodeTop(data)) {
                    setSelectedAncestor(null);
                    setAllNodes([]);
                } else if (data.level === 1 && isNodeTop(data)) {
                    setSelectedAncestor([returnLastNode()]);
                }
            }
            return res.status;
        } catch {
            return "error";
        }
    };
    const isObjectBound = async (id) => {
        try {
            const res = await axios.post(
                process.env.REACT_APP_IC_SERVICE + "/organisation/is-object-bound",
                {
                    id,
                },
                {
                    headers: {
                        ContentType: "application/json",
                        authorization: "Bearer " + activeUser.token,
                    },
                }
            );

            if (res.data.length > 0) {

                setDeleteErrorMessage("Du kan inte ta bort objektet på grund av att det innehåller ")

                if (res.data.length === 1) { setDeleteErrorMessage((state) => state + res.data[0]) }
                else if (res.data.length === 2) { setDeleteErrorMessage((state) => state + res.data[0] + " och " + res.data[1]) }
                else if (res.data.length === 3) { setDeleteErrorMessage((state) => state + res.data[0] + ", " + res.data[1] + " och " + res.data[2]) }
                setShowWarningDeleteModal(true)

                return true
            } else {
                return false
            }

        } catch {
            return "error isObjectBound";
        }
    };

    const addChild = (noden) => {
        setShowTextInputModal(true)
        setClickedAddNode(noden);
        setDeleteErrorMessage("")
    };

    const deleteChild = async (noden) => {
        let isBounded = await isObjectBound(noden._id)
        if (!isBounded) {
            await sendDeleteChild(noden)
            setDeleteErrorMessage("")
            refetch();
        }
    };
    const submitNewArea = () => {

        let nodeAlreadyExist = false;

        if (process.env.REACT_APP_COPE_WEB_TARGET === "kommunresurs") {
            allNodes.forEach((element) => {
                if (enteredName.trim().toUpperCase() === element.name.toUpperCase() && ((element.level === 1) || (element.ancestor.toString() === selectedAncestor.toString()))) {
                    nodeAlreadyExist = true;
                    setErrorInputMessage(element.name + " finns redan!");
                } else if (enteredName === "") {
                    nodeAlreadyExist = true;
                    setErrorInputMessage("Ange ett namn");
                } else if (enteredName.length > 30) {
                    nodeAlreadyExist = true;
                    setErrorInputMessage("Max 30 tecken");
                } else if (enteredName.trim().length < 2) {
                    nodeAlreadyExist = true;
                    setErrorInputMessage("Minst 2 tecken");
                }
            });
        } else if (process.env.REACT_APP_COPE_WEB_TARGET === "cope") {
            allNodes.forEach((element) => {
                if (enteredName.trim().toUpperCase() === element.name.toUpperCase() && ((element.level === 1))) {
                    nodeAlreadyExist = true;
                    setErrorInputMessage(element.name + " finns redan!");
                } else if (enteredName === "") {
                    nodeAlreadyExist = true;
                    setErrorInputMessage("Ange ett namn");
                } else if (enteredName.length > 30) {
                    nodeAlreadyExist = true;
                    setErrorInputMessage("Max 30 tecken");
                } else if (enteredName.trim().length < 2) {
                    nodeAlreadyExist = true;
                    setErrorInputMessage("Minst 2 tecken");
                }
            });
        }

        if (nodeAlreadyExist) { setShowWarningModal(true) }

        if (!nodeAlreadyExist) {
            sendAddChild();
            setShowTextInputModal(false)
            setEnteredName("");
            setErrorInputMessage("");
        }
    };

    const renderTree = () => {
        return <DrawChildren children={selectedAncestor} />
    };

    const handleContextMenu = (event, childId, childObject) => {
        event.preventDefault()
        const { pageX, pageY } = event
        setContextMenu({ show: true, x: pageX, y: pageY, childId: childId, childObject: childObject })
    }

    function DrawChildren({ children }) {
        return (
            <ul>
                {selectedAncestor && allNodes.length > 0
                    ?
                    children.map((childId, index) => {
                        let childObject = allNodes.find((element) => element._id === childId)
                        if (childObject !== undefined) {
                            return (
                                <li key={index} style={nrOfObjectToPrint > 50 ? smallestSizeStyle : nrOfObjectToPrint > 25 ? smallerSizeStyle : normalSizeStyle}>
                                    <div
                                        onContextMenu={(e) => handleContextMenu(e, childId, childObject)}
                                        disable={activeUser.admin ? 'false' : (activeUser.objectManager && accessList?.includes(childId)) ? 'false' : 'true'}
                                    >
                                        <div className="ruta">{childObject.name}</div>
                                    </div>
                                    {
                                        childObject.children.length > 0 ? (
                                            <DrawChildren children={childObject.children} />
                                        ) : null
                                    }
                                </li>
                            )
                        } else {
                            return null
                        }
                    })
                    : null
                }
            </ul>
        )
    }

    // function DrawChildren({ children }) {
    //     return (
    //         <ul>
    //             {selectedAncestor && allNodes.length > 0
    //                 ? children.map((childId, index) => {
    //                     let childObject = allNodes.find((element) => element._id === childId);

    //                     if (childObject !== undefined) {
    //                         return (
    //                             <li key={index} style={nrOfObjectToPrint > 50 ? smallestSizeStyle : nrOfObjectToPrint > 25 ? smallerSizeStyle : normalSizeStyle}>
    //                                 <ContextMenuTrigger id={childId} disable={activeUser.admin ? false : (activeUser.objectManager && accessList?.includes(childId)) ? false : true}>
    //                                     <div className="ruta">{childObject.name}</div>
    //                                 </ContextMenuTrigger>
    //                                 <ContextMenu
    //                                     id={childId}
    //                                     style={{
    //                                         display: "flex",
    //                                         flexDirection: "column",
    //                                         background: "none",
    //                                         border: "none",
    //                                         zIndex: "10",
    //                                     }}
    //                                 >
    //                                     <MenuItem
    //                                         data={{ parent: childId }}
    //                                         onClick={(e) => {
    //                                             addChild(childObject);
    //                                         }}
    //                                     >
    //                                         Lägg till underområde
    //                                     </MenuItem>
    //                                     <MenuItem
    //                                         onClick={() => {
    //                                             deleteChild(childObject);
    //                                         }}
    //                                     >
    //                                         Ta bort område
    //                                     </MenuItem>
    //                                 </ContextMenu>
    //                                 {
    //                                     childObject.children.length > 0 ? (
    //                                         <DrawChildren children={childObject.children} />
    //                                     ) : null
    //                                 }
    //                             </li>
    //                         );
    //                     } else {
    //                         return null;
    //                     }
    //                 })
    //                 : null}
    //         </ul >
    //     );
    // }

    useEffect(() => {
        setDeleteErrorMessage("")
        let getNrOfObjectToPrint = allNodes?.filter((node) => { return node?.ancestor?.toString() === selectedAncestor?.toString() }).length
        setNrOfObjectToPrint(getNrOfObjectToPrint)

    }, [selectedAncestor, setSelectedAncestor, allNodes])


    return (
        <>
            <WarningModal
                passedFunction={() => { }}
                warningTitle={"Det gick inte skapa område"}
                warningText={errorInputMessage}
                // warningText={`Var god överlåt ansvar till annan användare innan du ändrar organisation, avdelningen eller tar bort den här användaren.
                // Användaren är ansvarig över följande kontroller och riskhanteringsåtgärder. Du kan
                // klicka på risknamnet för att ta dig vidare direkt till riskerna.`}
                optionTextOne={"OK"}
                // optionTextTwo={"Ångra"}
                colour={"blue"}
                open={showWarningModal}
                onClose={() => {
                    setShowWarningModal(false);
                    setEnteredName("")
                }}
            />
            <WarningModal
                passedFunction={() => { }}
                warningTitle={"Det gick inte ta bort objektet!"}
                warningText={deleteErrorMessage}
                // warningText={`Var god överlåt ansvar till annan användare innan du ändrar organisation, avdelningen eller tar bort den här användaren.
                // Användaren är ansvarig över följande kontroller och riskhanteringsåtgärder. Du kan
                // klicka på risknamnet för att ta dig vidare direkt till riskerna.`}
                optionTextOne={"OK"}
                // optionTextTwo={"Ångra"}
                colour={"blue"}
                open={showWarningDeleteModal}
                onClose={() => {
                    setShowWarningDeleteModal(false);
                }}
            />
            <TextInputModal
                passedFunction={() => {
                    submitNewArea();
                }}
                setNewOrganisationName={setEnteredName}
                labelText={"Ange namn på område"}
                warningText={"Användaren kommer att raderas från systemet!"}
                optionTextOne={"Skapa"}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={showTextInputModal}
                onClose={() => {
                    setShowTextInputModal(false);
                }}
            />
            {contextMenu.show &&
                <OrganisationContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    closeContextMenu={(e, isAdding, isDeleting) => {
                        if (isAdding) {
                            addChild(contextMenu.childObject)
                        }
                        if (isDeleting) {
                            deleteChild(contextMenu.childObject)
                        }
                        setContextMenu(initialContextMenu)
                    }}
                />
            }
            {/* <div style={{ color: "red", height: "20px" }}>{deleteErrorMessage}</div> */}
            <div style={{ color: "red", marginTop: "30px" }} className="tree">{renderTree()}</div>
        </>
    );
}

export default OrganisationTree;
