import React, { useContext, useEffect } from "react"
import { UserContext } from "../../../../contexts/UserContext"
// import { useHistory } from "react-router-dom"
import axios from "axios"
// import RiskGroupModal from "./../../../../modal/RiskGroupModal";

// import LoadingModal from "./../../../../modal/LoadingModal";
import styles from './styles/RenderNewRisksList.module.css'
import { useForm, useFieldArray, useFormState } from "react-hook-form";
import { PageHeaderButton, SaveButton } from '../../../../styles/buttons'

export default function RenderNewRisksList({ groupName, settings, accessList, defaultSettingsRisk, refetch }) {
  const { activeUser } = useContext(UserContext)
  // const [showRiskGroupModal, setShowRiskGroupModal] = useState(false);


  // const history = useHistory()
  const { register, control, handleSubmit, setValue, getValues, watch, formState, reset } = useForm({ defaultValues: { newRisks: [] } });
  const { fields, append, remove } = useFieldArray({ control, name: "newRisks" });

  const onSubmit = async (data) => {

    // console.table(data.newRisks)
    const result = await axios.post(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/create-risks-to-group", data, {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    refetch()
    // if (result.status === 200) history.goBack()
    if (result.status === 200) reset({ newRisks: [] })
    // return result
  }


  const { dirtyFields } = useFormState({ control });



  const sortDropDown = (index) => {
    let GETVAL33 = getValues(`newRisks.${index}.organisation`)

    if (GETVAL33.toString() === "") return []
    let initList = accessList.filter((org) => { return org.ancestor === GETVAL33.toString() || org._id === GETVAL33.toString() })
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep.level === 1)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log('value', value);
      // console.log('name', name);

      if (name) {

        var arr = name.split(".")
        let index = arr[1]
        let whatFieldIsThis = arr[2]
        if (whatFieldIsThis === "category") {
          let GETVAL2 = getValues(`newRisks.${index}.subCategory`)
          if (GETVAL2 !== "Ej definierad process") { setValue(`newRisks.${index}.subCategory`, "Ej definierad delprocess") }
        } else if (whatFieldIsThis === "organisation") {
          let GETVAL2 = getValues(`newRisks.${index}.department`)
          if (GETVAL2 !== "") { setValue(`newRisks.${index}.department`, "") }
        }
      }
    })
    return () => subscription.unsubscribe();
  }, [watch, getValues, setValue])

  return (
    <>
      {/* <RiskGroupModal
        passedFunction={async () => {
        }}
        warningTitle={"Skapa risker till objeksträd"}
        warningText={"Genom att välja objekt och trycka på skapa, så kommer det att skapas en risk till varje underobjekt med de förvalda riskegenskaperna."}
        optionTextOne={"Skapa"}
        optionTextTwo={"Avbryt"}
        colour={"blue"}
        accessList={accessList}
        open={showRiskGroupModal}
        onClose={() => {
          setShowRiskGroupModal(false);
        }}
      /> */}
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => {
            return (
              <div key={item.id}>
                <div className={styles.newRiskListContainer}>
                  <div className={styles.searchInputRow}>
                    <input
                      defaultValue={"Namn på risk"}
                      {...register(`newRisks.${index}.name`, {
                        required: { value: true, message: "Ange namn på risk" },
                        minLength: { value: 2, message: "Minst två bokstäver i förnamn" }
                      })}
                    />
                  </div>
                  {/* {formState.errors?.newRisks?.hasOwnProperty(index) ? "ja" : "nej"} */}
                  <div className={styles.selectBox}>
                    <select
                      {...register(`newRisks.${index}.category`)}>
                      <option value="Ej definierad process" >Välj huvudprocess</option>
                      {settings?.categories?.map((cat, index) => {
                        return <option value={cat.main} key={index + "category"}>{cat.main}</option>
                      })}
                    </select>
                  </div>
                  <div className={styles.selectBox}>
                    <select
                      {...register(`newRisks.${index}.subCategory`)}>
                      <option value="Ej definierad delprocess" >Välj delprocess</option>
                      {watch(`newRisks.${index}.category`) !== "Ej definierad process" ? settings?.categories
                        .find((cat) => cat.main === watch(`newRisks.${index}.category`))
                        .children.map((cato, index) => {
                          return <option value={cato} key={index}>{cato}</option>
                        }) : null}
                    </select>
                  </div>
                  <div className={styles.selectBox}>
                    <select defaultValue={"Ej definierad kategori"} {...register(`newRisks.${index}.process`)} >
                      <option value="Ej definierad kategori" >Välj kategori</option>
                      {settings?.processes?.map((cat, index) => {
                        return <option value={cat} key={index + "process"}>{cat}</option>
                      })}
                    </select>
                  </div>
                  <div className={styles.selectBox}>
                    <select {...register(`newRisks.${index}.organisation`, { required: { value: true, message: "Ange organisation" } })} >
                      <option value="" >Välj organisation</option>
                      {accessList?.filter((org) => { return org.level === 1 }).map((dep, index) => {
                        return <option key={index + "organisation"} value={dep._id} >{dep.name}</option>
                      })}
                    </select>
                  </div>
                  <div className={styles.selectBox}>
                    <select {...register(`newRisks.${index}.department`, { required: { value: true, message: "Ange avdelning" } })}>
                      <option value="">Välj avdelning</option>
                      {watch(`newRisks.${index}.organisation`) !== "" ? sortDropDown(index).map((dep, index) => {
                        return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                      }) : null}
                    </select>
                  </div>
                  <div className={styles.selectBox}><input type="number" min="1"
                    max={settings.coso.size} {...register(`newRisks.${index}.probability`, {
                      valueAsNumber: true,
                      min: 1,
                      max: settings.coso.size
                    })} /></div>
                  <div className={styles.selectBox}><input type="number" min="1"
                    max={settings.coso.size} {...register(`newRisks.${index}.consequence`, {
                      valueAsNumber: true,
                      min: 1,
                      max: settings.coso.size
                    })} /></div>
                  <i onClick={() => remove(index)} style={{ cursor: "pointer", color: "red", fontSize: "14px", marginTop: "4px" }} className={"fas fa-trash-alt"} ></i>
                </div>

                {formState.errors?.newRisks?.hasOwnProperty(index) && formState?.errors?.newRisks[index] ? <div className={styles.riskEntryError}>{formState?.errors?.newRisks[index][Object.keys(formState?.errors?.newRisks[index])[0]]?.message} </div> : null}
                {/* </div> */}
                {/* <div className={styles.riskEntryError}>
              {formState.errors?.newRisks?.hasOwnProperty(index) && formState?.errors?.newRisks[index] ? formState?.errors?.newRisks[index][Object.keys(formState?.errors?.newRisks[index])[0]]?.message : null}
            </div> */}
              </div>
            );
          })}
        </form >
        <div style={{ marginTop: "40px" }}>
          <PageHeaderButton icon="add" onClick={(e) => {
            e.preventDefault()
            append(defaultSettingsRisk)
            // append({
            //   name: "Namn på risk",
            //   category: "Ej definierad process",
            //   subCategory: "Ej definierad delprocess",
            //   process: "Ej definierad kategori",
            //   organisation: "",
            //   department: "",
            //   probability: 1,
            //   consequence: 1,
            //   groups: [groupName]
            // })
          }
          }
          >Lägg till ny risk</PageHeaderButton>
          {/* <PageHeaderButton icon="add" onClick={(e) => {
            setShowRiskGroupModal(true)
          }
          }
          >Skapa risker till objektsträd</PageHeaderButton> */}
          {dirtyFields.newRisks ? <SaveButton icon="save" onClick={handleSubmit(onSubmit)} >Spara ändringar</SaveButton> : null}
        </div>
      </div>
    </>
  );
}