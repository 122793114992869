import { cosoImage_3_1_1 } from './cosoImages/coso-3/coso-3-1/cosoImage_3_1_1'
import { cosoImage_3_1_2 } from './cosoImages/coso-3/coso-3-1/cosoImage_3_1_2'
import { cosoImage_3_1_3 } from './cosoImages/coso-3/coso-3-1/cosoImage_3_1_3'

import { cosoImage_3_2_1 } from './cosoImages/coso-3/coso-3-2/cosoImage_3_2_1'
import { cosoImage_3_2_2 } from './cosoImages/coso-3/coso-3-2/cosoImage_3_2_2'
import { cosoImage_3_2_3 } from './cosoImages/coso-3/coso-3-2/cosoImage_3_2_3'

import { cosoImage_3_3_1 } from './cosoImages/coso-3/coso-3-3/cosoImage_3_3_1'
import { cosoImage_3_3_2 } from './cosoImages/coso-3/coso-3-3/cosoImage_3_3_2'
import { cosoImage_3_3_3 } from './cosoImages/coso-3/coso-3-3/cosoImage_3_3_3'

import { cosoImage_4_1_1 } from './cosoImages/coso-4/coso-4-1/cosoImage_4_1_1'
import { cosoImage_4_1_2 } from './cosoImages/coso-4/coso-4-1/cosoImage_4_1_2'
import { cosoImage_4_1_3 } from './cosoImages/coso-4/coso-4-1/cosoImage_4_1_3'
import { cosoImage_4_1_4 } from './cosoImages/coso-4/coso-4-1/cosoImage_4_1_4'

import { cosoImage_4_2_1 } from './cosoImages/coso-4/coso-4-2/cosoImage_4_2_1'
import { cosoImage_4_2_2 } from './cosoImages/coso-4/coso-4-2/cosoImage_4_2_2'
import { cosoImage_4_2_3 } from './cosoImages/coso-4/coso-4-2/cosoImage_4_2_3'
import { cosoImage_4_2_4 } from './cosoImages/coso-4/coso-4-2/cosoImage_4_2_4'

import { cosoImage_4_3_1 } from './cosoImages/coso-4/coso-4-3/cosoImage_4_3_1'
import { cosoImage_4_3_2 } from './cosoImages/coso-4/coso-4-3/cosoImage_4_3_2'
import { cosoImage_4_3_3 } from './cosoImages/coso-4/coso-4-3/cosoImage_4_3_3'
import { cosoImage_4_3_4 } from './cosoImages/coso-4/coso-4-3/cosoImage_4_3_4'

import { cosoImage_4_4_1 } from './cosoImages/coso-4/coso-4-4/cosoImage_4_4_1'
import { cosoImage_4_4_2 } from './cosoImages/coso-4/coso-4-4/cosoImage_4_4_2'
import { cosoImage_4_4_3 } from './cosoImages/coso-4/coso-4-4/cosoImage_4_4_3'
import { cosoImage_4_4_4 } from './cosoImages/coso-4/coso-4-4/cosoImage_4_4_4'

import { cosoImage_5_1_1 } from './cosoImages/coso-5/coso-5-1/cosoImage_5_1_1'
import { cosoImage_5_1_2 } from './cosoImages/coso-5/coso-5-1/cosoImage_5_1_2'
import { cosoImage_5_1_3 } from './cosoImages/coso-5/coso-5-1/cosoImage_5_1_3'
import { cosoImage_5_1_4 } from './cosoImages/coso-5/coso-5-1/cosoImage_5_1_4'
import { cosoImage_5_1_5 } from './cosoImages/coso-5/coso-5-1/cosoImage_5_1_5'

import { cosoImage_5_2_1 } from './cosoImages/coso-5/coso-5-2/cosoImage_5_2_1'
import { cosoImage_5_2_2 } from './cosoImages/coso-5/coso-5-2/cosoImage_5_2_2'
import { cosoImage_5_2_3 } from './cosoImages/coso-5/coso-5-2/cosoImage_5_2_3'
import { cosoImage_5_2_4 } from './cosoImages/coso-5/coso-5-2/cosoImage_5_2_4'
import { cosoImage_5_2_5 } from './cosoImages/coso-5/coso-5-2/cosoImage_5_2_5'

import { cosoImage_5_3_1 } from './cosoImages/coso-5/coso-5-3/cosoImage_5_3_1'
import { cosoImage_5_3_2 } from './cosoImages/coso-5/coso-5-3/cosoImage_5_3_2'
import { cosoImage_5_3_3 } from './cosoImages/coso-5/coso-5-3/cosoImage_5_3_3'
import { cosoImage_5_3_4 } from './cosoImages/coso-5/coso-5-3/cosoImage_5_3_4'
import { cosoImage_5_3_5 } from './cosoImages/coso-5/coso-5-3/cosoImage_5_3_5'

import { cosoImage_5_4_1 } from './cosoImages/coso-5/coso-5-4/cosoImage_5_4_1'
import { cosoImage_5_4_2 } from './cosoImages/coso-5/coso-5-4/cosoImage_5_4_2'
import { cosoImage_5_4_3 } from './cosoImages/coso-5/coso-5-4/cosoImage_5_4_3'
import { cosoImage_5_4_4 } from './cosoImages/coso-5/coso-5-4/cosoImage_5_4_4'
import { cosoImage_5_4_5 } from './cosoImages/coso-5/coso-5-4/cosoImage_5_4_5'

import { cosoImage_5_5_1 } from './cosoImages/coso-5/coso-5-5/cosoImage_5_5_1'
import { cosoImage_5_5_2 } from './cosoImages/coso-5/coso-5-5/cosoImage_5_5_2'
import { cosoImage_5_5_3 } from './cosoImages/coso-5/coso-5-5/cosoImage_5_5_3'
import { cosoImage_5_5_4 } from './cosoImages/coso-5/coso-5-5/cosoImage_5_5_4'
import { cosoImage_5_5_5 } from './cosoImages/coso-5/coso-5-5/cosoImage_5_5_5'

const getCosoImage = (cosoSize, probability, consequence) => {
    let imageData = null
    let imageSize = null

    if (cosoSize === 3) {
        if (probability === 1) {
            if (consequence === 1) imageData = cosoImage_3_1_1
            if (consequence === 2) imageData = cosoImage_3_1_2
            if (consequence === 3) imageData = cosoImage_3_1_3
        }
        if (probability === 2) {
            if (consequence === 1) imageData = cosoImage_3_2_1
            if (consequence === 2) imageData = cosoImage_3_2_2
            if (consequence === 3) imageData = cosoImage_3_2_3
        }
        if (probability === 3) {
            if (consequence === 1) imageData = cosoImage_3_3_1
            if (consequence === 2) imageData = cosoImage_3_3_2
            if (consequence === 3) imageData = cosoImage_3_3_3
        }
    }

    if (cosoSize === 4) {
        if (probability === 1) {
            if (consequence === 1) imageData = cosoImage_4_1_1
            if (consequence === 2) imageData = cosoImage_4_1_2
            if (consequence === 3) imageData = cosoImage_4_1_3
            if (consequence === 4) imageData = cosoImage_4_1_4
        }
        if (probability === 2) {
            if (consequence === 1) imageData = cosoImage_4_2_1
            if (consequence === 2) imageData = cosoImage_4_2_2
            if (consequence === 3) imageData = cosoImage_4_2_3
            if (consequence === 4) imageData = cosoImage_4_2_4
        }
        if (probability === 3) {
            if (consequence === 1) imageData = cosoImage_4_3_1
            if (consequence === 2) imageData = cosoImage_4_3_2
            if (consequence === 3) imageData = cosoImage_4_3_3
            if (consequence === 4) imageData = cosoImage_4_3_4
        }
        if (probability === 4) {
            if (consequence === 1) imageData = cosoImage_4_4_1
            if (consequence === 2) imageData = cosoImage_4_4_2
            if (consequence === 3) imageData = cosoImage_4_4_3
            if (consequence === 4) imageData = cosoImage_4_4_4
        }
    }

    if (cosoSize === 5) {
        if (probability === 1) {
            if (consequence === 1) imageData = cosoImage_5_1_1
            if (consequence === 2) imageData = cosoImage_5_1_2
            if (consequence === 3) imageData = cosoImage_5_1_3
            if (consequence === 4) imageData = cosoImage_5_1_4
            if (consequence === 5) imageData = cosoImage_5_1_5
        }
        if (probability === 2) {
            if (consequence === 1) imageData = cosoImage_5_2_1
            if (consequence === 2) imageData = cosoImage_5_2_2
            if (consequence === 3) imageData = cosoImage_5_2_3
            if (consequence === 4) imageData = cosoImage_5_2_4
            if (consequence === 5) imageData = cosoImage_5_2_5
        }
        if (probability === 3) {
            if (consequence === 1) imageData = cosoImage_5_3_1
            if (consequence === 2) imageData = cosoImage_5_3_2
            if (consequence === 3) imageData = cosoImage_5_3_3
            if (consequence === 4) imageData = cosoImage_5_3_4
            if (consequence === 5) imageData = cosoImage_5_3_5
        }
        if (probability === 4) {
            if (consequence === 1) imageData = cosoImage_5_4_1
            if (consequence === 2) imageData = cosoImage_5_4_2
            if (consequence === 3) imageData = cosoImage_5_4_3
            if (consequence === 4) imageData = cosoImage_5_4_4
            if (consequence === 5) imageData = cosoImage_5_4_5
        }
        if (probability === 5) {
            if (consequence === 1) imageData = cosoImage_5_5_1
            if (consequence === 2) imageData = cosoImage_5_5_2
            if (consequence === 3) imageData = cosoImage_5_5_3
            if (consequence === 4) imageData = cosoImage_5_5_4
            if (consequence === 5) imageData = cosoImage_5_5_5
        }
    }

    imageSize = 44

    return { imageData, imageSize }
}

export default getCosoImage
