import React, { useState, useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { PageHeaderButton } from '../../../styles/buttons'
import styles from "../styles/ListRisksInIcp.module.css";
import Tooltip from "../../../components/tooltip/Tooltip";
import ExportRisksModal from "./../../../modal/ExportRisksModal";
import { exportAsExcel } from "./../../../functions/exportToFile/exportExcel"
import { exportAsPdf } from "./../../../functions/exportToFile/exportPdf"
import FilterRisks from "../../../components/filter-risks/FilterRisks";
import calculateRiskGroupColor from '../../../functions/calculateRiskGroupColorRGB'


const ListRisksInIcp = (props) => {
    let initList = props.icpDepartments

    const history = useHistory();
    const { activeUser, sideBar } = useContext(UserContext);
    const [sortAfter, setSortAfter] = useState("name")
    const [sortOrder, setSortOrder] = useState('')
    const [searchText, setSearchText] = useState("")
    const [filterDepartment, setFilterDepartment] = useState("")
    const [listChildren, setListChildren] = useState(false)
    const [objChildren, setObjChildren] = useState([])
    const [unfoldList, setUnfoldList] = useState(false)
    const [filterOptions, setFilterOptions] = useState({ category: "Välj process", process: "Välj kategori", minRiskValue: 1, maxRiskValue: 25 })
    const [minRiskValue, setMinRiskValue] = useState(1)
    const [maxRiskValue, setMaxRiskValue] = useState(20)
    const [calendarOnly, setCalendarOnly] = useState(false)
    const [ikpOnly, setIkpOnly] = useState(false)
    const [reportStatus, setReportStatus] = useState(-1)

    function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (key === "riskValue") {

                let compared = 0;
                if (a.probability * a.consequence < b.probability * b.consequence) {
                    compared = 1;
                } else if (a.probability * a.consequence > b.probability * b.consequence) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }

            if (key === "reviews") {

                let compared = 0;
                if (a.reviews.length < b.reviews.length) {
                    compared = 1;
                } else if (a.reviews.length > b.reviews.length) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }

            if (key === "departmentName") {

                let compared = 0;
                if (a.department?.name.toUpperCase() < b.department?.name.toUpperCase()) {
                    compared = 1;
                } else if (a.department?.name.toUpperCase() > b.department?.name.toUpperCase()) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }

            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    const sortDropDown = () => {
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt = initList.find((dep) => {
            if (activeUser.admin) {

                return dep.level === 1
            } else {
                return dep._id === activeUser.department
            }

        })
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }

    const readIcpRisks = async () => {
        let res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/get-filtered-icp-risks",
            {
                icpId: props.icpId,
            },
            {
                headers: {
                    ContentType: "application/json",
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        let moppe = res.data.map((ir) => {
            return {
                ...ir.originalRisk, reviews: ir.reviews, icpRiskId: ir._id, calendar: ir.calendar,
                latestDeviationReport: ir.latestDeviationReport, result: ir.result,
                suggestion: ir.suggestion, status: ir.status
            }
        })

        return moppe;
    };

    const readSettings = async () => {
        let res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/read-settings", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        })
        return res.data.coso
    }

    const { data: settingsData, status: settingsStatus } = useQuery(["getSettings"], readSettings, {
        cacheTime: 0,
    });

    const getObjectAcceessList = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });
        return res.data;
    };

    const { data: accessList, status: accessListStatus } = useQuery(["getAccessList"], getObjectAcceessList, {});
    const { data: riskList, status, error, isError } = useQuery(["getIcpRisks"], readIcpRisks);

    const mailAllUsers = () => {
        axios.post(process.env.REACT_APP_IC_SERVICE + "/user/mail-all-populated",
            { calendar: `Internkontrollplan ${props.icpName}` },
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        )
    }

    const listToRender = () => {
        let tempList = riskList?.filter(s => {
            if (filterOptions.process === "Välj kategori") { return true }
            return s.process === filterOptions.process
        }).filter(risk => {
            if (calendarOnly) { return risk.calendar }
            return true
        }).filter(risk => {
            if (ikpOnly) { return risk.icp }
            return true
        }).filter(s => {
            if (filterOptions.category === "Välj process") { return true }
            return s.category === filterOptions.category
        }).filter(risk => {
            if (reportStatus < 0) { return true }
            return parseInt(risk.status) === parseInt(reportStatus)
        }).filter(risk => {
            if (risk.probability * risk.consequence >= minRiskValue && risk.probability * risk.consequence <= maxRiskValue) { return true }
            return false
        }).filter(s => {
            if (filterDepartment === "") {
                return s.name?.toUpperCase().includes(searchText)
            } else if (filterDepartment !== "" && listChildren) {
                return s.name?.toUpperCase().includes(searchText) && (objChildren.includes(s.department?._id) || filterDepartment === s.department._id)
            } else if (filterDepartment !== "" && !listChildren) {
                return s.name?.toUpperCase().includes(searchText) && filterDepartment === s.department?._id
            } else return null
        }
        ).sort(compareValues(`${sortAfter}`, sortOrder))
        return tempList
    }

    return (
        <>
            <ExportRisksModal
                passedFunction={(fileType, extendedList) => {
                    let title = props.icpName
                    let settings = { riskportfolio: false, active: true, extended: extendedList, title }
                    switch (fileType) {
                        case "pdf":
                            exportAsPdf(listToRender(), settings, settingsData, activeUser.customer)
                            break;
                        case "excel":
                            exportAsExcel(listToRender(), settings)
                            break;
                        default:
                            break;
                    }
                }}
                warningTitle={"Skapa rapport av risker!"}
                warningText={"Välj filformat och om du vill ha utvecklad listvy eller inte."}
                optionTextOne={"Ladda ner"}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={props.showExportModal}
                onClose={() => {
                    props.setShowExportModal(false);
                }}
            />
            <div className={styles.listRisksContainer} >
                {riskList?.length > 0 ? (<>
                    <div style={{ width: "800px", marginBottom: "30px" }}>
                        <div className="bodyText">
                            Nedan visas alla risker som tillhör internkontrollsplanen.
                            Klicka på rubrikerna i listan för
                            sortering. För att gå in och arbeta med en risk så klickar du dig
                            fram genom länken i risknamnet.
                        </div>
                    </div>

                    <div className={styles.searchInputRow}>
                        <label>Sök på risker: </label>
                        <input name="name" placeholder="Sök på risknamn" autoComplete="off"
                            onChange={(e) => { setSearchText(e.target.value.toUpperCase()) }}
                        />
                    </div>
                </>) : <div className="bodyText"></div>}
                {(activeUser.admin || activeUser.objectManager) && riskList?.length > 0 ?
                    <div className={styles.selectBox}>
                        <label>Avdelning: </label>
                        {accessListStatus === "success" ? (
                            <select name="department" style={{ marginLeft: "40px" }}
                                onChange={(e) => {
                                    let foundParents = accessList
                                        .filter((obj) => { return obj.parents.includes(e.target.value) })
                                        .map((filt) => { return filt._id })
                                    setObjChildren(foundParents)
                                    setFilterDepartment(e.target.value)
                                }}>
                                <option value="">Alla avdelningar</option>
                                {sortDropDown().map((dep, index) => { return <option key={index} value={dep?._id} >{" - ".repeat(parseInt(dep?.level - 1))}{dep?.name}</option> })}
                            </select>
                        ) : null}
                        <div className={styles.riskOption}
                        >
                            <label>Visa underavdelningar: </label>
                            {listChildren
                                ? <i style={{ cursor: "pointer" }} className="far fa-check-square" onClick={() => setListChildren(false)}></i>
                                : <i style={{ cursor: "pointer" }} className="far fa-square" onClick={() => setListChildren(true)}></i>
                            }
                        </div>
                    </div>
                    : null}

                <FilterRisks
                    changeCategory={(a) => { setFilterOptions((state) => { return { ...state, process: a } }) }}
                    changeProcess={(a) => { setFilterOptions((state) => { return { ...state, category: a } }) }}
                    changeRiskValues={(a, b) => {
                        setMinRiskValue(a)
                        setMaxRiskValue(b)
                    }}
                    changeCalendarOnly={() => {
                        if (!calendarOnly) {
                            mailAllUsers()
                        }
                        setCalendarOnly((state) => { return !state })
                    }}
                    changeSetIkpOnly={() => { setIkpOnly((state) => { return !state }) }}
                    calendarOnly={calendarOnly}
                    // setIkpOnly={setIkpOnly}
                    thisIsRiskPortfolio={false}
                    thisIsActiveIcp={true}
                    // changeRiskValues={(a, b) => { setFilterOptions((state) => { return { ...state, category: a } }) }}
                    incList={listToRender()}
                    changeStatus={(value) => { setReportStatus((state) => { return value }) }}
                />

                {riskList?.length > 0 ?
                    <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                        <PageHeaderButton onClick={() => {
                            setUnfoldList((state) => { return !state })
                        }}>{!unfoldList ? "Visa" : "Dölj"} utfälld lista</PageHeaderButton>
                    </div>
                    : null
                }

                {
                    riskList?.length > 0 ? (<div className={styles.riskRow} >
                        <div className={styles.riskRowTitleItemName}
                            onClick={() => {

                                if (sortAfter === "name") {
                                    if (sortOrder === '') {
                                        setSortOrder('desc')
                                    } else { setSortOrder('') }
                                } else {
                                    setSortAfter("name")
                                    setSortOrder('')
                                }
                            }}>Totalt {listToRender()?.length} risker</div>
                        <div className={styles.riskRowTitleItem} onClick={() => {
                            if (sortAfter === "category") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("category")
                                setSortOrder('')
                            }
                        }}>Huvudprocess</div>
                        {!sideBar ?
                            <div className={styles.riskRowTitleItem} onClick={() => {
                                if (sortAfter === "subCategory") {
                                    if (sortOrder === '') {
                                        setSortOrder('desc')
                                    } else { setSortOrder('') }
                                } else {
                                    setSortAfter("subCategory")
                                    setSortOrder('')
                                }
                            }}>Delprocess</div>
                            : null}
                        <div className={styles.riskRowTitleItem} onClick={() => {
                            if (sortAfter === "process") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("process")
                                setSortOrder('')
                            }
                        }}>Kategori</div>
                        <div className={styles.riskRowTitleItem} onClick={() => {
                            if (sortAfter === "departmentName") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("departmentName")
                                setSortOrder('')
                            }
                        }}>Avdelning</div>
                        <div className={styles.riskRowTitleItemDate} onClick={() => {
                            if (sortAfter === "latestDeviationReport") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("latestDeviationReport")
                                setSortOrder('')
                            }
                        }}>
                            <Tooltip content={"Senaste avvikelserapport"} direction={"left"}>AVR</Tooltip>
                        </div>
                        <div className={styles.riskRowTitleItemSmall} onClick={() => {
                            if (sortAfter === "calendar") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("calendar")
                                setSortOrder('')
                            }
                        }}>Status</div>
                        <div className={styles.riskRowTitleItemSmall} onClick={() => {
                            if (sortAfter === "riskValue") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("riskValue")
                                setSortOrder('')
                            }
                        }}><Tooltip content={"Riskvärde"} direction={"left"}>RV</Tooltip></div>
                        <div className={styles.riskRowTitleItemSmall} onClick={() => {
                            if (sortAfter === "reviews") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("reviews")
                                setSortOrder('')
                            }
                        }}>Kontroller</div>
                    </div>) : null
                }
                {/* {status === "loading" && <div>Laddar data...</div>} */}
                {isError && <div>Fel vid inladdning av data....</div>}
                {error && <div>Fel vid inladdning av data...</div>}
                {
                    status === "success" && settingsStatus === "success" && riskList.length > 0 ? (
                        listToRender().map((risk) => {
                            return (
                                <div key={risk._id}>
                                    <div className={styles.riskRow} style={unfoldList ? { fontWeight: "500", fontSize: "11px" } : null}>
                                        <span className={styles.statusColourBoxRP}
                                            // <span className={`banner ${unfoldList ? styles.statusColourBoxFolded : styles.statusColourBox}`}
                                            // style={
                                            //     // (risk.status === 1) ? { background: "rgb(84, 180, 122)" } :
                                            //     (risk.status === 1) ? { background: "rgb(32, 150, 13)" } :
                                            //         // (risk.status === 2) ? { background: "rgb(252, 255, 102)" } :
                                            //         (risk.status === 2) ? { background: "rgb(255, 216, 0)" } :
                                            //             (risk.status === 3) ? { background: "rgb(231, 37, 37)" } : { background: "white" }
                                            // }
                                            style={{ backgroundColor: calculateRiskGroupColor(risk.probability, risk.consequence, settingsData) }}
                                        ></span>
                                        <span className={styles.statusColourBox}
                                            // <span className={`banner ${unfoldList ? styles.statusColourBoxFolded : styles.statusColourBox}`}
                                            style={
                                                (risk.status === 1) ? { background: "rgb(84, 180, 122)" } :
                                                    // (risk.status === 1) ? { background: "rgb(32, 150, 13)" } :
                                                    (risk.status === 2) ? { background: "rgb(252, 255, 102)" } :
                                                        // (risk.status === 2) ? { background: "rgb(255, 216, 0)" } :
                                                        (risk.status === 3) ? { background: "rgb(231, 37, 37)" } : { background: "white" }
                                            }
                                        ></span>
                                        <div className={styles.riskRowItemLink} onClick={() => {
                                            history.push("/show-icp-risk", {
                                                riskId: risk._id,
                                                icpRiskId: risk.icpRiskId,
                                                reviews: risk.reviews,
                                                icpName: props.icpName
                                            })
                                        }}>
                                            {/* <span style={{ position: "relative", left: "-20px" }}>
                                                {risk?.status === 1}
                                                <span style={{ height: "5px", width: "10px", backgroundColor: "red", minWidth: "15px" }}>O</span>
                                            </span> */}
                                            {/* <Tooltip content={risk.name} direction={"right"}>{risk.name.length > 27 ? risk.name.slice(0, 27) + "..." : risk.name}</Tooltip> */}
                                            {risk.name.length > 30 ?
                                                <Tooltip content={risk.name} direction={"right"}>{risk.name.slice(0, 30)}...</Tooltip>
                                                : risk.name
                                            }
                                        </div>
                                        <div className={styles.riskRowItem}>{risk.category}</div>
                                        {!sideBar ? <div className={styles.riskRowItem}>{risk.subCategory}</div> : null}
                                        <div className={styles.riskRowItem}>{risk.process}</div>
                                        <div className={styles.riskRowItem}>{risk.department?.name.length > 26 ? risk.department?.name.slice(0, 26) + "..." : risk.department?.name}</div>
                                        <div className={styles.riskRowItemDate}>{risk?.latestDeviationReport?.slice(0, 10)}</div>
                                        <div className={styles.riskRowItemSmall}>
                                            {risk.calendar ? <div className={styles.riskRowIconItem}><i style={{ color: "red", paddingLeft: "10px" }} className={"far fa-calendar-alt"} ></i></div> : null}
                                        </div>
                                        <div className={styles.riskRowItemSmall}>{risk.probability * risk.consequence}</div>
                                        <div className={styles.riskRowItemSmall}>
                                            {risk.reviews.length}
                                        </div>
                                    </div>
                                    {
                                        unfoldList ? <div className={styles.riskRow} >
                                            <div style={sideBar ? { width: "271px" } : { width: "227px" }} className={styles.riskFoldDescription}>
                                                <div className={styles.foldedTitle}>Beskrivning: {risk.description ? null : <div style={{ fontWeight: "400" }}>-</div>}</div>
                                                <div className={styles.foldedText}>{risk.description}</div>
                                            </div>
                                            <div style={sideBar ? { width: "240px" } : { width: "200px" }} className={styles.riskFoldDescription}>
                                                <div className={styles.foldedTitle}>Utfall: {risk.result ? null : <div style={{ fontWeight: "400" }}>-</div>}</div>
                                                <div className={styles.foldedText}>{risk.result}</div>
                                            </div>
                                            <div style={sideBar ? { width: "237px" } : { width: "198px" }} className={styles.riskFoldDescription}>
                                                <div className={styles.foldedTitle}>Åtgärd: {risk.suggestion ? null : <div style={{ fontWeight: "400" }}>-</div>}</div>
                                                <div className={styles.foldedText}>{risk.suggestion}</div>
                                            </div>
                                            <div className={styles.riskFoldReviews}>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "175px" }} className={styles.foldedTitle}>Kontroller</div>
                                                    <div style={{ width: "105px" }} className={styles.foldedTitle}>Avvikelserapport</div>
                                                    <div className={styles.foldedTitle}>Förbättringsförslag</div>
                                                </div>
                                                {risk.reviews.length > 0 ? risk.reviews.map((review, index) => {
                                                    return <div key={index} style={{ display: "flex" }}>
                                                        <li style={{ width: "175px" }} className={styles.foldedText}>
                                                            {review.name.length > 25 ? review.name.slice(0, 25) + "..." : review.name}
                                                        </li>
                                                        <div style={{ width: "105px" }} className={styles.foldedText}>{review.deviationReports.length}</div>
                                                        <div className={styles.foldedText}>{review.verifyChecks.filter((check) => { return check.asSuggestion }).length}</div>
                                                    </div>
                                                }) : "-"}
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            )
                        })
                    ) : null
                }
            </div >
        </>
    );
};

export default ListRisksInIcp;

//369