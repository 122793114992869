import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import styles from "./styles/CreateRiskPage.module.css"
import ViewCosoDiagram from "../../components/view-coso-diagram/ViewCosoDiagram";
import axios from "axios"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query";
import { RiskDefinitionLabel, RiskDefinitionCircle } from '../../styles/risk-definitions'
import { PageHeaderButton, SaveButton, HelpButton } from "../../styles/buttons"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import calculateRiskGroup from '../../functions/calculateRiskGroup'
import LoadingModal from "./../../modal/LoadingModal";

const CreateRiskPage = () => {
  const { sideBar, activeUser } = useContext(UserContext)
  const history = useHistory()
  const [probabilitySelection, setProbabilitySelection] = useState(1)
  const [consequenceSelection, setConsequenceSelection] = useState(1)
  const [cosoSize, setCosoSize] = useState(4)
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  const getCurrentSettings = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-current-portfolio-settings", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    setCosoSize(res.data.coso.size)
    if (isMounted) { setFadeInTrigger(true) }
    return res.data;
  };

  const getObjectAccessList = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    return res.data;
  };

  const { data, status } = useQuery(["readSettingsForCreateRisk"], getCurrentSettings, {});
  const { data: accessList, status: accessListStatus } = useQuery(["getAccessList"], getObjectAccessList, {});

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    defaultValues: {
      category: "",
      subCategory: "",
      process: "",
      department: "",
    },
  })

  const watchCategory = watch("category")
  const watchOrganisation = watch("organisation")

  const submitRisk = async (passedData) => {
    let risk = {
      ...passedData,
      consequence: consequenceSelection,
      probability: probabilitySelection
    }
    const result = await axios.post(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/create-risk", { risk }, {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    if (result.status === 200) history.goBack()
    return result
  }

  const sortDropDownJustDepartments = () => {
    let initList = accessList
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep._id === activeUser.department)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  const sortDropDown = () => {
    if (watchOrganisation.toString() === "") return []
    let initList = accessList.filter((org) => { return org.ancestor === watchOrganisation.toString() || org._id === watchOrganisation.toString() })
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep.level === 1)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "organisation") {
        setValue("department", "")
      } else if (name === "category") {
        setValue("subCategory", "Ej definierad delprocess")
      }
    })

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <>
        <PageTitle>Skapa ny risk</PageTitle>
        <PageDelimiter />
        <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Avbryt</PageHeaderButton>
        <SaveButton icon="save" onClick={handleSubmit(submitRisk)} >Spara</SaveButton>
        <HelpButton></HelpButton>
      </>
      <div className={`${data?.coso.size === 5 ? styles.largeCoso : styles.smallCoso}`} >
        <ViewCosoDiagram scale={data?.coso.size}
          maxConsequenceMakesRed={data?.coso.maxConsequenceRed}
          maxProbabilityMakesRed={data?.coso.maxProbabilityRed}
          consequence={consequenceSelection}
          probability={probabilitySelection}
        />
      </div>
      <div className={styles.formError}>
        {Object.keys(errors).length > 0 ? <p>{errors[Object.keys(errors)[0].toString()].message}</p> : <p></p>}
      </div>
      {status === "success" ? (
        <form className={styles.formContainer} autoComplete="off" onSubmit={handleSubmit(submitRisk)}>
          <input name="organisation" type="hidden" {...register('organisation')} />
          <div className={styles.formData}>
            <div className={styles.name}>
              <div className={styles.textInputRow}>
                <label>Benämning: </label>
                <input name="name" placeholder="Benämn risken här"
                  onChange={e => e.target.value = e.target.value.trimStart().replace(/\s+/g, " ")}
                  onBlur={(e) => {
                    e.target.value = e.target.value.trimEnd()
                  }}
                  {...register('name', {
                    required: "Fyll i fältet benämning",
                    pattern: {
                      value: /^[a-zåäöA-ZÅÄÖ0-9\s]*$/,
                      message: "Bara bokstäver och siffror"
                    },
                    minLength: {
                      value: 3,
                      message: "Minst tre bokstäver i namn"
                    },
                    maxLength: {
                      value: 50,
                      message: "Max femtio bokstäver i namn"
                    }
                  })} />
              </div>
            </div>
            <div className={styles.description}>
              <div className={styles.textBox}>
                <label>Beskrivning: </label>
                <textarea className={styles.textBoxArea} name="description" type="textarea" placeholder="Beskriv risken här" {...register('description')} />
              </div>
            </div>

            <div className={styles.category}>
              {(activeUser.admin) && accessListStatus === "success" && accessList[0] !== null ? (
                <>
                  <div className="selectBox">
                    <label>Organisation: </label>
                    <select name="organisation" onChange={() => {
                      let element = document.getElementById("leaveCodeCreateUser");
                      if (element) {
                        element.value = ""
                      }
                    }} {...register('organisation', { required: "Välj organisation" })}>
                      <option value="">Välj organisation</option>
                      {accessList.filter((org) => { return org.level === 1 }).map((dep, index) => {
                        return <option key={index} value={dep._id} >{dep.name}</option>
                      })}
                    </select>
                  </div>

                  {watchOrganisation && (
                    <>
                      <div className="selectBox">
                        <label>Avdelning: </label>
                        <select name="department" {...register('department', { required: "Välj avdelning" })} id="leaveCodeCreateUser" >
                          <option value="">Välj avdelning</option>
                          {sortDropDown().map((dep, index) => {
                            return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                          })}
                        </select>
                      </div>
                    </>
                  )}
                </>
              ) : null}

              {(!activeUser.admin && activeUser.objectManager) && accessListStatus === "success" && accessList[0] !== null ?
                <div className="selectBox">
                  <label>Avdelning: </label>
                  <select name="department" {...register('department', { required: "Välj avdelning" })}>
                    <option value="">Välj avdelning</option>
                    {sortDropDownJustDepartments().map((dep, index) => {
                      return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}
                      </option>
                    })}
                  </select>
                </div> : null}
              <div className="selectBox">
                <label>Huvudprocess: </label>
                <select name="category" {...register('category')}>
                  <option value="">Välj process</option>
                  {data.categories?.map((cat, index) => {
                    return <option key={index}>{cat.main}</option>
                  })}
                </select>
              </div>
              <div className="selectBox">
                {watchCategory && (
                  <>
                    <label>Delprocess: </label>
                    <select name="subCategory" {...register('subCategory')}>
                      <option value="Ej definierad delprocess" >Välj delprocess</option>
                      {data.categories
                        .find((cat) => cat.main === watchCategory)
                        .children.map((cato, index) => {
                          return <option key={index}>{cato}</option>
                        })}
                    </select>
                  </>
                )}
              </div>
            </div>
            <div className={styles.process}>
              <div className="selectBox">
                <label>Kategori: </label>
                <select name="process" {...register('process')}>
                  <option value="">Välj kategori</option>
                  {data.processes.map(p => {
                    return <option key={p}>{p}</option>
                  })}
                </select>
              </div>
            </div>
            <div className={styles.probability}>
              <span className={styles.definition}>
                <RiskDefinitionLabel size={cosoSize}>Sannolikhet: </RiskDefinitionLabel>
                {data.riskDefinitionArray?.map((definition, index) => {
                  return (
                    <RiskDefinitionCircle
                      key={index}
                      definition={cosoSize * (index + 1)}
                      selected={probabilitySelection === definition.value ? true : false}
                      onClick={() => setProbabilitySelection(definition.value)}
                    >
                      {definition.value}
                      {/* <span className="tooltip">{definition.probability}</span> */}
                    </RiskDefinitionCircle>)
                })}
              </span>
              <div className={styles.textBox}>
                <label>Motivering: </label>
                <textarea className={styles.textBoxArea} name="probabilityMotivation" type="textarea" placeholder="Motivera valet här"
                  {...register('probabilityMotivation')} />
              </div>
            </div>
            <div className={styles.matrix}>
              <div className={styles.definitionContainer}>
                {probabilitySelection && consequenceSelection ? (<>
                  <div className={styles.definitionRow}>
                    <RiskDefinitionLabel size={cosoSize}>Riskpoäng: {probabilitySelection * consequenceSelection}
                    </RiskDefinitionLabel>
                  </div>
                  <div className={styles.definitionRow}>
                    <RiskDefinitionLabel size={cosoSize}>Riskvärdesgrupp: {calculateRiskGroup(probabilitySelection, consequenceSelection,
                      data.riskGroupDefinitions, data.coso).group}
                    </RiskDefinitionLabel>
                  </div>
                  <div className={styles.definitionRow}>
                    <div className={styles.definitionDescriptionText}>
                      {calculateRiskGroup(probabilitySelection, consequenceSelection,
                        data.riskGroupDefinitions, data.coso).rgd}
                    </div>
                  </div>
                </>) : null}
                {probabilitySelection ? (<>
                  <div className={styles.definitionRow}>
                    <RiskDefinitionLabel size={cosoSize}>Beskrivning av sannolikhet: </RiskDefinitionLabel>
                  </div>
                  <div className={styles.definitionRow}>
                    <div className={styles.definitionDescriptionText}>
                      {data.riskDefinitionArray.find(row => row.value === probabilitySelection).probability}
                    </div>
                  </div>
                </>) : null}
                {consequenceSelection ? (<>
                  <div className={styles.definitionRow}>
                    <RiskDefinitionLabel size={cosoSize}>Beskrivning av konsekvens: </RiskDefinitionLabel>
                  </div>
                  <div className={styles.definitionRow}>
                    <div className={styles.definitionDescriptionText}>
                      {data.riskDefinitionArray.find(row => row.value === consequenceSelection).consequence}
                    </div>
                  </div>
                </>) : null}
              </div>
            </div>

            <div className={styles.consequence}>
              <div className={styles.definition}>
                <RiskDefinitionLabel size={cosoSize}>Konsekvens: </RiskDefinitionLabel>
                {data.riskDefinitionArray.map((definition, index) => {
                  return (
                    <RiskDefinitionCircle
                      key={index}
                      definition={cosoSize * (index + 1)}
                      selected={consequenceSelection === definition.value ? true : false}
                      onClick={() => setConsequenceSelection(definition.value)}
                    >
                      {definition.value}
                      {/* <span className="tooltip">{definition.consequence}</span> */}
                    </RiskDefinitionCircle>)
                })}
              </div>
              <div className={styles.textBox}>
                <label>Motivering: </label>
                <textarea className={styles.textBoxArea} name="consequenceMotivation" type="textarea" placeholder="Motivera valet här" {...register('consequenceMotivation')} />
              </div>
            </div>
          </div>
        </form>
      ) : null
      }
    </div >
  )
}

export default CreateRiskPage