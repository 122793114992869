import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import styles from "./styles/AdminPage.module.css";
import ViewAdminCosoDiagram from "./components/view-admin-coso-diagram/ViewAdminCosoDiagram";
import RiskDefinitions from "./components/risk-definitions/RiskDefinitions"
import RiskOptions from "./components/risk-options/RiskOptions"
import { useQuery } from "react-query";
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton } from '../../styles/buttons'
import axios from "axios";
import { useHistory } from "react-router-dom"
import WarningModal from "./../../modal/WarningModal";
import LoadingModal from "./../../modal/LoadingModal";

const MatrixSettingsPage = () => {
  const { sideBar, activeUser } = useContext(UserContext);
  const history = useHistory();
  const [selectedScale, setSelectedScale] = useState(null);
  const [maxConsequenceMakesRed, setMaxConsequenceMakesRed] = useState(null);
  const [maxProbabilityMakesRed, setMaxProbabilityMakesRed] = useState(null);
  const [riskDefinitionMatrices, setRiskDefinitionMatrices] = useState(null);
  const [riskGroupDefinitions, setRiskGroupDefinitions] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [changeToThisMatrixSize, setChangeToThisMatrixSize] = useState(null);
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  const changeCosoSize = async (clickedSize) => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/setting/change-coso-size",
      {
        id: data._id,
        newSize: clickedSize,
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    setSelectedScale(clickedSize);
    refetch();
    return res.data;
  };
  const toggleMaxConsequenceRed = async () => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/setting/toggle-max-consequence-red",
      {
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    refetch();
    return res.data;
  };
  const toggleMaxProbabilityRed = async () => {
    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/setting/toggle-max-probability-red",
      {
      },
      {
        headers: {
          authorization: "Bearer " + activeUser.token,
        },
      }
    );
    refetch();
    return res.data;
  };

  const readSettings = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/read-settings", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });

    if (isLoading) {
      setMaxConsequenceMakesRed(res.data.coso.maxConsequenceRed)
      setMaxProbabilityMakesRed(res.data.coso.maxProbabilityRed)
      setSelectedScale(res.data.coso.size)
    };
    setRiskDefinitionMatrices({
      smallMatrixRiskDefinitionArray: res.data.smallMatrixRiskDefinitionArray,
      mediumMatrixRiskDefinitionArray: res.data.mediumMatrixRiskDefinitionArray,
      largeMatrixRiskDefinitionArray: res.data.largeMatrixRiskDefinitionArray
    })
    setRiskGroupDefinitions({
      smallRiskGroupDefinition: res.data.smallRiskGroupDefinition,
      mediumRiskGroupDefinition: res.data.mediumRiskGroupDefinition,
      largeRiskGroupDefinition: res.data.largeRiskGroupDefinition
    })
    if (isMounted) { setFadeInTrigger(true) }
    return res.data;
  };

  const { data, status, isLoading, refetch } = useQuery(["getSettings"], readSettings, {
    cacheTime: 0,
  });

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <>
        <PageTitle>Admin - Inställningar för riskmatris</PageTitle>
        <PageDelimiter />
        <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Tillbaka</PageHeaderButton>
      </>
      {status === "success" ? (
        <>
          <div className="pageSubTitle" style={{ marginTop: "40px" }}>Storlek på riskmatris</div>
          <div className={styles.cosoDiagramContainer}>
            {status === "success" ? (
              <>
                <RiskOptions
                  maxConsequenceMakesRed={maxConsequenceMakesRed}
                  maxProbabilityMakesRed={maxProbabilityMakesRed}
                  setMaxConsequenceMakesRed={setMaxConsequenceMakesRed}
                  toggleMaxConsequenceRed={toggleMaxConsequenceRed}
                  setMaxProbabilityMakesRed={setMaxProbabilityMakesRed}
                  toggleMaxProbabilityRed={toggleMaxProbabilityRed}
                />
                <ViewAdminCosoDiagram scale={selectedScale}
                  maxConsequenceMakesRed={maxConsequenceMakesRed}
                  maxProbabilityMakesRed={maxProbabilityMakesRed}
                />
              </>
            ) : null}
          </div>
          <WarningModal
            passedFunction={() => changeCosoSize(changeToThisMatrixSize)}
            warningTitle={"Vill du verkligen ändra storlek på riskmatrisen?"}
            warningText={"Detta kommer påverka alla risker i riskportföljen och de internkontrollplaner som inte är arkiverade. "}
            optionTextOne={"Ja"}
            optionTextTwo={"Nej"}
            colour={"red"}
            open={showWarningModal}
            onClose={() => {
              setShowWarningModal(false);
            }}
          />
          <div style={{ width: "500px", marginBottom: "30px" }}>
            <div className="bodyText">
              Välj storlek på organisationens riskmatris. Denna inställning förblir permanent i de efterskapande
              riskerna och portföljerna.
        </div>
          </div>
          <div className={styles.cosoMenuContainer}>
            <div
              onClick={() => {
                if (selectedScale !== 3) {
                  setChangeToThisMatrixSize(3)
                  setShowWarningModal(true)
                }
              }}
              className={`${styles.scaleButton} ${selectedScale === 3 ? styles.selectedScaleButton : null}`}
            >
              3 X 3
            </div>
            <div
              onClick={() => {
                if (selectedScale !== 4) {
                  setChangeToThisMatrixSize(4)
                  setShowWarningModal(true)
                }
              }}
              className={`${styles.scaleButton} ${selectedScale === 4 ? styles.selectedScaleButton : null}`}
            >
              4 X 4
            </div>
            <div
              onClick={() => {
                if (selectedScale !== 5) {
                  setChangeToThisMatrixSize(5)
                  setShowWarningModal(true)
                }
              }}
              className={`${styles.scaleButton} ${selectedScale === 5 ? styles.selectedScaleButton : null}`}
            >
              5 X 5
            </div>
          </div>
        </>
      ) : <div className="pageSubTitle" style={{ marginTop: "40px" }}>Laddar ...</div>}
      {status === "success" ? (
        <div style={{ marginBottom: "60px" }}>
          <RiskDefinitions cosoSize={selectedScale}
            riskDefinitionMatrices={riskDefinitionMatrices}
            RiskGroupDefinitions={riskGroupDefinitions}
            refetch={refetch}
          ></RiskDefinitions>
        </div>
      ) : null}
    </div>
  );
};

export default MatrixSettingsPage;
