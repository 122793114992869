import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton } from '../../styles/buttons'
import RiskCategoryHandler from "./components/risk-categories/RiskCategoryHandler";
import ProcessHandler from "./components/processes/ProcessHandler";
import { useQuery } from "react-query";
import axios from 'axios'
import LoadingModal from "./../../modal/LoadingModal";

const CategorySettingsPage = () => {
    const history = useHistory()
    const { sideBar, activeUser } = useContext(UserContext)
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [isMounted, setIsMounted] = useState(true)

    const readSettings = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/read-settings", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });
        if (isMounted) { setFadeInTrigger(true) }
        return res.data;
    };

    const { data, status, refetch } = useQuery(["getSettings"], readSettings, { cacheTime: 0, });

    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [])

    return (
        <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <>
                <PageTitle>Admin - Inställningar för kategorier och processer</PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Tillbaka</PageHeaderButton>
            </>
            <div style={{ maxWidth: "800px", marginTop: "40px", marginBottom: "50px" }} className="bodyText">

                <p>Här lägger ni upp de kategorier ni vill använda i programmet. Dessa blir då
                tillgängliga för vid skapandet av risker, för att kategorisera dessa. Om ni
                tar bort en kategori som används i någon risk, finns denna kvar i den risken
                    men blir inte valbar för nya risker</p>

                <p>Här lägger ni också upp benämningar på de processer ni har i er organisation.
                Ni kan också lägga upp delprocesser för att ytterligare kategorisera riskarbetet.
                Om ni tar bort en process eller delprocess som används i någon risk, finns denna
                    kvar i den risken men blir inte valbar för nya risker.</p>
            </div>
            { status === "success" ?
                <>
                    <RiskCategoryHandler riskCategories={data.riskCategories} refetch={refetch} />
                    <ProcessHandler processes={data.processes} refetch={refetch} />
                    <div style={{ height: "40px" }}></div>
                </>
                : null}
        </div >
    )
}

export default CategorySettingsPage