import React, { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import styles from "./LogoutBar.module.css";
import { useHistory } from "react-router-dom";
// import { useQueryCache } from "react-query";
import { useQueryClient } from 'react-query'


const LogoutBar = () => {
  // const queryCache = useQueryCache()
  const queryClient = useQueryClient()
  // console.log('queryClient', queryClient);


  const { setIsLoggedIn, setActivePortfolioId, clearActiveUser } = useContext(UserContext);
  const history = useHistory();
  const logout = () => {
    setIsLoggedIn(false);
    setActivePortfolioId(null);
    clearActiveUser()
    // queryCache.clear()
    queryClient.clear()
    localStorage.clear();
    history.push("/", { msg: "logged out" });
  };
  return (
    <div className={styles.logoutBarContainer}>
      {/* <div className="">
        <i className={`fas fa-envelope ${styles.logoutBarIcons}`}></i>
        <label className={styles.logoutBarLabels}>Notiser</label>
      </div> */}
      <div onClick={logout}>
        <i className={`fas fa-sign-out-alt ${styles.logoutBarIcons}`}></i>
        <label className={styles.logoutBarLabels}>Logga ut</label>
      </div>
    </div>
  );
};

export default LogoutBar;
