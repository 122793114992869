import React, { useRef, useEffect, useState } from "react";
import ReactDom from "react-dom";
import styled from "styled-components"

export const OptionButton = styled.button`

font-size: 13px;
font-family: "Montserrat", sans-serif;
  width: 150px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  background-color: ${({ colour }) => (colour === "red") ? "rgb(171, 47, 47)"
    : (colour === "blue") ? "rgb(47, 100, 171)" : "rgb(55, 55, 55)"};
  height: 25px;
  padding-left: 21px;
  padding-right: 21px;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;

&:hover {
  background-color: ${({ colour }) => (colour === "red") ? "rgb(161, 37, 37)" : (colour === "blue") ? "rgb(78,123,184)" : "rgb(40, 40, 40)"};
}
&:focus {
    outline: 0;
}
`

export const TextInput = styled.div`

  font-size: 14px;
  /* width: 300px; */
  color: red;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  margin: 10px 0;

  textarea {
  width: 300px;
  resize: none;
  border-bottom: 1px solid #ccc;
  padding-bottom: 3px;
  color: #333;
  margin-left: 15px;
  font-size: 13px;
  height: 95px;
  font-family: Arial;
  padding: 5px;
  border-radius: 8px;
  }

  textarea:focus {
    outline-width: 0px;
    color: #333;
  }
  &:focus {
    outline: 0;
  }
`

const MODAL_STYLES = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  position: "fixed",
  top: "50%",
  left: "50%",
  borderRadius: "15px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  zIndex: "1000",
  padding: "20px"
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgb(0,0,0, 0.6)",
  zIndex: "999",
};

const LABEL_STYLES = {
  fontSize: "14px",
  fontWeight: "600",
};

export default function TextAreaInputModal({ onClose, todoToComment, open, labelText, warningText, optionTextOne, optionTextTwo, passedFunction, children, colour, setNewOrganisationName }) {
  const orgInput = useRef(null);
  const [newText, setNewText] = useState((""))
  useEffect(() => {
    orgInput?.current?.focus()
  })

  if (!open) return null;



  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>
        <div style={LABEL_STYLES}>{labelText}</div>

        {children}
        <div>
          <TextInput>
            <textarea
              ref={orgInput}
              defaultValue={todoToComment.comment}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  onClose()
                }
              }}
              name="description"
              type="textarea"
              onChange={(data) => setNewText(data.target.value)}
            />
          </TextInput>
          <OptionButton alert={true} colour={colour} onClick={(e) => {
            if (newText !== todoToComment.comment) {
              passedFunction(newText)
            }
            setNewText("")
            onClose()
          }}>{optionTextOne}</OptionButton>
          {optionTextTwo ?
            <OptionButton alert={false} onClick={() => {
              onClose()
            }}>{optionTextTwo}</OptionButton>
            : null}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
}
