import React, { useState } from "react";
// import axios from "axios";
import styles from "./DeviationSection.module.css";
import Modal from "./../../../../../modal/Modal";
import DeviationReportEditor from "./DeviationReportEditor";

export default function DeviationReport({ setUpdateState, queriedReview, data, refetch, status, pathFromHomeScreen, riskId }) {
  const [showModal, setShowModal] = useState(false);
  const [activeDeviationReport, setActiveDeviationReport] = useState(null);

  return (
    <>
      <Modal
        activeDeviationReport={activeDeviationReport}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setActiveDeviationReport(null);
        }}
      >
        <DeviationReportEditor
          setActiveDeviationReport={setActiveDeviationReport}
          pathFromHomeScreen={pathFromHomeScreen}
          queriedReview={queriedReview}
          riskId={queriedReview._id}
          refetch={refetch}
          setShowModal={setShowModal}
          controllerId={data && data._id}
          deviationReport={queriedReview && queriedReview.deviationReports.find((e) => e._id === activeDeviationReport)}
        />
      </Modal>
      <div
        style={{
          padding: "0px",
          margin: "0px",
          height: "28px",
          marginBottom: "5px",
          marginTop: "35px",
        }}
      >
        <h4>Avvikelserapporter</h4>
        <i
          onClick={() =>
            setShowModal(true)
          }
          style={{
            cursor: "pointer",
            color: "rgb(47, 100, 171)",
            position: "relative",
            top: "-41px",
            left: "153px",
            paddingLeft: "0px",
            marginRight: "0px",
            fontSize: "20px",
          }}
          className="fas fa-plus-circle"
        ></i>
      </div>
      {status === "success" ? (
        <div>
          <div id={styles.styleScroller} className={styles.verifyChecksContainer}>
            {queriedReview.deviationReports.map((check, index) => {
              return (
                <div
                  key={check._id}
                  className={styles.verifyListRow}
                  onClick={() => {
                    setActiveDeviationReport(check._id);
                    setShowModal(true);
                  }}
                >
                  <div style={{ width: "130px" }}>Avvikelserapport {index + 1}</div>
                  <div style={{ marginRight: "50px" }}>{check.date.slice(0, 10)}</div>
                  <div className={styles.barne}>
                    <i
                      style={check.flagged ? { color: "green" } : { color: "grey" }}
                      className={check.flagged ? "fas fa-flag" : "far fa-flag"}
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>laddar</div>
      )}
    </>
  );
}
