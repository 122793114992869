import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton } from '../../styles/buttons'
import styles from "./styles/AttestPage.module.css";
import axios from "axios"
import { useQuery } from "react-query";
import LoadingModal from "./../../modal/LoadingModal";
import Tooltip from "../../components/tooltip/Tooltip"
import ReportIcpModal from "./../../modal/ReportIcpModal";

const AttestPage = () => {
    const history = useHistory()
    const { sideBar, activeUser } = useContext(UserContext)
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [isMounted, setIsMounted] = useState(true)
    const [showReportIcpModal, setShowReportIcpModal] = useState(false);
    const isActiveIcp = history.location.state.active
    const [todoToComment, setTodoToComment] = useState(null)
    const [currentIcpState, setCurrentIcpState] = useState(null)

    const readIcpData = async () => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/read-icp-attest-data",
            { icpId: history.location.state.icpId },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            });
        if (isMounted) { setFadeInTrigger(true) }

        return res.data;
    };
    const { data: attestList, status, refetch } = useQuery(["getIcpAttestListData"], readIcpData);

    const sendSecondAttestRequest = async (data) => {

        await axios.put(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/second-icp-attest",
            data,
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        );
        setTodoToComment(null)
        setCurrentIcpState(null)
        refetch()
        return "ok"
    }

    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, []);

    return (
        <div style={{ paddingBottom: "10px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <ReportIcpModal
                passedFunction={(newText) => {
                    sendSecondAttestRequest({ ...currentIcpState, comment: newText.newText, done: newText.markAsDone })

                }}
                todoToComment={todoToComment}
                labelText={"Avrapportera internkontrollplanen"}
                warningText={"Här avrapporterar du slutresultatet för den här internkontrollplanen. När du som objektsägare " +
                    "klarmarkerar och sparar innebär det att internkontrollsarbetet på ditt objekt är klart."}
                optionTextOne={"Spara"}
                optionTextTwo={"Avbryt"}
                checkMarkOption={true}
                colour={"blue"}
                open={showReportIcpModal}
                onClose={() => {
                    setCurrentIcpState(null)
                    setShowReportIcpModal(false);
                }}
            />
            <>
                <PageTitle>{isActiveIcp ? "Avrapportering" : "Attestering"} för {history.location.state.icpName} </PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Gå tillbaka</PageHeaderButton>
                {isActiveIcp ? <PageHeaderButton icon="sitemap" onClick={() => history.push("/icp-summary-attests", {
                    icpId: history.location.state.icpId,
                    icpName: history.location.state.icpName,
                    active: history.location.state.active,
                    icpOrg: history.location.state.organisation
                })}>Visa sammanställning</PageHeaderButton> : null}
            </>

            <div style={{ width: "750px", marginBottom: "50px", marginTop: "40px" }}>
                <div className="bodyText">
                    Här visas en översikt av attesteringen för den här internkontrollplanen. Kommentering och
                    avrapportering görs från hemskärmen. Klicka på visa sammanställning för att se samtliga kommenterar.
                </div>
            </div >

            <div className={styles.attestRow} style={{ fontSize: "14px" }}>
                <div className={`${styles.nameColumn} ${styles.titleName}`}>Avdelning</div>
                {isActiveIcp ? <div className={`${styles.iconColumn} ${styles.titleName}`}>Klar</div> : null}
                {isActiveIcp ? <div className={`${styles.normalColumn} ${styles.titleName}`}>Kommentar</div> : null}
                <div className={`${styles.nameColumn} ${styles.titleName}`}>{isActiveIcp ? "Avrapporterad" : "Attesterad"} av</div>
                <div className={`${styles.managersColumn} ${styles.titleName}`}>Objektsägare</div>
            </div>

            {status === "success" && attestList.length > 0 ?
                <div style={{ marginBottom: "60px", fontSize: "14px" }}>
                    {attestList.map((attestRow, index) => {

                        return <div className={styles.attestRow} key={index}>
                            <div className={styles.nameColumn}>{attestRow?.name}</div>
                            {isActiveIcp ? <>
                                <div className={styles.iconColumn}>
                                    {attestRow.done ? <i style={{ marginLeft: "7px", color: "darkgreen" }} className={"far fa-check-circle"} ></i> :
                                        <i style={{ marginLeft: "7px" }} className={"far fa-circle"} ></i>}
                                </div>
                                <div className={`${styles.normalColumn}`} onClick={() => {
                                    setTodoToComment({ comment: attestRow.comment, done: attestRow.done })
                                    setCurrentIcpState({ departmentId: attestRow._id, icpId: history.location.state.icpId, icpName: history.location.state.icpName, comment: attestRow.comment, update: true })
                                    setShowReportIcpModal(true)
                                }}>
                                    {!attestRow?.comment ?
                                        <i className={"far fa-comment"} ></i>
                                        :
                                        <i className={"fas fa-comment"} ></i>
                                    }
                                </div>
                            </> : null}
                            <div className={styles.nameColumn}>
                                {attestRow.done && isActiveIcp ? <Tooltip content={"Avrapporterad " + attestRow?.attestDate?.slice(0, 10)} direction={"right"}>{attestRow?.attestedBy}</Tooltip>
                                    : attestRow?.attestedBy && !isActiveIcp ?
                                        <Tooltip content={"Avrapporterad " + attestRow?.attestDate?.slice(0, 10)} direction={"right"}>{attestRow?.attestedBy}</Tooltip>
                                        : null
                                }
                            </div>
                            <div className={styles.managersColumn}>{attestRow?.objectManagers?.map(om => { return om.firstName + " " + om.lastName + ", " })}</div>
                        </div>
                    })}
                </div>
                : null
            }
        </div >
    )
}

export default AttestPage