import React, { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext"
import styles from "../styles/IcpInfoCardIcpRisk.module.css";
import axios from "axios";
import { useQuery } from "react-query";

export default function IcpInfocard({ risk, icpRiskId }) {
    const { activeUser } = useContext(UserContext)

    const readIcpRisk = async () => {
        const res = await axios.post(
            process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/get-icp-risk",
            { icpRiskId: icpRiskId },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token
                }
            }
        );
        return res.data;
    };

    const { data: icpRiskData } = useQuery(["getIcpRiskInfo"], readIcpRisk, {
        cacheTime: 0,
    });

    return (
        <div className={styles.cardContainer}>
            <div className={styles.infoContainer}>
                <div> Tillagd i internkontrollplanen av: {icpRiskData?.icpBy || ''} </div>
                <div>Tillagd: {icpRiskData?.icpDate.slice(0, 10)}</div>
            </div>
            <div>
                <div className={styles.descriptionContainer}>
                    <div className={styles.subHeader}>Motivering till internkontrollplan:</div>
                    <div className={styles.textBox} > {icpRiskData?.icpMotivation} </div>
                </div>
            </div>
        </div>
    );
}