import React from 'react'
import styles from './styles/SuggestionsAndDeviationAnalyze.module.css'
import { useHistory } from "react-router-dom"

export default function AttestsRP({ riskData, SetYear, year, setOnlyWithAttestComment, onlyWithAttestComment, risksWithAttestsRP }) {
    const history = useHistory()

    const prepareYearOptions = (riskData) => {
        riskData?.map(risk => {
            risk?.controllers?.map(ctrls => {
                ctrls.verifyChecks?.map(checks => {
                    options.add(checks.date?.slice(0, 4))
                    return null
                })
                return null
            })
            return null
        })
    }

    const options = new Set()
    const allYears = 'Samtliga'
    options.add(allYears)
    prepareYearOptions(riskData)
    if (options.size < 3) options.delete(allYears)

    let risksWithAttests = risksWithAttestsRP()

    const hasAttestToRender = (risk) => {
        let hasComment = false
        let hasYear = false
        for (const controller of risk.reviewsWithSuggestions) {
            if (onlyWithAttestComment) {
                let foundComment = controller.suggestions?.some(attest => attest.comment)
                if (foundComment) hasComment = true
            } else {
                hasComment = true
            }

            if (year === allYears) {
                hasYear = true
            } else {
                let foundYear = controller.suggestions?.some(attest => attest.date.slice(0, 4) === year)
                if (foundYear) hasYear = true
            }
        }
        return (hasComment && hasYear)
    }

    const renderYearOptions = () => {
        if (options.size > 1) {
            return (
                <div className={styles.riskOption}>
                    <label style={{ fontSize: "14px" }}>Välj år: </label>
                    <select name='year' id='year' value={year} onChange={(e) => SetYear(e.currentTarget.value)}>
                        {[...options].sort(function (a, b) { return a - b }).map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            )
        } else {
            return null
        }
    }

    const renderOnlyWithAttestComment = () => {
        return (
            <div className={styles.riskOption}>
                <>
                    <label style={{ fontSize: "14px" }}>Visa endast med kommentar: </label>
                    {onlyWithAttestComment
                        ? <i className="far fa-check-square" onClick={() => { setOnlyWithAttestComment(false) }}></i>
                        : <i className="far fa-square" onClick={() => { setOnlyWithAttestComment(true) }}></i>
                    }
                </>
            </div>
        )
    }

    const renderSuggestions = (suggestions) => {
        let suggestionToRender = suggestions.map((suggestion, index) => {
            if (suggestion.asSuggestion && onlyWithAttestComment) {
                return null
            }
            return <li key={index}><div className={styles.suggestionNameRow}>
                <div style={{ fontWeight: "600" }}>Attest: {suggestion.date.slice(0, 10)} - {suggestion.user}</div>
                <div style={{ marginBottom: "10px" }}>{suggestion.asSuggestion ? '' : suggestion.comment}</div>
            </div></li>
        })
        return suggestionToRender
    }

    const renderControllers = (reviewsWithSuggestions) => {
        let reviewToRender = reviewsWithSuggestions.map((review, index) => {
            return <div key={index} className={styles.reviewNameRow}>
                <div className={styles.riskNameRow2} style={{ marginBottom: "6px" }}>Riskhanteringsåtgärd: {review.reviewName}</div>
                <ul>{renderSuggestions(review.suggestions)}</ul>
            </div>
        })
        return reviewToRender
    }

    const renderRisks = () => {
        let riskToRender = risksWithAttests.map((risk, index) => {
            const hasAttest = hasAttestToRender(risk)
            if (!hasAttest) {
                return null
            }
            return <div key={index} className={styles.riskDataContainer}><div className={styles.riskNameRow} onClick={() => {
                history.push("/show-risk", { riskId: risk.orgRiskId })
            }}>{risk.riskName} - {risk.departmentName}</div>{renderControllers(risk.reviewsWithSuggestions)}</div>
        })
        return riskToRender
    }

    return (
        <>
            <div className={styles.riskSelections}>
                {renderYearOptions()}
                {renderOnlyWithAttestComment()}
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.ContainerTitle}>Attester</div>
                {renderRisks()}
            </div >
        </>
    )
}
