import React from 'react'
import styles from "./styles/RiskDataBoxes.module.css";

export default function RiskDataBoxes({ onlyIcp, activeIcp, data, departments, objectToAnalyze, includeChildren, firstAttestData }) {
    let nrOfBranches = departments?.filter(dep => {
        return (dep.level === objectToAnalyze?.level + 1) && dep.parents.includes(objectToAnalyze._id)
    }).length

    let countDeviationReportsInAllReviews = 0
    let countSuggestionsInAllReviews = 0
    let nrOfReviews = 0
    let nrOfControllers = 0
    let accumulatedRiskValue = 0

    for (const risk of data) {
        accumulatedRiskValue = accumulatedRiskValue + (risk.probability * risk.consequence)
        nrOfControllers = nrOfControllers + risk.controllers.reduce((sum, controller) => { if (!controller.icp) { return sum + 1 } else return sum }, 0)
        if (activeIcp) {
            nrOfReviews = nrOfReviews + risk.reviews.length
            // nrOfReviews = nrOfReviews + risk.reviews.reduce((sum, review) => { if (controller.icp) { return sum + 1 } else return sum }, 0)            
            for (const review of risk.reviews) {
                if (review.deviationReports.length > 0) {
                    countDeviationReportsInAllReviews = countDeviationReportsInAllReviews + review.deviationReports.length
                }

                if (review.verifyChecks.length > 0) {
                    let filteredChecks = review.verifyChecks.filter((check) => { return check.asSuggestion })
                    countSuggestionsInAllReviews = countSuggestionsInAllReviews + filteredChecks.length
                }
            }
        } else {
            nrOfReviews = nrOfReviews + risk.controllers.reduce((sum, controller) => { if (controller.icp) { return sum + 1 } else return sum }, 0)

        }
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.boxContainer}>
                <div className={styles.firstBoxRow}>
                    <div className={styles.firstBoxRowFirstColumn}>
                        <div>Antal risker</div>
                    </div>
                    <div className={styles.firstBoxRowSecondColumn}>
                        <div>{data.length}</div>
                    </div>
                </div>
                <div className={styles.secondBoxRow}>
                    <div>
                        {/* //TODO */}
                        {includeChildren && nrOfBranches > 0 ?
                            <>

                                <div>{(data?.length / nrOfBranches).toFixed(1)} risker per organisationsgren</div>
                                <div>{nrOfBranches} underobjektsgrenar</div>
                            </>
                            :
                            <div>{objectToAnalyze.children.length} underobjektsgrenar</div>
                        }
                    </div>
                </div>
            </div>

            <div className={styles.boxContainer}>
                <div className={styles.firstBoxRow}>
                    <div className={styles.firstBoxRowFirstColumn}>
                        <div>Riskvärde /snitt</div>
                    </div>
                    <div className={styles.firstBoxRowSecondColumn}>
                        <div>
                            {data.length > 0 ? (accumulatedRiskValue / data.length).toFixed(1) : 0}
                        </div>
                    </div>
                </div>
                <div className={styles.secondBoxRow}>
                    <div>
                        <div>Lägsta riskvärde:
                            {data.length > 0 ? " " + (data[data.length - 1]?.probability * data[data.length - 1]?.consequence) : " 0"}
                        </div>
                    </div>
                    <div>
                        <div>Högsta riskvärde:
                            {data.length > 0 ? " " + (data[0]?.probability * data[0]?.consequence) : " 0"}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.boxContainer}>
                <div className={styles.firstBoxRow}>
                    <div className={styles.firstBoxWholeRow}>
                        <div>{nrOfControllers} / {nrOfReviews}</div>
                    </div>
                </div>
                <div className={styles.secondBoxRowCentered}>
                    <div>Antal riskhanteringsåtgärder / </div>
                    <div> Antal kontroller</div>
                </div>
            </div>

            <div className={styles.boxContainer}>
                <div className={styles.firstBoxRow}>
                    <div className={styles.firstBoxWholeRow}>
                        {onlyIcp && !activeIcp ? <div>{firstAttestData?.nrOfAttests} / {firstAttestData?.nrOfObjects}</div>
                            :
                            null
                        }
                        {activeIcp ? <div>{countDeviationReportsInAllReviews} / {countSuggestionsInAllReviews}</div>
                            :
                            null
                        }
                        {!onlyIcp ? <div> {data.filter(risk => risk.icp).length} </div>
                            // {!onlyIcp ? <div>{firstAttestData?.nrOfControllers} / {firstAttestData?.nrOfReviews}</div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className={styles.secondBoxRowCentered}>
                    {onlyIcp && !activeIcp ? <div>Attesterade objekt</div> : null}
                    {!onlyIcp ? <div>Risker till internkontrollplan</div> : null}
                    {activeIcp ? <><div>Avvikelserapporter / </div>
                        <div>Förbättringsförslag</div></> : null}
                </div>
            </div>
        </div >
    )
}
