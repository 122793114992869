import React, { useState } from "react";
import ReactDom from "react-dom";
import styled from "styled-components"
import styles from "./ExportRisksModal.module.css";

export const OptionButton = styled.button`
font-size: 13px;
font-family: "Montserrat", sans-serif;
  width: 150px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  background-color: ${({ colour }) => (colour === "red") ? "rgb(171, 47, 47)"
        : (colour === "blue") ?

            "rgb(47, 100, 171)" : "rgb(55, 55, 55)"};
  /* background-color: ${({ colour }) => (colour === "blue") ? "rgb(171, 47, 47)" : "rgb(55, 55, 55)"}; */
  /* ${({ icon }) => icon === 'star' && 'content: "\f005"'}; */
  height: 25px;
  padding-left: 21px;
  padding-right: 21px;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;

&:hover {
  /* background-color: ${({ alert }) => (alert === true) ? "rgb(161, 37, 37)" : "rgb(25, 25, 25)"}; */

  background-color: ${({ colour }) => (colour === "red") ? "rgb(161, 37, 37)"
        : (colour === "blue") ?

            "rgb(78,123,184)" : "rgb(40, 40, 40)"};
}
&:focus {
    outline: 0;
}
`

const MODAL_STYLES = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "fixed",
    top: "50%",
    left: "50%",
    // width: "480px",
    // height: "200px",
    borderRadius: "15px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    // padding: "0px",
    zIndex: "1000",
    padding: "30px"
};

const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0,0,0, 0.6)",
    zIndex: "999",
};

const LABEL_STYLES = {
    fontSize: "14px",
    fontWeight: "600",
};

export default function Modal({ onClose, open, warningTitle,
    warningText, optionTextOne, optionTextTwo, passedFunction, children, colour }) {
    const [fileType, setFileType] = useState(0)
    const [includeChildren, setIncludeChildren] = useState(true)
    if (!open) return null;

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES} >
                {/* <div style={{ ...MODAL_STYLES, width: `${width}`, height: `${height}` }}>{children} */}
                {/* {warningTitle} */}
                <div style={LABEL_STYLES}>{warningTitle}</div>
                <div style={{
                    marginTop: "10px", marginBottom: "20px", maxWidth: "650px", textAlign: "center", fontSize: "13px"
                }}>{warningText}</div>
                {children}

                <div className={styles.radioContainer}>
                    {/* <label className={styles.subHeader}>Status: </label> */}

                    <div className={styles.redRadio}>
                        <input
                            type="radio"
                            className={`${styles.radioButtonStyle}`}
                            id="radioRed"
                            name="materialExampleRadios"
                            onChange={() => {
                                setFileType(0)
                                // updateIcpRiskResult({ status: 0 })
                            }}
                            checked={fileType === 0}
                        ></input>
                        <label htmlFor="radioRed">
                            PDF
                        </label>
                    </div>

                    <div className={`${styles.generalRadioButton} ${styles.greenRadio}`}>
                        <input
                            type="radio"
                            className={`${styles.radioButtonStyle}`}
                            id="radioGreen"
                            name="materialExampleRadios"
                            onChange={() => {
                                setFileType(1)
                                // updateIcpRiskResult({ status: 1 })
                            }}
                            checked={fileType === 1}
                        ></input>
                        <label htmlFor="radioGreen">
                            Excel
                        </label>
                    </div>
                </div>
                <div className={styles.riskOption} >
                    <label style={{ fontSize: "14px" }}>Exportera lista med utvecklad vy</label>
                    {includeChildren
                        ? <i className="far fa-check-square" onClick={() => setIncludeChildren(false)}></i>
                        : <i className="far fa-square" onClick={() => setIncludeChildren(true)}></i>
                    }
                </div>
                <div>
                    <OptionButton alert={true} colour={colour} onClick={() => {
                        passedFunction(fileType === 0 ? "pdf" : "excel", includeChildren)
                        onClose()
                    }}>{optionTextOne}</OptionButton>
                    {optionTextTwo ?
                        <OptionButton alert={false} onClick={() => {
                            onClose()
                        }}>{optionTextTwo}</OptionButton>
                        : null}
                </div>
            </div>
        </>,
        document.getElementById("portal")
    );
}
