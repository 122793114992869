export default function calculateRiskGroup(prob, cons, riskGroupDefinitions, cosoSettings) {

    if (cosoSettings.size === 3) {


        if (prob * cons >= 6) { return { group: "R3", rgd: riskGroupDefinitions[2] } }
        else if (prob === 3 && cosoSettings.maxProbabilityRed) {
            return { group: "R3", rgd: riskGroupDefinitions[2] }
        }
        else if (cons === 3 && cosoSettings.maxConsequenceRed) {
            return { group: "R3", rgd: riskGroupDefinitions[2] }
        }
        else if (prob * cons === 3 || prob * cons === 4) {
            return { group: "R2", rgd: riskGroupDefinitions[1] }
        }
        else { return { group: "R1", rgd: riskGroupDefinitions[0] } }


    } else if (cosoSettings.size === 4) {

        if (cons === 4 && cosoSettings.maxConsequenceRed) { return { group: "R4", rgd: riskGroupDefinitions[3] } }
        else if (prob === 4 && cosoSettings.maxProbabilityRed) {
            return { group: "R4", rgd: riskGroupDefinitions[3] }
        }
        else if (prob * cons < 4) {
            return { group: "R1", rgd: riskGroupDefinitions[0] }
        }
        else if (prob * cons < 8) {
            return { group: "R2", rgd: riskGroupDefinitions[1] }
        }
        else if (prob * cons < 12) {
            return { group: "R3", rgd: riskGroupDefinitions[2] }
        }
        else { return { group: "R4", rgd: riskGroupDefinitions[3] } }


    } else if (cosoSettings.size === 5) {


        if (cons === 5 && cosoSettings.maxConsequenceRed) { return { group: "R5", rgd: riskGroupDefinitions[4] } }
        else if (prob === 5 && cosoSettings.maxProbabilityRed) {
            return { group: "R5", rgd: riskGroupDefinitions[4] }
        }
        else if (prob * cons < 3) {
            return { group: "R1", rgd: riskGroupDefinitions[0] }
        }
        else if (prob * cons < 5) {
            return { group: "R2", rgd: riskGroupDefinitions[1] }
        }
        else if (prob * cons < 10) {
            return { group: "R3", rgd: riskGroupDefinitions[2] }
        }
        else if (prob * cons < 15) {
            return { group: "R4", rgd: riskGroupDefinitions[3] }
        }
        else { return { group: "R5", rgd: riskGroupDefinitions[4] } }


    }

}

