import React, { useState, useContext } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { PageHeaderButton } from '../../../styles/buttons'
import axios from "axios";
import styles from "../styles/ListTodos.module.css";
import Tooltip from "../../../components/tooltip/Tooltip";
import TextAreaInputModal from "./../../../modal/TextAreaInputModal";

const ListTodos = ({ setFadeFromRiskPortfolio }) => {
    const history = useHistory();
    const { activeUser } = useContext(UserContext);
    const [sortAfter, setSortAfter] = useState("name")
    const [sortOrder, setSortOrder] = useState('')
    const [orgPicked, setOrgPicked] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [filterDepartment, setFilterDepartment] = useState("")
    const [listChildren, setListChildren] = useState(false)
    const [objChildren, setObjChildren] = useState([])
    const [unfoldList, setUnfoldList] = useState(false)
    const [showTextAreaInputModal, setShowTextAreaInputModal] = useState(false);
    const [todoToComment, setTodoToComment] = useState(null)

    function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (key === "departmentName") {
                let compared = 0;
                if (a.department?.name.toUpperCase() < b.department?.name.toUpperCase()) {
                    compared = 1;
                } else if (a.department?.name.toUpperCase() > b.department?.name.toUpperCase()) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }
            if (key === "icpName") {
                let compared = 0;
                if (a.icp?.name.toUpperCase() < b.icp?.name.toUpperCase()) {
                    compared = 1;
                } else if (a.icp?.name.toUpperCase() > b.icp?.name.toUpperCase()) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }
            if (key === "comment") {
                let compared = 0;
                if (a.comment && !b.comment) {
                    compared = 1;
                } else if (!a.comment && b.comment) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }
            if (key === "todoName") {
                let compared = 0;
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    compared = 1;
                } else if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }
            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];
            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    const readAllTodos = async () => {
        const res = await axios.get(
            process.env.REACT_APP_IC_SERVICE + "/todo/read-all-todos",
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        if (setFadeFromRiskPortfolio) { setFadeFromRiskPortfolio() }
        return res.data;
    };


    const getObjectAcceessList = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });
        return res.data;
    };

    const { data: accessList, status: accessListStatus } = useQuery(["getAccessListToListRiskInRp"], getObjectAcceessList, {});
    const { data: allTodos, status, error, isError, isFetchedAfterMount, refetch } = useQuery(["getAllTodos"], readAllTodos, {
        enabled: !!accessList
    });

    const toggleTodoDone = async (data) => {
        let dataToSend = data
        await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/todo/update-todo",
            dataToSend,
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        refetch();
    };

    const sortDropDown = () => {
        let initList = accessList.filter((org) => { return org.ancestor === orgPicked.toString() || org._id === orgPicked.toString() })
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt = initList.find((dep) => dep.level === 1)
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }
    const sortDropDownJustDepartments = () => {
        let initList = accessList
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt = initList.find((dep) => dep._id === activeUser.department)
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }

    const listToRender = () => {
        let tempList = allTodos?.filter(s => {
            if (filterDepartment === "" && orgPicked) {
                return s.name.toUpperCase().includes(searchText) && s.organisation === orgPicked.toString()
            } else if (filterDepartment === "") {
                return s.name.toUpperCase().includes(searchText)
            } else if (filterDepartment !== "" && listChildren && s.department) {
                return s.name.toUpperCase().includes(searchText) && (objChildren.includes(s.department._id) || filterDepartment === s.department._id)
            } else if (filterDepartment !== "" && !listChildren && s.department) {
                return s.name.toUpperCase().includes(searchText) && filterDepartment === s.department._id
            } else return null
        }
        ).filter((todo) => { return unfoldList ? todo : !todo.done }).sort(compareValues(`${sortAfter}`, sortOrder))
        return tempList
    }



    return (
        <>
            <TextAreaInputModal
                passedFunction={(newText) => {
                    toggleTodoDone({ id: todoToComment._id, comment: newText })
                }}
                todoToComment={todoToComment}
                labelText={"Kommentera uppgiften!"}
                warningText={"Användaren kommer att raderas från systemet!"}
                optionTextOne={"Spara"}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={showTextAreaInputModal}
                onClose={() => {
                    setShowTextAreaInputModal(false);
                }}
            />
            <div className={styles.listRisksContainer} >
                <div className={styles.filterContainer} >
                    <div style={{ width: "700px", marginBottom: "40px" }}>
                        {/* <div className="bodyText">
                            Uppgifter kan kopplas till avdelningar samt internkontrollplaner. Hoovra för att se fullständig beskrivning.
                </div> */}
                    </div>
                    <div className={styles.searchInputRow}>
                        <label className="labelText">Sök på uppgifter: </label>
                        <input name="name" placeholder="Sök på uppgiftsnamn" autoComplete="off"
                            onChange={(e) => { setSearchText(e.target.value.toUpperCase()) }}
                        />
                    </div>

                    <div className="selectBox">
                        {(activeUser.admin) && accessListStatus === "success" && accessList[0] !== null ? (
                            <>
                                <div>
                                    <label>Filtrera uppgifter: </label>
                                    <select style={{ marginLeft: "15px" }} name="organisation" onChange={(e) => {

                                        let element = document.getElementById("leaveCode");
                                        if (element) {
                                            element.value = ""
                                        }
                                        setObjChildren([])
                                        setFilterDepartment("")
                                        setOrgPicked(e.target.value)
                                    }}>
                                        <option value="">Välj organisation</option>
                                        {accessList.filter((org) => { return org.level === 1 }).map((dep) => {
                                            return <option key={dep._id} value={dep._id} >{dep.name}</option>
                                        })}
                                    </select>
                                </div>

                                {orgPicked && (
                                    <>
                                        <div className="selectBox">
                                            <label style={{ marginLeft: "30px" }}>Avdelning: </label>
                                            <select name="department"
                                                id="leaveCode"
                                                onChange={(e) => {
                                                    let foundParents = accessList.filter((obj) => {
                                                        return obj.parents.includes(e.target.value)

                                                    }).map((filt) => {
                                                        return filt._id

                                                    })
                                                    setObjChildren(foundParents)
                                                    setFilterDepartment(e.target.value)
                                                }}>
                                                <option value="">Alla avdelningar</option>
                                                {sortDropDown().map((dep) => {
                                                    return <option key={dep._id} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                                                })}
                                            </select>
                                            <div className={styles.riskOption}
                                            >
                                                <label>Visa underavdelningar: </label>
                                                {listChildren
                                                    ? <i style={{ cursor: "pointer" }} className="far fa-check-square" onClick={() => setListChildren(false)}></i>
                                                    : <i style={{ cursor: "pointer" }} className="far fa-square" onClick={() => setListChildren(true)}></i>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : null}
                        {((activeUser.objectManager && !activeUser.admin)) && accessListStatus === "success" && accessList[0] !== null ? (
                            <>
                                <div className="selectBox">
                                    <label>Avdelning: </label>
                                    <select name="department" style={{ marginLeft: "40px" }}
                                        onChange={(e) => {
                                            let foundParents = accessList.filter((obj) => {
                                                return obj.parents.includes(e.target.value)

                                            }).map((filt) => { return filt._id })
                                            setObjChildren(foundParents)
                                            setFilterDepartment(e.target.value)
                                        }}
                                    >
                                        <option value="">Alla avdelningar</option>
                                        {
                                            sortDropDownJustDepartments().map((dep, index) => {
                                                return <option key={index} value={dep?._id} >{"- ".repeat(parseInt(dep?.level - 1))}{dep?.name}
                                                </option>
                                            })
                                        }
                                    </select>
                                    <div className={styles.riskOption}
                                    >
                                        <label>Visa underavdelningar: </label>
                                        {listChildren
                                            ? <i style={{ cursor: "pointer" }} className="far fa-check-square" onClick={() => setListChildren(false)}></i>
                                            : <i style={{ cursor: "pointer" }} className="far fa-square" onClick={() => setListChildren(true)}></i>
                                        }
                                    </div>
                                </div>

                            </>
                        ) : null}
                    </div>
                </div>

                <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                    <PageHeaderButton onClick={() => {
                        setUnfoldList((state) => { return !state })
                    }}>{!unfoldList ? "Visa" : "Dölj"} klarmarkerade uppgifter</PageHeaderButton>
                </div>

                <div className={styles.riskRow} >
                    <div className={styles.riskRowTitleItemName}
                        onClick={() => {
                            if (sortAfter === "todoName") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("todoName")
                                setSortOrder('')
                            }
                        }}>Uppgifter</div>
                    <div className={styles.riskRowTitleItemDescription}>Beskrivning</div>

                    <div className={styles.riskRowTitleItem} onClick={() => {
                        if (sortAfter === "departmentName") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("departmentName")
                            setSortOrder('')
                        }
                    }}>Avdelning</div>
                    <div className={styles.riskRowTitleItem} onClick={() => {
                        if (sortAfter === "icpName") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("icpName")
                            setSortOrder('')
                        }
                    }}>Internkontrollplan</div>
                    <div className={styles.riskRowTitleItemDate} onClick={() => {
                        if (sortAfter === "createdAt") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("createdAt")
                            setSortOrder('')
                        }
                    }}>Skapad</div>
                    <div className={styles.riskRowTitleItemDate} onClick={() => {
                        if (sortAfter === "readyDate") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("readyDate")
                            setSortOrder('')
                        }
                    }}>Färdig senast</div>
                    <div className={styles.riskRowIconItemTitle} onClick={() => {
                        if (sortAfter === "comment") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("comment")
                            setSortOrder('')
                        }
                    }}>Kom</div>
                    <div className={styles.riskRowIconItemTitle} onClick={() => {
                        if (sortAfter === "done") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("done")
                            setSortOrder('')
                        }
                    }}>Klar</div>
                </div>
                {status === "loading" && <div>Laddar data...</div>}
                {isError && <div>Fel vid inladdning av data....</div>}
                {error && <div>Fel vid inladdning av data...</div>}
                {
                    isFetchedAfterMount === true && status === "success" && (
                        listToRender().map((risk) => {
                            return (
                                <div className={styles.riskRow} key={risk._id} >
                                    <div className={styles.riskRowItemLink} onClick={() => { history.push("/ändra-uppgift", { risk }) }}>
                                        {risk.name.length > 32 ? <Tooltip content={risk.name} direction={"right"}>{risk.name.slice(0, 32)}...</Tooltip> : risk.name}
                                    </div>

                                    <div className={styles.riskRowItemDescription}>
                                        {risk.description.length > 36 ? <Tooltip content={risk.description} direction={"right"}>{risk.description.slice(0, 36)}...</Tooltip> : risk.description}
                                    </div>
                                    <div className={styles.riskRowItem}>{risk.department?.name.length > 26 ? <Tooltip content={risk.department?.name} direction={"right"}>{risk.department?.name.slice(0, 26)}...</Tooltip> : risk.department?.name}</div>
                                    <div className={styles.riskRowItem}>{risk.icp?.name.length > 24 ? <Tooltip content={risk.icp?.name} direction={"right"}>{risk.icp?.name.slice(0, 24)}...</Tooltip> : risk.icp?.name}</div>
                                    <div className={styles.riskRowItemDate}>{risk.createdAt.slice(0, 10)}</div>
                                    <div className={styles.riskRowItemDate}>{risk.readyDate?.slice(0, 10)}</div>
                                    <div onClick={() => {
                                        setTodoToComment(risk)
                                        setShowTextAreaInputModal(true)
                                    }}>
                                        {!risk?.comment ? <div className={styles.riskRowIconItem}><i style={{ marginLeft: "7px" }} className={"far fa-comment"} ></i></div> :
                                            <div className={styles.riskRowIconItem}><Tooltip content={risk.comment} direction={"left"}><i style={{ marginLeft: "7px", color: "#888" }} className={"fas fa-comment"} ></i></Tooltip></div>
                                        }
                                    </div>
                                    <div onClick={() => toggleTodoDone({ id: risk._id, done: !risk.done })}>
                                        {risk.done ? <div className={styles.riskRowIconItem}><i style={{ marginLeft: "7px", color: "darkgreen" }} className={"far fa-check-circle"} ></i></div> :
                                            <div className={styles.riskRowIconItem}><i style={{ marginLeft: "7px" }} className={"far fa-circle"} ></i></div>}
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div >
        </>
    );
};

export default ListTodos;