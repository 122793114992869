import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"
import styles from "./styles/LoginPage.module.css"

const ResetPassword = () => {
    const {
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [email, setEmail] = useState()
    const [loginError, setLoginError] = useState("")
    const [regPasswordOne, setRegPasswordOne] = useState("")
    const [regPasswordTwo, setRegPasswordTwo] = useState("")
    const history = useHistory()
    const params = useParams()
    const api = axios.create({
        baseURL: process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_BASE_URL + process.env.REACT_APP_IC_SERVICE
            : process.env.REACT_APP_IC_SERVICE,
    })

    const postChangePassword = async () => {
        api
            .post("/auth/change-password-reset", {
                password: regPasswordOne,
                resetToken: params.resetCode,
                email,
            })
            .then((res) => {
                history.push("/login")
            })
            .catch(() => {
                setLoginError("Lösenordet kunde inte återställas.")
            })
    }
    const submitPasswordChange = async () => {
        if (regPasswordOne !== regPasswordTwo) {
            setLoginError("Olika lösenord!")
        } else if (
            regPasswordOne.length > 7 &&
            regPasswordOne.length < 20 &&
            regPasswordOne.match(/[a-z]/) &&
            regPasswordOne.match(/\d+/)
        ) {
            postChangePassword()
        } else if (regPasswordOne.length === 0 && regPasswordOne.length === 0) {
            setLoginError("Skriv lösenord!")
        } else {
            setLoginError(
                "Lösenordet måste vara minst åtta tecken långt samt innehålla minst en siffra och en bokstav!"
            )
        }
    }

    useEffect(() => {
        api
            .post("/auth/check-reset-token", {
                resetToken: params.resetCode,
            })
            .then((res) => {
                if (res.data.status === 400) {
                    history.push("/login")
                } else {
                    setEmail(res.data.email)
                }
            })
    }, [params, history])

    if (!email) return "Laddar..."
    return (
        <>
            <div className={styles.loginPageContainer}>
                <div className={styles.loginFormContainer}>
                    <form
                        onSubmit={handleSubmit(submitPasswordChange)}
                        className={styles.loginForm}
                    >
                        <div>
                            <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                                Byt lösenord för {email}
                            </p>
                            <>
                                <input
                                    value={regPasswordOne}
                                    onChange={(e) => setRegPasswordOne(e.currentTarget.value)}
                                    autoComplete="newPassword"
                                    style={{ textAlign: "center" }}
                                    placeholder="Nytt lösenord"
                                    type="password"
                                    name="passwordOne"
                                />
                                <input
                                    value={regPasswordTwo}
                                    onChange={(e) => setRegPasswordTwo(e.currentTarget.value)}
                                    autoComplete="newPassword"
                                    style={{ textAlign: "center" }}
                                    placeholder="Nytt lösenord igen"
                                    type="password"
                                    name="passwordTwo"
                                />
                            </>
                        </div>
                        <input
                            type="submit"
                            id="registerButton"
                            className={styles.loginButton}
                            style={{ marginLeft: "0px" }}
                            value={"Byt lösenord"}
                        />
                    </form>
                    <div className={styles.regMessageContainer}>
                        <p></p>
                        <div className={styles.errorTextRegPage}>
                            {Object.keys(errors).length > 0 ? (
                                <>{errors[Object.keys(errors)[0].toString()].message}</>
                            ) : loginError ? (
                                loginError
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
