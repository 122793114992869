import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { PageHeaderButton } from '../../../styles/buttons'
import axios from "axios";
import styles from "../styles/ListRisksInPortfolio.module.css";
import Tooltip from "../../../components/tooltip/Tooltip";
import FilterRisks from "../../../components/filter-risks/FilterRisks";
import ExportRisksModal from "./../../../modal/ExportRisksModal";
import { exportAsExcel } from "./../../../functions/exportToFile/exportExcel"
import { exportAsPdf } from "./../../../functions/exportToFile/exportPdf"
import calculateRiskGroupColor from '../../../functions/calculateRiskGroupColorRGB'
// import { testDataRP } from "../../../functions/exportToFile/testData"

const ListRisksInPortfolio = ({ onlyIcp, icpOrg, icpName, setFadeFromRiskPortfolio, setFadeFromWaitingPlan, showExportModal, setShowExportModal }) => {
    const history = useHistory();
    const { activeUser, sideBar } = useContext(UserContext);
    const [sortAfter, setSortAfter] = useState("name")
    const [sortOrder, setSortOrder] = useState('')
    const [orgPicked, setOrgPicked] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [filterDepartment, setFilterDepartment] = useState("")
    const [listChildren, setListChildren] = useState(false)
    const [objChildren, setObjChildren] = useState([])
    const [unfoldList, setUnfoldList] = useState(false)
    const [filterOptions, setFilterOptions] = useState({ category: "Välj process", process: "Välj kategori", minRiskValue: 1, maxRiskValue: 25 })
    const [minRiskValue, setMinRiskValue] = useState(1)
    const [maxRiskValue, setMaxRiskValue] = useState(20)
    const [calendarOnly, setCalendarOnly] = useState(false)
    const [ikpOnly, setIkpOnly] = useState(false)

    function compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (key === "riskValue") {
                let compared = 0;
                if (a.probability * a.consequence < b.probability * b.consequence) {
                    compared = 1;
                } else if (a.probability * a.consequence > b.probability * b.consequence) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }

            if (key === "controllers") {
                let compared = 0;
                if (a.controllers.filter((kontroll) => { return kontroll.icp === false }).length < b.controllers.filter((kontroll) => { return kontroll.icp === false }).length) {
                    compared = 1;
                } else if (a.controllers.filter((kontroll) => { return kontroll.icp === false }).length > b.controllers.filter((kontroll) => { return kontroll.icp === false }).length) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }

            if (key === "departmentName") {

                let compared = 0;
                if (a.department?.name.toUpperCase() < b.department?.name.toUpperCase()) {
                    compared = 1;
                } else if (a.department?.name.toUpperCase() > b.department?.name.toUpperCase()) {
                    compared = -1;
                }
                return (
                    (order === 'desc') ? (compared * -1) : compared
                );
            }

            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    const readAllRisks = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-filtered-risks", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });

        if (setFadeFromRiskPortfolio) { setFadeFromRiskPortfolio() }
        else if (setFadeFromWaitingPlan) { setFadeFromWaitingPlan() }
        return res.data;
    };

    const getObjectAcceessList = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });
        return res.data;
    };

    const { data: accessList, status: accessListStatus } = useQuery(["getAccessListToListRiskInRp"], getObjectAcceessList, {});
    const { data: riskList, status, error, isError, isFetchedAfterMount } = useQuery(
        ["getAllRisksToLRP"],
        readAllRisks, {
        enabled: !!accessList,
        // refetchOnWindowFocus: false,
        // refetchOnmount: false,
        // refetchOnReconnect: false,
        // retry: false,
        // staleTime: 20000,
    });

    const sortDropDown = () => {
        let initList = accessList.filter((org) => { return org.ancestor === orgPicked.toString() || org._id === orgPicked.toString() })
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt = initList.find((dep) => dep.level === 1)
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }
    const sortDropDownJustDepartments = () => {
        let initList = accessList
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt
        if (activeUser.admin) {
            levelEtt = initList.find((dep) => dep._id === icpOrg)
        } else {
            levelEtt = initList.find((dep) => dep._id === activeUser.department)
        }
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }

    const readSettings = async () => {
        let res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/read-settings", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        })
        return res.data.coso
    }

    const { data: settingsData, status: settingsStatus } = useQuery(["getSettings"], readSettings, {
        cacheTime: 0,
    });

    const mailAllUsers = () => {
        axios.post(process.env.REACT_APP_IC_SERVICE + "/user/mail-all-populated",
            { calendar: 'Riskportföljen' },
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        )
    }

    useEffect(() => {
        setOrgPicked(icpOrg)
    }, [icpOrg])

    const listToRender = () => {
        let tempList = riskList?.filter((icpRisk) => {
            if (onlyIcp) { return icpRisk.icp }
            else { return icpRisk }
        }).filter(s => {
            if (filterOptions.process === "Välj kategori") { return true }
            return s.process === filterOptions.process
        }).filter(risk => {
            if (calendarOnly) { return risk.calendar }
            return true
        }).filter(risk => {
            if (ikpOnly) { return risk.icp }
            return true
        }).filter(s => {
            if (filterOptions.category === "Välj process") { return true }
            return s.category === filterOptions.category
        }).filter(risk => {
            if (risk.probability * risk.consequence >= minRiskValue && risk.probability * risk.consequence <= maxRiskValue) { return true }
            return false
        }).filter(s => {
            if (filterDepartment === "" && orgPicked) {
                return s.name.toUpperCase().includes(searchText) && s.organisation === orgPicked.toString()
            } else if (filterDepartment === "") {
                return s.name.toUpperCase().includes(searchText)
            } else if (filterDepartment !== "" && listChildren && s.department) {
                return s.name.toUpperCase().includes(searchText) && (objChildren.includes(s.department._id) || filterDepartment === s.department._id)
            } else if (filterDepartment !== "" && !listChildren && s.department) {
                return s.name.toUpperCase().includes(searchText) && filterDepartment === s.department._id
            } else return null
        }
        ).sort(compareValues(`${sortAfter}`, sortOrder))
        return tempList
    }

    return (
        <>
            <ExportRisksModal
                passedFunction={(fileType, extendedList) => {
                    let title = onlyIcp ? icpName : ""
                    let settings = { riskportfolio: !onlyIcp, active: false, extended: extendedList, title }

                    switch (fileType) {
                        case "pdf":
                            exportAsPdf(listToRender(), settings, settingsData, activeUser.customer)
                            break;
                        case "excel":
                            exportAsExcel(listToRender(), settings)
                            break;
                        default:
                            break;
                    }
                }}
                warningTitle={"Skapa rapport av risker!"}
                warningText={"Välj filformat och om du vill ha utvecklad listvy eller inte."}
                optionTextOne={"Ladda ner"}
                optionTextTwo={"Avbryt"}
                colour={"blue"}
                open={showExportModal}
                onClose={() => {
                    setShowExportModal(false);
                }}
            />
            <div className={styles.listRisksContainer} >
                <div className={styles.filterContainer} >
                    <div style={{ width: "600px", marginBottom: "40px" }}>
                        <div className="bodyText">
                            Här visas alla risker. Klicka på rubrikerna i listan för
                            sortering. För att gå in och arbeta med en risk så klickar du dig
                            fram genom länken i risknamnet.
                        </div>
                    </div>
                    <div className={styles.searchInputRow}>
                        <label className="labelText">Sök på risker: </label>
                        <input name="name" placeholder="Sök på risknamn" autoComplete="off"
                            onChange={(e) => { setSearchText(e.target.value.toUpperCase()) }}
                        />
                    </div>

                    <div className={styles.selectBox}>
                        {(activeUser.admin) && !onlyIcp && accessListStatus === "success" && accessList[0] !== null ? (
                            <>
                                <div>
                                    <label>Filtrera på organisation: </label>
                                    <select style={{ marginLeft: "15px" }} name="organisation" onChange={(e) => {

                                        let element = document.getElementById("leaveCode");
                                        if (element) {
                                            element.value = ""
                                        }
                                        setObjChildren([])
                                        setFilterDepartment("")
                                        setOrgPicked(e.target.value)
                                    }}>
                                        <option value="">Välj organisation</option>
                                        {accessList.filter((org) => { return org.level === 1 }).map((dep) => {
                                            return <option key={dep._id} value={dep._id} >{dep.name}</option>
                                        })}
                                    </select>
                                </div>

                                {orgPicked && (
                                    <>
                                        <div className={styles.selectBox}>
                                            <label style={{ marginLeft: "30px" }}>Avdelning: </label>
                                            <select name="department"
                                                id="leaveCode"
                                                onChange={(e) => {
                                                    let foundParents = accessList.filter((obj) => {
                                                        return obj.parents.includes(e.target.value)

                                                    }).map((filt) => {
                                                        return filt._id

                                                    })
                                                    setObjChildren(foundParents)
                                                    setFilterDepartment(e.target.value)
                                                }}>
                                                <option value="">Alla avdelningar</option>
                                                {sortDropDown().map((dep) => {
                                                    return <option key={dep._id} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                                                })}
                                            </select>
                                            <div className={styles.riskOption}
                                            >
                                                <label>Visa underavdelningar: </label>
                                                {listChildren
                                                    ? <i style={{ cursor: "pointer" }} className="far fa-check-square" onClick={() => setListChildren(false)}></i>
                                                    : <i style={{ cursor: "pointer" }} className="far fa-square" onClick={() => setListChildren(true)}></i>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : null}
                        {((activeUser.admin && onlyIcp) || (activeUser.objectManager && !activeUser.admin)) && accessListStatus === "success" && accessList[0] !== null ? (
                            <>
                                <div className={styles.selectBox}>
                                    <label>Avdelning: </label>
                                    <select name="department" style={{ marginLeft: "40px" }}
                                        onChange={(e) => {
                                            let foundParents = accessList.filter((obj) => {
                                                return obj.parents.includes(e.target.value)

                                            }).map((filt) => { return filt._id })
                                            setObjChildren(foundParents)
                                            setFilterDepartment(e.target.value)
                                        }}
                                    >
                                        <option value="">Alla avdelningar</option>
                                        {
                                            sortDropDownJustDepartments().map((dep, index) => {
                                                return <option key={index} value={dep?._id} >{"- ".repeat(parseInt(dep?.level - 1))}{dep?.name}
                                                </option>
                                            })
                                        }
                                    </select>
                                    <div className={styles.riskOption}
                                    >
                                        <label>Visa underavdelningar: </label>
                                        {listChildren
                                            ? <i style={{ cursor: "pointer" }} className="far fa-check-square" onClick={() => setListChildren(false)}></i>
                                            : <i style={{ cursor: "pointer" }} className="far fa-square" onClick={() => setListChildren(true)}></i>
                                        }
                                    </div>
                                </div>

                            </>
                        ) : null}
                    </div>
                </div>

                <FilterRisks
                    changeCategory={(a) => { setFilterOptions((state) => { return { ...state, process: a } }) }}
                    changeProcess={(a) => { setFilterOptions((state) => { return { ...state, category: a } }) }}
                    changeRiskValues={(a, b) => {
                        setMinRiskValue(a)
                        setMaxRiskValue(b)
                    }}
                    changeCalendarOnly={() => {
                        if (!calendarOnly) {
                            mailAllUsers()
                        }
                        setCalendarOnly((state) => { return !state })
                    }}
                    changeSetIkpOnly={() => { setIkpOnly((state) => { return !state }) }}
                    calendarOnly={calendarOnly}
                    // setIkpOnly={setIkpOnly}
                    ikpOnly={ikpOnly}
                    thisIsRiskPortfolio={!onlyIcp}
                    thisIsActiveIcp={false}
                    // changeRiskValues={(a, b) => { setFilterOptions((state) => { return { ...state, category: a } }) }}
                    incList={listToRender()}
                />

                <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                    <PageHeaderButton onClick={() => {
                        setUnfoldList((state) => { return !state })
                    }}>{!unfoldList ? "Visa" : "Dölj"} utfälld lista</PageHeaderButton>
                </div>

                <div className={styles.riskRow} >
                    <div className={styles.riskRowTitleItemName}
                        onClick={() => {
                            if (sortAfter === "name") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("name")
                                setSortOrder('')
                            }
                        }}>Totalt {listToRender()?.length} risker</div>
                    <div className={styles.riskRowTitleItem} onClick={() => {
                        if (sortAfter === "category") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("category")
                            setSortOrder('')
                        }
                    }}>Huvudprocess</div>
                    {!sideBar || onlyIcp ?
                        <div className={styles.riskRowTitleItem} onClick={() => {
                            if (sortAfter === "subCategory") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("subCategory")
                                setSortOrder('')
                            }
                        }}>Delprocess</div>
                        : null}
                    <div className={styles.riskRowTitleItem} onClick={() => {
                        if (sortAfter === "process") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("process")
                            setSortOrder('')
                        }
                    }}>Kategori</div>
                    <div className={styles.riskRowTitleItem} onClick={() => {
                        if (sortAfter === "departmentName") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("departmentName")
                            setSortOrder('')
                        }
                    }}>Avdelning</div>
                    {onlyIcp ? null :
                        <>
                            <div className={styles.riskRowTitleItemDate} onClick={() => {
                                if (sortAfter === "latestDeviationReport") {
                                    if (sortOrder === '') {
                                        setSortOrder('desc')
                                    } else { setSortOrder('') }
                                } else {
                                    setSortAfter("latestDeviationReport")
                                    setSortOrder('')
                                }
                            }}><Tooltip content="Senaste avvikelserapport" direction={"left"}>AVR</Tooltip></div>
                            <div className={styles.riskRowTitleItemSmall} onClick={() => {
                                if (sortAfter === "calendar") {
                                    if (sortOrder === '') {
                                        setSortOrder('desc')
                                    } else { setSortOrder('') }
                                } else {
                                    setSortAfter("calendar")
                                    setSortOrder('')
                                }
                            }}>Status</div>
                        </>}
                    <div className={styles.riskRowTitleItemSmall} onClick={() => {
                        if (sortAfter === "riskValue") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("riskValue")
                            setSortOrder('')
                        }
                    }}><Tooltip content="Riskvärde" direction={"left"}>RV</Tooltip></div>

                    {onlyIcp ? null :
                        <div className={styles.riskRowTitleItemSmall} onClick={() => {
                            if (sortAfter === "icp") {
                                if (sortOrder === '') {
                                    setSortOrder('desc')
                                } else { setSortOrder('') }
                            } else {
                                setSortAfter("icp")
                                setSortOrder('')
                            }
                        }}><Tooltip content="Internkontrollplan" direction={"left"}>IKP</Tooltip></div>
                    }

                    <div className={styles.riskRowTitleItemSmall} onClick={() => {
                        if (sortAfter === "controllers") {
                            if (sortOrder === '') {
                                setSortOrder('desc')
                            } else { setSortOrder('') }
                        } else {
                            setSortAfter("controllers")
                            setSortOrder('')
                        }
                    }}>{onlyIcp ? "Kontroller" : <Tooltip content="Riskhanteringsåtgärder" direction={"left"}>RHÅ</Tooltip>}</div>
                </div>
                {status === "loading" && <div>Laddar data...</div>}
                {isError && <div>Fel vid inladdning av data....</div>}
                {error && <div>Fel vid inladdning av data...</div>}

                {
                    isFetchedAfterMount === true && settingsStatus === "success" && status === "success" && (
                        listToRender().map((risk) => {
                            return (
                                <div key={risk._id}>
                                    {/* <div className={styles.riskRow} style={unfoldList ? { fontWeight: "600" } : null} > */}
                                    <div className={styles.riskRow} style={unfoldList ? { fontWeight: "500", fontSize: "11px" } : null}>
                                        <span className={styles.statusColourBox}
                                            // <span className={`banner ${unfoldList ? styles.statusColourBoxFolded : styles.statusColourBox}`}
                                            style={{ backgroundColor: calculateRiskGroupColor(risk.probability, risk.consequence, settingsData) }
                                                // (risk.status === 1) ? { background: "rgb(84, 180, 122)" } :
                                                // (risk.status === 1) ? { background: "rgb(32, 150, 13)" } :
                                                // (risk.status === 2) ? { background: "rgb(252, 255, 102)" } :
                                                // (risk.status === 2) ? { background: "rgb(255, 216, 0)" } :
                                                // (risk.status === 3) ? { background: "rgb(231, 37, 37)" } : { background: "white" }
                                            }
                                        ></span>

                                        <div className={styles.riskRowItemLink} onClick={() => {
                                            // <div className={styles.riskRowItemLink} style={unfoldList ? { fontWeight: "600" } : null} onClick={() => {
                                            if (onlyIcp === true) {
                                                history.push("/show-risk", { riskId: risk._id, onlyIcp: true, mirrorIcp: true, icpName: icpName, settingsData: settingsData })
                                            } else {
                                                history.push("/show-risk", { riskId: risk._id, onlyIcp: false, mirrorIcp: false, settingsData: settingsData })
                                            }
                                        }}>
                                            {/* <Tooltip content={risk.name} direction={"right"}>{risk.name.length > 28 ? risk.name.slice(0, 28) + "..." : risk.name}</Tooltip> */}
                                            {risk.name.length > 30 ?
                                                <Tooltip content={risk.name} direction={"right"}>{risk.name.slice(0, 30)}...</Tooltip>
                                                : risk.name
                                            }
                                        </div>

                                        <div className={styles.riskRowItem}>{risk.category}</div>
                                        {!sideBar || onlyIcp ?
                                            <div className={styles.riskRowItem}>{risk.subCategory}</div>
                                            : null
                                        }
                                        <div className={styles.riskRowItem}>{risk.process}</div>
                                        <div className={styles.riskRowItem}>{risk.department?.name.length > 26 ? risk.department?.name.slice(0, 26) + "..." : risk.department?.name}</div>
                                        {onlyIcp ? null :
                                            <div className={styles.riskRowItemDate}>{risk?.latestDeviationReport?.slice(0, 10)}</div>

                                        }
                                        {onlyIcp ? null :
                                            <div className={styles.riskRowItemSmall}>
                                                {risk.calendar ? <div className={styles.riskRowIconItem}><i style={{ color: "red", paddingLeft: "10px" }} className={"far fa-calendar-alt"} ></i></div> : null}
                                            </div>
                                        }
                                        <div className={styles.riskRowItemSmall}>{risk.probability * risk.consequence}</div>
                                        {onlyIcp ? null :
                                            <div className={styles.riskRowItemSmall}>
                                                {risk.icp ? <div className={styles.riskRowIconItem}><i style={{ marginLeft: "7px" }} className={"far fa-check-circle"} ></i></div> : null}
                                            </div>
                                        }
                                        <div className={styles.riskRowItemSmall}>
                                            {risk.controllers.filter((filteredController) => {
                                                if (onlyIcp) {
                                                    return filteredController.icp === true

                                                } else {
                                                    return filteredController.icp === false
                                                }
                                            }).length}
                                        </div>
                                    </div>
                                    {unfoldList ? <div className={styles.riskRow} >
                                        {onlyIcp ? <>
                                            <div style={{ width: "532px" }} className={styles.riskFoldDescription}>
                                                <div className={styles.foldedTitle}>Beskrivning: {risk.description ? null : <div style={{ fontWeight: "400" }}>-</div>}</div>
                                                <div className={styles.foldedText}>{risk.description}</div>
                                            </div>
                                            <div style={{ width: "502px" }} className={styles.riskFoldDescription}>
                                                <div className={styles.foldedTitle}>Motivering till IKP: {risk.icpMotivation ? null : <div style={{ fontWeight: "400" }}>-</div>}</div>
                                                <div className={styles.foldedText}>{risk.icpMotivation}</div>
                                            </div>
                                            <div style={{ width: "332px" }} className={styles.riskFoldDescription}>
                                                <div className={styles.foldedTitle}>Kontroller:</div>
                                                {/* <div className={styles.foldedText}>{risk.description}</div> */}
                                                {risk.controllers.filter(ctrl => ctrl.icp).length > 0 ? risk.controllers.filter((contr) => { return contr.icp }).map((controller) => {
                                                    return <li key={controller._id} style={{ fontWeight: "400", fontSize: "11px" }}>{controller.name}</li>
                                                }) : "-"}
                                            </div>
                                        </>
                                            :
                                            <>
                                                <div style={sideBar ? { width: "542px" } : { width: "428px" }} className={styles.riskFoldDescription}>
                                                    <div className={styles.foldedTitle}>Beskrivning: {risk.description ? null : <div style={{ fontWeight: "400" }}>-</div>}</div>
                                                    <div className={styles.foldedText}>{risk.description}</div>
                                                </div>
                                                <div style={{ width: "332px" }} className={styles.riskFoldDescription}>
                                                    <div className={styles.foldedTitle}>Riskhanteringsåtgärder:</div>
                                                    {/* <div className={styles.foldedText}>{risk.description}</div> */}
                                                    {risk.controllers.filter(ctrl => !ctrl.icp).length > 0 ? risk.controllers.filter((contr) => { return !contr.icp }).map((controller) => {
                                                        return <li key={controller._id} style={{ fontWeight: "400", fontSize: "11px" }}>{controller.name}</li>
                                                    }) : "-"}
                                                </div>
                                            </>
                                        }
                                    </div>
                                        : null
                                    }
                                </div>
                            )
                        })
                    )
                }
            </div >
        </>
    );
};
export default ListRisksInPortfolio;