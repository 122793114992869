import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import axios from "axios";
import styles from "./styles/CreateUserPage.module.css";
import { PageHeaderButton, SaveButton, DeleteHeaderButton } from "../../styles/buttons"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import WarningModal from "./../../modal/WarningModal";
import LoadingModal from "./../../modal/LoadingModal";

const EditUserPage = () => {
    const { sideBar, activeUser, setSideBarActiveItem } = useContext(UserContext);
    const history = useHistory();
    const [listOfBounds, setListOfBounds] = useState({})
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showWarningResetModal, setShowWarningResetModal] = useState(false);
    const [showWarningBoundModal, setShowWarningBoundModal] = useState(false);
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [isMounted, setIsMounted] = useState(true)

    let userOrgData = { department: history.location.state.department?._id, organisation: history.location.state.organisation?._id }


    const getObjectAcceessList = async () => {
        const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });
        return res.data;
    };

    const { data: accessList, status: accessListStatus } = useQuery(["getAccessList"], getObjectAcceessList, {
        enabled: !fadeInTrigger,
        refetchOnWindowFocus: false
    });



    const readOneUser = async (email) => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/user/one", history.location.state, {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });
        if (isMounted) { setFadeInTrigger(true) }
        return res.data;
    };
    const { data, status: dataStatus, isLoading } = useQuery(["getUser"], readOneUser, {
        cacheTime: 0,
        enabled: !fadeInTrigger
    });

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({
        defaultValues: {
            organisation: userOrgData.organisation,
            department: userOrgData.department
        }
    });
    const watchOrganisation = watch("organisation")
    const watchDepartment = watch("department")

    const updateUserRequest = async (userData) => {
        const res = await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/user/update",
            { email: data.email, payload: userData },
            {
                headers: {
                    authorization: "Bearer " + activeUser.token,
                },
            }
        );
        return res;
    };
    const resetPasswordRequest = async (userData) => {
        const res = await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/user/reset-password",
            { email: data.email, payload: userData },
            { headers: { authorization: "Bearer " + activeUser.token }, }
        );
        return res;
    };

    const submitUser = async (datan) => {

        if (data.organisation !== watchOrganisation || data.department !== watchDepartment) {

            let controllersBound = await isUserBound()

            setListOfBounds(controllersBound)
            if (controllersBound.foundControllers.length > 0 || controllersBound.foundReviews.length > 0) {
                setShowWarningBoundModal(true)
            }
            else {
                let rass = await updateUserRequest(datan);
                if (rass.status === 200) history.push("/användare");
            }

        } else {
            let rass = await updateUserRequest(datan);
            if (rass.status === 200) history.push("/användare");

        }
    };

    const resetPassword = async () => {
        const resetCode = Math.random().toString(36).substring(2, 18).toUpperCase()
        let rass = await resetPasswordRequest({ registered: false, resetCode: resetCode });
        if (rass.status === 200) history.push("/användare");
    };

    const deleteUser = async () => {
        const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/user/delete-user", {
            headers: { authorization: "Bearer " + activeUser.token },
            data: { email: data.email, },
        });
        history.push("/användare");

        return res.status;
    };
    const isUserBound = async () => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/user/is-user-bound", data, {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        });

        return res.data;
    };

    const sortDropDownJustDepartments = () => {
        let initList = accessList
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt = initList.find((dep) => dep._id === activeUser.department)
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }

    const sortDropDown = () => {
        if (watchOrganisation.toString() === "") return []
        let initList = accessList.filter((org) => { return org.ancestor === watchOrganisation.toString() || org._id === watchOrganisation.toString() })
        let theList = []
        const recursiveChild = (node) => {
            if (node?.children?.length > 0) {
                for (const child of node.children) {
                    let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
                    theList.push(theChild)
                    recursiveChild(theChild)
                }
            }
        }
        let levelEtt = initList.find((dep) => dep.level === 1)
        theList.push(levelEtt)
        recursiveChild(levelEtt)
        return theList
    }

    useEffect(() => {
        setSideBarActiveItem("användare")
        return () => {
            setIsMounted(false)
        }
    }, [setSideBarActiveItem])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "organisation") { setValue("department", "") }
        })
        return () => subscription.unsubscribe();
    }, [watch, setValue]);

    return (
        // <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} `}>
        <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
            <LoadingModal open={!fadeInTrigger} />
            <WarningModal
                passedFunction={async () => {
                    let controllersBound = await isUserBound()
                    setListOfBounds(controllersBound)
                    if (controllersBound.foundControllers.length > 0 || controllersBound.foundReviews.length > 0) { setShowWarningBoundModal(true) } else {
                        deleteUser();
                    }
                }}
                warningTitle={"Vill du verkligen ta bort användaren?"}
                warningText={"Användaren kommer att raderas från systemet!"}
                optionTextOne={"Ta bort"}
                optionTextTwo={"Ångra"}
                colour={"red"}
                open={showWarningModal}
                onClose={() => {
                    setShowWarningModal(false);
                }}
            />
            <WarningModal
                passedFunction={async () => { resetPassword() }}
                warningTitle={"Vill du återställa lösenordet för den här användaren?"}
                warningText={"Användaren får ett mail om att lösenordet är återställt och en instruktion om hur hen byter till ett nytt."}
                optionTextOne={"Återställ"}
                optionTextTwo={"Ångra"}
                colour={"red"}
                open={showWarningResetModal}
                onClose={() => { setShowWarningResetModal(false); }}
            />
            <WarningModal
                passedFunction={() => { }}
                warningTitle={"Användaren är kopplad till riskarbetet!"}
                warningText={`Var god överlåt ansvar till annan användare innan du ändrar organisation, avdelningen eller tar bort den här användaren.
        Användaren är ansvarig över följande kontroller och riskhanteringsåtgärder. Du kan
        klicka på risknamnet för att ta dig vidare direkt till riskerna.`}
                optionTextOne={"OK"}
                colour={"blue"}
                open={showWarningBoundModal}
                onClose={() => { setShowWarningBoundModal(false); }}
            >
                <div style={{ marginBottom: "30px" }}>

                    {listOfBounds?.foundControllers?.map((controll, index) => {
                        return <div key={index} style={{
                            marginBottom: "3px", color: "black", textUnderlinePosition: "under",
                            fontSize: "14px", fontWeight: "600", cursor: "pointer", textDecoration: "underline"
                        }}><div onClick={() => {
                            setSideBarActiveItem("riskportfolio")
                            history.push("/show-risk", {
                                riskId: controll.riskId._id
                            })
                        }}>{controll.name} i risken {controll.riskId.name}</div></div>
                    })}

                    {listOfBounds?.foundReviews?.map((controll, index) => {
                        return <div key={index} style={{
                            marginBottom: "3px", color: "black", textUnderlinePosition: "under",
                            fontSize: "14px", fontWeight: "600", cursor: "pointer", textDecoration: "underline"
                        }}>
                            <div onClick={() => {
                                setSideBarActiveItem("internalcontrolplans")
                                history.push("/show-icp-risk", {
                                    riskId: controll.belongToIcpRisk.originalRisk._id,
                                    icpRiskId: controll.belongToIcpRisk,
                                    icpName: controll.belongToIcp.name
                                })
                            }}>{controll.name} i risken {controll.belongToIcpRisk.originalRisk.name}</div></div>
                    })}


                </div>
            </WarningModal>
            <>
                <PageTitle>Ändra i användare</PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Ångra</PageHeaderButton>
                <SaveButton icon="save" onClick={handleSubmit(submitUser)} >Spara ändringar</SaveButton>
                {(data?._id !== activeUser._id && dataStatus === "success") ?
                    <>
                        <DeleteHeaderButton icon="unlock" onClick={() => {
                            setShowWarningResetModal(true)
                        }} >Återställ lösenord</DeleteHeaderButton>
                        <DeleteHeaderButton icon="delete" onClick={() => {
                            setShowWarningModal(true)
                        }} >Ta bort användaren</DeleteHeaderButton>
                    </> : null}
            </>

            <div style={{ width: "1000px", marginTop: "40px" }}>
                {dataStatus === "success" && activeUser.admin && (data._id === activeUser._id) ?
                    (
                        <div className="bodyText">Du kan inte själv ta bort din roll som admin och inte heller byta din kopplade e-postadress. Ändringar börjar gälla efter nästa inloggning.</div>
                    ) : (dataStatus === "success" && activeUser.admin) ?
                        (<div className="bodyText">Som admin hanterar du användare för hela programmet. Ändringar börjar gälla efter nästa inloggning.</div>)
                        : (<div className="bodyText">Som objektsägare hanterar du användare för ditt egna och underliggande objekt i trädet. Ändringar börjar gälla efter nästa inloggning.</div>)
                }
            </div>

            {isLoading ? (
                null
            ) : (
                <form onSubmit={handleSubmit(submitUser)} className={styles.formContainer}>
                    {(activeUser.admin) && accessListStatus === "success" ? (
                        <>
                            <div className="selectBox">
                                <label>Organisation: </label>
                                <select {...register("organisation", { required: "Välj organisation" })} >
                                    <option value="">Välj organisation</option>
                                    {accessList.filter((obj) => obj.level === 1).map((el, index) => { return <option value={el._id} key={index}>{el.name}</option> })}
                                </select>
                            </div>
                            {watch("organisation") !== "" ?
                                <div className="selectBox">
                                    <label>Avdelning: </label>
                                    <select {...register("department", { required: "Välj avdelning" })}>
                                        <option value="">Välj avdelning</option>
                                        {sortDropDown().map((dep, index) => {
                                            return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                                        })}
                                    </select>
                                </div>
                                : null}
                        </>
                    ) : null}
                    {(!activeUser.admin && activeUser.objectManager) && accessListStatus === "success" ? (
                        <>
                            <div className="selectBox">
                                <label>Avdelning: </label>
                                <select name="department" {...register('department', { required: "Välj avdelning" })} placeholder={data.department}
                                    defaultValue={data.department}>
                                    <option value="">Välj avdelning</option>
                                    {sortDropDownJustDepartments().map((dep, index) => {
                                        return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}
                                        </option>
                                    })}
                                </select>
                            </div>
                        </>
                    ) : null}

                    <div className={styles.textInputRow}>
                        <label>E-postadress:</label>
                        <input
                            readOnly
                            name="email"
                            style={{ color: "gray", fontSize: "13px" }}
                            placeholder={data.email}
                            value={data.email}
                        // {...register({ required: "Fyll i e-postadress" })}
                        />
                    </div>
                    <div className={styles.textInputRow}>
                        <label>Förnamn:</label>
                        <input
                            name="firstName"
                            placeholder={data.firstName}
                            defaultValue={data.firstName}
                            {...register('firstName', {
                                required: "Fyll i förnamn",
                                // pattern: { value: /^[a-zåäöA-ZÅÄÖ\s]*$/, message: "Namn kan bara innehålla bokstäver" },
                                minLength: { value: 2, message: "Minst två bokstäver i förnamn" },
                                maxLength: { value: 30, message: "Max trettio bokstäver i förnamn" }
                            })}
                        />
                    </div>
                    <div className={styles.textInputRow}>
                        <label>Efternamn:</label>
                        <input
                            name="lastName"
                            placeholder={data.lastName}
                            defaultValue={data.lastName}
                            {...register('lastName', {
                                required: "Fyll i efternamn",
                                minLength: { value: 2, message: "Minst två bokstäver i efternamn" },
                                maxLength: { value: 20, message: "Max tjugo bokstäver i efternamn" }
                            })}
                        />
                    </div>
                    {activeUser.admin ?
                        <div className={styles.textInputRow}>
                            <label className={styles.superCheckBox}>
                                Admin:
                                <input disabled={(data._id === activeUser._id) || !activeUser.admin} name="admin" type="checkbox" defaultChecked={data.admin} {...register('admin')} />
                                <span className={styles.checkmark}></span>
                            </label>
                        </div>
                        : null}
                    <div className={styles.textInputRow}>
                        <label className={styles.superCheckBox}>
                            Objektsägare:
                            <input
                                name="objectManager"
                                defaultChecked={data.objectManager}
                                type="checkbox"
                                {...register('objectManager')}
                            />
                            <span className={styles.checkmark}></span>
                        </label>
                    </div>
                </form>
            )
            }
            {Object.keys(errors).length > 0 ? <p style={{ color: "red" }}>{errors[Object.keys(errors)[0].toString()].message}</p> : null}
        </div >
    );
};

export default EditUserPage;
