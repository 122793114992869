import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import styles from "./styles/CreateTodoPage.module.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { PageHeaderButton, SaveButton, DeleteHeaderButton } from "../../styles/buttons"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import LoadingModal from "../../modal/LoadingModal";
import DatePicker from "react-datepicker";
import WarningModal from "./../../modal/WarningModal";

const EditTodoPage = () => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const history = useHistory();
  const { sideBar, activeUser } = useContext(UserContext);
  let todoToEdit = history.location.state.risk
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)
  const [stopDate, setStopDate] = useState(() => todoToEdit.readyDate ? new Date(todoToEdit.readyDate) : new Date());

  const deleteTodo = async () => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/todo/delete-todo", {
      headers: { authorization: "Bearer " + activeUser.token },
      data: { id: todoToEdit._id, },
    });
    history.push("/uppgifter");

    return res.status;
  };

  const onChangeStopDate = (date) => {
    setStopDate(date);
  };

  const updateTodoRequest = async (todoData) => {
    const res = await axios.put(process.env.REACT_APP_IC_SERVICE + "/todo/update-todo", todoData, {
      headers: {
        authorization: "Bearer " + activeUser.token
      },
    });
    return res
  };

  const getObjectAcceessList = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    if (isMounted) { setFadeInTrigger(true) }
    return res.data
  };

  const { data: accessList, status: accessListStatus } = useQuery(["getAccessListTodo"], getObjectAcceessList, {});

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    defaultValues: {
      organisation: todoToEdit.organisation,
      department: todoToEdit.department._id
    }
  });
  const watchOrganisation = watch("organisation")

  const submitTodo = async (datan) => {
    let rass = await updateTodoRequest({ ...datan, readyDate: stopDate, id: todoToEdit._id });
    if (rass.data) {
      history.push("/uppgifter")
    }
  };

  const sortDropDownJustDepartments = () => {
    let initList = accessList
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep._id === activeUser.department)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  const sortDropDown = () => {
    if (watchOrganisation.toString() === "") return []
    let initList = accessList.filter((org) => { return org.ancestor === watchOrganisation.toString() || org._id === watchOrganisation.toString() })
    let theList = []
    const recursiveChild = (node) => {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          let theChild = initList?.find((dep) => dep._id.toString() === child.toString())
          theList.push(theChild)
          recursiveChild(theChild)
        }
      }
    }
    let levelEtt = initList.find((dep) => dep.level === 1)
    theList.push(levelEtt)
    recursiveChild(levelEtt)
    return theList
  }

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "organisation") { setValue("department", "") }
    })
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <WarningModal
        passedFunction={async () => {
          deleteTodo()
        }}
        warningTitle={"Vill du verkligen ta bort uppgiften?"}
        warningText={"Det går inte ångra den här processen!"}
        optionTextOne={"Ta bort"}
        optionTextTwo={"Ångra"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />
      <>
        <PageTitle>Ändra i uppgift</PageTitle>
        <PageDelimiter />
        <PageHeaderButton icon="undo" onClick={() => history.goBack()}>Ångra</PageHeaderButton>
        <SaveButton icon="save" onClick={handleSubmit(submitTodo)} >Spara ändringar</SaveButton>
        <DeleteHeaderButton icon="delete" onClick={() => {
          setShowWarningModal(true)
        }} >Ta bort uppgiften</DeleteHeaderButton>
      </>

      <form onSubmit={handleSubmit(submitTodo)} className={styles.formContainer} autoComplete="off" >

        {(activeUser.admin) && accessListStatus === "success" && accessList[0] !== null ? (
          <>
            <div className="selectBox">
              <label>Organisation: </label>
              <select {...register("organisation", { required: "Välj organisation" })} >
                <option value="">Välj organisation</option>
                {accessList.filter((obj) => obj.level === 1).map((el, index) => { return <option value={el._id} key={index}>{el.name}</option> })}
              </select>
            </div>
            {watch("organisation") !== "" ?
              <div className="selectBox">
                <label>Avdelning: </label>
                <select {...register("department", { required: "Välj avdelning" })}>
                  <option value="">Välj avdelning</option>
                  {sortDropDown().map((dep, index) => {
                    return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}</option>
                  })}
                </select>
              </div>
              : null}
          </>
        ) : null}

        {(!activeUser.admin && activeUser.objectManager) && accessListStatus === "success" && accessList[0] !== null ? (
          <>
            <div className="selectBox">
              <label>Avdelning: </label>
              <select name="department"
                defaultValue={todoToEdit.department._id}
                placeholder={todoToEdit.department._id}
                {...register('department', { required: "Välj avdelning" })}>
                <option value="">Välj avdelning</option>
                {sortDropDownJustDepartments().map((dep, index) => {
                  return <option key={index} value={dep._id} >{"- ".repeat(parseInt(dep.level - 1))}{dep.name}
                  </option>
                })}
              </select>
            </div>

          </>
        ) : null}
        <div className={styles.textInputRow}>
          <label>Benämning:</label>
          <input name="name"
            placeholder={todoToEdit?.name} defaultValue={todoToEdit?.name}
            {...register('name', {
              required: "Fyll i benämning",
              minLength: { value: 2, message: "Minst två bokstäver i namn" },
              maxLength: { value: 70, message: "Max 50 bokstäver i namn" }
            })} />
        </div>
        <div className={styles.textInputRow}>
          <label>Beskrivning: </label>
          <textarea
            placeholder={todoToEdit?.description} defaultValue={todoToEdit?.description}
            name="description"
            {...register('description')}
            type="textarea"
            className={styles.createPortfolioItem}
            required
          />
        </div>

        <div className={styles.textInputRow}>
          <label style={{ fontSize: "14px" }}>Slutdatum: </label>
          <div>
            <DatePicker required selected={stopDate} onChange={onChangeStopDate} className="pickerClass" />
          </div>
        </div>
      </form>
      {Object.keys(errors).length > 0 ? <p style={{ color: "red" }}>{errors[Object.keys(errors)[0].toString()].message}</p> : null}
    </div >
  );
};

export default EditTodoPage;