import React, { useContext, useEffect } from "react"
import { UserContext } from "../../../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import axios from "axios"

import Tooltip from "./../../../../components/tooltip/Tooltip";

// import LoadingModal from "./../../../../modal/LoadingModal";
import styles from './styles/RenderNewRisksList.module.css'
import { useForm, useFieldArray } from "react-hook-form";

export default function RenderGroupRisksList({ groupName, settings, accessList, risksInGroupList }) {
  const { activeUser } = useContext(UserContext)

  const history = useHistory()
  const { control, handleSubmit, setValue, getValues, watch, formState } = useForm({ defaultValues: { groupRisks: risksInGroupList } });
  // const { register, control, handleSubmit, setValue, getValues, watch, formState } = useForm({ defaultValues: { groupRisks: risksInGroupList } });
  const { fields } = useFieldArray({ control, name: "groupRisks" });

  const onSubmit = async (data) => {

    const result = await axios.post(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/create-risks-to-group", data, {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    if (result.status === 200) history.goBack()
    return result
  }
  // const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      var arr = name.split(".")
      let index = arr[1]
      let whatFieldIsThis = arr[2]
      if (whatFieldIsThis === "category") {
        let GETVAL2 = getValues(`groupRisks.${index}.subCategory`)
        if (GETVAL2 !== "Ej definierad process") { setValue(`groupRisks.${index}.subCategory`, "Ej definierad delprocess") }
      } else if (whatFieldIsThis === "organisation") {
        let GETVAL2 = getValues(`groupRisks.${index}.department`)
        if (GETVAL2 !== "") { setValue(`groupRisks.${index}.department`, "") }
      }
    })
    return () => subscription.unsubscribe();
  }, [watch, getValues, setValue])

  useEffect(() => {
    // console.log("risksInGroupList data refetch changed !!!!!")
    setValue("groupRisks", risksInGroupList)
  }, [risksInGroupList, setValue])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => {
          return (
            <div key={item.id}>
              <div className={styles.newRiskListContainer} >
                <div className={styles.searchInputRow}>

                  <div className={styles.riskRowItemLink} style={{ textUnderlinePosition: "under", textDecoration: "underline" }} onClick={() => {
                    // <div className={styles.riskRowItemLink} style={unfoldList ? { fontWeight: "600" } : null} onClick={() => {

                    history.push("/show-risk", { riskId: item._id, onlyIcp: false, mirrorIcp: false })

                  }}>

                    {item.name.length > 21 ?
                      <Tooltip content={item.name} direction={"right"}>{item.name.slice(0, 21)}...</Tooltip>
                      : item.name
                    }
                  </div>
                  {/* <input
                    defaultValue={"Namn på risk"}
                    {...register(`groupRisks.${index}.name`, { minLength: { value: 2, message: "Minst två bokstäver i förnamn" } })}
                  /> */}
                </div>
                <div className={styles.selectBox}>
                  {/* <select
                    {...register(`groupRisks.${index}.category`)}>
                    <option value="Ej definierad process" >Välj huvudprocess</option>
                    {settings?.categories?.map((cat, index) => {
                      return <option value={cat.main} key={index + "category"}>{cat.main}</option>
                    })}
                  </select> */}
                  {/* <select disabled > */}
                  <option>{item.category}</option>
                  {/* </select> */}
                </div>
                <div className={styles.selectBox}>
                  {/* <select
                    {...register(`groupRisks.${index}.subCategory`)}>
                    <option value="Ej definierad delprocess" >Välj delprocess</option>
                    {watch(`groupRisks.${index}.category`) !== "Ej definierad process" ? settings?.categories
                      .find((cat) => cat.main === watch(`groupRisks.${index}.category`))
                      .children.map((cato, index) => {
                        return <option value={cato} key={index}>{cato}</option>
                      }) : null}
                  </select> */}
                  {/* <select disabled > */}
                  <option>{item.subCategory}</option>
                  {/* </select> */}
                </div>
                <div className={styles.selectBox}>
                  {/* <select defaultValue={"Ej definierad kategori"} {...register(`groupRisks.${index}.process`)} >
                    <option value="Ej definierad kategori" >Välj kategori</option>
                    {settings?.processes?.map((cat, index) => {
                      return <option value={cat} key={index + "process"}>{cat}</option>
                    })}
                  </select> */}
                  {/* <select disabled > */}
                  <option>{item.process}</option>
                  {/* </select> */}
                </div>
                {/* <div className={styles.selectBox}>
                  <select disabled {...register(`groupRisks.${index}.organisation`, { required: { value: true, message: "Ange organisation" } })} >
                    <option value="" >Välj organisation</option>
                    {accessList?.filter((org) => { return org.level === 1 }).map((dep, index) => {
                      return <option key={index + "organisation"} value={dep._id} >{dep.name}</option>
                    })}
                  </select>
                </div> */}
                <div className={styles.selectBox}>
                  {/* <select disabled > */}
                  <option>{accessList.find(risken => risken._id === item.organisation).name}</option>
                  {/* </select> */}
                </div>
                <div className={styles.selectBox}>
                  {/* <select disabled > */}
                  {/* risk.department?.name.length > 26 ? risk.department?.name.slice(0, 26) + "..." : risk.department?.name */}
                  {/* <option>{accessList.find(risken => risken._id === item.department).name.slice(0, 5)}</option> */}
                  <option>{accessList.find(risken => risken._id === item.department).name.length > 20 ? accessList.find(risken => risken._id === item.department).name.slice(0, 20) + "..." : accessList.find(risken => risken._id === item.department).name}</option>
                  {/* </select> */}
                </div>
                <div className={styles.selectBox}>
                  {item.probability}
                  {/* <input type="number" min="1"
                  max={settings.coso.size} {...register(`groupRisks.${index}.probability`, {
                    valueAsNumber: true,
                    min: 1,
                    max: settings.coso.size
                  })} /> */}
                </div>
                <div className={styles.selectBox}>
                  {item.consequence}
                  {/* <input type="number" min="1"
                  max={settings.coso.size} {...register(`groupRisks.${index}.consequence`, {
                    valueAsNumber: true,
                    min: 1,
                    max: settings.coso.size
                  })} /> */}
                </div>
                {/* <i onClick={() => remove(index)} style={{ cursor: "pointer", color: "red", fontSize: "14px", marginTop: "4px" }} className={"fas fa-trash-alt"} ></i> */}
              </div>

              {formState.errors?.groupRisks?.hasOwnProperty(index) && formState?.errors?.groupRisks[index] ? <div className={styles.riskEntryError}>{formState?.errors?.groupRisks[index][Object.keys(formState?.errors?.groupRisks[index])[0]]?.message} </div> : null}
              {/* </div> */}
              {/* <div className={styles.riskEntryError}>
              {formState.errors?.groupRisks?.hasOwnProperty(index) && formState?.errors?.groupRisks[index] ? formState?.errors?.groupRisks[index][Object.keys(formState?.errors?.groupRisks[index])[0]]?.message : null}
            </div> */}
            </div>
          );
        })}
      </form >
      <div style={{ marginTop: "40px" }}>
      </div>
    </div>
  );
}