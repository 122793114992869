import React, { useState, useEffect } from "react";
import "./select-period-style.css";
import DatePicker from "react-datepicker";

const SelectedPeriod = (props) => {
  const [periodTypeYear, setPeriodTypeYear] = useState(true);
  const [startDate, setStartDate] = useState(!props.orgDoc ? new Date() : Date.parse(props.orgDoc.startDate));
  const [stopDate, setStopDate] = useState(!props.orgDoc ? new Date() : Date.parse(props.orgDoc.stopDate));
  const [orgYear, setOrgYear] = useState();
  const onChangeStartDate = (date) => {
    setStartDate(date);
    props.startDate(date);
  };

  const onChangeStopDate = (date) => {
    setStopDate(date);
    props.stopDate(date);
  };

  const onChangePeriodType = () => {
    setPeriodTypeYear(!periodTypeYear);
    props.periodTypeYear(!periodTypeYear);
  };

  const onChangeYear = (e) => {
    props.year(e.target.value);
    setOrgYear(e.target.value);
  };

  useEffect(() => {
    setOrgYear(props.orgY);

    if (props.orgY) {
      setPeriodTypeYear(true);
    } else {
      setPeriodTypeYear(false);
    }
  }, [props.orgY]);

  return (
    <div>
      {!props.orgDoc ? <label style={{ fontSize: "14px" }}>Period: </label> : null}
      <div className="mt-2">
        {!props.orgDoc ? (
          <div className="tt">
            <input
              type="radio"
              className=" radio-inline radio3"
              id="materialUnchecked"
              name="materialExampleRadios"
              onChange={onChangePeriodType}
              checked={!periodTypeYear}
            ></input>
            <label style={{ fontSize: "14px" }} className="form-check-label radio-inline pushRight" htmlFor="materialUnchecked">
              Del av år
            </label>
            <input
              type="radio"
              className="form-check-input radio-inline radio3"
              id="materialChecked"
              name="materialExampleRadios"
              onChange={onChangePeriodType}
              checked={periodTypeYear}
            ></input>
            <label style={{ fontSize: "14px" }} className="form-check-label radio-inline" htmlFor="materialChecked">
              Helår
            </label>
            <br></br>
            <br></br>
          </div>
        ) : null}

        {!periodTypeYear ? (
          <div className="rt">
            <div className="rtt mt-3">
              <div className="form-group">
                <label style={{ fontSize: "14px" }}>Startdatum: </label>
                <div>
                  <DatePicker selected={startDate} onChange={onChangeStartDate} className="pickerClass" />
                </div>
              </div>
            </div>
            <div className="rtt">
              <div className="form-group">
                <label style={{ fontSize: "14px" }}>Slutdatum: </label>
                <div>
                  <DatePicker
                    required
                    selected={stopDate}
                    onChange={onChangeStopDate}
                    className="pickerClass"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="form-group mt-2 mb-5">
            <label style={{ fontSize: "14px", marginRight: "5px" }}>Välj år: </label>
            <select
              style={{
                width: "20px",
                padding: "2px"
              }}
              value={orgYear}
              required
              className="selectBoxes"
              onChange={onChangeYear}
            >
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
              <option>2023</option>
              <option>2024</option>
              <option>2025</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedPeriod;
