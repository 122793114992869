import { jsPDF } from 'jspdf'

import calculateRiskGroup from '../calculateRiskGroup'
import frequencyOverdue from '../frequencyOverdue'
import getCosoImage from './getCosoImage'

import { kommunresursLogo } from './logotypes/kommunresursLogo'
import { ltnbdLogo } from './logotypes/ltnbdLogo'
import { calendarImageRed } from './calendarImageRed'
import { briefcaseImage } from './briefcaseImage'
import { statusImageWhite } from './statusImages/statusImageWhite'
import { statusImageGreen } from './statusImages/statusImageGreen'
import { statusImageYellow } from './statusImages/statusImageYellow'
import { statusImageRed } from './statusImages/statusImageRed'

const fontName = 'helvetica'
let fileName = null

const px5mm = 1.322917
const px10mm = 2.645833
const px12mm = 3.175
const px700mm = 185.208333

export const exportOneRisk = (data, settings, settingsData, customer, reviewsList, icpRiskData) => {
    if (!data || !settings) return null
    const riskData = data[0]

    // eslint-disable-next-line
    const printSettings = (pdf, text) => {
        // console.log('getFontList', pdf.getFontList())
        const fontName = `FontName: ${pdf.getFont().fontName}`
        const fontStyle = `FontStyle: ${pdf.getFont().fontStyle}`
        const fontSize = `FontSize: ${pdf.getFontSize()}`
        const drawColor = `DrawColor: ${pdf.getDrawColor()}`
        const fillColor = `FillColor: ${pdf.getFillColor()}`
        const lineWidth = `LineWidth: ${pdf.getLineWidth()}`
        const numberOfPages = `NumberOfPages: ${pdf.getNumberOfPages()}`

        const message = `
        printSettings: ${text}
        ${fontName}, ${fontStyle},
        ${fontSize},
        ${drawColor}, ${fillColor},
        ${lineWidth},
        ${numberOfPages}
        `

        console.log(message)
    }

    // eslint-disable-next-line
    const printCurrentSizes = (pdf, x, y, w) => {
        const currentSizes = `x: ${x}, y: ${y}, w: ${w}`
        console.log('currentSizes: ' + currentSizes)
    }

    const createPageHeader = () => {
        if (customer === 'ltnbd') {
            pdf.addImage(ltnbdLogo, 'PNG', x - 2, y + 3, 23, 15)
        } else {
            pdf.addImage(kommunresursLogo, 'JPG', x - 2, y + 3, 23, 15)
        }
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(8)
        pdf.text(x + 150, y + 17, 'Rapport uttagen ' + pdf.getCreationDate().slice(2, 10).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'))
    }

    const createPageFooter = () => {
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(8)

        const numberOfPages = pdf.getNumberOfPages()

        let offsetTotalPage = 0
        if (numberOfPages > 9) {
            offsetTotalPage = 3
        } else if (numberOfPages > 99) {
            offsetTotalPage = 6
        }

        for (let page = 0; page < numberOfPages; page++) {
            pdf.setPage(page)
            let offsetCurrentPage = 0
            if (page > 9) {
                offsetCurrentPage = 3
            } else if (page > 99) {
                offsetCurrentPage = 6
            }
            let totalOffset = offsetTotalPage + offsetCurrentPage
            pdf.text((x + 165 - totalOffset), y + 270, 'Rapportsida ' + pdf.getCurrentPageInfo().pageNumber + ' / ' + numberOfPages)
        }
    }

    const createNewPage = (type) => {
        pdf.addPage()

        x = 10
        y = 10
        createPageHeader()
    }

    const computeMultipleLines = (str, maxSize) => {
        let lineArray = []

        if (str.length <= maxSize) {
            lineArray.push(str)
            return lineArray
        }
        let strArray = str.split(/\s+/)

        let currentLine = null
        let newLine = null
        for (const word of strArray) {
            let cutWord = word.length > 35 ? word.slice(0, 32) + "..." : word
            currentLine = newLine
            if (newLine) {
                newLine = newLine.concat(" ", cutWord)
            } else {
                newLine = cutWord
            }

            if (newLine?.length > maxSize) {
                lineArray.push(currentLine)
                newLine = cutWord
            }
        }

        lineArray.push(newLine)
        return lineArray
    }

    const createCosoDiagram = (pdf, x, y, type) => {
        const cosoImage = getCosoImage(settingsData.coso.size, riskData.probability, riskData.consequence)
        if (cosoImage.imageData) {
            if (type === 'rp') {
                pdf.addImage(cosoImage.imageData, 'PNG', x + 130, y + 18, cosoImage.imageSize, cosoImage.imageSize)
            }
            if (type === 'icp') {
                pdf.addImage(cosoImage.imageData, 'PNG', x + 130, y + 28, cosoImage.imageSize, cosoImage.imageSize)
            }
        }
    }

    const createRiskInfoCard = (pdf, x, y, w, type) => {
        createCosoDiagram(pdf, x, y, type)

        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(16.0)

        if (type === 'rp') {
            x += 6.3
            y += 12
            pdf.text(x, y, riskData.name)
        }

        if (type === 'icp') {
            y += 7.5
            if (settings.active) {
                pdf.setDrawColor(20, 106, 114)
                pdf.setFillColor(20, 106, 114)
            } else {
                pdf.setDrawColor(78, 123, 184)
                pdf.setFillColor(78, 123, 184)
            }
            pdf.setTextColor(255, 255, 255)
            pdf.roundedRect(x, y - 7, w, 12.5, px5mm, px5mm, 'FD')

            pdf.addImage(briefcaseImage, 'PNG', x + 6.7, y - 5.5, 7.1, 7.1)
            pdf.text(x + 22, y, settings.title)

            pdf.setTextColor(40, 40, 40)
            x += 6.3
            y += 14
            pdf.text(x, y, riskData.name)
        }

        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(11.5)
        y += 14
        const organisationAndDepartment = `${riskData.organisation?.name} - ${riskData.department?.name}`
        pdf.text(x, y, organisationAndDepartment)
        y += 5.4
        const mainProcess = `Huvudprocess: ${riskData.category}`
        pdf.text(x, y, mainProcess)
        y += 5.4
        const subProcess = `Delprocess: ${riskData.subCategory}`
        pdf.text(x, y, subProcess)
        y += 5.4
        const category = `Kategori: ${riskData.process}`
        pdf.text(x, y, category)
        if (riskData.createdBy) {
            y += 5.4
            const createdBy = `Skapad av: ${riskData.createdBy?.firstName} ${riskData.createdBy.lastName}`
            pdf.text(x, y, createdBy)
        }
        y += 5.4
        const createdDate = `Skapad: ${riskData.createdAt.slice(0, 10)}`
        pdf.text(x, y, createdDate)

        if (settings.extended) {
            pdf.setFont(fontName, 'bold')
            y += 29
            const riskValue = `Riskvärde: ${riskData.probability * riskData.consequence}`
            pdf.text(x, y, riskValue)
            y += 5.4
            const group = calculateRiskGroup(riskData.probability, riskData.consequence, settingsData?.riskGroupDefinitions, settingsData?.coso).group
            const rgd = calculateRiskGroup(riskData.probability, riskData.consequence, settingsData?.riskGroupDefinitions, settingsData?.coso).rgd
            const riskValueGroup = `Riskvärdesgrupp: ${group}`
            pdf.text(x, y, riskValueGroup)
            pdf.setFont(fontName, 'normal')
            y += 5.4
            pdf.text(x, y, rgd)

            pdf.setFont(fontName, 'bold')
            y += 10
            pdf.text(x, y, 'Beskrivning:')
            pdf.setFont(fontName, 'normal')
            pdf.setFontSize(10)
            y += 4.5
            if (riskData.description) {
                const descriptionLines = computeMultipleLines(riskData.description, 100)
                for (let line = 0; line < descriptionLines?.length; line++) {
                    if (y + 4 + 10 > maxPageHeight) {
                        if (type === 'rp') pdf.setDrawColor(78, 123, 184)
                        if (type === 'icp') {
                            if (settings.active) {
                                pdf.setDrawColor(20, 106, 114)
                            } else {
                                pdf.setDrawColor(78, 123, 184)
                            }
                        }
                        pdf.setFillColor(255, 255, 255)
                        pdf.setLineWidth(px5mm)
                        pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                        createNewPage()
                        pdf.setFont(fontName, 'normal')
                        pdf.setFontSize(10)
                        y = 50
                    }
                    pdf.text(x, y, descriptionLines[line])
                    y += 4
                }
            } else {
                pdf.text(x, y, '')
            }

            pdf.setFont(fontName, 'bold')
            pdf.setFontSize(11.5)
            y += 10
            pdf.text(x, y, `Sannolikhet: ${riskData.probability?.toString() || '-'}`)
            pdf.text(x + 90, y, `Konsekvens: ${riskData.consequence?.toString() || '-'}`)
            pdf.setFont(fontName, 'normal')
            pdf.setFontSize(10)
            y += 4.5
            const maxSize = 50
            const probabilityLines = computeMultipleLines(riskData.probabilityMotivation, maxSize)
            const consequenceLines = computeMultipleLines(riskData.consequenceMotivation, maxSize)
            const p_len = probabilityLines?.length
            const c_len = consequenceLines?.length
            const numLines = Math.max(p_len, c_len)
            for (let line = 0; line < numLines; line++) {
                if (y + 4 + 10 > maxPageHeight) {
                    if (type === 'rp') pdf.setDrawColor(78, 123, 184)
                    if (type === 'icp') {
                        if (settings.active) {
                            pdf.setDrawColor(20, 106, 114)
                        } else {
                            pdf.setDrawColor(78, 123, 184)
                        }
                    }
                    pdf.setFillColor(255, 255, 255)
                    pdf.setLineWidth(px5mm)
                    pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                    createNewPage()
                    pdf.setFont(fontName, 'normal')
                    pdf.setFontSize(10)
                    y = 10
                }
                if (probabilityLines[line]) pdf.text(x, y, probabilityLines[line])
                if (consequenceLines[line]) pdf.text(x + 90, y, consequenceLines[line])
                y += 4
            }

            if (y + 16 + 1 + 4 > maxPageHeight) {
                if (type === 'rp') pdf.setDrawColor(78, 123, 184)
                if (type === 'icp') {
                    if (settings.active) {
                        pdf.setDrawColor(20, 106, 114)
                    } else {
                        pdf.setDrawColor(78, 123, 184)
                    }
                }
                pdf.setFillColor(255, 255, 255)
                pdf.setLineWidth(px5mm)
                pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                createNewPage()
                pdf.setFont(fontName, 'normal')
                pdf.setFontSize(10)
                y = 35
            }

            pdf.setFont(fontName, 'bold')
            pdf.setFontSize(11.5)
            y += 16
            pdf.text(x, y, 'Bifogade filer:')
            pdf.setFont(fontName, 'normal')
            y += 1
            if (riskData.files?.length > 0) {
                pdf.setFontSize(10)
                for (const file of riskData.files) {
                    if (y + 4 > maxPageHeight) {
                        if (type === 'rp') pdf.setDrawColor(78, 123, 184)
                        if (type === 'icp') {
                            if (settings.active) {
                                pdf.setDrawColor(20, 106, 114)
                            } else {
                                pdf.setDrawColor(78, 123, 184)
                            }
                        }
                        pdf.setFillColor(255, 255, 255)
                        pdf.setLineWidth(px5mm)
                        pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                        createNewPage()
                        pdf.setFont(fontName, 'normal')
                        pdf.setFontSize(10)
                        y = 50
                    }
                    y += 4
                    const fileName = file.slice(file.lastIndexOf("/") + 1)
                    pdf.text(x, y, fileName)
                }
                pdf.setFontSize(11.5)
            } else {
                pdf.setFontSize(10)
                y += 5.4
                pdf.text(x, y, 'Det finns inga bifogade filer.')
            }

            if (type === 'rp') pdf.setDrawColor(78, 123, 184)
            if (type === 'icp') {
                if (settings.active) {
                    pdf.setDrawColor(20, 106, 114)
                } else {
                    pdf.setDrawColor(78, 123, 184)
                }
            }
            pdf.setFillColor(255, 255, 255)
            pdf.setLineWidth(px5mm)
            pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
        } else {
            if (type === 'rp') pdf.setDrawColor(78, 123, 184)
            if (type === 'icp') {
                if (settings.active) {
                    pdf.setDrawColor(20, 106, 114)
                } else {
                    pdf.setDrawColor(78, 123, 184)
                }
            }
            pdf.setFillColor(255, 255, 255)
            pdf.setLineWidth(px5mm)
            pdf.roundedRect(10, 40, w, y - 10, px10mm, px10mm, 'S')
        }
    }

    const createListControllers = (pdf, x, y, w) => {
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(16.0)
        x += 6.3
        y += 12
        pdf.text(x, y, 'Riskhanteringsåtgärder')

        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(9.5)
        if (riskData.controllers?.length > 0) {
            y += 4
        } else {
            y += 12
            pdf.text(x, y, 'Det finns inga riskhanteringsåtgärder till den här risken!')
        }

        for (const controller of riskData.controllers) {
            if (y + 10 > maxPageHeight) {
                pdf.setDrawColor(78, 123, 184)
                pdf.setFillColor(255, 255, 255)
                pdf.setLineWidth(px5mm)
                pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                createNewPage()
                pdf.setFontSize(9.5)
                y = 45
            }
            if (!controller.icp) {
                y += 10
                pdf.setDrawColor(105, 152, 218)
                pdf.setFillColor(105, 152, 218)
                pdf.setTextColor(255, 255, 255)
                pdf.roundedRect(x, y - 4, w - 12, 6.5, px5mm, px5mm, 'FD')

                let controllerName = ''
                if (controller.name.length > 23) {
                    controllerName = controller.name.slice(0, 23)
                } else {
                    controllerName = controller.name
                }
                pdf.text(x + 3.5, y, controllerName)
                const firstName = controller.assignedUserId?.firstName
                const lastName = controller.assignedUserId?.lastName
                const assignedUser = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
                pdf.text(x + 50, y, assignedUser)
                pdf.text(x + 93, y, controller.method)
                pdf.text(x + 127, y, controller.frequency)
                pdf.text(x + 155, y, controller.deviationReports?.length?.toString() || '')
                if (frequencyOverdue(controller.verifyChecks, controller.frequency, controller.createdAt)) {
                    pdf.addImage(calendarImageRed, 'PNG', x + 166, y - 2.5, px12mm, px12mm)
                }
            }
        }

        pdf.setDrawColor(78, 123, 184)
        pdf.setFillColor(255, 255, 255)
        pdf.setTextColor(40, 40, 40)
        pdf.setLineWidth(px5mm)
        pdf.roundedRect(10, 40, w, y - 28, px10mm, px10mm, 'S')
    }

    const createIcpInfoCard = (pdf, x, y, w, type) => {
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(16.0)
        x += 6.3
        y += 12
        pdf.text(x, y, `Internkontrollplan för ${riskData.name}`)
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(11.5)
        y += 8
        pdf.text(x, y, `Tillagd i internkontrollplanen av: ${riskData.icpBy || ''}`)
        y += 6.3
        pdf.text(x, y, `Tillagd: ${riskData.icpDate.slice(0, 10)}`)
        y += 12
        pdf.text(x, y, 'Beskrivning:')
        y += 6.3
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(10.2)
        if (riskData.icpMotivation) {
            const motivationLines = computeMultipleLines(riskData.icpMotivation, 100)
            for (let line = 0; line < motivationLines?.length; line++) {
                if (y + 4 + 10 > maxPageHeight) {
                    if (type === 'rp') pdf.setDrawColor(78, 123, 184)
                    if (type === 'icp') {
                        if (settings.active) {
                            pdf.setDrawColor(20, 106, 114)
                        } else {
                            pdf.setDrawColor(78, 123, 184)
                        }
                    }
                    pdf.setFillColor(255, 255, 255)
                    pdf.setLineWidth(px5mm)
                    pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                    createNewPage()
                    pdf.setFont(fontName, 'normal')
                    pdf.setFontSize(10)
                    y = 50
                }
                pdf.text(x + 1.15, y, motivationLines[line])
                y += 4
            }
        } else {
            pdf.setTextColor(128, 128, 128)
            pdf.text(x + 1.15, y, 'Här beskriver du varför risken ska läggas till i internkontrollplanen.')
        }

        pdf.setDrawColor(20, 106, 114)
        pdf.setFillColor(255, 255, 255)
        pdf.setTextColor(40, 40, 40)
        pdf.setLineWidth(px5mm)
        pdf.roundedRect(10, 40, w, y - 25, px10mm, px10mm, 'S')
    }

    const createIcpInfoIcpRiskCard = (pdf, x, y, w) => {
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(11.5)
        x += 6.3
        y += 10
        pdf.text(x, y, `Tillagd i internkontrollplanen av: ${riskData.icpBy || ''}`)
        y += 5.4
        pdf.text(x, y, `Tillagd: ${riskData.icpDate.slice(0, 10)}`)
        y += 8
        pdf.text(x, y, 'Motivering till internkontrollplan:')
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(10)
        y += 4.5
        if (riskData.icpMotivation) {
            const motivationLines = computeMultipleLines(riskData.icpMotivation, 20)
            for (let line = 0; line < motivationLines?.length; line++) {
                if (y + 4 + 10 > maxPageHeight) {
                    pdf.setDrawColor(20, 106, 114)
                    pdf.setFillColor(255, 255, 255)
                    pdf.setLineWidth(px5mm)
                    pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                    createNewPage()
                    pdf.setFont(fontName, 'normal')
                    pdf.setFontSize(10)
                    y = 50
                }
                pdf.text(x, y, motivationLines[line])
                y += 4
            }

        } else {
            pdf.text(x, y, '')
        }

        pdf.setDrawColor(20, 106, 114)
        pdf.setFillColor(255, 255, 255)
        pdf.setTextColor(40, 40, 40)
        pdf.setLineWidth(px5mm)
        pdf.roundedRect(10, 40, w, y - 25, px10mm, px10mm, 'S')
    }

    const createReportAndSuggestIcpCard = (pdf, x, y, w) => {
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(16.0)
        x += 6.3
        y += 12
        pdf.text(x, y, 'Avrapportering')

        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(11.5)
        y += 10
        // pdf.text(x + 35, y, 'Ej rapporterad')
        // pdf.text(x + 85, y, 'Klar')
        // pdf.text(x + 115, y, 'Avvaktande')
        // pdf.text(x + 155, y, 'Varningar')
        pdf.text(x, y, 'Status: ')
        if (icpRiskData?.status === 0) { // white
            pdf.text(x + 27, y, 'Ej rapporterad')
            pdf.addImage(statusImageWhite, 'PNG', x + 18, y - 4)
        }
        else if (icpRiskData?.status === 1) { // green
            pdf.text(x + 27, y, 'Klar')
            pdf.addImage(statusImageGreen, 'PNG', x + 18, y - 4)
        }
        else if (icpRiskData?.status === 2) { // yellow
            pdf.text(x + 27, y, 'Avvaktande')
            pdf.addImage(statusImageYellow, 'PNG', x + 18, y - 4)
        }
        else if (icpRiskData?.status === 3) { // red
            pdf.text(x + 27, y, 'Varningar')
            pdf.addImage(statusImageRed, 'PNG', x + 18, y - 4)
        } else {
            pdf.text(x + 27, y, 'Ej rapporterad')
            pdf.addImage(statusImageWhite, 'PNG', x + 18, y - 4)
        }

        y += 10
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(11.5)
        pdf.text(x, y, 'Utfall: ')
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(10)
        y += 6.3
        if (icpRiskData?.result) {
            const resultLines = computeMultipleLines(icpRiskData.result, 100)
            for (let line = 0; line < resultLines?.length; line++) {
                if (y + 4 + 10 > maxPageHeight) {
                    pdf.setDrawColor(20, 106, 114)
                    pdf.setFillColor(255, 255, 255)
                    pdf.setLineWidth(px5mm)
                    pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                    createNewPage()
                    pdf.setFont(fontName, 'normal')
                    pdf.setFontSize(10)
                    y = 50
                }
                pdf.text(x, y, resultLines[line])
                y += 4
            }
        } else {
            pdf.text(x, y, 'Här sammanfattar du utfallet av arbetet med risken.')
        }
        y += 14
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(11.5)
        pdf.text(x, y, 'Åtgärd: ')
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(10)
        y += 6.3
        if (icpRiskData?.latestTodo) {
            pdf.text(x, y, `(uppgift skapad: ${icpRiskData.latestTodo?.slice(0, 10)})`)
            y += 5.4
            const suggestionLines = computeMultipleLines(icpRiskData.suggestion, 100)
            for (let line = 0; line < suggestionLines?.length; line++) {
                if (y + 4 + 10 > maxPageHeight) {
                    pdf.setDrawColor(20, 106, 114)
                    pdf.setFillColor(255, 255, 255)
                    pdf.setLineWidth(px5mm)
                    pdf.roundedRect(10, 40, w, y - 30, px10mm, px10mm, 'S')
                    createNewPage()
                    pdf.setFont(fontName, 'normal')
                    pdf.setFontSize(10)
                    y = 50
                }
                pdf.text(x, y, suggestionLines[line])
                y += 4
            }
        } else {
            pdf.text(x, y, 'Finns det några förslag på förbättringar eller åtgärder så anger du dem här.')
        }

        pdf.setDrawColor(20, 106, 114)
        pdf.setFillColor(255, 255, 255)
        pdf.setTextColor(40, 40, 40)
        pdf.setLineWidth(px5mm)
        pdf.roundedRect(10, 40, w, y - 25, px10mm, px10mm, 'S')
    }

    const createListReviews = (pdf, x, y, w, type) => {
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(16.0)
        x += 6.3
        y += 12
        pdf.text(x, y, `Kontroller`)

        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(9.5)
        if (riskData.controllers?.length > 0) {
            y += 4
        } else {
            y += 12
            pdf.text(x, y, 'Det finns inga kontroller till den här risken!')
        }

        for (const controller of riskData.controllers) {
            if (y + 10 > maxPageHeight) {
                pdf.setDrawColor(20, 106, 114)
                pdf.setFillColor(255, 255, 255)
                pdf.setTextColor(40, 40, 40)
                pdf.setLineWidth(px5mm)
                createNewPage()
                pdf.setFontSize(9.5)
                y = 45
            }
            if (controller.icp) {
                y += 10
                pdf.setDrawColor(27, 127, 136)
                pdf.setFillColor(27, 127, 136)
                pdf.setTextColor(255, 255, 255)
                pdf.roundedRect(x, y - 4, w - 12, 6.5, px5mm, px5mm, 'FD')

                let controllerName = ''
                if (controller.name.length > 23) {
                    controllerName = controller.name.slice(0, 23)
                } else {
                    controllerName = controller.name
                }
                pdf.text(x + 3.5, y, controllerName)
                const firstName = controller.assignedUserId?.firstName
                const lastName = controller.assignedUserId?.lastName
                const assignedUser = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
                pdf.text(x + 50, y, assignedUser)
                pdf.text(x + 100, y, controller.method)
                pdf.text(x + 142, y, controller.frequency)
            }
        }

        pdf.setDrawColor(20, 106, 114)
        pdf.setFillColor(255, 255, 255)
        pdf.setTextColor(40, 40, 40)
        pdf.setLineWidth(px5mm)
        pdf.roundedRect(10, 40, w, y - 28, px10mm, px10mm, 'S')
    }

    const createListIcpRiskReviews = (pdf, x, y, w) => {
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(16.0)
        x += 6.3
        y += 12
        pdf.text(x, y, `Kontroller`)

        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(9.5)
        if (reviewsList?.length > 0) {
            y += 4
        } else {
            y += 12
            pdf.text(x, y, 'Det finns inga kontroller till den här risken!')
        }

        for (const controller of reviewsList) {
            if (y + 10 > maxPageHeight) {
                pdf.setDrawColor(20, 106, 114)
                pdf.setFillColor(255, 255, 255)
                pdf.setTextColor(40, 40, 40)
                pdf.setLineWidth(px5mm)
                pdf.roundedRect(10, 40, w, y - 28, px10mm, px10mm, 'S')
                createNewPage()
                pdf.setFontSize(9.5)
                y = 45
            }
            y += 10
            pdf.setDrawColor(27, 127, 136)
            pdf.setFillColor(27, 127, 136)
            pdf.setTextColor(255, 255, 255)
            pdf.roundedRect(x, y - 4, w - 12, 6.5, px5mm, px5mm, 'FD')

            let controllerName = ''
            if (controller.name.length > 23) {
                controllerName = controller.name.slice(0, 23)
            } else {
                controllerName = controller.name
            }
            pdf.text(x + 3.5, y, controllerName)
            const firstName = controller.assignedUserId?.firstName
            const lastName = controller.assignedUserId?.lastName
            const assignedUser = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
            pdf.text(x + 50, y, assignedUser)
            pdf.text(x + 93, y, controller.method)
            pdf.text(x + 127, y, controller.frequency)
            pdf.text(x + 155, y, controller.deviationReports?.length?.toString() || '')
            if (frequencyOverdue(controller.verifyChecks, controller.frequency, controller.createdAt)) {
                pdf.addImage(calendarImageRed, 'PNG', x + 166, y - 2.5, px12mm, px12mm)
            }
        }

        pdf.setDrawColor(20, 106, 114)
        pdf.setFillColor(255, 255, 255)
        pdf.setTextColor(40, 40, 40)
        pdf.setLineWidth(px5mm)
        pdf.roundedRect(10, 40, w, y - 28, px10mm, px10mm, 'S')
    }

    const createOneRpRisk = (pdf, x, y, w) => {
        x = 10
        y = 40
        w = px700mm
        createRiskInfoCard(pdf, x, y, w, 'rp')

        createNewPage()
        x = 10
        y = 40
        w = px700mm
        createListControllers(pdf, x, y, w)

        if (riskData.icp) {
            createNewPage()
            x = 10
            y = 40
            w = px700mm
            createIcpInfoCard(pdf, x, y, w, 'rp')

            createNewPage()
            x = 10
            y = 40
            w = px700mm
            createListReviews(pdf, x, y, w, 'rp')
        }
    }

    const createOneIcpRisk = (pdf, x, y, w) => {
        x = 10
        y = 40
        w = px700mm
        createRiskInfoCard(pdf, x, y, w, 'icp')

        if (settings.active) {
            createNewPage()
            x = 10
            y = 40
            w = px700mm
            createIcpInfoIcpRiskCard(pdf, x, y, w)

            createNewPage()
            x = 10
            y = 40
            w = px700mm
            createReportAndSuggestIcpCard(pdf, x, y, w)

            createNewPage()
            x = 10
            y = 40
            w = px700mm
            createListIcpRiskReviews(pdf, x, y, w)

            createNewPage()
            x = 10
            y = 40
            w = px700mm
            createListControllers(pdf, x, y, w)
        } else {
            createNewPage()
            x = 10
            y = 40
            w = px700mm
            createListControllers(pdf, x, y, w)

            if (riskData.icp) {
                createNewPage()
                x = 10
                y = 40
                w = px700mm
                createIcpInfoCard(pdf, x, y, w, 'icp')

                createNewPage()
                x = 10
                y = 40
                w = px700mm
                createListReviews(pdf, x, y, w, 'icp')
            }
        }
    }

    const options = { orientation: 'portrait', unit: 'mm' }
    const pdf = new jsPDF(options)
    // printSettings(pdf, 'default')

    pdf.setTextColor(40, 40, 40)
    const maxPageHeight = pdf.getPageHeight() - 30

    let x = 10
    let y = 10
    let w = 0
    createPageHeader()
    // printCurrentSizes(pdf, x, y, w)

    if (settings.riskportfolio) { // Riskportfölj
        createOneRpRisk(pdf, x, y, w)
        if (settings.extended) {
            fileName = `Riskportfölj_risk_${riskData.name}_utfälld.pdf`
        } else {
            fileName = `Riskportfölj_risk_${riskData.name}.pdf`
        }
    } else { // IKP
        if (settings.active) { // IKP, aktiv
            createOneIcpRisk(pdf, x, y, w)
            if (settings.extended) {
                fileName = `IKP_aktiv_risk_${riskData.name}_utfälld.pdf`
            } else {
                fileName = `IKP_aktiv_risk_${riskData.name}.pdf`
            }
        } else { // IKP, väntande
            createOneIcpRisk(pdf, x, y, w)
            if (settings.extended) {
                fileName = `IKP_väntande_risk_${riskData.name}_utfälld.pdf`
            } else {
                fileName = `IKP_väntande_risk_${riskData.name}.pdf`
            }
        }
    }

    x = 10
    y = 10
    createPageFooter()

    pdf.save(fileName)
}
