import React, { useState, useContext } from "react";
import axios from "axios";
import styles from "./styles/Organisation.module.css";
import { UserContext } from "../../contexts/UserContext";
import { PageHeaderButton, HelpButton } from '../../styles/buttons'
import { PageTitle, PageDelimiter, P } from '../../styles/pages'
import TextInputModal from "./../../modal/TextInputModal";
import WarningModal from "./../../modal/WarningModal";
import HelpTextModal from "./../../modal/HelpTextModal";
import { organisationText1, organisationText2, organisationText3, organisationText4 } from "./../../constants/helpText"

const helpText =
  <>
    <P>
      {organisationText1}
    </P>
    <P>
      {organisationText2}
    </P>
    <P>
      {organisationText3}
    </P>
    <P>
      {organisationText4}
    </P>
  </>
// const helpTextAdmin =
//   <>
//     <P>
//       Som administratör kommer du åt samtliga organisationer som finns upplagda.
//       Byt organisation i drop-down-listan högst upp. Skapa en ny organisation
//       genom att trycka på plustecknet.
//     </P>
//     <P>
//       Administratörer och objektsägare kan förändra organisationsträdet, administratören på
//       samtliga nivåer och objektsägare från sin nivå och neråt. Användare i varje objekt kan
//       därefter skapa risker och agera inom objektet. Tänk på att bygga organisationsträdet så
//       att det möjliggör rätt behörigheter. En objektsägare har behörighet till sitt objekt
//       och de nedanför på grenen / grenarna under. En användare bara till ett enskilt objekt.
//       Om ni vill ge någon tillgång till två grenar i ett träd men inte alla nedåt kan det
//       vara en idé att bygga en mellannivå som knyter samman just de grenarna.
//     </P>
//   </>

// const helpTextObjectManager =
//   <>
//     <P>
//       Administratörer och objektsägare kan förändra organisationsträdet, administratören på
//       samtliga nivåer och objektsägare från sin nivå och neråt. Användare i varje objekt kan
//       därefter skapa risker och agera inom objektet. Tänk på att bygga organisationsträdet
//       så att det möjliggör rätt behörigheter. En objektsägare har behörighet till sitt objekt
//       och de nedanför på grenen / grenarna under. En användare bara till ett enskilt objekt.
//       Om ni vill ge någon tillgång till två grenar i ett träd men inte alla nedåt kan det
//       vara en idé att bygga en mellannivå som knyter samman just de grenarna.
//     </P>
//   </>

// const helpTextUser =
//   <>
//     <P>
//       För dig som användare i programmet är organisationsträdet endast i visningsvy.
//       Kontakta er systemadministratör eller en chef för att förändra trädet.
//     </P>
//   </>

const OrganisationPageHeader = ({
  refetch, setSelectedAncestor, selectedAncestor, allNodes, setNewlyCreatedAncestor, newlyCreatedAncestor,
}) => {
  const { activeUser } = useContext(UserContext);
  const [newOrganisationName, setNewOrganisationName] = useState("");
  const [optionToShow, setOptionToShow] = useState("");
  const [errorInputMessage, setErrorInputMessage] = useState("");
  const [showTextInputModal, setShowTextInputModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showHelpTextModal, setShowHelpTextModal] = useState(false);

  const sendAddChild = async () => {
    let payload = {
      name: newOrganisationName,
      level: 1,
      ancestor: null,
    };

    let nodeAlreadyExist = false;
    allNodes.forEach((element) => {
      if (newOrganisationName.trim().toUpperCase() === element.name.toUpperCase() && ((element.level === 1) || (element.ancestor.toString() === selectedAncestor.toString()))) {
        nodeAlreadyExist = true;
        setErrorInputMessage(element.name + " finns redan!");
      } else if (newOrganisationName === "") {
        nodeAlreadyExist = true;
        setErrorInputMessage("Ange ett namn");
      } else if (newOrganisationName.length > 20) {
        nodeAlreadyExist = true;
        setErrorInputMessage("Max 20 tecken");
      } else if (newOrganisationName.trim().length < 2) {
        nodeAlreadyExist = true;
        setErrorInputMessage("Minst 2 tecken");
      }
    });

    if (nodeAlreadyExist) { setShowWarningModal(true) }
    if (!nodeAlreadyExist) {
      try {
        let svar = await axios.post(process.env.REACT_APP_IC_SERVICE + "/organisation/new-object", payload, {
          headers: {
            ContentType: "application/json",
            authorization: "Bearer " + activeUser.token,
          },
        });
        if (svar.status === 200) {
          setNewlyCreatedAncestor(svar.data);
          setErrorInputMessage("");
          setOptionToShow(newOrganisationName);
          setNewOrganisationName("")
          refetch();
        }
        return svar;
      } catch (error) {
        return error;
      }
    }
  };

  const getOrgNameFromId = () => {
    if (allNodes !== undefined) {
      let tille = allNodes.find((node) => { return node._id === activeUser.organisation })
      return tille?.name
    }
  }
  const getOrgNameFromSelectedAncestor = (ancestorId) => {
    if (allNodes !== undefined) {
      let tille = allNodes.find((node) => { return node?._id?.toString() === ancestorId?.toString() })
      return tille?.name
    }
  }

  return (
    <div>
      <HelpTextModal
        title="Hjälp"
        // helpText={activeUser.admin ? helpTextAdmin : activeUser.objectManager ? helpTextObjectManager : helpTextUser}
        helpText={helpText}
        optionTextOne={"OK"}
        open={showHelpTextModal}
        onClose={() => { setShowHelpTextModal(false); }}
      />
      <WarningModal
        passedFunction={() => { }}
        warningTitle={"Det gick inte skapa organisationen"}
        warningText={errorInputMessage}
        // warningText={`Var god överlåt ansvar till annan användare innan du ändrar organisation, avdelningen eller tar bort den här användaren.
        // Användaren är ansvarig över följande kontroller och riskhanteringsåtgärder. Du kan
        // klicka på risknamnet för att ta dig vidare direkt till riskerna.`}
        optionTextOne={"OK"}
        // optionTextTwo={"Ångra"}
        colour={"blue"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
          setNewOrganisationName("")
        }}
      />
      <TextInputModal
        passedFunction={() => {
          sendAddChild();
        }}
        setNewOrganisationName={setNewOrganisationName}
        labelText={"Ange namn på den nya organisationen"}
        warningText={"Användaren kommer att raderas från systemet!"}
        optionTextOne={"Skapa"}
        optionTextTwo={"Avbryt"}
        colour={"blue"}
        open={showTextInputModal}
        onClose={() => {
          setShowTextInputModal(false);
        }}
      />
      <PageTitle>Organisation {getOrgNameFromSelectedAncestor(selectedAncestor)}</PageTitle>
      <PageDelimiter />
      {activeUser.admin || activeUser.objectManager ? (<>
        <PageHeaderButton icon="sitemap" onClick={() => { }}>
          <div className={styles.headerButtonPanel}>
            <div style={{ color: "white", paddingLeft: "0px", width: "120px", marginRight: "10px", }} >
              Välj organisation:
            </div>
            <div className={styles.selectContainer}>
              {allNodes && allNodes.length === 0 ? (
                <p className={styles.ChooseOrgButton}>Finns ingen!</p>
              ) : (
                <select
                  disabled={!activeUser.admin}
                  className={styles.selectOrganisation}
                  value={optionToShow}
                  onChange={(e) => {
                    setOptionToShow(e.target.value);
                    let findedOptionObject = allNodes
                      .filter((node) => node.level === 1)
                      .find((element) => element.name === e.target.value);
                    setSelectedAncestor([findedOptionObject?._id]);
                  }}
                >
                  <option value={getOrgNameFromId()}>{getOrgNameFromId()}</option>
                  {allNodes !== undefined
                    ? allNodes
                      .filter((node) => node.level === 1 && node._id !== activeUser.organisation)
                      .map((d) => {
                        return (
                          <option key={d._id} value={d.name}>
                            {d.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              )}
            </div>
          </div>
        </PageHeaderButton>

        {activeUser.admin ?
          <PageHeaderButton icon="add" onClick={() => {
            setShowTextInputModal(true)
          }}>Skapa ny organisation</PageHeaderButton>
          : null}

        <HelpButton onClick={() => setShowHelpTextModal(true)}></HelpButton>
      </>
      ) : <div style={{ marginTop: "40px" }}></div>
      }
    </div >
  );
};

export default OrganisationPageHeader;