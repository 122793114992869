import React from "react"
import styles from "./RiskOptions.module.css"

const RiskOptions = (props) => {
    const onToggleProbability = () => {
        props.setMaxProbabilityMakesRed(!props.maxProbabilityMakesRed)
        props.toggleMaxProbabilityRed()
    }

    const onToggleConsequence = () => {
        props.setMaxConsequenceMakesRed(!props.maxConsequenceMakesRed)
        props.toggleMaxConsequenceRed()
    }

    return (
        <>
            <div className={styles.probabilityLabel}>Sannolikhet</div>
            <div className={styles.consequenceLabel}>Konsekvens</div>
            <div className={styles.riskOptions}>
                <div className="pageSubTitle">Maxpoäng</div>
                <div className={styles.riskOption} onClick={onToggleProbability}>
                    {props.maxProbabilityMakesRed
                        ? <i className={`${styles.cosoCheckBox} far fa-check-square`}></i>
                        : <i className={`${styles.cosoCheckBox} far fa-square`}></i>
                    }
                    <span className={styles.riskOptionDescr}>
                        Maxpoäng för sannolikhet klassas som röd
                </span>
                </div>
                <div className={styles.riskOption} onClick={onToggleConsequence}>
                    {props.maxConsequenceMakesRed
                        ? <i className={`${styles.cosoCheckBox} far fa-check-square`}></i>
                        : <i className={`${styles.cosoCheckBox} far fa-square`}></i>
                    }
                    <span className={styles.riskOptionDescr}>
                        Maxpoäng för konsekvens klassas som röd
                </span>
                </div>
            </div>
        </>
    )
}

export default RiskOptions
