import styles from './styles/CategoryDiagram.module.css'

const CategoryDiagram = ({ data, title, connectedId }) => {
    let chartColors = ["rgb(45, 120, 163)", "rgb(20, 100, 100)", "rgb(145, 163, 45)", "rgb(200, 100, 100)", "rgb(130, 180, 150)", "rgb(128, 97, 30)", "rgb(118, 157, 194)", "rgb(185, 120, 120)", "rgb(128, 121, 30)", "rgb(82, 159, 165)"]

    const clrBlue = "rgb(87, 130, 190)"


    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    function describeArc(x, y, radius, startAngle, endAngle) {

        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);

        var arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

        var d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
            "L", x, y,
            "L", start.x, start.y
        ].join(" ");
        return d;
    }

    // var colorObj = {
    //     primary: "#4e7bb8",
    //     secondary: "#7987a1",
    //     success: "#42b72a",
    //     info: "#68afff",
    //     warning: "#fbbc06",
    //     danger: "#ff3366"
    // }

    // const LightenColor = (percent) => {
    //     let color =
    //         title === "kategori" ? "#4e7bb8" : "#333333"

    //     let num = parseInt(color.replace("#", ""), 16),
    //         amt = Math.round(2.55 * percent),
    //         R = (num >> 16) + amt,
    //         B = ((num >> 8) & 0x00FF) + amt,
    //         G = (num & 0x0000FF) + amt;
    //     return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
    // };


    const onProcessStep = () => { console.log('Clicked in process step...') }

    const step_1 = {
        x: 225, y: 2, width: 100, height: 18,
        rx: 10, ry: 10, offset: 3, stroke: clrBlue, strokeWidth: 2, fontSize: 13,
        tx: 100, ty: 100, dy: 12,
        label: '1. IKP skapas',
        details: 'När en internkontrollplan skapas sätts ett attestdatum och notiser går iväg till objektsägarna.'
    }

    const renderSectors = () => {
        let count
        let newColor
        return data?.categoryDiagramData?.map((sector, index) => {
            // var randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16)
            count = index % chartColors.length
            newColor = chartColors[count]
            return <g className={`${styles.notInUse} works`} onClick={onProcessStep} key={index}>
                <path className={styles.circle} d={describeArc(90, 135, 70, sector.startDegree, sector.stopDegree)} fill={newColor}>
                    {/* <path className={styles.circle} d={describeArc(90, 135, 70, sector.startDegree, sector.stopDegree)} fill={LightenColor(index * 6)}> */}
                    {/* <animate attributeType="XML" attributeName="r" from="0" to="50"
                        dur="1s" repeatCount="1" /> */}
                </path>
                <text x={230} y={data.startY + 20 * index} fontFamily={step_1.fontFamily} fontSize={step_1.fontSize} dominantBaseline='middle' textAnchor='start'>
                    {sector.n} {sector.category} - {sector.percentage}%
                </text>
                <rect id={`${index} IKEA`} x={200} y={data.startY - 8 + 20 * index} rx={2} ry={2} width={25} height={12} stroke-position='center' stroke='black' strokeWidth={1} fill={newColor} />
                {/* <rect id={`${index} IKEA`} x={200} y={data.startY - 8 + 20 * index} rx={2} ry={2} width={25} height={12} stroke-position='center' stroke='black' strokeWidth={1} fill={LightenColor(index * 6)} /> */}
            </g>
        })
    }

    let sizeKoeff = 1

    return (
        <div className={styles.categoryChart}>
            {/* <svg id={connectedId} viewBox={`-10 0 502 270`}> */}
            <svg id={connectedId} viewBox={`-10 0 ${sizeKoeff * 462} 250`}>
                {/* <g> */}
                {/* <path d={arc1} fill={clrBlue} />
                    <path d={arc2} fill={clrGreen} /> */}
                {renderSectors()}
                {/* <path d={arc3} fill={clrGrey} />
                    <path d={arc4} fill={clrYellow} style={{ opacity: "0.6" }} />4 */}
                {/* <circle r="1" cx="195" cy="70" fill='white' /> */}
                <circle className={styles.circle} r="50" cx="90" cy="135" fill='rgb(246, 246, 246)'>
                    <animate attributeType="XML" attributeName="r" from="0" to="50"
                        dur="0.6s" repeatCount="1" />
                </circle>
                <text y={20} x={0} fontSize={16} fontWeight="600" textAnchor="start" fill="black" >
                    Risker per {title}
                </text>
                {/* </g> */}
            </svg>
        </div >
    )
}

export default CategoryDiagram