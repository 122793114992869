import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import styles from "./styles/ListUsersPage.module.css";
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton, HelpButton } from '../../styles/buttons'
import compareForSort from '../../functions/compareForSort.js'
import LoadingModal from "./../../modal/LoadingModal";
import HelpTextModal from "./../../modal/HelpTextModal"
import { userText1, userText2, userText3, userText4, userText5 } from "./../../constants/helpText"

const helpText =
  // <div style={{ height: "100px", overflow: "scroll", margin: "auto" }}>
  <div style={{ textAlign: "left" }}>
    {/* <div style={{ marginTop: "10px", fontWeight: "600", textAlign: "center", marginBottom: "20px" }}>
      Hjälp
    </div> */}
    <div style={{ marginTop: "10px" }}>
      {userText1}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Skapa en ny användare</div>
      {userText2}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Behörigheter</div>
      {userText3}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Ta bort eller ändra namn på en användare</div>
      {userText4}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Ändra lösenord för en användare</div>
      {userText5}
    </div>
  </div>

// const helpText =
//   <>
//     <P>
//       Klicka på användarens namn för att redigera denne. Du kan inne i användarens profil
//       redigera namn och behörigheter, samt ta bort användaren eller återställa användarens
//       lösenord. Vid återställande av lösenord registrerar användaren sig på nytt. Om en
//       användare tas bort finns namnet kvar på tidigare utförda kontroller och uppgifter.
//     </P>
//     <P>
//       En objektsägare kan utföra ovanstående uppgifter för användare i sitt
//       organisationsobjekt och de nedanför.
//     </P>
//   </>

const ListUsersPage = () => {
  const history = useHistory();
  const { sideBar, activeUser } = useContext(UserContext);
  const [sortAfter, setSortAfter] = useState("organisationName")
  const [sortOrder, setSortOrder] = useState('')
  const [orgPicked, setOrgPicked] = useState("")
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [isMounted, setIsMounted] = useState(true)
  const [showHelpTextModal, setShowHelpTextModal] = useState(false)

  useEffect(() => {
    // setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  const getObjectAcceessListId = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-access-to-objects-id", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    return res.data;
  };
  const readAncestorTopick = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/organisation/all-ancestors", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    return res.data;
  };

  const { data: accessList, status: accessListStatus } = useQuery(["getAccessListId"], getObjectAcceessListId, {});
  const { data: organisationsList } = useQuery(["getAncestorList"], readAncestorTopick, {});

  const readAllUsers = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/user/all-populated", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });
    if (isMounted) { setFadeInTrigger(true) }
    return res.data;
  };

  const { data: userListData, status } = useQuery(["getUsers"], readAllUsers, {
    // staleTime: 2000,
    cacheTime: 0,
    refetchInterval: 10000,
  });

  const User = (props) => (
    <tr>
      {activeUser.admin ? (
        <td className={styles.linkEditUserName} onClick={() => {
          history.push("/ändra-användare", { email: props.user.email, department: props.user.department, organisation: props.user.organisation });
        }}>
          {(props.user.firstName + " " + props.user.lastName).length > 28 ?
            (props.user.firstName + " " + props.user.lastName).slice(0, 27) + "..." : (props.user.firstName + " " + props.user.lastName)}
        </td>
      ) : activeUser.objectManager && !props.user.admin && props.user._id !== activeUser._id && accessList.includes(props.user.department?._id.toString()) ? (
        <td className={styles.linkEditUserName} onClick={() => {
          history.push("/ändra-användare", { email: props.user.email, department: props.user.department, organisation: props.user.organisation });
        }}>
          {(props.user.firstName + " " + props.user.lastName).length > 28 ?
            (props.user.firstName + " " + props.user.lastName).slice(0, 27) + "..." : (props.user.firstName + " " + props.user.lastName)}
        </td>
      ) :
        <td>
          {(props.user.firstName + " " + props.user.lastName).length > 28 ?
            (props.user.firstName + " " + props.user.lastName).slice(0, 27) + "..." : (props.user.firstName + " " + props.user.lastName)}
        </td>
      }
      <td>
        {(props.user.email).length > 28 ?
          (props.user.email).slice(0, 27) + "..." : (props.user.email)}
      </td>
      <td>
        {(props.user.organisation.name).length > 25 ?
          (props.user.organisation.name).slice(0, 25) + "..." : (props.user.organisation.name)}
      </td>
      <td>
        {(props.user.department.name).length > 25 ?
          (props.user.department.name).slice(0, 25) + "..." : (props.user.department.name)}
      </td>
      <td className={styles.centerColumnClass}>{props.user.admin ? "Ja" : "Nej"}</td>
      <td className={styles.centerColumnClass}>{props.user.objectManager ? "Ja" : "Nej"}</td>
      {
        activeUser.admin || activeUser.objectManager ? (
          <td className={styles.centerColumnClass}>{props.user.registered ? "Ja" : "Nej"}</td>
        ) : null
      }
    </tr >
  );

  const UserList = () => {
    return userListData.filter((usr) => { return (usr.firstName + " " + usr.lastName).toUpperCase().includes(searchText) }).filter(user => { return user.organisation._id === orgPicked || orgPicked === "" }).sort(compareForSort(`${sortAfter}`, sortOrder)).map((user) => {
      return <User user={user} key={user._id} />;
    });
  };

  return (
    <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <HelpTextModal
        title="Hjälp"
        helpText={helpText}
        optionTextOne={"OK"}
        open={showHelpTextModal}
        onClose={() => { setShowHelpTextModal(false); }}
      />
      <>
        <PageTitle>Användare</PageTitle>
        <PageDelimiter />
        {activeUser.admin || activeUser.objectManager ?
          <>
            <PageHeaderButton icon="add" onClick={() => { history.push("/skapa-användare") }}>Skapa ny användare</PageHeaderButton>
            <HelpButton onClick={() => setShowHelpTextModal(true)} ></HelpButton>
          </> : null
        }
      </>

      <div style={{ maxWidth: "1000px", marginTop: "40px", marginBottom: "30px", fontSize: "13px" }}>
        {activeUser.admin || activeUser.objectManager ?
          (
            <>
              <div className="bodyText">Här skapar och redigerar ni användare till programmet.
                En adminbehörighet är den högsta behörigheten och ger full redigerbarhet i
                risker och styr arbetet med internkontrollplanen, i
                samtliga organisationer (om det finns flera). En objektsägare kan administrera sina
                risker i organisationsobjektet och i de objekt som finns nedanför i
                organisationsträdet. En användare kan hantera de risker
                i det objekt den är tilldelad. Vill ni att en användare
                eller objektsägare ska komma åt fler organisationsobjekt?
                Lägg dom högre upp i trädet eller skapa en mellannivå
                som samlar de organisationsobjekt du vill tilldela personen.
              </div>
              <p className="bodyText">Klicka på rubrikerna för att sortera listan.</p>
            </>
          ) : (
            <p className="bodyText">Här visas de användare som finns tillagda i programmet. Kontakta er systemadministratör eller
              respektive objektsägare för att lägga till ytterligare användare. Klicka på rubrikerna för att sortera listan.</p>
          )}
      </div>

      <div>
        <div className={styles.textInputRow}>
          <input name="lastName" placeholder="Sök efter användare" autoComplete="off"
            onChange={(e) => { setSearchText(e.target.value.toUpperCase()) }} /></div>
      </div>
      <div className="selectBox">
        <select style={{ marginLeft: "0px" }} name="organisation" onChange={(e) => {
          setOrgPicked(e.target.value)
        }}>
          <option value="">Alla organisationer</option>
          {organisationsList?.map((anc) => {
            return <option key={anc._id} value={anc._id} >{anc.name}</option>
          })}
        </select>
      </div>

      {status === "success" && accessListStatus === "success" ? (
        <table className={styles.listUsersTable}>
          <thead>
            <tr>
              <th style={{ width: "230px" }} ><p className={styles.titleName} onClick={() => {
                if (sortAfter === "firstName") {
                  if (sortOrder === '') {
                    setSortOrder('desc')
                  } else { setSortOrder('') }
                } else {
                  setSortAfter("firstName")
                  setSortOrder('')
                }
              }}>Namn</p></th>
              <th style={{ width: "240px" }}><p className={styles.titleName} onClick={() => {
                if (sortAfter === "email") {
                  if (sortOrder === '') {
                    setSortOrder('desc')
                  } else { setSortOrder('') }
                } else {
                  setSortAfter("email")
                  setSortOrder('')
                }
              }}>Email</p></th>
              <th style={{ width: "210px" }}><p className={styles.titleName} onClick={() => {
                if (sortAfter === "organisationName") {
                  if (sortOrder === '') {
                    setSortOrder('desc')
                  } else { setSortOrder('') }
                } else {
                  setSortAfter("organisationName")
                  setSortOrder('')
                }
              }}>Organisation</p></th>
              <th style={{ width: "210px" }} onClick={() => {
                if (sortAfter === "departmentName") {
                  if (sortOrder === '') {
                    setSortOrder('desc')
                  } else { setSortOrder('') }
                } else {
                  setSortAfter("departmentName")
                  setSortOrder('')
                }
              }}><p className={styles.titleName}>Avdelning</p></th>
              <th className={styles.centerColumnClass} onClick={() => {
                if (sortAfter === "admin") {
                  if (sortOrder === '') {
                    setSortOrder('desc')
                  } else { setSortOrder('') }
                } else {
                  setSortAfter("admin")
                  setSortOrder('')
                }
              }}><p className={styles.titleName}>Admin</p></th>
              <th className={styles.centerColumnClass}><p className={styles.titleName} onClick={() => {
                if (sortAfter === "objectManager") {
                  if (sortOrder === '') {
                    setSortOrder('desc')
                  } else { setSortOrder('') }
                } else {
                  setSortAfter("objectManager")
                  setSortOrder('')
                }
              }}>Objektägare</p></th>
              {activeUser.admin || activeUser.objectManager ? (
                <th className={styles.centerColumnClass} ><p className={styles.titleName} onClick={() => {
                  if (sortAfter === "registered") {
                    if (sortOrder === '') {
                      setSortOrder('desc')
                    } else { setSortOrder('') }
                  } else {
                    setSortAfter("registered")
                    setSortOrder('')
                  }
                }}>Registrerad</p></th>
              ) : null}
              <th></th>
            </tr>
          </thead>
          <tbody className={styles.listUsersTable}>{userListData.length > 0 ? UserList() : null}</tbody>
        </table>
      ) : "Laddar lista ..."}
      <div style={{ height: "20px" }}></div>
    </div>
  );
};

export default ListUsersPage;

