import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter, Space, P } from '../../styles/pages'
import { PageHeaderButton, HelpButton } from '../../styles/buttons'
import LoadingModal from "../../modal/LoadingModal";
// import { useQuery } from "react-query";
import ListTodos from "./components/ListTodos"
import HelpTextModal from "./../../modal/HelpTextModal"
import { todoText1, todoText2, todoText3 } from "./../../constants/helpText"


const helpText =
  <>
    <P>
      {todoText1}
    </P>
    <P>
      {todoText2}
    </P>
    <P>
      {todoText3}
    </P>
  </>

// const helpText =
//   <>
//     <P>
//       En uppgift skapas för att markera att något av engångskaraktär ska utföras,
//       eller för att något skall utföras längre fram i tiden, t.ex. något som
//       framkommit som en åtgärd i en risk.
//     </P>
//     <P>
//       Det är inte en särskild person som är ansvarig för en uppgift utan den kopplas
//       till ett organisationsobjekt. Samtliga användare och objektsägare har då tillgång
//       till uppgiften. Uppgiften syns både i uppgiftslistan i sidopanelen och via hemvyn.
//       När en uppgift klarmarkeras visas den inte längre i uppgiftslistan eller på hemvyn
//       men kan tas fram genom att trycka på ”visa klarmarkerade uppgifter”.
//     </P>
//   </>

const TodosPage = () => {
  const history = useHistory()
  const { sideBar } = useContext(UserContext)
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)
  const [showHelpTextModal, setShowHelpTextModal] = useState(false)

  const setFadeIfMount = () => { if (isMounted) { setFadeInTrigger(true) } }

  useEffect(() => {
    return () => { setIsMounted(false) }
  }, [])

  return (
    <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <HelpTextModal
        title="Hjälp"
        helpText={helpText}
        optionTextOne={"OK"}
        open={showHelpTextModal}
        onClose={() => { setShowHelpTextModal(false); }}
      />
      <PageTitle>Uppgifter</PageTitle>
      <PageDelimiter />
      <PageHeaderButton icon="add" onClick={() => { history.push("/skapa-uppgift") }}>Skapa uppgift</PageHeaderButton>
      <HelpButton onClick={() => setShowHelpTextModal(true)} ></HelpButton>
      <ListTodos setFadeFromRiskPortfolio={setFadeIfMount} />
      <Space />
    </div >
  )
}

export default TodosPage