import { jsPDF } from 'jspdf'
import Canvg from 'canvg'
import { kommunresursLogo } from './logotypes/kommunresursLogo'
import { ltnbdLogo } from './logotypes/ltnbdLogo'

const fontName = 'helvetica'
const orientation = 'landscape' // optional value: portrait 
const bulletItem = '\u2022  '

let pageTitle = null
let fileName = null

export const exportAnalyzeAsPdf = async ({ onlyIcp, icpName, icpDescription, activeIcp, data, departments, objectToAnalyze, includeChildren, firstAttestData,
    subProcessData, customer }) => {

    // DEVIATIONREPORTSUMMARY & WITHSUGGESTIONS
    let risksWithSuggestions = null
    let risksWithAttests = null
    let risksDeviationReportSummary = null

    const hasAttestToRender = (risk) => {
        let hasComment = false
        let hasYear = false
        for (const controller of risk.controllers) {
            for (const attest of controller.verifyChecks) {
                if (attest.onlyWithAttestComment) {
                    if (attest.comment) hasComment = true
                } else {
                    hasComment = true
                }
                if (attest.year === 'Samtliga') {
                    hasYear = true
                } else {
                    if (attest.date.slice(0, 4) === attest.year) hasYear = true
                }
            }
        }
        return (hasComment && hasYear)
    }

    if (activeIcp) {
        risksWithSuggestions = data.map((risk) => {
            let riskReviews = risk["reviews"]
            if (riskReviews.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.verifyChecks.length > 0) {
                        let suggestionsToReviews = []
                        for (const vCheck of rev.verifyChecks) {
                            if (vCheck.asSuggestion) {
                                suggestionsToReviews.push(vCheck)
                            }
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        riskName: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk.riskId, icpRiskId: risk.icpRiskId, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })

        risksDeviationReportSummary = data.map((risk) => {
            let riskReviews = risk["reviews"]
            if (riskReviews.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.deviationReports.length > 0) {
                        let suggestionsToReviews = []
                        for (const devRep of rev.deviationReports) {
                            suggestionsToReviews.push(devRep)
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        name: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk.riskId, icpRiskId: risk.icpRiskId, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })

        risksWithAttests = data.map((risk) => {
            let riskReviews = risk["reviews"]
            if (riskReviews.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.verifyChecks.length > 0) {
                        let suggestionsToReviews = []
                        for (const vCheck of rev.verifyChecks) {
                            if (vCheck.onlyWithAttestComment) {
                                if (vCheck.comment) {
                                    suggestionsToReviews.push(vCheck)
                                }
                            } else {
                                suggestionsToReviews.push(vCheck)
                            }
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        riskName: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk.riskId, icpRiskId: risk.icpRiskId, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })
    } else {
        risksWithSuggestions = data.map((risk) => {
            let riskReviews = risk["controllers"]
            if (riskReviews.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.verifyChecks.length > 0) {
                        let suggestionsToReviews = []
                        for (const vCheck of rev.verifyChecks) {
                            if (vCheck.asSuggestion) {
                                suggestionsToReviews.push(vCheck)
                            }
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        riskName: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk._id, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })

        risksDeviationReportSummary = data.map((risk) => {
            let riskReviews = risk["controllers"]
            if (riskReviews.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.deviationReports.length > 0) {
                        let suggestionsToReviews = []
                        for (const devRep of rev.deviationReports) {
                            suggestionsToReviews.push(devRep)
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        name: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk._id, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })

        risksWithAttests = data.map((risk) => {
            const hasAttest = hasAttestToRender(risk)
            if (!hasAttest) return null

            let riskReviews = risk["controllers"]
            if (riskReviews.length > 0) {
                let reviewsToRisks = []
                for (const rev of riskReviews) {
                    if (rev.verifyChecks.length > 0) {
                        let suggestionsToReviews = []
                        for (const vCheck of rev.verifyChecks) {
                            if (vCheck.year === 'Samtliga' || vCheck.year === vCheck.date.slice(0, 4)) {
                                if (vCheck.onlyWithAttestComment) {
                                    if (vCheck.comment) {
                                        suggestionsToReviews.push(vCheck)
                                    }
                                } else {
                                    suggestionsToReviews.push(vCheck)
                                }
                            }
                        }

                        if (suggestionsToReviews.length > 0) {
                            reviewsToRisks.push({ reviewName: rev.name, suggestions: suggestionsToReviews })
                        }
                    }
                }

                if (reviewsToRisks.length > 0) {
                    return {
                        riskName: risk.name, reviewsWithSuggestions: reviewsToRisks,
                        orgRiskId: risk._id, departmentName: risk.department.name
                    }
                } else return null

            } else return null

        }).filter(element => { return element !== null })
    }

    // RISKDATABOXES
    let nrOfBranches = departments?.filter(dep => {
        return (dep.level === objectToAnalyze?.level + 1) && dep.parents.includes(objectToAnalyze._id)
    }).length

    let countDeviationReportsInAllReviews = 0
    let countSuggestionsInAllReviews = 0
    let nrOfReviews = 0
    let nrOfControllers = 0
    let accumulatedRiskValue = 0

    for (const risk of data) {
        accumulatedRiskValue = accumulatedRiskValue + (risk.probability * risk.consequence)
        nrOfControllers = nrOfControllers + risk.controllers.reduce((sum, controller) => { if (!controller.icp) { return sum + 1 } else return sum }, 0)
        if (activeIcp) {
            nrOfReviews = nrOfReviews + risk.reviews.length
            // nrOfReviews = nrOfReviews + risk.reviews.reduce((sum, review) => { if (controller.icp) { return sum + 1 } else return sum }, 0)            
            for (const review of risk.reviews) {
                if (review.deviationReports.length > 0) {
                    countDeviationReportsInAllReviews = countDeviationReportsInAllReviews + review.deviationReports.length
                }

                if (review.verifyChecks.length > 0) {
                    let filteredChecks = review.verifyChecks.filter((check) => { return check.asSuggestion })
                    countSuggestionsInAllReviews = countSuggestionsInAllReviews + filteredChecks.length
                }
            }
        } else {
            nrOfReviews = nrOfReviews + risk.controllers.reduce((sum, controller) => { if (controller.icp) { return sum + 1 } else return sum }, 0)

        }
    }

    const riskData = {
        numRisks: data.length,
        numBranches: nrOfBranches,
        perBranch: (data?.length / nrOfBranches).toFixed(1),
        riskValueLowest: data.length > 0 ? " " + (data[data.length - 1]?.probability * data[data.length - 1]?.consequence) : " 0",
        riskValueHighest: data.length > 0 ? " " + (data[0]?.probability * data[0]?.consequence) : " 0",
        riskValueAverage: data.length > 0 ? (accumulatedRiskValue / data.length).toFixed(1) : 0,
        numMeasures: nrOfControllers,
        numControls: nrOfReviews,
        toIcp: data.filter(risk => risk.icp).length,
        deviations: countDeviationReportsInAllReviews,
        proposals: countSuggestionsInAllReviews,
        attested: firstAttestData?.nrOfAttests,
        maxAttest: firstAttestData?.nrOfObjects
    }

    const createCoverPage = (pageTitle) => {
        x = 20
        y = 10
        createPageHeader()
        y += 30
        createPageTitle(pageTitle)
        y += 10
        // pdf.setFontSize(12)
        // let withChildren = includeChildren ? 'ja' : 'nej'
        // pdf.text(x, y, 'Filtreringsval: ' + objectToAnalyze.name + withChildren)
        // y += 10
        // pdf.text(x, y, 'Beskrivning av internkontrollplanen:')
        pdf.setFontSize(10)
        y += 2
        const icpDescriptionLines = computeMultipleLines(icpDescription, 110)
        for (let line = 0; line < icpDescriptionLines.length; line++) {
            y += 4
            pdf.text(x, y, icpDescriptionLines[line].replace(/(\r\n|\n|\r)/gm, ""))
        }
    }


    const createPageHeader = () => {
        if (customer === 'ltnbd') {
            pdf.addImage(ltnbdLogo, 'PNG', x - 1, y + 3, 23, 15)
        } else {
            pdf.addImage(kommunresursLogo, 'JPG', x - 1, y + 3, 23, 15)
        }
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(8)
        if (orientation === 'portrait') {
            pdf.text(x + 153, y + 17, 'Rapport uttagen ' + pdf.getCreationDate().slice(2, 10).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'))
        } else {
            pdf.text(x + 220, y + 10, 'Rapport uttagen ' + pdf.getCreationDate().slice(2, 10).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'))
        }
    }

    const createPageFooter = () => {
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(8)

        const numberOfPages = pdf.getNumberOfPages()

        let offsetTotalPage = 0
        if (numberOfPages > 9) {
            offsetTotalPage = 3;
        } else if (numberOfPages > 99) {
            offsetTotalPage = 6;
        }

        for (let page = 0; page < numberOfPages; page++) {
            pdf.setPage(page)
            let offsetCurrentPage = 0
            if (page > 9) {
                offsetCurrentPage = 3;
            } else if (page > 99) {
                offsetCurrentPage = 6;
            }
            let totalOffset = offsetTotalPage + offsetCurrentPage
            if (orientation === 'portrait') {
                pdf.text((x + 167 - totalOffset), y + 277, 'Rapportsida ' + pdf.getCurrentPageInfo().pageNumber + ' / ' + numberOfPages)
            } else {
                pdf.text((x + 245 - totalOffset), y + 190, 'Rapportsida ' + pdf.getCurrentPageInfo().pageNumber + ' / ' + numberOfPages)
            }
        }
    }

    const createPageTitle = (pageTitle) => {
        pdf.setFont(fontName, 'normal')
        pdf.setFontSize(12)
        pdf.text(x, y, pageTitle)
        y += 10
        pdf.setFontSize(10)
        if (onlyIcp) {
            pdf.text(x, y, icpName)
            y += 4
        }
        let withChildren = includeChildren ? ' med samtliga undernivåer' : ''
        // pdf.text(x, y, 'Filtreringsval: ' + objectToAnalyze.name + withChildren)
        pdf.text(x, y, objectToAnalyze.name + withChildren)
    }

    const createNewPage = (pageTitle) => {
        pdf.addPage()

        x = 20
        y = 10
        createPageHeader()
        y += 30

        if (pageTitle) {
            createPageTitle(pageTitle)
        }
    }

    const drawRiskDataBoxes = (pdf, x, y, w, h) => {
        pdf.setTextColor(0, 0, 0)

        let offset = 0

        // Antal risker
        pdf.setDrawColor(158, 158, 158)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(x, y, w, h, 5, 5, 'FD')
        pdf.setFontSize(12)
        pdf.text(x + 8, y + 10, 'Antal')
        pdf.text(x + 8, y + 14, 'risker')
        pdf.setFontSize(26)
        if (riskData.numRisks > 9) offset += 5
        if (riskData.numRisks > 99) offset += 5
        if (riskData.numRisks > 999) offset += 5
        pdf.text(x + 40 - offset, y + 14, riskData.numRisks.toString())
        pdf.setFontSize(9)
        if (includeChildren && nrOfBranches > 0) {
            pdf.text(x + 8, y + 22, riskData.perBranch.toString() + ' risker per organisationsgren')
            pdf.text(x + 8, y + 26, riskData.numBranches.toString() + ' underobjektsgrenar')
        } else {
            pdf.text(x + 8, y + 22, riskData.numBranches.toString() + ' underobjektsgrenar')
        }

        // Riskvärde snitt
        x += 67
        // x += 65
        pdf.setDrawColor(158, 158, 158)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(x, y, w, h, 5, 5, 'FD')
        pdf.setFontSize(12)
        pdf.text(x + 8, y + 10, 'Riskvärde')
        pdf.text(x + 8, y + 14, '/snitt')
        pdf.setFontSize(26)
        if (riskData.riskValueAverage >= 10) offset = 10
        pdf.text(x + 42 - offset, y + 14, riskData.riskValueAverage.toString())
        pdf.setFontSize(9)
        pdf.text(x + 8, y + 22, 'Lägsta riskvärde: ' + riskData.riskValueLowest.toString())
        pdf.text(x + 8, y + 26, 'Högsta riskvärde: ' + riskData.riskValueHighest.toString())

        // Antal riskhanteringsåtgärder / Antal kontroller
        x += 67
        pdf.setDrawColor(158, 158, 158)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(x, y, w, h, 5, 5, 'FD')
        pdf.setFontSize(26)
        offset = 0
        if (riskData.numMeasures > 9) offset += 2.5
        if (riskData.numMeasures > 99) offset += 2.5
        if (riskData.numControls > 9) offset += 2.5
        if (riskData.numControls > 99) offset += 2.5
        pdf.text(x + 19 - offset, y + 14, riskData.numMeasures.toString() + ' / ' + riskData.numControls.toString())
        pdf.setFontSize(9)
        pdf.text(x + 7, y + 22, 'Antal riskhanteringsåtgärder /')
        pdf.text(x + 17, y + 26, 'Antal kontroller')

        if (!onlyIcp) { // Riskportfölj, Risker till Internkontrollplan
            x += 67
            pdf.setDrawColor(158, 158, 158)
            pdf.setFillColor(255, 255, 255)
            pdf.roundedRect(x, y, w, h, 5, 5, 'FD')
            pdf.setFontSize(26)
            offset = 0
            if (riskData.toIcp > 9) offset += 2.5
            if (riskData.toIcp > 99) offset += 2.5
            if (riskData.toIcp > 999) offset += 2.5
            pdf.text(x + 25 - offset, y + 14, riskData.toIcp.toString())
            pdf.setFontSize(9)
            pdf.text(x + 8, y + 22, 'Risker till Internkontrollplan')
        } else {
            if (activeIcp) { // IKP, aktiv, Avvikelserapporter / Förbättringsförslag
                x += 67
                pdf.setDrawColor(158, 158, 158)
                pdf.setFillColor(255, 255, 255)
                pdf.roundedRect(x, y, w, h, 5, 5, 'FD')
                pdf.setFontSize(26)
                offset = 0
                if (riskData.deviations > 9) offset += 2.5
                if (riskData.deviations > 99) offset += 2.5
                if (riskData.proposals > 9) offset += 2.5
                if (riskData.proposals > 99) offset += 2.5
                pdf.text(x + 19 - offset, y + 14, riskData.deviations.toString() + ' / ' + riskData.proposals.toString())
                pdf.setFontSize(9)
                pdf.text(x + 14, y + 22, 'Avvikelserapporter /')
                pdf.text(x + 14, y + 26, 'Förbättringsförslag')
            } else { // IKP, väntande, Attesterade objekt
                x += 67
                pdf.setDrawColor(158, 158, 158)
                pdf.setFillColor(255, 255, 255)
                pdf.roundedRect(x, y, w, h, 5, 5, 'FD')
                pdf.setFontSize(26)
                offset = 0
                if (riskData.attested > 9) offset += 2.5
                if (riskData.attested > 99) offset += 2.5
                if (riskData.attested > 999) offset += 2.5
                pdf.text(x + 15 - offset, y + 14, riskData.attested.toString() + ' / ' + riskData.maxAttest.toString())
                pdf.setFontSize(9)
                pdf.text(x + 14, y + 22, 'Attesterade objekt')
            }
        }
    }

    const drawTopTenList = (pdf, title, x, y, w, h) => {

        pdf.setDrawColor(158, 158, 158)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(x, y, w, h, 5, 5, 'FD')
        pdf.setFontSize(10)
        pdf.text(x + 8, y + 10, title)

        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(8)
        pdf.text(x + 8, y + 20, 'Risk')
        pdf.text(x + 50, y + 20, 'Avdelning')
        pdf.text(x + 100, y + 20, 'Kategori')
        pdf.text(x + 150, y + 20, 'Process')
        // pdf.text(x + 200, y + 20, 'AVR')
        pdf.text(x + 213, y + 20, 'RV')
        pdf.text(x + 229, y + 20, 'S')
        pdf.text(x + 244, y + 20, 'K')
        pdf.setFont(fontName, 'normal')

        y += 22

        data.slice(0, 10).forEach(item => {
            y += 4.5
            pdf.text(x + 8, y, item.name.length > 29 ? item.name.slice(0, 29) + "..." : item.name)
            pdf.text(x + 50, y, item.department.name.length > 29 ? item.department.name.slice(0, 29) + "..." : item.department.name)
            pdf.text(x + 100, y, item.process.length > 29 ? item.process.slice(0, 29) + "..." : item.process)
            pdf.text(x + 150, y, item.category.length > 29 ? item.category.slice(0, 29) + "..." : item.category)
            // pdf.text(x + 200 + 3, y, (item.consequence * item.probability).toString(), 'center')
            pdf.text(x + 215, y, (item.consequence * item.probability).toString(), 'center')
            pdf.text(x + 230, y, item.probability.toString(), 'center')
            pdf.text(x + 245, y, item.consequence.toString(), 'center')
        })
    }

    const drawSubProcesses = (pdf, title, x, y, w, h) => {
        y += 0
        pdf.setFontSize(11)
        pdf.text(x + 8, y, title)

        y += 8
        pdf.setFont(fontName, 'bold')
        pdf.setFontSize(9)
        pdf.text(x + 8, y, 'Processer')
        pdf.text(x + 70, y, 'Antal risker')
        pdf.text(x + 100, y, 'Andel')

        y += 6.5
        subProcessData.forEach(item => {
            pdf.setFont(fontName, 'bold')
            pdf.setFontSize(10)
            pdf.text(x + 8, y, item.mainProcess)
            pdf.setFont(fontName, 'normal')
            pdf.setFontSize(9)
            for (let key in item.subProcesses) {
                y += 5
                pdf.text(x + 8, y, key)
                pdf.text(x + 70 + 9, y, item.subProcesses[key].toString(), 'center')
                let percentage = ((item.subProcesses[key] / item.totalNr) * 100).toString().slice(0, 4)
                pdf.text(x + 100 + 1.5, y, percentage + '%')
            }
            y += 8
            if (y > maxPageHeight) {
                y = 50
                createNewPage(null)
                pdf.setFontSize(11)
                pdf.text(x + 8, y, title)

                y += 8
                pdf.setFont(fontName, 'bold')
                pdf.setFontSize(9)
                pdf.text(x + 8, y, 'Processer')
                pdf.text(x + 70, y, 'Antal risker')
                pdf.text(x + 100, y, 'Andel')
                pdf.setFont(fontName, 'normal')
                y += 8
            }
        })
    }

    const computeMultipleLines = (str, maxSize) => {
        let lineArray = []

        if (!str) return lineArray

        if (str.length <= maxSize) {
            lineArray.push(str)
            return lineArray
        }

        // Put words in array, excluding white spaces
        let strArray = str.split(/\s+/)

        let currentLine = null
        let newLine = null
        for (const word of strArray) {
            currentLine = newLine
            if (newLine) { newLine = newLine.concat(' ', word) }
            else { newLine = word }

            if (newLine?.length > maxSize) {
                lineArray.push(currentLine)
                newLine = word
            }
        }

        lineArray.push(newLine)
        return lineArray
    }

    const drawSuggestions = (pdf, x, y, w, h) => {
        y += 0
        pdf.setFontSize(11)
        pdf.text(x + 8, y, "Förbättringsförslag")

        risksWithSuggestions.forEach(item => {
            if (y + 8 + 12 + 4 > maxPageHeight) {
                y = 40
                createNewPage(null)
                pdf.setFontSize(11)
                pdf.text(x + 8, y, "Förbättringsförslag")
            }
            y += 8
            pdf.setFont(fontName, 'bold')
            pdf.setFontSize(10)
            pdf.text(x + 11, y, item.riskName + ' - ' + item.departmentName)
            pdf.setFont(fontName, 'normal')

            item.reviewsWithSuggestions.forEach(item => {
                if (y + 12 + 4 > maxPageHeight) {
                    y = 40
                    createNewPage(null)
                    pdf.setFontSize(11)
                    pdf.text(x + 8, y, "Förbättringsförslag")
                    y += 2
                }
                y += 6
                pdf.setFontSize(9)
                pdf.text(x + 14, y, onlyIcp ? 'Kontroll: ' + item.reviewName : 'Riskhanteringsåtgärd: ' + item.reviewName)
                item.suggestions.forEach(item => {
                    y += 6
                    pdf.setFontSize(9)
                    pdf.text(x + 18, y, bulletItem + ' Förbättringsförslag: ' + item.date.slice(0, 10) + ' ' + item.user)
                    pdf.setFontSize(9)
                    const commentLines = computeMultipleLines(item.comment, 150)
                    for (let line = 0; line < commentLines.length; line++) {
                        if (y + 4 > maxPageHeight) {
                            y = 40
                            createNewPage(null)
                            pdf.setFontSize(11)
                            pdf.text(x + 8, y, "Förbättringsförslag")
                            pdf.setFontSize(9)
                            y += 4
                        }
                        y += 4
                        pdf.text(x + 21.5, y, commentLines[line].replace(/(\r\n|\n|\r)/gm, ""))
                    }
                })
            })
        })
    }

    const drawDeviations = (pdf, x, y, w, h) => {
        y += 0
        pdf.setFontSize(11)
        pdf.text(x + 8, y, "Avvikelserapporter")

        risksDeviationReportSummary.forEach(item => {
            if (y + 8 + 12 + 8 > maxPageHeight) {
                y = 40
                createNewPage(null)
                pdf.setFontSize(11)
                pdf.text(x + 8, y, "Avvikelserapporter")
            }
            y += 8
            pdf.setFont(fontName, 'bold')
            pdf.setFontSize(10)
            pdf.text(x + 11, y, item.name + ' - ' + item.departmentName)
            pdf.setFont(fontName, 'normal')
            item.reviewsWithSuggestions.forEach(item => {
                if (y + 12 + 4 > maxPageHeight) {
                    y = 40
                    createNewPage(null)
                    pdf.setFontSize(11)
                    pdf.text(x + 8, y, "Avvikelserapporter")
                    y += 2
                }
                y += 6
                pdf.setFontSize(9)
                pdf.text(x + 14, y, onlyIcp ? 'Kontroll: ' + item.reviewName : 'Riskhanteringsåtgärd: ' + item.reviewName)
                item.suggestions.forEach(item => {
                    y += 6
                    pdf.setFontSize(9)
                    const handled = item.flagged ? ' (hanterad)' : ' (ej hanterad)'
                    pdf.text(x + 18, y, bulletItem + ' Avvikelserapport: ' + item.date.slice(0, 10) + ' ' + item.user + handled)
                    pdf.setFontSize(9)
                    const descriptionLines = computeMultipleLines(item.description, 132)
                    for (let line = 0; line < descriptionLines.length; line++) {
                        if (y + 4 > maxPageHeight) {
                            y = 40
                            createNewPage(null)
                            pdf.setFontSize(11)
                            pdf.text(x + 8, y, "Avvikelserapporter")
                            pdf.setFontSize(9)
                            y += 4
                        }
                        y += 4
                        if (line === 0) {
                            pdf.text(x + 21.5, y, 'Beskrivning: ' + descriptionLines[line])
                        } else {
                            pdf.text(x + 38, y, descriptionLines[line])
                        }
                    }
                    const proposalLines = computeMultipleLines(item.proposal, 130)
                    for (let line = 0; line < proposalLines.length; line++) {
                        if (y + 4 > maxPageHeight) {
                            y = 40
                            createNewPage(null)
                            pdf.setFontSize(11)
                            pdf.text(x + 8, y, "Avvikelserapporter")
                            pdf.setFontSize(9)
                            y += 4
                        }
                        y += 4
                        if (line === 0) {
                            pdf.text(x + 21.5, y, 'Förslag till åtgärd: ' + proposalLines[line])
                        } else {
                            pdf.text(x + 45, y, proposalLines[line].replace(/(\r\n|\n|\r)/gm, ""))
                        }
                    }
                })
            })
        })
    }

    const drawAttests = (pdf, x, y, w, h) => {
        y += 0
        pdf.setFontSize(11)
        pdf.text(x + 8, y, "Attester")

        risksWithAttests.forEach(item => {
            if (y + 8 + 12 + 4 > maxPageHeight) {
                y = 40
                createNewPage(null)
                pdf.setFontSize(11)
                pdf.text(x + 8, y, "Attester")
            }
            y += 8
            pdf.setFont(fontName, 'bold')
            pdf.setFontSize(10)
            pdf.text(x + 11, y, item.riskName + ' - ' + item.departmentName)
            pdf.setFont(fontName, 'normal')

            item.reviewsWithSuggestions.forEach(item => {
                if (y + 12 + 4 > maxPageHeight) {
                    y = 40
                    createNewPage(null)
                    pdf.setFontSize(11)
                    pdf.text(x + 8, y, "Attester")
                    y += 2
                }
                y += 6
                pdf.setFontSize(9)
                pdf.text(x + 14, y, onlyIcp ? 'Kontroll: ' + item.reviewName : 'Riskhanteringsåtgärd: ' + item.reviewName)
                item.suggestions.forEach(item => {
                    if (item.asSuggestion && item.onlyWithAttestComment) {
                        return
                    }
                    y += 6
                    pdf.setFontSize(9)
                    pdf.text(x + 18, y, bulletItem + ' Attest: ' + item.date.slice(0, 10) + ' ' + item.user)
                    pdf.setFontSize(9)
                    const commentLines = computeMultipleLines(item.asSuggestion ? '' : item.comment, 150)
                    for (let line = 0; line < commentLines.length; line++) {
                        if (y + 4 > maxPageHeight) {
                            y = 40
                            createNewPage(null)
                            pdf.setFontSize(11)
                            pdf.text(x + 8, y, "Attester")
                            pdf.setFontSize(9)
                            y += 4
                        }
                        y += 4
                        pdf.text(x + 21.5, y, commentLines[line].replace(/(\r\n|\n|\r)/gm, ""))
                    }
                })
            })
        })
    }

    const drawDiagramBox = (pdf, title, x, y, w, h) => {
        pdf.setDrawColor(158, 158, 158)
        // pdf.setFillColor(246, 246, 246)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(x - 10, y + 10, w, h, 5, 5, 'FD')
        pdf.setFontSize(11)
        pdf.setTextColor(0, 0, 0)
        // pdf.text(x + (w/2), y+5, title,  'center')
        // pdf.text(x + 10, y + 8, title)
    }

    const drawDiagram = async (pdf, canvas, context, svg, title, x, y, w, h) => {
        drawDiagramBox(pdf, title, x, y, w, h)
        const svgRenderer = await Canvg.fromString(context, svg.outerHTML)
        svgRenderer.start()
        svgRenderer.stop()
        const imgData = canvas.toDataURL('image/png')
        pdf.addImage(imgData, 'PNG', x, y)
    }
    const drawBranchDiagram = async (pdf, canvas, context, svg) => {
        pdf.setDrawColor(158, 158, 158)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(17, 100, 160, 80, 5, 5, 'FD')
        const svgRenderer = await Canvg.fromString(context, svg.outerHTML)
        svgRenderer.start()
        svgRenderer.stop()
        const imgData = canvas.toDataURL('image/png')
        pdf.addImage(imgData, 'PNG', 25, 105, 140, 65)
    }

    const drawPlotterDiagram = async (pdf, canvas, context, svg) => {
        pdf.setDrawColor(158, 158, 158)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(187, 100, 90, 80, 5, 5, 'FD')
        const svgRenderer = await Canvg.fromString(context, svg.outerHTML)
        svgRenderer.start()
        svgRenderer.stop()
        const imgData = canvas.toDataURL('image/png')
        pdf.addImage(imgData, 'PNG', 188, 100, 95, 77)
    }

    const options = { orientation, unit: 'mm', format: 'a4' }
    const pdf = new jsPDF(options)
    const maxPageHeight = pdf.getPageHeight() - 30
    pdf.setTextColor(40, 40, 40)
    pdf.setLineWidth(0.05)

    let x = 20
    let y = 10
    createPageHeader()

    let canvas = document.createElement('canvas')
    let context = canvas.getContext('2d')

    if (!onlyIcp) { // Riskportfölj
        pageTitle = 'Analys av Riskportfölj'
        fileName = 'Analys_Riskportfölj.pdf'
    } else {
        if (activeIcp) { // IKP, aktiv
            // pageTitle = 'Analys IKP, aktiv'
            pageTitle = 'Analys av aktiv internkontrollplan '
            fileName = 'Analys_IKP_aktiv.pdf'
        } else { // IKP, väntande
            // pageTitle = 'Analys IKP, väntande'
            pageTitle = 'Analys av väntande internkontrollplan '
            fileName = 'Analys_IKP_väntande.pdf'
        }
    }

    y += 30
    // createPageTitle(pageTitle)
    if (onlyIcp) {
        createCoverPage(pageTitle)
    } else {
        createPageTitle(pageTitle)
    }

    let w = 0
    let h = 0

    let title = null
    let svg = null

    const diagrams = ['riskDataBoxes', 'perObjectBranch', 'plotter', 'topTenList', 'perProcess', 'perCategory', 'subProcesses', 'suggestions', 'deviations', 'attests']
    for (const diagram of diagrams) {
        switch (diagram) {
            case 'riskDataBoxes':
                if (onlyIcp) createNewPage(null)
                x = 17
                y = 60
                w = 58
                h = 30
                drawRiskDataBoxes(pdf, x, y, w, h)
                break
            case 'perObjectBranch':
                svg = document.getElementById(diagram)
                await drawBranchDiagram(pdf, canvas, context, svg)
                break
            case 'plotter':
                svg = document.getElementById(diagram)
                await drawPlotterDiagram(pdf, canvas, context, svg)
                break
            case 'topTenList':
                if (data.length) {
                    createNewPage(null)
                    x = 17
                    y += 0
                    w = 260
                    h = 75
                    // h = 30 + data.slice(0, 10).length * 4.5
                    title = 'Topplistan - 10 risker med högst riskvärde'
                    drawTopTenList(pdf, title, x, y, w, h)
                }
                break
            case 'perProcess':
                x = 28
                y = 115
                svg = document.getElementById(diagram)
                w = 125
                h = 62
                title = 'Risker per huvudprocess'
                await drawDiagram(pdf, canvas, context, svg, title, x, y, w, h)
                break
            case 'perCategory':
                x = 162
                y = 115
                svg = document.getElementById(diagram)
                w = 125
                h = 62
                title = 'Risker per kategori'
                await drawDiagram(pdf, canvas, context, svg, title, x, y, w, h)
                break
            case 'subProcesses':
                if (subProcessData.length) {
                    createNewPage(null)
                    x = 10
                    y += 0
                    w = 125
                    const numSubprocesses = subProcessData.reduce((sum, item) => { return sum + Object.keys(item.subProcesses).length }, 0)
                    h = 18 + subProcessData.length * 8 + numSubprocesses * 5 + 6
                    title = 'Riskfördelning i delprocesser'
                    drawSubProcesses(pdf, title, x, y, w, h)
                }
                break
            case 'suggestions':
                if (risksWithSuggestions?.length > 0) {
                    createNewPage(null)
                    x = 10
                    y += 0
                    w = 125
                    h = 200
                    drawSuggestions(pdf, x, y, w, h)
                }
                break
            case 'deviations':
                if (risksDeviationReportSummary?.length > 0) {
                    createNewPage(null)
                    x = 10
                    y += 0
                    w = 125
                    h = 200
                    drawDeviations(pdf, x, y, w, h)
                }
                break
            case 'attests':
                if (risksWithAttests?.length > 0) {
                    createNewPage(null)
                    x = 10
                    y += 0
                    w = 125
                    h = 200
                    drawAttests(pdf, x, y, w, h)
                }
                break
            default:
                console.error('Not found diagram: ' + diagram)
                break
        }
    }
    x = 10
    y = 10
    createPageFooter()
    pdf.save(fileName)
}
