import React, { useContext, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { UserContext } from "../../contexts/UserContext"
import Loader from "react-loader-spinner"
import axios from "axios"
import styles from "./styles/LoginPage.module.css"
import ResetPasswordModal from "../../modal/WarningModal"

const LoginPage = () => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm()
    const { setIsLoggedIn, initActiveUser } =
        useContext(UserContext)
    const [loginError, setLoginError] = useState(null)
    const history = useHistory()
    const [loadingSpinner, setLoadingSpinner] = useState(false)
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)

    const submitLogin = async (data) => {
        setLoadingSpinner(true)
        try {
            const res = await axios.post(
                process.env.REACT_APP_IC_SERVICE + "/auth/login",
                {
                    email: data.email.toLowerCase(),
                    password: data.password,
                }
            )

            if (res.status === 200 && res.data.user) {
                initActiveUser({
                    ...res.data.user,
                    token: res.data.token,
                })
                setIsLoggedIn(true)

                // DEV
                if (process.env.NODE_ENV === "development") {
                    localStorage.setItem("token", res.data.token)
                    localStorage.setItem("user", JSON.stringify(res.data.user))
                }

                history.push("/hem")
            } else if (res.status === 200 && res.data.error) {
                setLoginError(res.data.error)
                setLoadingSpinner(false)
            }
            return res
        } catch (error) {
            setLoginError("Fel uppgifter!")
            setLoadingSpinner(false)
            return error
        }
    }

    const passwordResetRequest = async () => {
        const res = await axios.put(
            process.env.REACT_APP_IC_SERVICE + "/auth/password-reset",
            {
                email: getValues()?.email,
            }
        )
        return res
    }

    const passwordReset = async () => {
        const email = getValues()?.email
        if (email.startsWith("admin@") && email.endsWith(".ikp")) {
            return null
        }
        let rass = await passwordResetRequest()
        if (rass.status === 200) {
            history.push("/login")
        }
    }

    useEffect(() => {
        if (history.location.state?.autoLogOut) setIsLoggedIn(false)
        document.getElementsByTagName("body")[0].style.overflowY = "hidden"
        return () => {
            document.getElementsByTagName("body")[0].style.overflowY = "visible"
        }
    }, [history.location.state?.autoLogOut, setIsLoggedIn])

    return (
        <>
            <ResetPasswordModal
                passedFunction={async () => {
                    passwordReset()
                }}
                warningTitle={"Vill du återställa ditt lösenord?"}
                warningText={
                    getValues()?.email
                        ? // ? "Du får ett mail om att lösenordet är återställt och en instruktion om hur du går tillväga."
                        "Du får ett mail om att lösenordet är återställt och en instruktion om hur du byter till ett nytt."
                        : "Du behöver fylla i din e-postadress för att återställa lösenordet."
                }
                optionTextOne={getValues()?.email ? "Återställ" : "OK"}
                optionTextTwo={getValues()?.email ? "Ångra" : null}
                colour={getValues()?.email ? "red" : "black"}
                open={showResetPasswordModal}
                onClose={() => {
                    setShowResetPasswordModal(false)
                }}
            />
            <div className={styles.loginPageContainer}>
                {history.location.state?.autoLogOut ? (
                    <h5>
                        Du har varit inloggad under lång tid och behöver verifiera dig på
                        nytt
                    </h5>
                ) : null}
                <div className={styles.loginFormContainer}>
                    <form
                        onSubmit={handleSubmit(submitLogin)}
                        className={styles.loginForm}
                    >
                        <div
                            style={{
                                fontSize: "11px",
                                position: "relative",
                                top: "-10px",
                                left: "140px",
                            }}
                        >
                            Återställ ditt lösenord
                            <span
                                className={styles.regLink}
                                onClick={() => setShowResetPasswordModal(true)}
                            >
                                här
                            </span>
                        </div>
                        <div>
                            <label htmlFor="userInput">
                                <i className="fas fa-envelope fa-xs"></i>
                            </label>
                            <input
                                maxLength="50"
                                name="email"
                                type="email"
                                id="userInput"
                                {...register("email", {
                                    required: "Fyll i e-postadress",
                                    maxLength: 50,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Felaktigt e-postadress",
                                    },
                                })}
                                placeholder="E-postadress"
                                onChange={() => setLoginError(null)}
                            />
                        </div>
                        <div>
                            <label htmlFor="passInput">
                                <i className="fas fa-key fa-xs"></i>
                            </label>
                            <input
                                id="passInput"
                                autoComplete="off"
                                maxLength="35"
                                placeholder="Lösenord"
                                onChange={() => setLoginError(null)}
                                type="password"
                                name="password"
                                {...register("password", { required: "Fyll i Lösenord" })}
                            />
                        </div>
                        <button
                            disabled={loadingSpinner}
                            type="submit"
                            className={styles.loginButton}
                        >
                            {loadingSpinner ? (
                                <Loader type="ThreeDots" color="#eee" height={16} width={25} />
                            ) : history.location.state?.autoLogOut ? (
                                "Verifiera"
                            ) : (
                                "Logga in"
                            )}
                        </button>
                    </form>
                    <div className={styles.regMessageContainer}>
                        <div className={styles.regText}>
                            {history.location.state?.autoLogOut ? (
                                <p></p>
                            ) : (
                                <>
                                    <p>eller registrera </p>{" "}
                                    <p className={styles.regLink} onClick={() => { if (!loadingSpinner) { history.push("/registrera") } }} > {" "} här </p>
                                </>
                            )}
                        </div>
                        <div className={styles.errorMessages}>
                            {Object.keys(errors).length > 0 ? (
                                <>{errors[Object.keys(errors)[0].toString()].message}</>
                            ) : loginError ? (
                                loginError
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage
