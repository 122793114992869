import React, { useState, useContext } from "react";
import { UserContext } from "../../../contexts/UserContext"
import styles from "./../styles/ListControllers.module.css";
import axios from "axios";
import Modal from "./../../../modal/Modal";
import { AddButtonShadow } from '../../../styles/buttons'
import ReviewModal from "./review-modal/ReviewModal";
import frequencyOverdue from '../../../functions/frequencyOverdue.js'
import WarningModal from "./../../../modal/WarningModal";
import Tooltip from "../../../components/tooltip/Tooltip";

export default function ListReviews({ risk, setUpdateState, setShowReviewForm, fromIcp,
  setShowControllerForm, mirrorFromIcp }) {
  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [activeReview, setActiveReview] = useState(null);
  const { activeUser } = useContext(UserContext)

  const deleteController = async () => {
    const res = await axios.delete(process.env.REACT_APP_IC_SERVICE + "/risk/delete-controller", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
      data: {
        controllerId: activeReview,
        riskId: risk._id,
      },
    });
    setUpdateState((state) => !state);
    return res.status;
  };

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ReviewModal
          setShowModal={setShowModal}
          risk={risk}
          activeReview={activeReview}
          setUpdateState={setUpdateState}
          mirrorFromIcp={mirrorFromIcp}
          fromIcp={fromIcp}
        />
      </Modal>
      <WarningModal
        passedFunction={() => deleteController()}
        warningTitle={"Vill du verkligen ta bort riskhanteringsåtgärden?"}
        warningText={"Allt arbete med riskhanteringsåtgärden kommer gå förlorat"}
        optionTextOne={"Ta bort"}
        optionTextTwo={"Ångra"}
        colour={"red"}
        open={showWarningModal}
        onClose={() => {
          setShowWarningModal(false);
        }}
      />

      <div className={styles.mainContainer}>
        <div className={styles.header}>Riskhanteringsåtgärder
        {fromIcp === false && mirrorFromIcp !== true ?
            <div style={{ position: "relative", left: "520px", top: "-25px", width: "200px" }}>
              <AddButtonShadow icp={false} icon="add" onClick={() => {
                setShowReviewForm(false);
                setShowControllerForm((status) => !status);
              }}>Lägg till</AddButtonShadow>
            </div>
            : null}
        </div>

        {risk.controllers.filter((controller) => !controller.icp).length > 0 ? (
          risk.controllers.filter((controller) => !controller.icp)
            .map((controller) => (
              <div className={styles.oneControllerContainer} key={controller._id}>
                <div style={{ cursor: "pointer" }} onClick={() => { setActiveReview(controller); setShowModal(true); }} >
                  {controller.name.length > 23 ?
                    <Tooltip content={controller.name} direction={"right"}>{controller.name.slice(0, 23)}...</Tooltip>
                    : controller.name
                  }
                </div>
                <div className={styles.controllerColumnZero}>{controller.assignedUserId?.firstName} {controller.assignedUserId?.lastName}</div>
                <div className={styles.controllerColumnOne}><Tooltip content={"Metod"} direction={"right"}>{controller.method}</Tooltip></div>
                <div className={styles.controllerColumnTwo}><Tooltip content={"Frekvens"} direction={"right"}>{controller.frequency}</Tooltip></div>
                <div className={styles.controllerColumnFour}>
                  {frequencyOverdue(controller.verifyChecks, controller.frequency, controller.createdAt) ?
                    <div className={styles.riskRowIconItem}><i className={"far fa-calendar-alt"} ></i></div>
                    : null
                  }
                </div>
                <div className={styles.controllerColumnThree}><Tooltip content={"Avvikelserapporter"} direction={"right"}>{controller.deviationReports.length}</Tooltip></div>
                {!fromIcp && mirrorFromIcp !== true ?
                  <div onClick={() => {
                    setActiveReview(controller._id)
                    setShowWarningModal(true)
                  }} className={styles.iconBox}>
                    <i className="fas fa-times fa-md sidebar-icon"></i>
                  </div>
                  : null}
              </div>
            ))
        ) : (
          <div className={styles.oneControllerContainer}>
            Det finns inga riskhanteringsåtgärder till den här risken!
          </div>
        )}
      </div>
    </>
  );
}
