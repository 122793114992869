import styled from 'styled-components'

export const OptionButton = styled.button`
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  width: 150px;
  color: white;
  border: 1px solid rgb(200, 200, 200, 0.7);
  border-radius: 15px;
  background-color: ${({ colour }) => (colour === "red") ? "rgb(171, 47, 47)"
    : (colour === "gray") ? "rgb(152,152,152)"
      : (colour === "blue") ?

        "rgb(47, 100, 171)" : "rgb(55, 55, 55)"};
  /* background-color: ${({ colour }) => (colour === "blue") ? "rgb(171, 47, 47)" : "rgb(55, 55, 55)"}; */
  /* ${({ icon }) => icon === 'star' && 'content: "\f005"'}; */
  height: 25px;
  padding-left: 21px;
  padding-right: 21px;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: ${({ colour }) => (colour === "gray") ? null : "pointer"};
  
  &:hover {
    /* background-color: ${({ alert }) => (alert === true) ? "rgb(161, 37, 37)" : "rgb(25, 25, 25)"}; */
    
    background-color: ${({ colour }) => (colour === "red") ? "rgb(161, 37, 37)"
    : (colour === "gray") ? "rgb(152,152,152)"
      : (colour === "blue") ?

        "rgb(78,123,184)" : "rgb(40, 40, 40)"};
}
&:focus {
  outline: 0;
}
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0,0,0, 0.6);
  z-index: 900;
`

export const OverlayChat = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgb(0,0,0, 0.6); */
  z-index: 900;
`

export const Modal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 999;
`

export const ModalChat = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  /* background-color: #fff; */
  background-color: var(--color-chat-bg);
  color: white;
  z-index: 999;
`

export const ModalTitle = styled.div`
  font-weight: bold;
  /* margin-bottom: 2.5rem; */
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  /* border: thin solid red; */
`

export const ModalTitleDetails = styled.div`
  margin-left: 2rem;
  font-size: .8rem;
  font-weight: normal;
  display: inline;
`

export const ModalTitleDetailsChat = styled.div`
  margin-top: .5rem;
  font-size: .8rem;
  font-weight: normal;
`

export const ModalButtons = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`
