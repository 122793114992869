import styled from "styled-components"

export const RiskDefinitionLabel = styled.label.attrs(({ size }) => ({
    margin: size === 5 ? '1.1rem'
        : size === 4 ? '3rem'
            : size === 3 ? '4.5rem'
                : '0rem'
}))`
  min-width: 135px;
  padding-top: 8px;
  //margin-right: 0rem;
`

export const RiskDefinitionCircle = styled.div.attrs(({ definition }) => ({
    color
        /** 3 x 3 */
        : definition === 3 ? 'var(--color-green-dark)'
            : definition === 6 ? 'var(--color-yellow)'
                : definition === 9 ? 'var(--color-orange-dark)'
                    /** 4 x 4 */
                    : definition === 4 ? 'var(--color-green-dark)'
                        : definition === 8 ? 'var(--color-yellow)'
                            : definition === 12 ? 'var(--color-orange-light)'
                                : definition === 16 ? 'var(--color-orange-dark)'
                                    /** 5 x 5 */
                                    : definition === 5 ? 'var(--color-green-dark)'
                                        : definition === 10 ? 'var(--color-green-light)'
                                            : definition === 15 ? 'var(--color-yellow)'
                                                : definition === 20 ? 'var(--color-orange-light)'
                                                    : definition === 25 ? 'var(--color-orange-dark)'
                                                        : 'var(--color-risk-definition-border)'
}))`
  height: 37px;
  width: 37px;
  line-height: 37px;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  user-select: none;
  color: var(--color-risk-definition-value);
  background-color: ${({ color }) => color};
  border: 2px solid ${({ selected }) => selected ? 'var(--color-risk-definition-border)' : ({ color }) => color};
  border-radius: 50%;
  cursor: pointer;
  margin-right: 1rem;
  /* margin-left: 1rem; */

  .tooltip {
    display: none;
    position: relative;
    bottom: 200%;
  }

  &:hover .tooltip {
    display: block;
  }
`
