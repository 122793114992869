import React from "react";
import logo from "../../media/kommunresurs-logo-header.svg";
import styles from "./MobileApp.module.css";

const MobileApp = () => {
  return (
    <div className={styles.MobileAppContainer}>
      <img className={styles.logoTitle} src={logo} alt="Kommunresurs" />
      <div className={styles.internkontroll}>Internkontroll</div>
      <div className={styles.kommunresurs}>Kommunresurs</div>
      <div className={styles.zooma}>zooma ut eller ändra till större bildskärm</div>
    </div>
  );
};

export default MobileApp;
