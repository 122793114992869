import React, { useContext } from 'react'
// import { useForm } from "react-hook-form"
import { useQuery } from "react-query";
import axios from "axios"
import { UserContext } from "../../contexts/UserContext"
import styles from "./FilterRisks.module.css";
import MultiRangeSlider from '../multi-range-slider/MultiRangeSlider';


export default function FilterRisks({ changeCategory, changeProcess, changeRiskValues, changeCalendarOnly,
  thisIsActiveIcp, calendarOnly, ikpOnly, changeSetIkpOnly, thisIsRiskPortfolio, changeStatus }) {
  const { activeUser } = useContext(UserContext)
  // const [filterOptions, setFilterOptions] = useState({ category: "1", process: "1" })

  const getCurrentSettings = async () => {
    const res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/get-current-portfolio-settings", {
      headers: {
        authorization: "Bearer " + activeUser.token,
      },
    });

    return res.data;
  };

  const { data, status } = useQuery(["readSettingsForCreateRisk"], getCurrentSettings, {});

  // const { register, getValues, watch } = useForm()

  return (
    status === "success" ? <div className={styles.filterContainer}>
      <div className={styles.filterTextBox}>Fler filtreringsalternativ</div>
      <div className={styles.selectBox}>
        <select name="category"
          onChange={(e) => changeProcess(e.target.value)}>
          <option>Välj process</option>
          <option value="Ej definierad process" >Ej definierad process</option>
          {data?.categories?.map((cat, index) => {
            return <option value={cat.main} key={index}>{cat.main}</option>
          })}
        </select>
      </div>
      <div className={styles.selectBox}>
        <select name="process"
          onChange={(e) => changeCategory(e.target.value)}>
          <option >Välj kategori</option>
          <option value="Ej definierad kategori" >Ej definierad kategori</option>
          {data?.processes.map(p => {
            return <option key={p} value={p}>{p}</option>
          })}
        </select>
      </div>


      {thisIsRiskPortfolio ?
        <>
          <div className={styles.calendarOnly} onClick={() => {
            changeCalendarOnly()
          }}>
            {calendarOnly ? <div className={styles.riskRowIconItem} > <i style={{ color: "red", paddingLeft: "10px" }} className={"far fa-calendar-alt"} ></i></div>
              : <div className={styles.riskRowIconItem} > <i style={{ color: "black", paddingLeft: "10px" }} className={"far fa-calendar-alt"} ></i></div>
            }
          </div>

          <div className={styles.ikpOnly}
            style={ikpOnly ? { fontWeight: "900" } : null}
            onClick={() => changeSetIkpOnly()} >
            IKP
          </div>
        </>
        : !thisIsRiskPortfolio && !thisIsActiveIcp ?
          <div className={styles.waitingIcpOnly}>.</div> : !thisIsRiskPortfolio && thisIsActiveIcp ?
            <>
              <div className={styles.selectBox} >
                <select name="process" style={{ width: "130px", minWidth: "130px" }}
                  onChange={(e) => changeStatus(e.target.value)}>
                  <option value={-1} >Välj status</option>
                  <option value={0}>Ej rapporterad</option>
                  <option value={1}>Klar</option>
                  <option value={2}>Avvaktande</option>
                  <option value={3}>Varningar</option>
                </select>
              </div>
              <div className={styles.calendarOnly} style={{ marginLeft: "45px" }} onClick={() => {
                changeCalendarOnly()
              }}>
                {calendarOnly ? <div className={styles.riskRowIconItem} > <i style={{ color: "red", paddingLeft: "0px" }} className={"far fa-calendar-alt"} ></i></div>
                  : <div className={styles.riskRowIconItem} > <i style={{ color: "black", paddingLeft: "0px" }} className={"far fa-calendar-alt"} ></i></div>
                }
              </div>
            </> : null
      }
      <div>
        <label className={styles.rvLabel}>Riskvärde:</label>
        <MultiRangeSlider
          min={1}
          max={Math.pow(data?.coso.size, 2)}
          onChange={({ min, max }) => {
            changeRiskValues(min, max)
          }}
        />
      </div>
    </div > : null
  )
}
