import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import axios from "axios"
import styles from "../styles/MyAssigned.module.css";
import { useHistory } from "react-router-dom";
import Modal from "./../../../modal/Modal";
import ReviewModal from "../../internalcontrolplan/components/review-modal/ReviewModal";
import { UserContext } from "../../../contexts/UserContext";
import frequencyOverdue from '../../../functions/frequencyOverdue.js'
import Tooltip from "../../../components/tooltip/Tooltip";

export default function MyAssignedReviews({ setFadeFromHome }) {
    const { setSideBarActiveItem, activeUser } = useContext(UserContext)
    const history = useHistory()
    const [activeIcpId, setActiveIcpId] = useState("")
    const [activeIcpName, setActiveIcpName] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [activeReview, setActiveReview] = useState(null)
    const [riskIdObject, setRiskIdObject] = useState({})
    const [updateState, setUpdateState] = useState(false)
    const [icpRiskId, setIcpRiskId] = useState(null)

    const readReviewData = async () => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/riskportfolio/get-assigned-reviews",
            {},
            { headers: { authorization: "Bearer " + activeUser.token }, }
        );

        if (activeIcpId === "" && res.data.icps.length > 0) {

            let sortedIcpList = res.data.icps.sort(function (a, b) {
                var nameA = a.name?.toUpperCase()
                var nameB = b.name?.toUpperCase()
                if (nameA < nameB) { return -1; }
                if (nameA > nameB) { return 1; }
                return 0;
            })
            setActiveIcpId(sortedIcpList[0].id)
            setActiveIcpName(sortedIcpList[0].name)
        }
        setFadeFromHome()
        return res.data;
    };

    const { data: reviewData, status: controllerStatus, refetch } = useQuery(["reviewDataQuery"],
        readReviewData, { enabled: true });

    useEffect(() => {
        refetch()
    }, [updateState, refetch])

    return (
        <>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <ReviewModal
                    setShowModal={setShowModal}
                    risk={riskIdObject}
                    activeReview={activeReview}
                    setUpdateState={setUpdateState}
                    icpRiskId={icpRiskId}
                    refetchReviews={() => { }}
                />
            </Modal>

            <div className={styles.container}>
                <div className={styles.buttonHeader}>
                    <div className={styles.header}>MINA KONTROLLER </div>
                    <div className={styles.buttonContainer}>
                        {controllerStatus === "success" && reviewData.icps.length > 0 ?
                            reviewData.icps.sort(function (a, b) {
                                var nameA = a.name?.toUpperCase()
                                var nameB = b.name?.toUpperCase()
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                return 0;
                            }).map(icp => {
                                return <button className={
                                    icp.id === activeIcpId
                                        ? `${styles.icpButton} ${styles.selectedIcpButton}`
                                        : `${styles.icpButton}`}
                                    key={icp.name}
                                    onClick={() => {
                                        setActiveIcpId(icp.id)
                                        setActiveIcpName(icp.name)
                                    }}
                                >{icp.name}</button>
                            })
                            : null
                        }
                    </div>
                </div>

                <div className={styles.titleRowDiv}>
                    <div className={styles.colDivRiskName}>Kontroll</div>
                    <div className={styles.colDiv}>Objekt</div>
                    <div className={styles.colDivRiskName}>Risk</div>
                    <div className={styles.colDivFrequency}>Frekvens</div>
                    <div className={styles.colDivDate}>Senast utförd</div>
                    <div className={styles.colDivCalendar}></div>
                </div>
                {controllerStatus === "success" && reviewData.reviews.length > 0 ?
                    reviewData.reviews.filter((rev) => { return rev.icpId === activeIcpId }).sort(function (a, b) {
                        var nameA = a.riskName?.toUpperCase()
                        var nameB = b.riskName?.toUpperCase()
                        if (nameA < nameB) { return -1; }
                        if (nameA > nameB) { return 1; }
                        return 0;
                    }).map(control => {
                        return (
                            <div className={styles.rowDiv} key={control._id}>
                                <div className={styles.colDivRiskName}
                                    onClick={() => {
                                        setIcpRiskId({ icpRiskId: control.icpRiskId })
                                        setRiskIdObject({ _id: control.riskId, department: { _id: control.departmentId } })
                                        setActiveReview(control);
                                        setShowModal(true);
                                    }}
                                >
                                    {/* {control.controllerName} */}
                                    {control.controllerName.length > 23 ?
                                        <Tooltip content={control.controllerName} direction={"right"}>{control.controllerName.slice(0, 23)}...</Tooltip>
                                        : control.controllerName
                                    }
                                </div>
                                <div className={styles.colDiv}>{control.departmentName}</div>
                                <div className={styles.colDivRiskName}
                                    onClick={() => {
                                        setSideBarActiveItem("internalcontrolplans")
                                        history.push("/show-icp-risk", {
                                            riskId: control.riskId,
                                            icpRiskId: control.icpRiskId,
                                            icpName: activeIcpName
                                        })
                                    }}
                                >
                                    {/* {control.riskName} */}
                                    {control.riskName.length > 30 ?
                                        <Tooltip content={control.riskName} direction={"right"}>{control.riskName.slice(0, 30)}...</Tooltip>
                                        : control.riskName
                                    }
                                </div>
                                <div className={styles.colDivFrequency}>{control.frequency}</div>
                                <div className={styles.colDivDate}>{control.lastCheckDate?.toString().slice(0, 10)}</div>
                                <div className={styles.colDivCalendar}>{
                                    frequencyOverdue(control.lastCheckDate ? [{ date: control.lastCheckDate }] : [], control.frequency, control.createdAt) ?
                                        <div className={styles.riskRowIconItem}><i className={"far fa-calendar-alt"} ></i></div>
                                        : null
                                }</div>
                            </div>
                        )
                    })
                    : <h4 style={{ fontSize: "13px", fontWeight: "400" }}>Det finns inga kontroller tilldelade till dig.</h4>
                }
            </div>
        </>
    );
}
