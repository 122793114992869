import React from "react";
import ReactDom from "react-dom";
import Loader from 'react-loader-spinner'
import styles from "./Loading.module.css";

const MODAL_STYLES = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  position: "fixed",
  top: "50%",
  left: "50%",
  borderRadius: "15px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  zIndex: "800",
  padding: "30px"
};

// const OVERLAY_STYLES = {
//   position: "fixed",
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: "rgb(0,0,0, 0.6)",
//   zIndex: "999",
// };

export default function LoadingModal({ onClose, open, passedFunction }) {
  if (!open) return null;
  return ReactDom.createPortal(
    <div className={styles.fadeIn}>
      {/* <div style={OVERLAY_STYLES} /> */}
      <div style={MODAL_STYLES}>

        <Loader className="" type="Circles" opacity="0.3" color="#4e7bb8" height={55} width={55} />

      </div>
    </div>,
    document.getElementById("portal")
  );
}
