import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../../contexts/UserContext"
import { useHistory } from "react-router-dom"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import { PageHeaderButton, HelpButton } from '../../styles/buttons'
import ListRisksInPortfolio from "./components/ListRisksInPortfolio"
import LoadingModal from "./../../modal/LoadingModal";
import HelpTextLargeModal from "./../../modal/HelpTextLargeModal";
import { riskText1, riskText2, riskText3, riskText4, riskText5, riskText6, riskText7, riskText8, riskText9, riskText10, riskText11 } from "./../../constants/helpText"
const helpText =
    // <div style={{ height: "100px", overflow: "scroll", margin: "auto" }}>
    <div style={{ paddingTop: "600px" }}>
        <div style={{ marginTop: "10px", fontWeight: "600", textAlign: "center", marginBottom: "20px" }}>
            Hjälp
        </div>
        <div style={{ marginTop: "10px" }}>
            {riskText1}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Skapa risk</div>
            {riskText2}
        </div>

        <div style={{ marginTop: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Riskgrupper</div>
            {riskText3}
            <ol style={{ listStyleType: "lower-roman" }}>
                <li>
                    {riskText4}
                </li>
                <li>
                    {riskText5}
                </li>
            </ol>
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            {riskText6}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Riskhanteringsåtgärder</div>
            {riskText7}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Inne i portföljen</div>
            {riskText8}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Analys</div>
            {riskText9}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Avvikelserapporter & förbättringsförslag</div>
            {riskText10}
        </div>
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ fontWeight: "600", marginBottom: "5px" }}>Export</div>
            {riskText11}
        </div>

        {/* <P style={{ background: "rgb(47, 100, 171, 0.85)", borderRadius: "15px", padding: "15px" }}>
      {homeText3}
    </P> */}
    </div>

// const oldHelpText =
//     <>
//         <P>
//             Alla risker som skapas i programmet finns i riskportföljen. Riskportföljen är inte
//             tidsstyrd utan visar samtliga risker som finns i organisationen. Varje risk är knuten
//             till ett organisationsobjekt. Varje användare ser de risker som är knutna till de
//             organisationsobjekt den har behörighet till. Olika egenskaper kan knytas till en risk,
//             såsom kategori och process, för att lättare sortera och analysera riskerna.
//         </P>
//         <P>
//             Klicka på en risk för att komma in i den och se mer detaljer.
//             Där kan du också ladda upp filer som t.ex. beskriver en kontroll som skall utföras,
//             en processmall för rapportering eller något annat.
//         </P>
//         <P>
//             Riskhanteringsåtgärder läggs upp inne i risken.
//             Det är de åtgärder som utförs för att minska risken, att minska sannolikheten för att
//             den ska inträffa. Exempel kan vara systemkontroller, dualitet i beslut, larmanordningar
//             eller något annat. I riskhanteringsåtgärden (benämns RHÅ) sätts hur ofta åtgärden ska
//             utföras och av vem. Här rapporteras sen åtgärden, avvikelserapporter läggs upp och ev.
//             förbättringsförslag.
//         </P>
//         <P>
//             Om en risk är markerad till internkontrollplanen finns en grön sektion längst ner inne
//             i risken. Där läggs kontroller upp, också de för att minska risken och ofta för att se
//             att man har kontroll på de riskhanteringsåtgärder som finns, att se att de fungerar som
//             tänkt. Ofta är det risker som har ett högt riskvärde, nya risker eller risker man i
//             organisationen har svårt att komma tillrätta med som finns med på internkontrollplanen.
//         </P>
//         <P>
//             Varje kolumn i riskportföljen kan sorteras genom att klicka på rubriken.
//         </P>
//         <P>
//             AVR visar datumet för den senaste avvikelserapporten som är skapad för risken.
//             Står det inget datum finns det ingen avvikelserapport rapporterad på risken.
//         </P>
//         <P>
//             Status, om en röd kalendersymbol visas här har frekvensen som är satt på riskens
//             riskhanteringsåtgärder missats.
//         </P>
//         <P>
//             RV visar riskvärdet, det vill säga produkten av sannolikhet och konsekvens.
//         </P>
//         <P>
//             IKP visar om risken är markerad till internkontrollplanen.
//         </P>
//         <P>
//             RHÅ visar hur många riskhanteringsåtgärder det finns upplagda på risken.
//         </P>
//         <P>
//             Den utfällda listan visar också beskrivningen av risken samt benämningarna på
//             riskhanteringsåtgärderna.
//         </P>
//         <P>
//             Genom analysknappen ser du en sammanställning över riskerna i portföljen, med
//             olika diagram över organisationsobjekt, kategorier och processer. Riskerna visas
//             också utifrån riskmatrisen och som en topplista över de med högst riskvärde.
//         </P>
//         <P>
//             Riskportföljen kan exporteras, till pdf eller excel.
//         </P>
//     </>

const RiskportfolioPage = () => {
    const history = useHistory()
    const { sideBar, activeUser } = useContext(UserContext)
    const [isMounted, setIsMounted] = useState(true)
    const [fadeInTrigger, setFadeInTrigger] = useState(false)
    const [showExportModal, setShowExportModal] = useState(false)
    const [showHelpTextModal, setShowHelpTextModal] = useState(false)

    const setFadeIfMount = () => {
        if (isMounted) { setFadeInTrigger(true) }
    }

    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [])

    return (
        <>
            <LoadingModal open={!fadeInTrigger} />
            <HelpTextLargeModal
                title="Hjälp"
                helpText={helpText}
                optionTextOne={"OK"}
                open={showHelpTextModal}
                onClose={() => { setShowHelpTextModal(false); }}
            />
            {/* <HelpTextModal
                title="Hjälp"
                helpText={helpText}
                optionTextOne={"OK"}
                open={showHelpTextModal}
                onClose={() => { setShowHelpTextModal(false); }}
            /> */}
            <div className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
                <PageTitle>Riskportfölj</PageTitle>
                <PageDelimiter />
                <PageHeaderButton icon="add" onClick={() => { history.push("/skapa-ny-risk") }}>Skapa ny risk</PageHeaderButton>
                {activeUser.admin ? <PageHeaderButton icon="matrix" onClick={() => { history.push("/risk-groups") }}>Riskgrupper</PageHeaderButton> : null}
                <PageHeaderButton icon="chart" onClick={() => {
                    history.push("/analyze-riskportfolio", { onlyIcp: false })
                }} > Analysera</PageHeaderButton>
                <PageHeaderButton icon="save" onClick={() => { setShowExportModal(true) }}>Exportera</PageHeaderButton>
                <HelpButton onClick={() => setShowHelpTextModal(true)} ></HelpButton>
                <ListRisksInPortfolio setFadeFromRiskPortfolio={setFadeIfMount} setShowExportModal={setShowExportModal} showExportModal={showExportModal} />
                <div style={{ height: "30px" }}></div>
            </div >
        </>
    )
}

export default RiskportfolioPage