
import React, { useContext } from "react";
import styles from './styles/PlotterDiagram.module.css'
import calculateRiskGroupColor from '../../../../functions/calculateRiskGroupColorRGB'
import axios from "axios";
import { UserContext } from "../../../../contexts/UserContext";
import { useQuery } from "react-query";


const PlotterDiagram = ({ data, onlyIcp }) => {
    const { activeUser } = useContext(UserContext);
    const textSize = 16

    const readSettings = async () => {
        let res = await axios.get(process.env.REACT_APP_IC_SERVICE + "/setting/read-settings", {
            headers: {
                authorization: "Bearer " + activeUser.token,
            },
        })
        return res.data.coso
    }

    const { data: settingsData, status } = useQuery(["getSettings"], readSettings, {
        cacheTime: 0,
    });

    const onProcessStep = () => { console.log('Clicked in process step...') }

    let gridPadding = 55
    let circleSize = 8

    const max = data.reduce((prev, current) => (prev.n > current.n) ? prev : current, 0)

    const renderPlotter = () => {
        let plotData = data?.filter((obj) => {
            return obj.probability <= settingsData.size && obj.consequence <= settingsData.size
        }).map((thisCircle, index) => {
            return <g className={`${styles.notInUse} works`} onClick={onProcessStep} key={index + "circle"}>
                <circle className={styles.circle} stroke="#555" r={circleSize + 80 / (max.n + 6 - thisCircle.n)} cx={thisCircle.probability * gridPadding} cy={250 + -thisCircle.consequence * gridPadding} fill={calculateRiskGroupColor(thisCircle.probability, thisCircle.consequence, settingsData)}>
                    {/* <animate attributeType="XML" attributeName="r" from="0" to={circleSize + 28 / (max.n + 3 - thisCircle.n)}
                        dur="0.8s" repeatCount="1" /> */}
                </circle>
                <text x={thisCircle.probability * gridPadding} y={251 + -thisCircle.consequence * gridPadding} fontWeight="bold" fill="black" fontSize={textSize} dominantBaseline='middle' textAnchor='middle'>
                    {thisCircle.n}
                </text>
            </g >
        })

        let gridArrToGo = [...Array(settingsData.size).keys()]
        let testDataToGo = gridArrToGo.map((item, index) => {
            return <g className={`${styles.notInUse} works`} onClick={onProcessStep} key={index + "axesNr"}>
                <text x={gridPadding * (index + 1)} y={50 + gridPadding * 3 * 1.1} fontWeight="bold" fill="#555" fontSize={textSize} dominantBaseline='middle' textAnchor='middle'>
                    {index + 1}
                </text>
                <text x={10} y={195 - gridPadding * index} fontWeight="bold" fill="#555" fontSize={textSize} dominantBaseline='middle' textAnchor='middle'>
                    {index + 1}
                </text>
            </g >
        })

        return [testDataToGo, plotData]
    }

    let consPosX = -25
    let consPosY = 135
    let probPosX = 110
    let probPosY = 262
    let titlePosX = onlyIcp ? 50 : 20
    let titlePosY = 10
    let labelFontSize = 12

    let xDir = -100
    let yDir = -20
    let viewBoxOne = 392
    let viewBoxTwo = 320
    let svgWidht = 392
    let svgHeight = 320

    if (settingsData?.size === 4) {
        viewBoxOne = 392 * 1.1
        viewBoxTwo = 320 * 1.1
        svgWidht = 392
        svgHeight = 320

        xDir = -100
        yDir = -60
        consPosX = -25
        consPosY = 112
        probPosX = 135
        probPosY = 262
        titlePosX = onlyIcp ? 50 : 30
        titlePosY = -25
        labelFontSize = 13
    } else if (settingsData?.size === 5) {
        viewBoxOne = 392 * 1.25
        viewBoxTwo = 320 * 1.25
        svgWidht = 392
        svgHeight = 320
        xDir = -100
        yDir = -110
        consPosX = -25
        consPosY = 85
        probPosX = 165
        probPosY = 262
        titlePosX = onlyIcp ? 70 : 50
        titlePosY = -70
        labelFontSize = 16
    }

    return (
        <div className={styles.plotterChart}>
            {status === "success" ?
                <svg id="plotter" viewBox={`${xDir} ${yDir} ${viewBoxOne} ${viewBoxTwo}`} width={svgWidht} height={svgHeight} >
                    {renderPlotter()}
                    <text y={titlePosY} x={titlePosX} fontSize={labelFontSize + 3} fontWeight="600" dominantBaseline='middle' textAnchor="middle" fill="black" >
                        Alla risker i {onlyIcp ? "internkontrollplanen" : "riskportföljen"}
                    </text>
                    <text x={probPosX} y={probPosY} fontWeight="500" fill="black" fontSize={labelFontSize} dominantBaseline='middle' textAnchor='middle'>
                        Sannolikhet
                    </text>
                    <text x={consPosX} y={consPosY} transform={`rotate(-90, ${consPosX}, ${consPosY})`} fontWeight="500" fill="black" fontSize={labelFontSize} dominantBaseline='middle' textAnchor='middle'>
                        Konsekvens
                    </text>
                </svg>
                : null}
        </div >
    )
}

export default PlotterDiagram