import styled from "styled-components"

/**
 * Icons
 * 
 * home : \f015 : <i class="fas fa-home"></i>
 * star : \f005 : <i class="fas fa-star"></i>
 * add  : \f055 : <i class="fas fa-plus-circle"></i>
 * cog  : \f013 : <i class="fas fa-cog"></i>
 * folder  : \f07b : <i class="fas fa-folder"></i>
 * archive  : \f187 : <i class="fas fa-archive"></i>
 * analyze  : \f187 : <i class="fas fa-archive"></i>
 * undo : \f0e2 : <i class="fas fa-undo"></i>
 * save : \f0c7 : <i class="far fa-save"></i>
 */

export const ResetButton = styled.button`
padding: 0;
border: none;
font: inherit;
font-size: 14px;
color: inherit;
background-color: transparent;
cursor: pointer;

&:focus {
    outline: 0;
}
`

export const HelpButton = styled(ResetButton).attrs(({ mini }) => ({}))`
--page-header-button-fg: white;
--page-header-button-bg: rgb(47, 100, 171, 1);
--page-header-button-bg-hover: rgb(47, 100, 171, 0.95);

color: var(--page-header-button-fg);
background-color: ${({ mini }) => (false) ? "rgb(27, 127, 136)" : "rgb(47, 100, 171, 0.85)"};

padding-bottom: 4px;
border-radius: 25px;
padding-top: 1px;
width: ${({ mini }) => (mini ? "20px" : "26px")}};
height: ${({ mini }) => (mini ? "20px" : "26px")}};
margin-right: ${({ mini }) => (mini ? "5px" : "0px")};
margin-left: ${({ mini }) => (mini ? "5px" : "0px")};

&:hover {
    background-color: var(--page-header-button-bg-hover);
}

&::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: relative;
    content: "\f128";
    padding-right: ${({ mini }) => (mini ? "1px" : "0px")};
    top: ${({ mini }) => (mini ? "-2px" : "0px")};
    font-size: ${({ mini }) => (mini ? "10px" : "12px")};
}
`

export const PageHeaderButton = styled(ResetButton)`
    --page-header-button-fg: white;
    --page-header-button-bg: rgb(47, 100, 171, 1);
    --page-header-button-bg-hover: rgb(47, 100, 171, 0.95);
    
    color: var(--page-header-button-fg);

    background-color: ${({ icon }) => (icon === 'toggleOn') ? "rgb(27, 127, 136)" : "rgb(47, 100, 171, 0.85)"};
    // background-color: rgb(47, 100, 171, 0.85);
    /* background-color: var(--page-header-button-bg); */

    /* padding-top: ${({ icon }) => (icon === 'toggleOff' || icon === 'toggleOn') ? "5px" : "3px"}; */
    padding-bottom: 5px;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 28px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
  font-weight: 300;

    &:hover {
        
        /* background-color: var(--page-header-button-bg-hover); */
        // background-color:  rgb(47, 100, 171, 0.95);
        background-color: ${({ icon }) => (icon === 'toggleOn') ? "rgb(23, 119, 128)" : "rgb(47, 100, 171, 0.95)"};
    }

    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: relative;
        top: ${({ icon }) => (icon === 'toggleOff' || icon === 'toggleOn') ? "2px" : "1px"};
        ${({ icon }) => icon === 'home' && 'content: "\f015"'};
        ${({ icon }) => icon === 'star' && 'content: "\f005"'};
        ${({ icon }) => icon === 'add' && 'content: "\f055"'};
        ${({ icon }) => icon === 'sitemap' && 'content: "\f0e8"'};
        ${({ icon }) => icon === 'cog' && 'content: "\f013"'};
        ${({ icon }) => icon === 'matrix' && 'content: "\f00a"'};
        ${({ icon }) => icon === 'customer' && 'content: "\f51c"'};
        ${({ icon }) => icon === 'undo' && 'content: "\f0e2"'};
        ${({ icon }) => icon === 'folder' && 'content: "\f07c"'};
        ${({ icon }) => icon === 'chart' && 'content: "\f080"'};
        ${({ icon }) => icon === 'save' && 'content: "\f0c7"'};
        ${({ icon }) => icon === 'toggleOn' && 'content: "\f205"'};
        ${({ icon }) => icon === 'toggleOff' && 'content: "\f204"'};
        ${({ icon }) => icon === 'help' && 'content: "\f128"'};
        ${({ icon }) => icon === 'export' && 'content: "\f019"'};
        ${({ icon }) => icon === 'info' && 'content: "\f05a"'};
        ${({ icon }) => icon === 'show' && 'content: "\f06e"'};
        ${({ icon }) => icon === 'hide' && 'content: "\f070"'};
        margin-right: 1rem;
        /* font-size: 14px; */
        font-size: ${({ icon }) => (icon === 'toggleOff' || icon === 'toggleOn') ? "16px" : "12px"};
    }
`

export const SaveButton = styled(ResetButton)`
    --page-save-button-fg: white;
    --page-save-button-bg: rgb(27, 127, 136);
    --page-save-button-bg-hover: rgb(23, 119, 128);
    
    color: var(--page-save-button-fg);

    background-color: var(--page-save-button-bg);

    padding-bottom: 5px;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 28px;

    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 300;

    &:hover {
        background-color: var(--page-save-button-bg-hover);
    }

    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        ${({ icon }) => icon === 'add' && 'content: "\f055"'};
        ${({ icon }) => icon === 'save' && 'content: "\f0c7"'};
        margin-right: 1rem;
        font-size: 12px;
    }
`

export const IcpMenuButton = styled(ResetButton)`
    font-size: 14px;
    font-weight: 900px;
    --page-save-button-fg: white;
    --page-save-button-bg: rgb(27, 127, 136);
    --page-save-button-bg-hover: rgb(34, 143, 153);
    // --page-save-button-bg-hover: rgb(23, 119, 128);
    
    color: var(--page-save-button-fg);

    background-color: var(--page-save-button-bg);

    padding-bottom: 5px;
    padding-top: 4px;
    padding-left: 23px;
    padding-right: 25px;
    border-radius: 20px;
    margin-right: 28px;

    &:hover {
        background-color: var(--page-save-button-bg-hover);
    }

    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        ${({ icon }) => icon === 'add' && 'content: "\f055"'};
        ${({ icon }) => icon === 'save' && 'content: "\f0c7"'};
        margin-right: 1rem;
        font-size: 16px;
    }
`

export const AddButtonShadow = styled(ResetButton).attrs(({ icp }) => ({
    backgroundColor: icp ? "rgb(27, 127, 136)" : "rgb(105, 152, 218);"
}))`
    --page-save-button-fg: white;
    --page-save-button-bg: rgb(27, 127, 136);
    --page-save-button-bg-hover: rgb(34, 143, 153);
    
    color: var(--page-save-button-fg);

    background-color: ${({ backgroundColor }) => backgroundColor};
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);

    padding-bottom: 5px;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 28px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 300;

    &:hover {
        opacity: 0.85;
    }

    &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f055";
        margin-right: 1rem;
        font-size: 13px;

        font-weight: 900;
        position: relative;
        top: 1px;
    }
`

export const ControllerButton = styled(ResetButton).attrs(({ blue }) => ({
    backgroundColor: blue ? "rgb(47, 100, 171)" : "rgb(35, 38, 43, 0.85)"
}))`
    --page-save-button-fg: white;
    --page-save-button-bg: rgb(27, 127, 136);
    --page-save-button-bg-hover: rgb(34, 143, 153);
    
    color: var(--page-save-button-fg);

    background-color: ${({ backgroundColor }) => backgroundColor};
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);

    padding-bottom: 5px;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 28px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 300;

    &:hover {
        opacity: 0.8;
        /* background-color: rgb(35, 38, 43); */
    }

    &::before {
        margin-right: 1rem;
        font-size: 13px;

        font-weight: 900;
        position: relative;
        top: 1px;
    }
`

export const DeleteHeaderButton = styled(ResetButton)`
    /* font-size: 15px; */
    --page-save-button-fg: white;
    --page-save-button-bg: rgb(27, 127, 136);
    --page-save-button-bg-hover: rgb(23, 119, 128);
    
    color: var(--page-save-button-fg);

    background-color: #922c34;
    /* background-color: var(--page-save-button-bg); */

    padding-bottom: 5px;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 28px;

    font-family: "Montserrat", sans-serif;
    font-size: 13px;
  font-weight: 300;

    &:hover {
        background-color: #881b24;
    }

    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        ${({ icon }) => icon === 'unlock' && 'content: "\f084"'};
        ${({ icon }) => icon === 'add' && 'content: "\f055"'}; 
        ${({ icon }) => icon === 'delete' && 'content: "\f2ed"'};
        margin-right: 1rem;
        font-size: 12px;
    }
`

export const PortfolioButton = styled(ResetButton).attrs(({ activePortfolio }) => ({
    backgroundColor: activePortfolio ? "rgb(47, 100, 171, 0.85)" : "rgb(47, 100, 171, 0.50)"
}))`
    --page-header-button-fg: white;
    --page-header-button-bg: rgb(47, 100, 171, 0.85);
    --page-header-button-bg-hover: rgb(47, 100, 171, 0.85);
    
    color: var(--page-header-button-fg);

    /* background-color: var(--page-header-button-bg); */
    background-color: ${({ backgroundColor }) => backgroundColor};

    padding-bottom: 5px;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 28px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
  font-weight: 300;
    

    &:hover {
        background-color: var(--page-header-button-bg-hover);
    }

    /* &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f07b";
        margin-right: 1rem;
    } */
`

// From task-handling branch
export const EditButton = styled(ResetButton)`
    //padding-left: 3px;
    padding-right: 13px;

    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        content: "\f044";
    }
`

export const DeleteButton = styled(ResetButton)`
    //padding-left: 3px;
    padding-right: 18px;

    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        content: "\f2ed";
    }
`

export const SelectionButton = styled(ResetButton)`
    --selection-button-fg: white;
    --selection-button-bg: rgb(152,152,152);
    --selection-button-hover-bg: rgb(152,152,152);

    --selection-button-bg: ${props => props.color === 'green' ? 'rgb(27, 127, 136)' : null};
    --selection-button-hover-bg: ${props => props.color === 'green' ? 'rgb(23, 119, 128)' : null};

    --selection-button-bg: ${props => props.color === 'orange' ? 'rgb(255, 140, 0)' : null};
    --selection-button-hover-bg: ${props => props.color === 'orange' ? 'rgb(255, 140, 0)' : null};

    --selection-button-bg: ${props => props.color === 'lightblue' ? 'rgb(149, 202, 255, .9)' : null};
    --selection-button-hover-bg: ${props => props.color === 'lightblue' ? 'rgb(149, 202, 255, 1)' : null};

    --selection-button-bg: ${props => props.color === 'blue' ? 'rgb(47, 100, 171, .9)' : null};
    --selection-button-hover-bg: ${props => props.color === 'blue' ? 'rgb(47, 100, 171, 1)' : null};

    color: var(--selection-button-fg);
    background-color: var(--selection-button-bg);
    /* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); */

    padding-bottom: 5px;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin-right: 28px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 300;

    &:hover {
      background-color: var(--selection-button-hover-bg);
      /* opacity: 0.85; */
    }
`
