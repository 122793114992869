import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios"
import { UserContext } from "../../../contexts/UserContext";
import styles from "../styles/MyIcpsToReport.module.css";
import { useHistory } from "react-router-dom";
import ReportIcpModal from "./../../../modal/ReportIcpModal";

export default function MyAssignedControllers() {
    const history = useHistory()
    const { activeUser, setSideBarActiveItem } = useContext(UserContext)
    const [disableAttest] = useState(false)
    const [showReportIcpModal, setShowReportIcpModal] = useState(false);
    const [todoToComment, setTodoToComment] = useState(null)
    const [currentIcpState, setCurrentIcpState] = useState(null)

    const sendAttestRequest = async (data) => {
        await axios.put(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/first-icp-attest",
            data,
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        );
        await refetch()
        return "ok"
    }

    const sendSecondAttestRequest = async (data) => {

        await axios.put(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/second-icp-attest",
            data,
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        );
        setTodoToComment(null)
        setCurrentIcpState(null)
        refetch()
        return "ok"
    }

    const ActiveIcpButton = (icpData) => {
        const userDepartmentId = activeUser.department
        const secondAttestArray = icpData.secondAttests
        let findSecondAttest = secondAttestArray?.find((attest) => { return attest?.departmentId === userDepartmentId })

        if (findSecondAttest?.done === true && icpData.active) {
            return (
                <button disabled={disableAttest} style={{ color: "rgb(30,30,30)", backgroundColor: "rgb(200,200,200)" }} className={styles.attestButton}
                    onClick={() => {
                        let secondAttestArray = icpData.secondAttests
                        let currentSecondAttestData = secondAttestArray?.find((tt) => { return tt.departmentId === activeUser.department })
                        setTodoToComment({ comment: currentSecondAttestData.comment, done: currentSecondAttestData.done })
                        setCurrentIcpState({ icpId: icpData._id, icpName: icpData.name, comment: currentSecondAttestData.comment, update: true })
                        setShowReportIcpModal(true)
                    }}>Avrapporterad av {findSecondAttest.user} </button>
            )
        } else if (icpData.active) {
            return (
                <button disabled={disableAttest} style={{ backgroundColor: "rgb(27, 127, 136)" }} className={styles.attestButton} onClick={() => {
                    let secondAttestArray = icpData.secondAttests
                    let currentSecondAttestData = secondAttestArray?.find((tt) => { return tt.departmentId === activeUser.department })
                    setTodoToComment({ comment: currentSecondAttestData?.comment, done: currentSecondAttestData?.done })
                    setCurrentIcpState({ icpId: icpData._id, icpName: icpData.name, comment: currentSecondAttestData?.comment, update: true })
                    setShowReportIcpModal(true)
                }
                }> Avrapportera</button >
            )
        }
    }

    const WaitingIcpButton = (icpData) => {
        const userDepartmentId = activeUser.department
        const firstAttestArray = icpData.firstAttests
        let findFirstAttest = firstAttestArray?.find((attest) => { return attest?.departmentId === userDepartmentId })

        if (findFirstAttest !== undefined && !icpData.active) {
            return (
                <button disabled={disableAttest} onClick={() => {
                    sendAttestRequest({ icpId: icpData._id, icpName: icpData.name, done: false })
                }} style={{ color: "rgb(30,30,30)", backgroundColor: "rgb(200,200,200)" }} className={styles.attestButton}>Attesterad av {findFirstAttest.user} </button>
            )
        } else if (findFirstAttest === undefined && !icpData.active) {
            return (
                <button disabled={disableAttest} style={{ backgroundColor: "rgb(51, 102, 178)" }} className={styles.attestButton}
                    onClick={() => {
                        sendAttestRequest({ icpId: icpData._id, icpName: icpData.name, done: true })
                    }}>Attestera</button>
            )
        }
    }

    const readIcpData = async () => {
        const res = await axios.post(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/get-icp-to-attest",
            {},
            { headers: { authorization: "Bearer " + activeUser.token, }, }
        );
        return res.data;
    };

    const { data: icpData, status, refetch } = useQuery(["icpDataQuery2"],
        readIcpData, { enabled: true, });

    return (
        <>
            <ReportIcpModal
                passedFunction={(newText) => {
                    sendSecondAttestRequest({ ...currentIcpState, comment: newText.newText, done: newText.markAsDone })
                }}
                todoToComment={todoToComment}
                labelText={"Avrapportera internkontrollplanen"}
                warningText={"Här avrapporterar du slutresultatet för den här internkontrollplanen. När du som objektsägare " +
                    "klarmarkerar och sparar innebär det att internkontrollsarbetet på ditt objekt är klart. Vill du hantera attesteringar " +
                    "för objekt under dig så klicka in på attesteringssidan via internkontrollplanen"}
                optionTextOne={"Spara"}
                optionTextTwo={"Avbryt"}
                checkMarkOption={true}
                colour={"blue"}
                open={showReportIcpModal}
                onClose={() => {
                    setShowReportIcpModal(false);
                }}
            />

            <div className={styles.container}>
                <div className={styles.header}>MINA INTERNKONTROLLPLANER ATT ATTESTERA OCH RAPPORTERA</div>
                <div className={styles.titleRowDiv}>
                    <div className={styles.colDivRiskName}>Internkontrollplan</div>
                    <div className={styles.colDivRiskName}>Sista datum för attest</div>
                    <div className={styles.colDivRiskName}>Status</div>
                </div>
                {status === "success" && icpData.length > 0 ?
                    icpData.sort(function (a, b) {
                        var nameA = a.active
                        var nameB = b.active
                        if (nameA > nameB) { return -1; }
                        if (nameA < nameB) { return 1; }
                        return 0;
                    }).map(icpObject => {
                        return (
                            <div className={styles.rowDiv} key={icpObject._id}>
                                <div className={styles.colDivRiskName} onClick={() => {
                                    setSideBarActiveItem("internalcontrolplans")
                                    history.push("/icp-view", { icpId: icpObject._id, icpName: icpObject.name, waiting: (!icpObject.active) ? true : false })
                                }}>{icpObject.name}</div>
                                <div className={styles.colDivRiskName}>{icpObject.attestDate.slice(0, 10)} </div>
                                <div className={styles.colDivRiskName} >{icpObject.active ? ActiveIcpButton(icpObject) : WaitingIcpButton(icpObject)}</div>
                            </div>
                        )
                    })
                    : <h4 style={{ fontSize: "14px", fontWeight: "400" }}>Det finns inga internkontrollplaner att rapportera.</h4>
                }
            </div>
        </>
    );
}
