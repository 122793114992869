import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
import styles from "./styles/InternalControlPlanPage.module.css";
import { PageHeaderButton, HelpButton } from "../../styles/buttons"
import { PageTitle, PageDelimiter } from '../../styles/pages'
import WarningModal from "./../../modal/WarningModal";
import LoadingModal from "./../../modal/LoadingModal";
import Tooltip from "../../components/tooltip/Tooltip";
import HelpTextLargeModal from "./../../modal/HelpTextLargeModal"
import Loader from 'react-loader-spinner'

import {
  ikpText1, ikpText2, ikpText3, ikpText4, ikpText5, ikpText6, ikpText7, ikpText8, ikpText9, ikpText10, ikpText11, ikpText12, ikpText13,
  ikpText14, ikpText15, ikpText16, ikpText17, ikpText18, ikpText19, ikpText20, ikpText21, ikpText22, ikpText23, ikpText24
} from "./../../constants/helpText"
const helpText =
  // <div style={{ height: "100px", overflow: "scroll", margin: "auto" }}>
  <div style={{ paddingTop: "1200px" }}>
    <div style={{ marginTop: "10px", fontWeight: "600", textAlign: "center", marginBottom: "20px" }}>
      Hjälp
    </div>
    <div style={{ marginTop: "10px" }}>
      {ikpText1}
    </div>
    <div style={{ marginTop: "10px" }}>
      {ikpText2}
    </div>
    <div style={{ marginTop: "10px" }}>
      {ikpText3}
    </div>
    <div style={{ marginTop: "10px" }}>
      {ikpText4}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Att skapa en plan</div>
      {ikpText5}
    </div>

    <div style={{ marginTop: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Initierade planer</div>
      {ikpText6}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Aktiverade planer</div>
      {ikpText7}
      <div style={{ marginTop: "10px" }}>
        {ikpText8}
      </div>
      <div style={{ marginTop: "10px" }}>
        {ikpText9}
      </div>
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Låsta planer</div>
      {ikpText10}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Kontroller</div>
      {ikpText11}
      <div style={{ marginTop: "10px" }}>
        {ikpText12}
      </div>
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Avrapportering</div>
      {ikpText13}
      <div style={{ marginTop: "10px" }}>
        {ikpText14}
      </div>
      <div style={{ marginTop: "10px" }}>
        {ikpText15}
      </div>
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Inne i planen</div>
      {ikpText16}
      <div style={{ marginTop: "10px" }}>
        {ikpText17}
      </div>
      <div style={{ marginTop: "10px" }}>
        {ikpText18}
      </div>
      <div style={{ marginTop: "10px" }}>
        {ikpText19}
      </div>
      <div style={{ marginTop: "10px" }}>
        {ikpText20}
      </div>
      <div style={{ marginTop: "10px" }}>
        {ikpText21}
        {/* </div> */}
      </div>
    </div >
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Analys av planen</div>
      {ikpText22}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Avvikelserapporter & förbättringsförslag</div>
      {ikpText23}
    </div>
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div style={{ fontWeight: "600", marginBottom: "5px" }}>Export</div>
      {ikpText24}
    </div>

    {/* <P style={{ background: "rgb(47, 100, 171, 0.85)", borderRadius: "15px", padding: "15px" }}>
      {homeText3}
    </P> */}
  </div >

// const oldHelpText =
//   <>
//     <P>
//       Internkontrollplanerna används för att välja ut de risker som skall ha extra fokus under
//       en viss tidsperiod, vanligen ett verksamhetsår. Alla risker finns i riskportföljen och
//       det är där man markerar om en risk ska finnas med på interkontrollplanen. När en ny
//       internkontrollplan skapas är den från början klassad som initierad. Det går då en signal
//       till objektsägare att det finns en process för ny internkontrollplan påbörjad. Så länge
//       planen endast är initierad speglar den riskportföljen och alla ändringar som görs i
//       portföljen kommer automatiskt med till internkontrollplanen. Det är via riskportföljen
//       man lägger upp riskhanteringsåtgärder (för det löpande riskarbetet) och kontroller till
//       respektive risk (för uppföljningen i internkontrollplanen). När förarbetet med riskerna
//       till internkontrollplanen är färdigt, aktiverar systemadministratören planen, för att
//       sedan låsa den tills tidsperioden för planen påbörjas. Vanligen fattas det då beslut om
//       planen i ansvarig nämnd eller styrelse.
//     </P>
//     <P>
//       När perioden för planen börjar, låser systemadministratören upp planen. Den är då öppen
//       för att utföra kontroller i och göra olika uppföljningar. Avvikelserapporter och
//       förbättringsförslag kan rapporteras. Kontrollerna som ska utföras syns nu i hemvyn för
//       respektive ansvarig person. Om det finns en frekvens inlagd i kontrollen och den inte
//       utförs inom den frekvensen dyker en röd kalendersymbol upp som visar att kontrollen är
//       försenad.
//     </P>
//     <P>
//       När perioden för internkontrollplanen närmar sig sitt slut ska planen avrapporteras.
//       För varje risk finns en sektion för detta. Risken färgmarkeras för att visa status
//       (grön, gul eller röd) och det finns utrymme för att beskriva utfall och ev. åtgärd.
//       Åtgärden kan skickas till uppgifter om så önskas. Planen i sin helhet avrapporteras
//       också, av respektive objektsägare. Planen markeras därefter som klar, för att visa att
//       det objektet är färdigrapporterat. När arbetet med avrapporteringen är färdigt låses
//       planen av systemadministratören. Inga ändringar kan då göras, men planen kan fortsatt
//       exporteras och analyseras.
//     </P>
//     <P>
//       Flera planer kan vara igång samtidigt och det är vanligt att en initierad plan påbörjas
//       innan den tidigare är färdigrapporterad. På så vis kan man planera för nästkommande år
//       även om det förra inte är stängt ännu.
//     </P>
//   </>

const InternalControlPlanPage = () => {
  const history = useHistory();
  const { sideBar, activeUser } = useContext(UserContext);
  const [loadingSpinner, setLoadingSpinner] = useState(false)
  const [clickedIcp, setClickedIcp] = useState(null)
  const [clickedOrganisation, setClickedOrganisation] = useState(null)
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [fadeInTrigger, setFadeInTrigger] = useState(false)
  const [isMounted, setIsMounted] = useState(true)
  const [showHelpTextModal, setShowHelpTextModal] = useState(false)

  const submitCastRisks = async (planId, orgId) => {
    setClickedIcp(planId)
    setLoadingSpinner(true)

    let res = await axios.post(
      process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/cast-risks",
      { icpId: planId, organisation: orgId },
      { headers: { ContentType: "application/json", authorization: "Bearer " + activeUser.token, }, }
    );
    if (res.status) { setLoadingSpinner(false) }
    refetch()
    mirrorRefetch()
  };

  const readActivePlans = async () => {
    let res = await axios.post(
      process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/aggregateicp", {},
      { headers: { ContentType: "application/json", authorization: "Bearer " + activeUser.token, }, }
    );
    return res.data;
  };

  const readWaitingPlans = async () => {
    let res = await axios.get(
      process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/get-waiting-plans",
      { headers: { ContentType: "application/json", authorization: "Bearer " + activeUser.token, }, }
    )

    if (isMounted) { setFadeInTrigger(true) }

    return res.data;
  };

  const toggleStatusRequest = async (idClicked) => {
    const res = await axios.put(process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/toggle-status",
      { id: idClicked, },
      { headers: { authorization: "Bearer " + activeUser.token, }, });
    refetch()
    mirrorRefetch()
    return res.data;
  };

  const saveChangesToIcp = async () => {
    let payLoad = { active: false, archived: true }

    const res = await axios.put(
      process.env.REACT_APP_IC_SERVICE + "/internalcontrolplan/update-internalcontrolplan",
      { icpId: clickedIcp, payLoad },
      { headers: { authorization: "Bearer " + activeUser.token, }, }
    );
    if (res.status === 200) refetch()
    return res.status;
  };

  const { data: icpList, status, refetch, error, isError } = useQuery(["getaggregatedplans"], readActivePlans);
  const { data: icpWaitingList, status: mirrorStatus, refetch: mirrorRefetch } = useQuery(["getWaitingPlans"], readWaitingPlans, { enabled: !!icpList });

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])
  useEffect(() => {
    if (isError) {
      history.push("/", { from: "location", autoLogOut: true })
    }
  }, [isError, history])

  return (
    <div style={{ paddingBottom: "40px" }} className={`${sideBar ? "large-side-bar" : "small-side-bar"} ${fadeInTrigger ? "fadeIn" : "fadeWaiting"}`}>
      <LoadingModal open={!fadeInTrigger} />
      <HelpTextLargeModal
        title="Hjälp"
        helpText={helpText}
        optionTextOne={"OK"}
        open={showHelpTextModal}
        onClose={() => { setShowHelpTextModal(false); }}
      />
      <WarningModal
        passedFunction={() => submitCastRisks(clickedIcp, clickedOrganisation)}
        warningTitle={"Vill du aktivera internkontrollplanen?"}
        warningText={"Efter aktivering kan arbetet med kontrollerna påbörjas för denna internkontrollsplan. Planen måste innehålla risker samt vara låst för att kunna aktiveras."}
        optionTextOne={"Ja"}
        optionTextTwo={"Nej"}
        colour={"blue"}
        open={showWaitingModal}
        onClose={() => {
          setShowWaitingModal(false);
        }}
      />
      <WarningModal
        passedFunction={() => saveChangesToIcp()}
        warningTitle={"Vill du arkivera internkontrollplanen?"}
        warningText={"Arbetet med internkontrollplanen avslutas och planen arkiveras. Planen är då inte längre redigerbar, men läsbar och går att analysera. Detta steg går inte att ångra. Vill du arkivera internkontrollplanen?"}
        optionTextOne={"Ja"}
        optionTextTwo={"Nej"}
        colour={"red"}
        open={showActiveModal}
        onClose={() => {
          setShowActiveModal(false);
        }}
      />
      <div style={{ marginBottom: "40px" }}>

        <PageTitle>Internkontrollplaner</PageTitle>
        <PageDelimiter />
        {activeUser.admin ?
          <PageHeaderButton icon="add" onClick={() => {
            history.push("/create-internalcontrolplan");
          }}>Skapa ny internkontrollplan</PageHeaderButton>
          : null}

        {/* <PageHeaderButton icon="folder" onClick={() => {
          history.push("/archived-internalcontrolplans");
        }}>Visa arkiverade planer</PageHeaderButton> */}
        <HelpButton onClick={() => setShowHelpTextModal(true)} ></HelpButton>
      </div>
      <div style={{ width: "750px", marginBottom: "30px" }}>
        <div style={{ marginBottom: "10px" }} className="bodyText">
          Här visas initierade och aktiva planer. Arkiverade planer nås genom knappen ovan.
          Se hjälpavsnitten vid frågetecknet för mer detaljerad information om processen och hantering av internkontrollplanerna.
        </div>
        {activeUser.admin ?
          <div style={{ marginBottom: "10px" }} className="bodyText">Genom att låsa planen försvinner möjligheten till redigering och rapportering, men planen är fortfarande läsbar och tillgänglig för analys.
            Planen skickas till nästa fas genom pilen till höger.</div>
          : null}
        <div style={{ marginBottom: "10px" }} className="bodyText">Klicka på respektive plan för att arbeta med den, se tillhörande risker samt analysera planen.</div>
      </div>
      <div className={styles.boxContainer}>
        <div className={styles.waitingBox}></div>
        <div className="bodyText">Väntande: Planen är initierad och inväntar klartecken från respektive avdelning i organisationen.
          I detta skede är planen en spegling av riskportföljen.
        </div>
      </div>
      <div className={styles.boxContainer} style={{ marginBottom: "50px" }}>
        <div className={styles.activeBox}></div>
        <div className="bodyText">Aktiv: Planen är förberedd och arbetet med uppföljning och kontroller
          kan nu börja.
        </div>
      </div>
      {status === "loading" && <div>Laddar data...</div>}
      {isError && <div>Fel vid inladdning av data....</div>}
      {error && <div>Fel vid inladdning av data...</div>}

      {
        mirrorStatus === "success" && (
          icpWaitingList.sort(function (a, b) {
            var nameA = a.period
            var nameB = b.period
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
          }).map((plan) => {
            return (
              <div className={styles.portfolioContainer} key={plan._id} >
                <div className={styles.portfolioIconChild}><i className={"fas fa-briefcase fa-lg"} onClick={() => {
                  if (((!plan.status && activeUser.admin) || plan.status) && !loadingSpinner && !plan.isActivating) {
                    history.push("/icp-view", { icpId: plan._id, icpName: plan.name, waiting: true })
                  }
                }}
                ></i></div>
                <div className={styles.portfolioTextContainer}>
                  <div className={styles.portfolioHeaderChild} onClick={() => {
                    if (((!plan.status && activeUser.admin) || plan.status) && !loadingSpinner && !plan.isActivating) {
                      history.push("/icp-view", { icpId: plan._id, icpName: plan.name, waiting: true })
                    }
                  }}>{plan.name}
                  </div>
                  <div className={styles.portfolioTextChild}>
                    <div className={styles.textItem}>{plan.nrOfRisks} Risker</div>
                    <div className={styles.textItem}>{plan.cosoAverage?.toFixed(1)} Riskmedelvärde</div>
                    <div className={styles.textItem}> {plan.nrOfReviews} kontroller</div>
                  </div>
                  <div className={styles.portfolioTextChild}>
                    {/* <div className={styles.textItem}>0 / 12 Attester</div> */}
                    <div className={styles.textItem}>  Sista datum för attest {plan.attestDate.slice(0, 10)} </div>
                    <div className={styles.textItem}>{plan.firstAttests.length} / {plan.nrOfDepartments} Attester</div>
                    {/* <div className={styles.textItem}> 3 Avvikelserapporter</div> */}
                  </div>
                </div>
                <div className={styles.portfolioIconChildColumn}>
                  {/* <i className={"fas fa-chart-bar"} onClick={() => {
                    history.push("/analysera-portfölj");
                  }}></i> */}
                </div>
                {activeUser.admin ?
                  <>
                    <div className={styles.portfolioIconChildColumn}>
                      {plan.isActivating || loadingSpinner ? null :
                        <Tooltip content={"Aktivera"} direction={"right"}>
                          <i className={"fas fa-share"} onClick={() => {
                            setClickedIcp(plan._id)
                            setClickedOrganisation(plan.organisation)
                            setShowWaitingModal(true)
                          }}
                          ></i>
                        </Tooltip>
                      }
                    </div>
                    {
                      plan.status ?
                        <div className={styles.portfolioIconChildColumn}>
                          {plan.isActivating || loadingSpinner ? null :
                            <Tooltip content={"Lås"} direction={"right"}>
                              <i className={"fas fa-unlock-alt"} onClick={() => {
                                toggleStatusRequest(plan._id)

                              }}></i>
                            </Tooltip>
                          }
                        </div> :
                        <div className={styles.portfolioIconChildColumn}>
                          {plan.isActivating || loadingSpinner ? null :
                            <Tooltip content={"Lås upp"} direction={"right"}>
                              <i style={{ color: "#c55e44" }} className={"fas fa-lock"} onClick={() => {
                                toggleStatusRequest(plan._id)
                              }}></i></Tooltip>
                          }
                        </div>
                    }
                    {(loadingSpinner && plan._id === clickedIcp) || plan.isActivating ?
                      <Loader className={styles.spinner
                      } type="Circles" color="#4e7bb8" height={40} width={40} />
                      : <div style={{ width: "41px" }}></div>
                    }
                  </> :
                  plan.status ?
                    <div className={styles.portfolioIconChildColumn}>

                      <i className={"fas fa-unlock-alt"}></i>

                    </div> :
                    <div className={styles.portfolioIconChildColumn}><i style={{ color: "#c55e44" }} className={"fas fa-lock"}></i></div>
                }
              </div >)
          })
        )
      }

      {
        status === "success" && (
          icpList.sort(function (a, b) {
            var nameA = a.period
            var nameB = b.period
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
          }).filter((icp) => { return icp.active }).map((plan) => {

            return (
              <div className={styles.portfolioContainerActive} key={plan._id} >
                <div className={styles.portfolioIconChild}><i className={"fas fa-briefcase fa-lg"} onClick={() => {
                  if ((!plan.status && activeUser.admin) || plan.status) {
                    history.push("/icp-view", { icpId: plan._id, icpName: plan.name })
                  }
                }}></i></div>
                <div className={styles.portfolioTextContainer}>
                  <div className={styles.portfolioHeaderChild} onClick={() => {
                    if ((!plan.status && activeUser.admin) || plan.status) {
                      history.push("/icp-view", { icpId: plan._id, icpName: plan.name })
                    }
                  }}>{plan.name}
                  </div>
                  <div className={styles.portfolioTextChild}>
                    <div className={styles.textItem}>{plan.nrOfRisks} Risker</div>
                    <div className={styles.textItem}>{plan.cosoAverage?.toFixed(1)} Riskmedelvärde</div>
                    <div className={styles.textItem}> {plan.nr} Avvikelserapporter</div>
                  </div>
                  <div className={styles.portfolioTextChild}>
                    <div className={styles.textItem}>Sista datum för attest {plan.attestDate?.slice(0, 10)} </div>
                    <div className={styles.textItem}>{plan.nrOfAttests} / {plan.nrOfDepartments} Attester</div>
                  </div>
                </div>
                <div className={styles.portfolioIconChildColumn}>
                </div>
                {activeUser.admin ?
                  <>
                    {
                      plan.status ?
                        <div className={styles.portfolioIconChildColumn}><i className={"fas fa-unlock-alt"} onClick={() => {
                          toggleStatusRequest(plan._id)
                        }}></i></div> :
                        <div className={styles.portfolioIconChildColumn}><i style={{ color: "#c55e44" }} className={"fas fa-lock"} onClick={() => {
                          toggleStatusRequest(plan._id)
                        }}></i></div>
                    }
                    {/* <div className={styles.portfolioIconChildColumn}><i className={"fas fa-share"} onClick={() => {
                      setClickedIcp(plan._id)
                      setClickedOrganisation(plan.organisation)
                      setShowActiveModal(true)
                    }}
                    ></i></div> */}
                    {loadingSpinner && plan._id === clickedIcp ?
                      <Loader className={styles.spinner} type="Circles" color="#4e7bb8" height={40} width={40} />
                      : <div style={{ width: "41px" }}></div>
                    }
                  </>
                  :
                  plan.status ?
                    <div className={styles.portfolioIconChildColumn}><i className={"fas fa-unlock-alt"}></i></div> :
                    <div className={styles.portfolioIconChildColumn}><i style={{ color: "#c55e44" }} className={"fas fa-lock"}></i></div>
                }
              </div>)
          })
        )
      }
    </div >
  );
};

export default InternalControlPlanPage;
// 317

